import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
    DUPLICATE_REPORT_REQUEST,
    SAVE_REPORT_REQUEST,
} from '../../../constants/Report';
import {
    getIsReportOwnedBySomeoneElse,
    getSaveReportDisplayStatus,
    getSaveReportError,
} from '../../../selectors/ReportHeader';
import * as ReportMetadata from '../../../selectors/ReportMetadata';
import * as ReportScreenConstants from '../../../constants/ReportScreen';

import Loader from '../../Loader/Loader';
import ReportAttributesInputs from './ReportAttributesInputs';
import ReportActionsModalContent from './ReportActionsModalContent';

const SaveReportModalContent = ({ onClose }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const saveReportDisplayStatus = useSelector(getSaveReportDisplayStatus);
    const saveReportError = useSelector(getSaveReportError);
    const isOwnedBySomeoneElse = useSelector(getIsReportOwnedBySomeoneElse);
    const initialReportTitle = useSelector(ReportMetadata.getReportTitle);
    const initialReportDescription = useSelector(
        ReportMetadata.getReportDescription,
    );
    const [reportTitle, setReportTitle] = useState(initialReportTitle);
    const [reportDescription, setReportDescription] = useState(
        initialReportDescription,
    );

    const requestSaveReport = () => {
        dispatch({
            type: isOwnedBySomeoneElse
                ? DUPLICATE_REPORT_REQUEST
                : SAVE_REPORT_REQUEST,
            payload: {
                reportTitle,
                reportDescription,
            },
        });
    };

    let content;
    let disableSaveAction;
    switch (saveReportDisplayStatus) {
        case ReportScreenConstants.SAVE_REPORT_STATUS_ERROR:
            content = <div>{saveReportError}</div>;
            disableSaveAction = true;
            break;
        case ReportScreenConstants.SAVE_REPORT_STATUS_LOADING:
            content = <Loader />;
            disableSaveAction = true;
            break;
        case ReportScreenConstants.SAVE_REPORT_STATUS_AVAILABLE:
        case ReportScreenConstants.SAVE_REPORT_STATUS_ALREADY_SAVED:
            content = (
                <ReportAttributesInputs
                    reportTitle={reportTitle}
                    onReportTitleChange={setReportTitle}
                    reportDescription={reportDescription}
                    onReportDescriptionChange={setReportDescription}
                />
            );
            disableSaveAction = false;
            break;
        default:
            content = undefined;
            disableSaveAction = true;
    }

    useEffect(() => {
        if (
            saveReportDisplayStatus ===
            ReportScreenConstants.SAVE_REPORT_STATUS_ALREADY_SAVED
        ) {
            onClose();
        }
    }, [onClose, saveReportDisplayStatus]);

    return (
        <ReportActionsModalContent
            header={intl.formatMessage({
                id: 'report.saveReport.saveHeader',
            })}
            onClose={onClose}
            mainButton={{
                action: requestSaveReport,
                text: intl.formatMessage({ id: 'save' }),
                disabled: disableSaveAction || !reportTitle,
            }}
        >
            {content}
        </ReportActionsModalContent>
    );
};

export default SaveReportModalContent;
