import * as SelectTablesPageConstants from '../constants/SelectTablesPage';
import * as TableHelper from '../helpers/Tables';
import { filterFalsy } from '../typescript/helper';
import { DefaultRootState } from 'react-redux';
import * as ReportApp from '../typescript/types';
import * as ReportMetadata from './ReportMetadata';
import { findTableGuidUsingTemplateTableProperties } from './Templates';

export const getDatasetsDisplayStatus = (
    state: DefaultRootState,
    surveyCodes: ReportApp.SurveyCode[],
) => {
    if (
        getDatasetsLoading(state) ||
        getShouldLoadDatasets(state, surveyCodes)
    ) {
        return SelectTablesPageConstants.DATASETS_LOADING;
    } else if (getDatasetsError(state)) {
        return SelectTablesPageConstants.DATASETS_ERROR;
    } else {
        return SelectTablesPageConstants.DATASETS_SELECTION;
    }
};

export const getAreDatasetsLoaded = (state: DefaultRootState) => {
    const surveyCodesUsedInReport = ReportMetadata.getSurveyCodesUsedInReport(
        state,
    );
    return (
        getDatasetsDisplayStatus(state, surveyCodesUsedInReport) ===
        SelectTablesPageConstants.DATASETS_SELECTION
    );
};

export const getDatasetsLoading = (state: DefaultRootState) =>
    state.selectTablesPageStatus.datasets.loading;

export const getDatasetsError = (state: DefaultRootState) =>
    state.selectTablesPageStatus.datasets.error;

export const getShouldLoadDatasets = (
    state: DefaultRootState,
    surveyCodes: ReportApp.SurveyCode[],
) => {
    const { loading, error } = state.selectTablesPageStatus.datasets;
    return (
        !loading &&
        !error &&
        surveyCodes.some((surveyCode) => !getDatasets(state, surveyCode))
    );
};

export const getShouldLoadDatasetsOfSurvey = (
    state: DefaultRootState,
    surveyCode: ReportApp.SurveyCode,
) => {
    const { loading, error } = state.selectTablesPageStatus.datasets;
    const datasets = getDatasets(state, surveyCode);
    return !loading && !error && !datasets;
};

export const getDatasets = (
    state: DefaultRootState,
    surveyCode: ReportApp.SurveyCode,
) => {
    return state.db.datasets[surveyCode];
};

export const getDatasetsForAllSurveys = (
    state: DefaultRootState,
    surveyCodes: ReportApp.SurveyCode[],
) => {
    const datasetsForSurveys = surveyCodes
        .map((surveyCode) => getDatasets(state, surveyCode))
        .filter(filterFalsy);
    const minNumberOfDatasets = Math.min(
        ...datasetsForSurveys.map((datasets) => datasets.length),
    );
    const datasets = [];
    const last = datasetsForSurveys.length - 1;
    for (let i = 0; i < minNumberOfDatasets; i += 1) {
        const { abbreviation, name } = datasetsForSurveys[last][i];
        datasets.push({
            longName: `${abbreviation}:${name}`,
            shortName: abbreviation,
            name,
        });
    }
    return datasets;
};

export const getTableForReportBySurveyCode = (
    state: DefaultRootState,
    pivotTable: ReportApp.Table,
    surveyCodes: ReportApp.SurveyCode[],
) => {
    const tables = getTablesByPivotTableAndSurveyCode(
        state,
        pivotTable,
        surveyCodes,
    );
    const guidBySurveyCode: ReportApp.TableGuidBySurveyCode = {};
    surveyCodes.reduce((memo, surveyCode, index) => {
        memo[surveyCode] = tables[index]?.guid;
        return memo;
    }, guidBySurveyCode);
    return TableHelper.constructTableForReport(
        guidBySurveyCode,
        pivotTable,
        ReportMetadata.isReportCOT(state),
    );
};

export const getTablesForDataset = (
    state: DefaultRootState,
    datasetIndex: number,
    surveyCodes: ReportApp.SurveyCode[],
) => {
    const datasetsForSurveyCode = surveyCodes.map(
        // at this point, we are sure datasets exist
        (surveyCode) => getDatasets(state, surveyCode)![datasetIndex],
    );
    const last = datasetsForSurveyCode.length - 1;
    const pivotTables = datasetsForSurveyCode[last].tableGuids.map(
        // at this point, we are sure some tables exist in dataset
        (tableGuid) => getTableByGuid(state, tableGuid)!,
    );
    const tablesBySurveyCode = [];
    for (const pivotTable of pivotTables) {
        const tableForReportBySurveyCode = getTableForReportBySurveyCode(
            state,
            pivotTable,
            surveyCodes,
        );
        if (tableForReportBySurveyCode.hasFoundTableInEverySurvey) {
            tablesBySurveyCode.push(tableForReportBySurveyCode);
        }
    }
    return tablesBySurveyCode;
};

export const getHasIntermittentTables = (state: DefaultRootState) =>
    !!getIntermittentTables(state)?.length;

export const getShouldSetIntermittentTables = (
    state: DefaultRootState,
    surveyCodes: ReportApp.SurveyCode[],
) => {
    const intermittentTables = getIntermittentTables(state);
    if (intermittentTables == null) {
        return true;
    }
    if (intermittentTables.length === 0) {
        return false;
    }
    const surveyCodesInTable = Object.keys(
        intermittentTables[0].guidBySurveyCode,
    );
    return (
        surveyCodesInTable.length !== surveyCodes.length ||
        !surveyCodesInTable.every((surveyCode) =>
            surveyCodes.includes(surveyCode),
        )
    );
};

export const getIntermittentTables = (state: DefaultRootState) =>
    state.selectTablesPageStatus.intermittentTables;

export const getNormalizedTables = (state: DefaultRootState) => state.db.tables;

export const getTableByGuid = (
    state: DefaultRootState,
    guid: ReportApp.TableGuid,
) => getNormalizedTables(state)[guid];

/**
 * Returning the flag weather the change on intermittentTables is currently in progress or not.
 * This flag is important to recognize cases when should we enable update and save as option
 * for the current template.
 */
export const isCurrentTemplateEditInProgress = (state: DefaultRootState) =>
    state.selectTablesPageStatus.intermittentTablesEditingInProgress;

function getTablesByPivotTableAndSurveyCode(
    state: DefaultRootState,
    pivotTable: ReportApp.Table,
    surveyCodes: string[],
) {
    return surveyCodes
        .map((surveyCode) => {
            const tableGuidByTableName =
                state.tableGuidBySurveyCodeTableName[surveyCode];
            if (!tableGuidByTableName) {
                return null;
            }
            const tableCode = TableHelper.getTableCode(pivotTable);
            return tableGuidByTableName[tableCode];
        })
        .map((tableGuids) => {
            if (!tableGuids) {
                return null;
            }
            const tables = tableGuids
                .map((tableGuid) => getTableByGuid(state, tableGuid))
                .filter(filterFalsy);
            if (tables.length !== tableGuids.length) {
                // This shouldn't happen, because state.tableGuidBySurveyCodeTableName
                // and state.db.tables is stored in the same time
                throw new Error('Table data is not stored correctly');
            }
            if (tables.length === 1) {
                return tables[0];
            }
            return (
                tables.find(
                    (table) =>
                        table.datasetAbbreviation ===
                        pivotTable.datasetAbbreviation,
                ) ?? null
            );
        });
}

export const prepareNormalizedTablesFromUrlParams = (
    state: DefaultRootState,
    templateTables: ReportApp.TemplateTable[] | undefined,
    surveyCode: ReportApp.SurveyCode | undefined,
) => {
    if (
        !surveyCode ||
        !templateTables ||
        !getDatasets(state, surveyCode) ||
        !Object.keys(getNormalizedTables(state)).length ||
        !templateTables.length
    ) {
        return [];
    }

    return templateTables
        .map((table) => {
            const tableGuid = findTableGuidUsingTemplateTableProperties(
                state,
                table,
                surveyCode,
            );
            if (!tableGuid) {
                return null;
            }
            const normalizedTable = getTableByGuid(state, tableGuid);
            if (!normalizedTable) {
                return null;
            }
            const tableForReportBySurveyCode = getTableForReportBySurveyCode(
                state,
                normalizedTable,
                [surveyCode],
            );
            if (tableForReportBySurveyCode.hasFoundTableInEverySurvey) {
                return tableForReportBySurveyCode;
            }
            return null;
        })
        .filter(filterFalsy);
};
