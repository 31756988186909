import React, { useEffect } from 'react';
import { TRACTIQ_PALETTE } from '../../typescript/enums';

export default function AppTheme() {
    useEffect(() => {
        Object.keys(TRACTIQ_PALETTE).forEach((key) => {
            document.documentElement.style.setProperty(
                key,
                TRACTIQ_PALETTE[key],
            );
        });
    }, []);

    return <></>;
}
