import React from 'react';
import { useIntl } from 'react-intl';
import './Avatar.scss';

const Avatar = ({ avatarUrl }) => {
    const intl = useIntl();
    return (
        <img
            className="small-avatar-image"
            src={avatarUrl}
            alt={intl.formatMessage({
                id: 'header.avatar.userAvatar',
            })}
            title={intl.formatMessage({
                id: 'header.avatar.myAccount',
            })}
        />
    );
};

export default Avatar;
