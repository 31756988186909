import { TemplateActions } from '../typescript/actions/TemplateActions';
import { Template } from '../typescript/types';
import {
    ADD_TABLE_TEMPLATE_TO_STORE,
    ADD_TABLE_TEMPLATES_TO_STORE,
} from '../constants/Templates';

const INITIAL_STATE: Template[] = [];

export default (state = INITIAL_STATE, action: TemplateActions) => {
    switch (action.type) {
        case ADD_TABLE_TEMPLATES_TO_STORE:
            const templates = [...state, ...action.payload];
            const templateIds = templates.map((template) => template.id);
            const uniqueTemplateIds = Array.from(new Set(templateIds));

            return uniqueTemplateIds.map((templateId: number) =>
                templates.find((template) => template.id === templateId),
            );
        case ADD_TABLE_TEMPLATE_TO_STORE:
            const { id } = action.payload;
            const allTemplatesExceptCurrent = [...state].filter(
                (template) => template.id !== id,
            );
            return [...allTemplatesExceptCurrent, action.payload];
        default:
            return state;
    }
};
