import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

/**
 * @typedef OldErrorResponse
 * @property {string} Message
 *
 * @typedef OmniErrorResponse
 * @property {{error: string}} message
 *
 * @param {string} actionType Name of the action that will bi dispatched
 * @param {Response|Error} errorResponse
 */
export function* handleError(actionType, errorResponse) {
    /** @type {string} */
    let message;
    if (errorResponse instanceof Response) {
        /** @type {OldErrorResponse|OmniErrorResponse} */
        let response;
        try {
            response = yield errorResponse.json();
        } catch {
            response = {};
        }
        message =
            response.Message ||
            response?.message?.error ||
            response?.error ||
            'Some error happened. Please try again';
    } else {
        message = errorResponse.message;
    }
    Sentry.captureException(message);
    return yield put({
        type: actionType,
        payload: message,
    });
}
