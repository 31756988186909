import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';

import GeoTypeSelectionItem from './GeoTypes/GeoTypeSelectionItem';

import './GeographiesSelection.scss';

const SelectedGeographiesList = ({
    selectedGeographies,
    selectedGeographiesCount,
}) => {
    const intl = useIntl();
    return (
        <Fragment>
            {Object.keys(selectedGeographies).map((sumLev) => {
                const listAriaLabel = intl.formatMessage(
                    { id: 'geographiesPage.selection.listDescription' },
                    {
                        count: selectedGeographiesCount,
                        label: sumLev,
                    },
                );
                return (
                    <Fragment key={sumLev}>
                        <div className="selection-separator">{sumLev}</div>
                        <ul
                            key={sumLev}
                            className="selection-group"
                            aria-label={listAriaLabel}
                        >
                            {selectedGeographies[sumLev].map((selected) => (
                                <GeoTypeSelectionItem
                                    key={selected}
                                    identifier={selected}
                                />
                            ))}
                        </ul>
                    </Fragment>
                );
            })}
        </Fragment>
    );
};

export default SelectedGeographiesList;
