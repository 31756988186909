import React from 'react';
import { useSelector } from 'react-redux';

import * as NewSurvey from '../../../../selectors/NewSurvey';

import GeoInfoForYear from './GeoInfoForYear';

const GeoInfo = () => {
    const geoAnalysisByGeoGroupAndYear = useSelector(
        NewSurvey.getGeoAnalyseByGeoGroupAndYear,
    );

    const elements = Object.keys(geoAnalysisByGeoGroupAndYear).map(
        (geoGroupIndex) => {
            const geoGroupAnalysis =
                geoAnalysisByGeoGroupAndYear[+geoGroupIndex];
            const byYearElements = Object.keys(
                geoGroupAnalysis.byYear,
            ).map((year) => (
                <GeoInfoForYear
                    key={year}
                    year={+year}
                    analysis={geoGroupAnalysis.byYear[+year]}
                />
            ));
            return (
                <React.Fragment key={geoGroupIndex}>
                    <h6 className="change-survey-collapse-info-section-heading">
                        {geoGroupAnalysis.name}
                    </h6>
                    {byYearElements}
                </React.Fragment>
            );
        },
    );

    return <>{elements}</>;
};

export default GeoInfo;
