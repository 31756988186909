import * as TableConstants from '../constants/Table';
import { ReportAppActions } from '../typescript/actions/actions';
import { SelectTablePageStatus } from '../typescript/types';
import * as TemplateConstants from '../constants/Templates';

const INITIAL_STATE: SelectTablePageStatus = {
    datasets: {
        loading: false,
        error: null,
    },
    premadeReports: {
        loading: false,
        error: null,
    },
    tableTemplate: {
        loading: false,
        error: null,
    },
    intermittentTables: null,
    intermittentTablesEditingInProgress: false,
};

export default (state = INITIAL_STATE, action: ReportAppActions) => {
    switch (action.type) {
        case TableConstants.FETCH_DATASETS_REQUEST:
            return {
                ...state,
                datasets: {
                    loading: true,
                    error: null,
                },
            };
        case TableConstants.FETCH_DATASETS_SUCCESS:
            return {
                ...state,
                datasets: {
                    loading: false,
                    error: null,
                },
            };
        case TableConstants.FETCH_DATASETS_FAILURE:
            return {
                ...state,
                datasets: {
                    loading: false,
                    error: action.payload.error.message,
                },
            };
        case TableConstants.SET_INTERMITTENT_TABLES_SELECTION:
            // Initial load
            if (!state.intermittentTables || !state.intermittentTables.length) {
                return {
                    ...state,
                    intermittentTables: action.payload,
                    intermittentTablesEditingInProgress: false,
                };
            }
            // Reset
            if (!action.payload || !action.payload.length) {
                return {
                    ...state,
                    intermittentTables: null,
                    intermittentTablesEditingInProgress: false,
                };
            }
            // On change
            // Case 1: array length is changed
            if (state.intermittentTables.length !== action.payload.length) {
                return {
                    ...state,
                    intermittentTables: [...action.payload],
                    intermittentTablesEditingInProgress: true,
                };
            } else {
                // Case 2: arrays lengths are the same, compare the elements
                const existingSet = state.intermittentTables
                    .map((table) => table.composeGuid)
                    .sort()
                    .join('');
                const newSet = action.payload
                    .map((table) => table.composeGuid)
                    .sort()
                    .join('');
                if (existingSet !== newSet) {
                    return {
                        ...state,
                        intermittentTables: [...action.payload],
                        intermittentTablesEditingInProgress: true,
                    };
                }
            }
            return state;
        case TableConstants.CLEAR_TEMPORARY_TABLES_SELECTION: {
            return {
                ...state,
                tableTemplate: {
                    ...state.tableTemplate,
                },
                intermittentTables: null,
                intermittentTablesEditingInProgress: false,
            };
        }
        case TableConstants.REMOVE_TABLE_FROM_TEMPORARY_SELECTION: {
            const index = action.payload;
            return {
                ...state,
                tableTemplate: {
                    ...state.tableTemplate,
                },
                intermittentTables: state.intermittentTables?.filter(
                    (_, i: number) => i !== index,
                ),
                intermittentTablesEditingInProgress: true,
            };
        }
        case TableConstants.FETCH_PREMADE_REPORTS_REQUEST:
            return {
                ...state,
                premadeReports: {
                    loading: true,
                    error: null,
                },
            };
        case TableConstants.FETCH_PREMADE_REPORTS_FAILURE:
            return {
                ...state,
                premadeReports: {
                    loading: false,
                    error: action.payload.error,
                },
            };
        case TableConstants.FETCH_PREMADE_REPORTS_SUCCESS:
            return {
                ...state,
                premadeReports: {
                    loading: false,
                    error: null,
                },
            };
        case TemplateConstants.FETCH_TABLE_TEMPLATE_REQUEST:
            return {
                ...state,
                tableTemplate: {
                    loading: true,
                    error: null,
                },
            };
        case TemplateConstants.FETCH_TABLE_TEMPLATE_FAILURE:
            return {
                ...state,
                tableTemplate: {
                    loading: false,
                    error: action.payload,
                },
            };
        case TemplateConstants.FETCH_TABLE_TEMPLATE_SUCCESS:
            return {
                ...state,
                tableTemplate: {
                    loading: false,
                    error: null,
                },
            };
        case TemplateConstants.SAVE_TEMPLATE_SUCCESS:
        case TemplateConstants.EDIT_TEMPLATE_SUCCESS:
            return {
                ...state,
                intermittentTablesEditingInProgress: false,
            };
        default:
            return state;
    }
};
