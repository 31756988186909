import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    getIsEditingTemplateFromDashboard,
    getSaveTemplatePayload,
} from '../../../../selectors/Templates';
import { isCurrentTemplateEditInProgress } from '../../../../selectors/Table';
import { SAVE_TEMPLATE_REQUEST } from '../../../../constants/Templates';
import { ReportAppDispatch } from '../../../../typescript/actions/actions';

import SaveTemplateContent from './SaveTemplateContent';
import SaveTemplateHeader from './SaveTemplateHeader';
import SaveTemplateFooter from './SaveTemplateFooter';

import './SaveTemplate.scss';

type Props = {
    onClose: () => void;
};

const SaveTemplate = ({ onClose }: Props) => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const payload = useSelector(getSaveTemplatePayload);
    const isEditingTemplateFromDashboard = useSelector(
        getIsEditingTemplateFromDashboard,
    );
    const currentTemplateEditInProgress = useSelector(
        isCurrentTemplateEditInProgress,
    );
    const [templateTitle, setTemplateTitle] = useState('');

    if (!payload) {
        return null;
    }

    const requestSaveTemplate = () => {
        dispatch({
            type: SAVE_TEMPLATE_REQUEST,
            payload: {
                ...payload,
                title: templateTitle,
            },
        });
        if (isEditingTemplateFromDashboard && currentTemplateEditInProgress) {
            onClose();
        }
    };

    return (
        <div>
            <SaveTemplateHeader onClose={onClose} />
            <SaveTemplateContent
                templateTitle={templateTitle}
                setTemplateTitle={setTemplateTitle}
                tablesCount={payload.tables.length}
            />
            <SaveTemplateFooter
                requestSaveTemplate={requestSaveTemplate}
                templateTitle={templateTitle}
                onClose={onClose}
            />
        </div>
    );
};

export default SaveTemplate;
