import * as Table from '../constants/Table';
import * as ReportApp from '../typescript/types';
import * as TableActions from '../typescript/actions/TablesActions';

/**
 * Dispatch Table.FETCH_DATASETS_REQUEST
 */
export const getPreparedTablesForSelectionAction = (
    selectedPremadeReportIds: number[],
    premadeReportsAndTemplates: ReportApp.PremadeReportWithTables[],
    intermittentTables: ReportApp.TableForReportBySurveyCode[],
): TableActions.SetIntermittentTablesSelection => ({
    type: Table.SET_INTERMITTENT_TABLES_SELECTION,
    payload: selectedPremadeReportIds
        .map((elementId: number) => {
            const premadeReport = premadeReportsAndTemplates.find(
                (report) => report.id === elementId,
            );
            if (!premadeReport) {
                throw new Error(
                    `Neither premade report, nor template with id ${elementId} was found`,
                );
            }
            return premadeReport;
        })
        .flatMap((report) => report.tables)
        .concat(...intermittentTables)
        // unique
        .filter(
            (table, index, allTables) =>
                allTables.findIndex(
                    (t) => t.composeGuid === table.composeGuid,
                ) === index,
        ),
});

export const getFetchDatasetsAction = (
    surveyCodes: ReportApp.SurveyCode[],
): TableActions.FetchDatasets => ({
    type: Table.FETCH_DATASETS_REQUEST,
    payload: surveyCodes,
});
