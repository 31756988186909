import React from 'react';

import { useSelector } from 'react-redux';
import * as ReportMetadata from '../../../../selectors/ReportMetadata';

import NoSurveyModification from '../NoSurveyModification/NoSurveyModification';
import ModifySurvey from './ModifySurvey';

const ModifySurveys = () => {
    const yearsBySurveyGroupId = useSelector(
        ReportMetadata.getYearsUsedInReportBySurveyGroupId,
    );

    const elements = Object.keys(yearsBySurveyGroupId).map((surveyGroupId) => {
        const hasCOT = yearsBySurveyGroupId[+surveyGroupId].years.length > 1;
        return hasCOT ? (
            <ModifySurvey
                key={surveyGroupId}
                surveyGroupId={surveyGroupId}
                yearsBySurveyGroupId={yearsBySurveyGroupId}
            />
        ) : (
            <NoSurveyModification key={surveyGroupId} />
        );
    });

    return <div className="section">{elements}</div>;
};

export default ModifySurveys;
