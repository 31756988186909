import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAreDatasetsLoaded } from '../../../selectors/Table';
import * as ReportMetadata from '../../../selectors/ReportMetadata';
import { ReportAppDispatch } from '../../../typescript/actions/actions';

import DownloadExcelButton from './DownloadExcelButton';

import * as ReportConstants from '../../../constants/Report';

const DownloadExcelButtons = () => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const activeButton = useSelector(getAreDatasetsLoaded);
    const shouldAllowExcelTranspose = useSelector(
        ReportMetadata.getShouldAllowExcelTranspose,
    );

    return (
        <>
            {shouldAllowExcelTranspose && (
                <DownloadExcelButton
                    messageId={`report.sidebar.downloadExcel.transposeButton.${
                        activeButton ? 'active' : 'inactive'
                    }`}
                    onClick={() =>
                        dispatch({
                            type: ReportConstants.DOWNLOAD_EXCEL_REQUEST,
                            payload: { isTranspose: true },
                        })
                    }
                />
            )}
            <DownloadExcelButton
                messageId={`report.sidebar.downloadExcel.button.${
                    activeButton ? 'active' : 'inactive'
                }`}
                onClick={() =>
                    dispatch({
                        type: ReportConstants.DOWNLOAD_EXCEL_REQUEST,
                        payload: { isTranspose: false },
                    })
                }
            />
        </>
    );
};

export default DownloadExcelButtons;
