import { ItemsPerPageListItem } from '../typescript/types';

export const CREATE_REPORT_REQUEST = '@@report/CREATE_REPORT_REQUEST';
export const CREATE_REPORT_SUCCESS = '@@report/CREATE_REPORT_SUCCESS';
export const CREATE_REPORT_FAILURE = '@@report/CREATE_REPORT_FAILURE';

export const FETCH_REPORT_REQUEST = '@@report/FETCH_REPORT_REQUEST';
export const FETCH_REPORT_SUCCESS = '@@report/FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_FAILURE = '@@report/FETCH_REPORT_FAILURE';
export const FETCH_REPORT_RESET = '@@report/FETCH_REPORT_RESET';

export const SET_NORMALIZED_REPORT_DATA = '@@report/SET_NORMALIZED_REPORT_DATA';
export const SET_REPORT_TOTALS_DATA = '@@report/SET_REPORT_TOTALS_DATA';

export const SET_SAVED_REPORT_INFO = '@@report/SET_SAVED_REPORT_INFO';
export const SET_DEFINITION = '@@report/SET_DEFINITION';
export const SET_GEOGRAPHIES_HEADER = '@@report/SET_GEOGRAPHIES_HEADER';
export const SET_METADATA_BY_OLD_REPORT_ID =
    '@@report/SET_METADATA_BY_OLD_REPORT_ID';
export const SET_TOTAL_NUMBER_OF_GEOGRAPHIES =
    '@@report/SET_TOTAL_NUMBER_OF_GEOGRAPHIES';
export const CLEAR_MODIFY_STATE = '@@report/CLEAR_MODIFY_STATE';

export const RESET_REPORT_METADATA = '@@report/RESET_REPORT_METADATA';
export const RESET_REPORT_DATA = '@@report/RESET_REPORT_DATA';
export const RESET_REPORT_VIEW = '@@report/RESET_REPORT_VIEW';
export const RESET_REPORT_TOTALS_DATA = '@@report/RESET_REPORT_TOTALS_DATA';

export const SET_FIPS_CODES_FOR_OLD_REPORT_ID =
    '@@report/SET_FIPS_CODES_FOR_OLD_REPORT_ID';
export const RESET_FIPS_CODES = '@@report/RESET_FIPS_CODES';

export const SET_REPORT_ID_REQUEST = '@@report/SET_REPORT_ID_REQUEST';

export const SAVE_REPORT_REQUEST = '@@report/SAVE_REPORT_REQUEST';
export const SAVE_REPORT_SUCCESS = '@@report/SAVE_REPORT_SUCCESS';
export const SAVE_REPORT_FAILURE = '@@report/SAVE_REPORT_FAILURE';

export const DELETE_REPORT_REQUEST = '@@report/DELETE_REPORT_REQUEST';
export const DELETE_REPORT_SUCCESS = '@@report/DELETE_REPORT_SUCCESS';
export const DELETE_REPORT_FAILURE = '@@report/DELETE_REPORT_FAILURE';

export const UPDATE_REPORT_REQUEST = '@@report/UPDATE_REPORT_REQUEST';
export const UPDATE_REPORT_SUCCESS = '@@report/UPDATE_REPORT_SUCCESS';
export const UPDATE_REPORT_FAILURE = '@@report/UPDATE_REPORT_FAILURE';

export const DUPLICATE_REPORT_REQUEST = '@@report/DUPLICATE_REPORT_REQUEST';
export const DUPLICATE_REPORT_SUCCESS = '@@report/DUPLICATE_REPORT_SUCCESS';
export const DUPLICATE_REPORT_FAILURE = '@@report/DUPLICATE_REPORT_FAILURE';

export const SET_DOLLAR_YEAR = '@@report/SET_DOLLAR_YEAR';

export const SET_TABLE_PAGE_REQUEST = '@@report/SET_TABLE_PAGE_REQUEST';
export const SET_TABLES_PER_PAGE_REQUEST =
    '@@report/SET_TABLES_PER_PAGE_REQUEST';
export const SET_GEO_ITEM_PAGE_REQUEST = '@@report/SET_GEO_ITEM_PAGE_REQUEST';
export const SET_GEO_ITEMS_PER_PAGE_REQUEST =
    '@@report/SET_GEO_ITEMS_PER_PAGE_REQUEST';
export const SET_SELECTED_DOLLAR_YEAR_ADJUSTMENT_REQUEST =
    '@@report/SET_SELECTED_DOLLAR_YEAR_ADJUSTMENT_REQUEST';

export const SET_AGGREGATION_TYPE_REQUEST =
    '@@report/SET_AGGREGATION_TYPE_REQUEST';
export const SET_AGGREGATION_TYPE = '@@report/SET_AGGREGATION_TYPE';

export const DOWNLOAD_EXCEL_REQUEST = '@@report/DOWNLOAD_EXCEL_REQUEST';
export const DOWNLOAD_EXCEL_SUCCESS = '@@report/DOWNLOAD_EXCEL_SUCCESS';
export const DOWNLOAD_EXCEL_FAILURE = '@@report/DOWNLOAD_EXCEL_FAILURE';

export const DOWNLOAD_PDF_REQUEST = '@@report/DOWNLOAD_PDF_REQUEST';
export const DOWNLOAD_PDF_SUCCESS = '@@report/DOWNLOAD_PDF_SUCCESS';
export const DOWNLOAD_PDF_FAILURE = '@@report/DOWNLOAD_PDF_FAILURE';

export const TOGGLE_DOWNLOAD_EXCEL_OPTION =
    '@@report/TOGGLE_DOWNLOAD_EXCEL_OPTION';
export const TOGGLE_DOWNLOAD_CSV_OPTION = '@@report/TOGGLE_DOWNLOAD_CSV_OPTION';

// Max number of tables per page is in direct corelation with the length of
// URL for the get data. For every table, we send table guid. Size of table
// guid is 36 characters. Table guid is separated with pipe (`|`). With that in
// mind, here is the calculation of max number of tables that can be shown:
// URL base length (https://dev.socialexplorer.com/stagingreportsservice/SeWebService.asmx/GetDataForReport) ~100 characters)
// URL query params (?sReportId="R12522903"&iGeoItemStartIndex=1&iGeoItemSlice=1&aggregationType=2&nAdjustDollarValuesToYear=0&sTableGuids=) ~120 characters
// 2048 - (100 + 120) = 1828 characters left for table guids
// 36 (table guid length) + 3 (encoded pipe delimiter) = 39 characters used for one table guid
// ⎣1828 / 39⎦  = 46 table guids.
// To be totally safe, we decided to go with 30 tables as max, even though this
// could probably be 45
export const TABLES_PER_PAGE_LIST: ItemsPerPageListItem[] = [
    { value: 1, isDefault: false },
    { value: 5, isDefault: false },
    { value: 10, isDefault: false },
    { value: 20, isDefault: false },
    { value: 30, isDefault: true },
];

export const GEOGRAPHIES_PER_PAGE_LIST: ItemsPerPageListItem[] = [
    { value: 1, isDefault: false },
    { value: 2, isDefault: false },
    { value: 3, isDefault: false },
    { value: 4, isDefault: false },
    { value: 5, isDefault: false },
    { value: 6, isDefault: false },
    { value: 7, isDefault: false },
    { value: 8, isDefault: false },
    { value: 9, isDefault: false },
    { value: 10, isDefault: false },
    { value: 15, isDefault: false },
    { value: 20, isDefault: false },
    { value: 30, isDefault: true },
];

export const GEO_COLUMN_NAME = 'geo_qname';
export const GEO_FIPS = 'geo_fips';

export const FETCH_CPI_REQUEST = '@@report/FETCH_CPI_REQUEST';
export const FETCH_CPI_SUCCESS = '@@report/FETCH_CPI_SUCCESS';
export const FETCH_CPI_FAILURE = '@@report/FETCH_CPI_FAILURE';

export const MAX_COLUMNS_ALLOWED_FOR_EXCEL_DOWNLOAD = 6000;
export const MAX_COLUMNS_ALLOWED_FOR_PDF_DOWNLOAD = 200;

export const PREMADE_REPORT_ITEM_TYPE = {
    PREMADE_REPORT: -1,
    TEMPLATE: -2,
};

// The name for Total column for aggregated values of all summary levels will be retrieved from
// .net server under the following name
export const ALL_SLS_TOTAL_COLUMN_NAME = 'total';
