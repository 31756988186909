import React, { useRef, useState, useEffect } from 'react';

import TableGeoHeader from './TableGeoHeader';
import TableGeoColumnNames from './TableGeoColumnNames';
import TableSideBySideHeader from './TableSideBySideHeader';

import './TableHeader.scss';

const REPORT_TABLE_MARGIN_LEFT = 16;

const TableHeader = () => {
    const firstCell = useRef<HTMLTableCellElement>(null);
    const secondCell = useRef<HTMLTableCellElement>(null);
    const [cellWidth, setCellWidth] = useState(0);
    const [firstCellHeight, setFirstCellHeight] = useState(0);
    const [secondCellHeight, setSecondCellHeight] = useState(0);

    // after component is mounted on DOM, get width and height of the first
    // (sticky) column. Use that value to position text inside other columns,
    // so that text is visible even when you scroll right
    useEffect(() => {
        if (firstCell.current) {
            const { offsetWidth, offsetHeight } = firstCell.current;
            setFirstCellHeight(offsetHeight);
            setCellWidth(offsetWidth);
        }
        if (secondCell.current) {
            const { offsetWidth, offsetHeight } = secondCell.current;
            setSecondCellHeight(offsetHeight);
            setCellWidth(offsetWidth);
        }
    }, []);

    return (
        <>
            <TableGeoColumnNames
                ref={firstCell}
                leftOffset={cellWidth + REPORT_TABLE_MARGIN_LEFT}
            />
            <TableGeoHeader
                ref={secondCell}
                topOffset={firstCellHeight}
                leftOffset={cellWidth + REPORT_TABLE_MARGIN_LEFT}
            />
            <TableSideBySideHeader
                topOffset={firstCellHeight + secondCellHeight}
                leftOffset={cellWidth + REPORT_TABLE_MARGIN_LEFT}
            />
        </>
    );
};

export default TableHeader;
