import React, { useRef, useState } from 'react';

import { useTabsKeyboardNavigation } from '../../customHooks/useTabsKeyboardNavigation';

import InfoIconTab from './SidebarIconTabs/InfoIconTab';
import DownloadCsvIconTab from './SidebarIconTabs/DownloadCsvIconTab';
import ShareIconTab from './SidebarIconTabs/ShareIconTab';
import DownloadExcelIconTab from './SidebarIconTabs/DownloadExcelIconTab';
import ModifyReportIconTab from './SidebarIconTabs/ModifyReportIconTab';
import DownloadPdfTab from './SidebarIconTabs/DownloadPdfTab';
import SidebarJumpButtons from './SidebarJumpButtons';

import * as SidebarConstants from '../../constants/Sidebar';

const SidebarTabsContainer = ({ activateTab, activatedTabId }) => {
    const tabList = useRef();
    const [focusedTabId, setFocusedTabId] = useState(
        SidebarConstants.MODIFY_REPORT,
    );

    useTabsKeyboardNavigation({
        ref: tabList,
        focusedTabId,
        activateTab: (tab) => {
            if (activatedTabId !== SidebarConstants.EMPTY) {
                activateTab(tab);
            }
            setFocusedTabId(tab);
        },
        tabsOrder: SidebarConstants.SIDEBAR_TABS_ORDER,
    });

    const activateAndFocusTab = (tabId) => {
        activateTab(tabId);
        setFocusedTabId(tabId);
    };

    return (
        <div className="sidebar-icons" role="tablist" ref={tabList}>
            <div className="sidebar-icons-top-divider" />
            <ModifyReportIconTab
                activateTab={activateAndFocusTab}
                activatedTabId={activatedTabId}
                focusedTabId={focusedTabId}
            />
            <DownloadExcelIconTab
                activateTab={activateAndFocusTab}
                activatedTabId={activatedTabId}
                focusedTabId={focusedTabId}
            />
            <DownloadCsvIconTab
                activateTab={activateAndFocusTab}
                activatedTabId={activatedTabId}
                focusedTabId={focusedTabId}
            />
            <DownloadPdfTab
                activatedTabId={activatedTabId}
                focusedTabId={focusedTabId}
                activateTab={activateAndFocusTab}
            />
            <ShareIconTab
                activateTab={activateAndFocusTab}
                activatedTabId={activatedTabId}
                focusedTabId={focusedTabId}
            />
            <InfoIconTab
                activateTab={activateAndFocusTab}
                activatedTabId={activatedTabId}
                focusedTabId={focusedTabId}
            />
            <SidebarJumpButtons activatedTabId={activatedTabId} />
        </div>
    );
};

export default SidebarTabsContainer;
