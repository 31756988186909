import React from 'react';
import { useSelector } from 'react-redux';

import { formatValue } from '../../../../helpers/ValueFormatter';
import * as ReportData from '../../../../selectors/ReportData';
import { FipsCodeWithSummaryLevel } from '../../../../typescript/types';

import SingleTableVariableCell from './SingleTableVariableCell';

import './SingleTableVariableValuePair.scss';

type Props = {
    oldReportId?: string;
    tableGuid?: string;
    variableIndex: number;
    geoFips: FipsCodeWithSummaryLevel;
    maxColSpan: number;
};

const SingleTableVariableValuePair = ({
    oldReportId,
    tableGuid,
    variableIndex,
    geoFips,
    maxColSpan,
}: Props) => {
    const values = useSelector((state) =>
        ReportData.getValueAndMetadataPairs(
            state,
            oldReportId,
            tableGuid,
            variableIndex,
            geoFips,
        ),
    );
    const elements: React.ReactNode[] = [];

    values.forEach((val, index) => {
        const { percent, percentFormat, value, valueFormat, hasAsterisk } = val;
        elements.push(
            <SingleTableVariableCell
                key={`${geoFips}-${tableGuid}-${index}`}
                valueOrCallback={value}
                format={valueFormat}
                hasAsterisk={hasAsterisk}
            />,
        );
        if (percent !== undefined) {
            elements.push(
                <td
                    key={`${geoFips}-${tableGuid}-p${index}`}
                    className="variable-percent"
                >
                    {formatValue(percent, percentFormat)}
                </td>,
            );
        }
    });

    const numberOfEmptyCells = maxColSpan - elements.length;
    if (numberOfEmptyCells > 0) {
        elements.push(
            <td
                colSpan={numberOfEmptyCells}
                key="rest"
                className="variable-value"
            ></td>,
        );
    }
    return <>{elements}</>;
};

export default SingleTableVariableValuePair;
