import React from 'react';
import { useSelector } from 'react-redux';

import * as ReportMetadata from '../../../selectors/ReportMetadata';
import { getTablesNavigationStatus } from '../../../selectors/ReportNavigator';

import SingleTable from './SingleTable/SingleTable';

const TableBody = () => {
    const tablesForReport = useSelector(ReportMetadata.getTablesUsedInReport);
    const [tablePage, tablesPerPage] = useSelector(getTablesNavigationStatus);
    const start = (tablePage - 1) * tablesPerPage;
    const end = start + tablesPerPage;
    const elements = tablesForReport
        .slice(start, end)
        .map((tableForReport, tableIndex) => (
            <SingleTable
                key={tableForReport.code}
                table={tableForReport}
                displayNavigationButtons={tableIndex === 0}
            />
        ));
    return <>{elements}</>;
};

export default TableBody;
