import React from 'react';
import { useSelector } from 'react-redux';
import { getUser, getLicenceTitle } from '../../../selectors/CurrentUser';
import UserMenu from '../../UserMenu/UserMenu';
import './SessionInfo.scss';
import BackendPaths from '../../../helpers/BackendPaths';
import LinkButton from '../../LinkButton/LinkButton';

const SessionInfo = () => {
    const user = useSelector(getUser);
    const licenceTitle = useSelector(getLicenceTitle);
    const signUpUrl = BackendPaths.signUpUrl.getPath();
    const loginUrl = BackendPaths.loginUrl.getPath();

    if (user) {
        return <UserMenu />;
    }

    return (
        <div className="session-info">
            <div className="licence-title">{licenceTitle}</div>
            <LinkButton variant="contained" url={signUpUrl}>
                Create Account
            </LinkButton>
            <LinkButton variant="flat" url={loginUrl}>
                Login
            </LinkButton>
        </div>
    );
};

export default SessionInfo;
