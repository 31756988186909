import React from 'react';
import classNames from 'classnames';

import { ButtonVariant } from '../../typescript/types';

import './Button.scss';

export type Props = {
    variant?: ButtonVariant;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = React.forwardRef<HTMLButtonElement, Props>(
    ({ children, variant, className, ...rest }, ref) => {
        const buttonClass = classNames(
            'button',
            // add button-variant class if variant is present
            { [`button-${variant}`]: variant != null },
            className,
        );
        return (
            <button ref={ref} className={buttonClass} {...rest}>
                {children}
            </button>
        );
    },
);

export default Button;
