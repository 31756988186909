import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { score } from '../../../../helpers/Search';
import * as ReportApp from '../../../../typescript/types';
import { getSurveyCode } from '../../../../selectors/Selection';

import FilterableByTextList from '../../../base/FilterableByTextList/FilterableByTextList';
import TablesFilterableByTextListItem from './TablesFilterableByTextListItem';

import './TablesFilterableByTextList.scss';

type Props = {
    isSelected: (table: ReportApp.TableForReportBySurveyCode) => boolean;
    tables: ReportApp.TableForReportBySurveyCode[];
    onChange: (
        actionItem: ReportApp.TableForReportBySurveyCode,
        affectedItems: ReportApp.TableForReportBySurveyCode[],
    ) => void;
};

/**
 * Used by FilterableByTextList to score out surveys
 */
const scoreCallback = (
    table: ReportApp.TableForReportBySurveyCode,
    lowerCaseTokens: string[],
) =>
    score(table.displayName, lowerCaseTokens) +
    score(table.title, lowerCaseTokens);

const TablesFilterableByTextList = ({ isSelected, tables, ...rest }: Props) => {
    const intl = useIntl();
    const surveyCode = useSelector(getSurveyCode);

    const itemRenderer = (table: ReportApp.TableForReportBySurveyCode) => (
        <TablesFilterableByTextListItem
            key={table.displayName}
            table={table}
            isSelected={isSelected}
        />
    );

    const placeholder = intl.formatMessage({
        id: 'tablesPage.tablesSelection.searchPlaceholder',
    });

    const ariaListDescription = intl.formatMessage({
        id: 'tablesPage.tablesSelection.listDescription',
    });

    return (
        <FilterableByTextList
            list={tables}
            scoreCallback={scoreCallback}
            render={itemRenderer}
            placeholder={placeholder}
            counterEventValue={(value: string): string =>
                `${surveyCode}|${value}`
            }
            resultsClassName="tables-list"
            description={ariaListDescription}
            {...rest}
        />
    );
};

export default TablesFilterableByTextList;
