import React, { useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import * as Survey from '../../../../constants/Survey';
import * as ReportApp from '../../../../typescript/types';
import { ReportAppDispatch } from '../../../../typescript/actions/actions';

import SelectYears from './SelectYears';
import Popover from '../../../base/Popover/Popover';
import ModifySurveyTrigger from './ModifySurveyTrigger';

import './ModifySurveyDropdown.scss';

type Props = {
    surveyGroupId: number;
    years: number[];
    selectedYears: number[];
    comparabilityGroup: ReportApp.ComparabilityGroup;
};

const ModifySurveyDropdown = ({
    surveyGroupId,
    years,
    selectedYears,
    comparabilityGroup,
}: Props) => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const popoverMenuContainer = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    const showPopover = useCallback(() => setIsOpen(true), [setIsOpen]);
    const hidePopover = useCallback(() => setIsOpen(false), [setIsOpen]);

    const dispatchChangeSurveyAction = (newYears: number[]) =>
        dispatch({
            type: Survey.ANALYSE_CHANGE_REQUEST,
            payload: {
                surveyGroupId,
                newYears,
                oldYears: selectedYears,
                comparabilityGroup,
            },
        });

    return (
        <div ref={popoverMenuContainer} className="modify-survey-dropdown">
            <ModifySurveyTrigger
                selectedYears={selectedYears}
                onClick={showPopover}
            />
            {isOpen && (
                <Popover
                    onClickAway={hidePopover}
                    parent={popoverMenuContainer.current}
                >
                    <SelectYears
                        initialSelection={selectedYears}
                        years={years}
                        onDone={dispatchChangeSurveyAction}
                        onClose={hidePopover}
                    />
                </Popover>
            )}
        </div>
    );
};

export default ModifySurveyDropdown;
