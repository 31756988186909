export const SET_TABLE_PAGE = '@@report/SET_TABLE_PAGE';
export const SET_TABLES_PER_PAGE = '@@report/SET_TABLES_PER_PAGE';
export const RESET_TABLE_PAGE = '@@report/RESET_TABLE_PAGE';
export const SET_GEO_ITEM_PAGE = '@@report/SET_GEO_ITEM_PAGE';
export const SET_GEO_ITEMS_PER_PAGE = '@@report/SET_GEO_ITEMS_PER_PAGE';
export const RESET_GEO_ITEM_PAGE = '@@report/RESET_GEO_ITEM_PAGE';
export const RESET_REPORT_NAVIGATOR = '@@report/RESET_REPORT_NAVIGATOR';

export const DEFAULT_ITEMS_PER_PAGE = 30;

export enum NavigatorVariants {
    SIMPLIFIED_NAVIGATOR,
    DETAILED_NAVIGATOR,
}

export enum NavigatorOptions {
    GEOGRAPHIES,
    TABLES,
}
