import {
    all,
    put,
    takeEvery,
    takeLatest,
    select,
    call,
} from 'redux-saga/effects';

import api from '../helpers/Api';
import BackendPaths from '../helpers/BackendPaths';

import * as ReportConstants from '../constants/Report';
import * as Selection from '../constants/Selection';
import * as ReportNavigatorConstants from '../constants/ReportNavigator';
import * as SurveyConstants from '../constants/Survey';
import * as GeographiesConstants from '../constants/Geographies';
import * as TablesConstants from '../constants/Table';
import * as ReportMetadata from '../selectors/ReportMetadata';
import * as ReportActions from '../typescript/actions/ReportActions';
import { ReportAppActions } from '../typescript/actions/actions';
import * as ReportApp from '../typescript/types';
import * as HelperSaga from './Helper';

function* setSelectedDollarYearAdjustmentAndResetReportData({
    payload: { dollarYearValue },
}: ReportActions.SetSelectedDollarYearAdjustment) {
    yield put<ReportAppActions>({
        type: ReportConstants.SET_DOLLAR_YEAR,
        payload: dollarYearValue,
    });
    // Clear report data will make method Report.getShouldFetchReport return
    // true, which will trigger ReportConstants.FETCH_REPORT_REQUEST
    // from component ExistingReport
    yield put<ReportAppActions>({ type: ReportConstants.RESET_REPORT_DATA });
    yield put<ReportAppActions>({
        type: ReportConstants.RESET_REPORT_TOTALS_DATA,
    });

    // if fetching data is in progress, this action will enable component
    // ExistingReport to trigger new fetch. Since fetch report action
    // only takes latest, it will abort in progress fetch request
    yield put<ReportAppActions>({ type: ReportConstants.FETCH_REPORT_RESET });
}

function* changeAggregationTypeAndReset({
    payload,
}: ReportActions.SetAggregationTypeRequest) {
    // we assume that only one aggregation type is available on report
    const oldReportId: ReportApp.OldReportId = yield select(
        ReportMetadata.getSingleReportId,
    );
    yield put<ReportAppActions>({
        type: ReportConstants.SET_AGGREGATION_TYPE,
        payload,
    });
    yield put<ReportAppActions>({ type: ReportConstants.RESET_FIPS_CODES });
    yield put<ReportAppActions>({ type: ReportConstants.RESET_REPORT_DATA });
    yield put<ReportAppActions>({
        type: ReportConstants.RESET_REPORT_TOTALS_DATA,
    });
    // if fetching data is in progress, this action will enable component
    // ExistingReport to trigger new fetch. Since fetch report action
    // only takes latest, it will abort in progress fetch request
    yield put<ReportAppActions>({ type: ReportConstants.FETCH_REPORT_RESET });
    yield put<ReportAppActions>({
        type: ReportNavigatorConstants.RESET_REPORT_NAVIGATOR,
    });
    // unset geo counts so that fetch report loads this info
    yield put<ReportAppActions>({
        type: ReportConstants.SET_TOTAL_NUMBER_OF_GEOGRAPHIES,
        payload: { oldReportId, totalNumberOfGeographies: null },
    });
}

function* setTablesPageAndResetReportData({
    payload: { page },
}: ReportActions.SetTablePageRequest) {
    yield put<ReportAppActions>({
        type: ReportNavigatorConstants.SET_TABLE_PAGE,
        payload: page,
    });
    // Clear report data will make method Report.getShouldFetchReport return
    // true, which will trigger ReportConstants.FETCH_REPORT_REQUEST
    // from component ExistingReport
    yield put<ReportAppActions>({ type: ReportConstants.RESET_REPORT_DATA });
    yield put<ReportAppActions>({
        type: ReportConstants.RESET_REPORT_TOTALS_DATA,
    });
    // if fetching data is in progress, this action will enable component
    // ExistingReport to trigger new fetch. Since fetch report action
    // only takes latest, it will abort in progress fetch request
    yield put<ReportAppActions>({ type: ReportConstants.FETCH_REPORT_RESET });
}

function* setTablesPerPageAndResetReportData({
    payload: { value },
}: ReportActions.SetTablePerPageRequest) {
    yield put<ReportAppActions>({
        type: ReportNavigatorConstants.SET_TABLES_PER_PAGE,
        payload: value,
    });
    // This prevents a bug where table page number is higher
    // than total number of pages
    yield put<ReportAppActions>({
        type: ReportNavigatorConstants.RESET_TABLE_PAGE,
    });
    // Clear report data will make method Report.getShouldFetchReport return
    // true, which will trigger ReportConstants.FETCH_REPORT_REQUEST
    // from component ExistingReport
    yield put<ReportAppActions>({ type: ReportConstants.RESET_REPORT_DATA });
    yield put<ReportAppActions>({
        type: ReportConstants.RESET_REPORT_TOTALS_DATA,
    });
    // if fetching data is in progress, this action will enable component
    // ExistingReport to trigger new fetch. Since fetch report action
    // only takes latest, it will abort in progress fetch request
    yield put<ReportAppActions>({ type: ReportConstants.FETCH_REPORT_RESET });
}

function* setGeoItemPageAndResetReportData({
    payload: { page },
}: ReportActions.SetGeoItemPageRequest) {
    yield put<ReportAppActions>({
        type: ReportNavigatorConstants.SET_GEO_ITEM_PAGE,
        payload: page,
    });
    // Clear report data will make method Report.getShouldFetchReport return
    // true, which will trigger ReportConstants.FETCH_REPORT_REQUEST
    // from component ExistingReport
    yield put<ReportAppActions>({ type: ReportConstants.RESET_REPORT_DATA });
    yield put<ReportAppActions>({
        type: ReportConstants.RESET_REPORT_TOTALS_DATA,
    });
    // if fetching data is in progress, this action will enable component
    // ExistingReport to trigger new fetch. Since fetch report action
    // only takes latest, it will abort in progress fetch request
    yield put<ReportAppActions>({ type: ReportConstants.FETCH_REPORT_RESET });
}

function* setGeoItemsPerPageAndResetReportData({
    payload: { value },
}: ReportActions.SetGeoItemsPerPageRequest) {
    yield put<ReportAppActions>({
        type: ReportNavigatorConstants.SET_GEO_ITEMS_PER_PAGE,
        payload: value,
    });
    // This prevents a bug where geo items page number is higher
    // than total number of geo items
    yield put<ReportAppActions>({
        type: ReportNavigatorConstants.RESET_GEO_ITEM_PAGE,
    });
    // Clear report data will make method Report.getShouldFetchReport return
    // true, which will trigger ReportConstants.FETCH_REPORT_REQUEST
    // from component ExistingReport
    yield put<ReportAppActions>({ type: ReportConstants.RESET_REPORT_DATA });
    yield put<ReportAppActions>({
        type: ReportConstants.RESET_REPORT_TOTALS_DATA,
    });
    // if fetching data is in progress, this action will enable component
    // ExistingReport to trigger new fetch. Since fetch report action
    // only takes latest, it will abort in progress fetch request
    yield put<ReportAppActions>({ type: ReportConstants.FETCH_REPORT_RESET });
}

function* resetAndSetReportId({ payload }: ReportActions.SetReportIdRequest) {
    const reportId = payload;
    yield put<ReportAppActions>({ type: Selection.RESET_SELECTION });
    yield put<ReportAppActions>({
        type: SurveyConstants.CLEAR_NEW_SURVEY_CHANGE,
    });
    yield put<ReportAppActions>({
        type: ReportConstants.RESET_REPORT_METADATA,
    });
    yield put<ReportAppActions>({ type: ReportConstants.RESET_REPORT_DATA });
    yield put<ReportAppActions>({
        type: ReportConstants.RESET_REPORT_TOTALS_DATA,
    });
    // if fetching data is in progress, this action will enable component
    // ExistingReport to trigger new fetch. Since fetch report action
    // only takes latest, it will abort in progress fetch request
    yield put<ReportAppActions>({ type: ReportConstants.FETCH_REPORT_RESET });
    yield put<ReportAppActions>({ type: ReportConstants.RESET_REPORT_VIEW });
    yield put<ReportAppActions>({
        type: ReportNavigatorConstants.RESET_REPORT_NAVIGATOR,
    });
    yield put<ReportAppActions>({
        type: Selection.SET_REPORT_ID,
        payload: reportId,
    });
}

function* clearModifyState() {
    yield put<ReportAppActions>({
        type: SurveyConstants.CLEAR_NEW_SURVEY_CHANGE,
    });
    yield put<ReportAppActions>({ type: GeographiesConstants.RESET_VALUES });
    yield put<ReportAppActions>({
        type: TablesConstants.CLEAR_TEMPORARY_TABLES_SELECTION,
    });
}

function* fetchConsumerPriceIndices() {
    let cpiResponse: ReportApp.AspNetWebService.ConsumerPriceIndicesResults;
    try {
        cpiResponse = yield call(
            api,
            BackendPaths.getConsumerPriceIndices.getPath(),
            'GET-CACHE',
        );
    } catch (error) {
        return yield HelperSaga.handleError(
            ReportConstants.FETCH_CPI_FAILURE,
            error,
        );
    }

    const consumerPriceIndices: ReportApp.ConsumerPriceIndex = {};
    cpiResponse.d.forEach((cpiByYear) => {
        consumerPriceIndices[cpiByYear.Year] = cpiByYear.Cpi;
    });

    yield put({
        type: ReportConstants.FETCH_CPI_SUCCESS,
        payload: { consumerPriceIndices },
    });
}

export default function* () {
    yield all([
        yield takeLatest(
            ReportConstants.SET_TABLE_PAGE_REQUEST,
            setTablesPageAndResetReportData,
        ),
        yield takeLatest(
            ReportConstants.SET_TABLES_PER_PAGE_REQUEST,
            setTablesPerPageAndResetReportData,
        ),
        yield takeLatest(
            ReportConstants.SET_GEO_ITEM_PAGE_REQUEST,
            setGeoItemPageAndResetReportData,
        ),
        yield takeLatest(
            ReportConstants.SET_GEO_ITEMS_PER_PAGE_REQUEST,
            setGeoItemsPerPageAndResetReportData,
        ),
        yield takeLatest(
            ReportConstants.SET_SELECTED_DOLLAR_YEAR_ADJUSTMENT_REQUEST,
            setSelectedDollarYearAdjustmentAndResetReportData,
        ),
        yield takeLatest(
            ReportConstants.SET_AGGREGATION_TYPE_REQUEST,
            changeAggregationTypeAndReset,
        ),
        yield takeEvery(
            ReportConstants.SET_REPORT_ID_REQUEST,
            resetAndSetReportId,
        ),
        yield takeEvery(ReportConstants.CLEAR_MODIFY_STATE, clearModifyState),
        yield takeEvery(
            ReportConstants.FETCH_CPI_REQUEST,
            fetchConsumerPriceIndices,
        ),
    ]);
}
