import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import * as Table from '../../selectors/Table';

import SelectionHint from '../SelectionHint/SelectionHint';

const SelectTablesHint = () => {
    const hasTablesSelected = useSelector(Table.getHasIntermittentTables);

    return hasTablesSelected ? null : (
        <SelectionHint>
            <p>
                <FormattedMessage id="tablesPage.selection.hint" />
            </p>
        </SelectionHint>
    );
};

export default SelectTablesHint;
