import { DefaultRootState } from 'react-redux';

import * as Table from './Table';
import * as Survey from './Survey';
import * as ReportMetadata from './ReportMetadata';
import * as Report from './Report';

import * as GeographiesHelper from '../helpers/Geographies';
import * as TablesHelper from '../helpers/Tables';

import NewSurveyScreen from '../constants/NewSurveyScreen';
import { filterFalsy } from '../typescript/helper';
import * as ReportApp from '../typescript/types';

export const getNewYears = (state: DefaultRootState) =>
    state.newSurvey.newYears;

export const getOldYears = (state: DefaultRootState) =>
    state.newSurvey.oldYears;

export const getSurveyGroupId = (state: DefaultRootState) =>
    state.newSurvey.surveyGroupId;

export const getComparabilityGroup = (state: DefaultRootState) =>
    state.newSurvey.comparabilityGroup;

export const getShouldDisplayChangeSurveyAnalyse = (state: DefaultRootState) =>
    !!getNewYears(state) && !!getOldYears(state);

const getIsLoading = (state: DefaultRootState) => !!state.newSurvey.loading;

export const getError = (state: DefaultRootState) => state.newSurvey.error;

export const getMatchedGeoItemsByYear = (state: DefaultRootState) =>
    state.newSurvey.matchedGeoItemsByYear;

const getMatchedGeoItemByYearSlFips = (
    state: DefaultRootState,
    year: number,
    sumLevel: string,
    fips: string,
) => {
    const matchedGeoItemsByYear = getMatchedGeoItemsByYear(state);
    return matchedGeoItemsByYear[year]?.[sumLevel]?.find(
        (geoItem) => geoItem.fips === fips,
    );
};

export const getWhatToDisplay = (state: DefaultRootState) => {
    if (getIsLoading(state)) {
        return NewSurveyScreen.LOADING;
    }
    if (getError(state)) {
        return NewSurveyScreen.ERROR;
    }
    return NewSurveyScreen.ANALYSIS_RESULTS;
};

export const getReportInfos = (state: DefaultRootState) =>
    state.newSurvey.reportInfoByOldReportId;

export const getSurveysToBeAdded = (state: DefaultRootState) => {
    const oldYears = getOldYears(state);
    const newYears = getNewYears(state);
    const surveyGroupId = getSurveyGroupId(state);
    const comparabilityGroup = getComparabilityGroup(state);
    if (!oldYears || !newYears || !surveyGroupId) {
        return [];
    }
    return newYears
        .filter((year) => !oldYears.includes(year))
        .map((year) =>
            Survey.getSurveyByYearSurveyGroupIdComparabilityGroup(
                state,
                year,
                surveyGroupId,
                comparabilityGroup,
            ),
        )
        .filter(filterFalsy);
};

export const getSurveysToBeRemoved = (state: DefaultRootState) => {
    const oldYears = getOldYears(state);
    const newYears = getNewYears(state);
    const surveyGroupId = getSurveyGroupId(state);
    const comparabilityGroup = getComparabilityGroup(state);
    if (!newYears || !oldYears || !surveyGroupId) {
        return [];
    }
    return oldYears
        .filter((year) => !newYears.includes(year))
        .map((year) =>
            Survey.getSurveyByYearSurveyGroupIdComparabilityGroup(
                state,
                year,
                surveyGroupId,
                comparabilityGroup,
            ),
        )
        .filter(filterFalsy);
};

const getAffectedTablesUsedInReport = (state: DefaultRootState) => {
    const reportInfoByOldReportId = getReportInfos(state);
    if (!reportInfoByOldReportId) {
        return [];
    }
    const oldReportIds = Object.keys(reportInfoByOldReportId);
    const tablesUsedInReport = ReportMetadata.getTablesUsedInReport(state);
    return tablesUsedInReport.filter((tableUsedInReport) =>
        Object.keys(tableUsedInReport.guidByOldReportId).some((oldReportId) =>
            oldReportIds.includes(oldReportId),
        ),
    );
};

const getFoundTables = (state: DefaultRootState) => {
    const newYears = getNewYears(state);
    const oldYears = getOldYears(state);
    const surveyGroupId = getSurveyGroupId(state);
    const comparabilityGroup = getComparabilityGroup(state);
    if (!surveyGroupId || !newYears || !oldYears) {
        return [];
    }
    const surveyCodes = Array.from(new Set([...oldYears, ...newYears]))
        .map(
            (year) =>
                Survey.getSurveyByYearSurveyGroupIdComparabilityGroup(
                    state,
                    year,
                    surveyGroupId,
                    comparabilityGroup,
                )?.code,
        )
        .filter(filterFalsy);
    return getAffectedTablesUsedInReport(state)
        .map((tableInReport) =>
            Object.values(tableInReport.guidByOldReportId).find(
                (tableGuid) => tableGuid != null,
            ),
        )
        .filter(filterFalsy)
        .map((tableGuid) => Table.getTableByGuid(state, tableGuid))
        .filter(filterFalsy)
        .map((pivotTable) =>
            Table.getTableForReportBySurveyCode(state, pivotTable, surveyCodes),
        )
        .filter(
            (tablesBySurveyCode) => tablesBySurveyCode.hasFoundTableInAnySurvey,
        );
};

export const getShouldProceedToReportCreation = (state: DefaultRootState) => {
    const oldYears = getOldYears(state);
    const newYears = getNewYears(state);
    if (!newYears || !oldYears) {
        return false;
    }

    return (
        newYears.length < oldYears.length && !getSurveysToBeAdded(state).length
    );
};

export const getChangeSurveyAnalysis = (state: DefaultRootState) => {
    const geoAnalysis = getGeoAnalyseByGeoGroupAndYear(state);
    const geoPairs = Object.values(geoAnalysis)
        .flatMap((geoAnalysisByYear) =>
            Object.values(geoAnalysisByYear.byYear).flatMap(
                (analysisForYear) => analysisForYear?.elements,
            ),
        )
        .filter(filterFalsy);
    const foundGeoFips = geoPairs.reduce(
        (sum, table) => sum + (table.foundGeoFips ? 1 : 0),
        0,
    );

    const tableAnalysis = getTableAnalysis(state);
    const tables = Object.values(tableAnalysis)
        .flatMap((tableAnalysisByYear) =>
            Object.values(tableAnalysisByYear.byYear).flatMap(
                (analysisForYear) => analysisForYear?.elements,
            ),
        )
        .filter(filterFalsy);
    const foundTables = tables.reduce(
        (sum, table) => sum + (table.foundTableLabel ? 1 : 0),
        0,
    );
    return {
        foundGeoFips,
        selectedGeoFips: geoPairs.length,

        foundTables,
        selectedTables: tables.length,
    };
};

export const getGeoAnalyseByGeoGroupAndYear = (state: DefaultRootState) => {
    const geoAnalysisResults: ReportApp.GeoAnalysisByGeoGroupAndYear = {};
    const definition = ReportMetadata.getDefinition(state);
    const surveyGroupId = getSurveyGroupId(state);
    if (!definition || !surveyGroupId) {
        return geoAnalysisResults;
    }
    const surveysToBeAdded = getSurveysToBeAdded(state);
    const surveysToBeRemoved = getSurveysToBeRemoved(state);
    const oldReportIdsForSurveyGroup: ReportApp.OldReportId[] = ReportMetadata.getOldReportIdsBySurveyGroupId(
        state,
        surveyGroupId,
    );
    for (let i = 0; i < definition.geoGroups.length; i++) {
        const geoGroup = definition.geoGroups[i];
        const geoGroupAnalysis: ReportApp.GeoAnalysisByGeoGroup = {
            name: geoGroup.name,
            byYear: {},
        };
        geoAnalysisResults[i] = geoGroupAnalysis;
        // Indicate years that will be removed
        surveysToBeRemoved.forEach(
            ({ year }) => (geoGroupAnalysis.byYear[year] = null),
        );

        // Get all geo fips that are used in this geo group (for all years)
        const oldReportIdsInGeoGroup = geoGroup.columnGroups
            .flatMap((columnGroup) => columnGroup.tables)
            .filter((oldReportId) =>
                oldReportIdsForSurveyGroup.includes(oldReportId),
            );
        const allGeoFipsWithDuplicates = oldReportIdsInGeoGroup.flatMap(
            (oldReportId) =>
                getGeoFipsFromReportInfoByOldReportId(state, oldReportId),
        );

        // allGeoFipsWithDuplicates consists from all NEW year&geo combinations. Therefore the importance
        // of the following lines can be tested in case of modifying report in a way to select multiple
        // years for change over time comparison (where applicable). Note: there is another way to get
        // duplicates in this collection (adding survey from another survey group), but currently is not
        // supported through the UI
        const allGeoFips = allGeoFipsWithDuplicates.filter((value, index) => {
            const {
                fips: currentFips,
                sumLev: currentSumLev,
            } = GeographiesHelper.getPartsFromGeoFips(value);
            return (
                allGeoFipsWithDuplicates.findIndex(
                    (fips) =>
                        GeographiesHelper.getPartsFromGeoFips(fips).fips ===
                            currentFips &&
                        GeographiesHelper.getPartsFromGeoFips(fips).sumLev ===
                            currentSumLev,
                ) === index
            );
        });

        // for every new year added in report find what geographies have been found
        surveysToBeAdded.forEach(({ year }) => {
            const analysis: ReportApp.GeoAnalysisForYear = {
                elements: [],
                hasFoundAny: false,
            };
            geoGroupAnalysis.byYear[year] = analysis;
            analysis.elements = allGeoFips.map((existingGeoFips) => {
                const { fips, sumLev } = GeographiesHelper.getPartsFromGeoFips(
                    existingGeoFips,
                );
                const matchedGeoItem = getMatchedGeoItemByYearSlFips(
                    state,
                    year,
                    sumLev,
                    fips,
                );
                analysis.hasFoundAny = analysis.hasFoundAny || !!matchedGeoItem;
                return {
                    existingGeoFips,
                    foundGeoFips: !!matchedGeoItem
                        ? GeographiesHelper.getFipsCodeForSelection(
                              matchedGeoItem,
                          )
                        : null,
                };
            });
        });
    }
    return geoAnalysisResults;
};

export const getTableAnalysis = (state: DefaultRootState) => {
    const tableAnalysisResults: ReportApp.TableAnalysisByGeoGroupAndYear = {};
    const definition = ReportMetadata.getDefinition(state);
    const surveyGroupId = getSurveyGroupId(state);
    if (!definition || !surveyGroupId) {
        return tableAnalysisResults;
    }
    const surveysToBeAdded = getSurveysToBeAdded(state);
    const surveysToBeRemoved = getSurveysToBeRemoved(state);

    const selectedDollarAdjustmentYear = Report.getSelectedDollarAdjustment(
        state,
    );
    const isCOT = ReportMetadata.isReportCOT(state);
    const foundTables = getFoundTables(state);
    for (let i = 0; i < definition.geoGroups.length; i++) {
        const geoGroup = definition.geoGroups[i];
        const tableGroupAnalysis: ReportApp.TableAnalysisByGeoGroup = {
            name: geoGroup.name,
            byYear: {},
        };
        tableAnalysisResults[i] = tableGroupAnalysis;
        // Indicate years that will be removed
        surveysToBeRemoved.forEach(
            ({ year }) => (tableGroupAnalysis.byYear[year] = null),
        );
        // for every new year added in report find what tables have been found
        surveysToBeAdded.forEach((survey) => {
            const analysis: ReportApp.TableAnalysisForYear = {
                elements: [],
                hasFoundAny: false,
            };
            tableGroupAnalysis.byYear[survey.year] = analysis;
            analysis.elements = foundTables.map((tableBySurveyCode) => {
                let foundTableLabel: string | null = null;
                let foundTableGuid: string | null = null;
                const tableGuid =
                    tableBySurveyCode.guidBySurveyCode[survey.code];
                if (tableGuid) {
                    const table = Table.getTableByGuid(state, tableGuid);
                    if (table) {
                        foundTableLabel = TablesHelper.getTableLabel(
                            table,
                            isCOT,
                            selectedDollarAdjustmentYear,
                        );
                        foundTableGuid = table.guid;
                    }
                }
                analysis.hasFoundAny =
                    analysis.hasFoundAny || !!foundTableLabel;
                return {
                    existingTableLabel: tableBySurveyCode.label,
                    foundTableLabel,
                    foundTableGuid,
                };
            });
        });
    }
    return tableAnalysisResults;
};

const isAtLeastOneGeoItemFoundInEveryOldReport = (state: DefaultRootState) => {
    const geoAnalysis = getGeoAnalyseByGeoGroupAndYear(state);
    return Object.values(geoAnalysis)
        .flatMap((geoAnalysisYear) => Object.values(geoAnalysisYear.byYear))
        .filter(filterFalsy)
        .every((geoAnalysisForYear) => geoAnalysisForYear.hasFoundAny);
};

const isAtLeastOneTableFoundInEveryOldReport = (state: DefaultRootState) => {
    const tableAnalysis = getTableAnalysis(state);
    return Object.values(tableAnalysis)
        .flatMap(({ byYear }) => Object.values(byYear))
        .filter(filterFalsy)
        .every((geoAnalysisForYear) => geoAnalysisForYear.hasFoundAny);
};

export const getGeoFipsFromReportInfoByOldReportId = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId,
) => {
    const reportsInfo = getReportInfos(state);
    if (!reportsInfo) {
        return [];
    }
    return reportsInfo[oldReportId].geoFips;
};

export const canGoForward = (state: DefaultRootState) =>
    getShouldDisplayChangeSurveyAnalyse(state) &&
    isAtLeastOneGeoItemFoundInEveryOldReport(state) &&
    isAtLeastOneTableFoundInEveryOldReport(state) &&
    getReportInfos(state) != null &&
    !getIsLoading(state);
