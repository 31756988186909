export function sortByUndefined<T>(itemA: T | undefined, itemB: T | undefined) {
    if (typeof itemA === typeof itemB) {
        return 0;
    }
    if (itemA === undefined) {
        return -1;
    }
    return 1;
}

export function mergeAndMaintainRelativeOrder(arrays: string[][]): string[] {
    interface NodeElement {
        value: string;
        predecessor: Set<NodeElement>;
        successor: Set<NodeElement>;
        collected: boolean;
    }

    const elements: { [key: string]: NodeElement } = {};

    arrays.flat().forEach(
        (value) =>
            (elements[value] = {
                value,
                predecessor: new Set<NodeElement>(),
                successor: new Set<NodeElement>(),
                collected: false,
            }),
    );

    arrays.forEach((list) => {
        for (let i = 0; i < list.length - 1; i += 1) {
            const node = elements[list[i]];
            const nextNode = elements[list[i + 1]];

            node.successor.add(nextNode);
            nextNode.predecessor.add(node);
        }
    });

    function addElementsInArray(head: NodeElement, array: string[]) {
        // At first this was implemented using recursion. But issue with that
        // approach was that for large number of geographies (3k+) that would
        // produce error in browsers (too much recursion, Maximum call stack
        // size exceeded), etc. So, the code below actually simulates recursion.
        //
        // initialize recursion simulation
        const stack: NodeElement[] = [head];
        while (stack.length > 0) {
            const first = stack.shift()!; // length > 0 means it can't be undefined
            const predecessors = Array.from(first.predecessor);
            if (
                predecessors.some((element) => !element.collected) ||
                first.collected
            ) {
                continue;
            }
            array.push(first.value);
            first.collected = true;
            stack.push(...Array.from(first.successor));
        }
    }

    const results: string[] = [];
    Object.values(elements)
        .filter((element) => element.predecessor.size === 0)
        .forEach((head) => {
            addElementsInArray(head, results);
        });
    return results;
}
