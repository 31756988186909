import React from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from '../../../Icon/Icon';
import ExistingTemplateDoneButton from './ExistingTemplateDoneButton';

import './ExistingTemplate.scss';

type Props = {
    onClose: () => void;
};

const ExistingTemplate = ({ ...rest }: Props) => (
    <div className="existing-template">
        <Icon>list_alt</Icon>
        <h2>
            <FormattedMessage id="report.tablesTemplate.templateIsSaved" />
        </h2>
        <p>
            <FormattedMessage id="report.tablesTemplate.templateIsSavedDescription" />
        </p>
        <ExistingTemplateDoneButton {...rest} />
    </div>
);

export default ExistingTemplate;
