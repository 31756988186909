import { CsvDownloadOptions } from '../typescript/types';
import BackendPaths from './BackendPaths';

export const openDownloadCsvUrlInNewTab = ({
    reportId,
    geoTypeIndex = 0,
    tabSeparated,
    outputLabels,
    outputAllGeoIds,
    percents,
    dbfFriendlyNames,
    downloadProgram,
    programType,
}: {
    reportId: string;
    geoTypeIndex: number;
    downloadProgram?: boolean;
    programType?: string;
} & CsvDownloadOptions) => {
    const downloadUrl = BackendPaths.csvDownload.getPath({
        reportId,
    });
    const queryParams = new URLSearchParams();
    queryParams.append('geoTypeIndex', geoTypeIndex.toString());
    if (tabSeparated) {
        queryParams.append('tabSeperated', 'true');
    }
    if (outputLabels) {
        queryParams.append('outputLabels', 'true');
    }
    if (outputAllGeoIds) {
        queryParams.append('outputAllGeoIds', 'true');
    }
    if (percents) {
        queryParams.append('percents', 'true');
    }
    if (dbfFriendlyNames) {
        queryParams.append('dbfFriendlyNames', 'true');
    }
    if (downloadProgram) {
        queryParams.append('downloadProgram', 'true');
    }
    if (programType) {
        queryParams.append('programType', programType);
    }

    window.open(`${downloadUrl}&${queryParams.toString()}`, '_blank');
};
