import * as Report from '../constants/Report';
import * as ReportApp from '../typescript/types';
import { ReportAppActions } from '../typescript/actions/actions';

const INITIAL_STATE: ReportApp.ReportStatus = {
    retrieveReport: {
        loading: false,
        error: null,
    },
    createReport: {
        loading: false,
        error: null,
    },
    saveReport: {
        error: null,
        loading: false,
    },
    deleteReport: {
        error: null,
        loading: false,
    },
    updateReport: {
        error: null,
        loading: false,
    },
    duplicateReport: {
        error: null,
        loading: false,
    },
    downloadExcel: {
        loading: false,
        error: null,
    },
    downloadPdf: {
        loading: false,
        error: null,
    },
    retrieveCpi: {
        loading: false,
        error: null,
    },
};

export default (state = INITIAL_STATE, action: ReportAppActions) => {
    switch (action.type) {
        case Report.CREATE_REPORT_REQUEST:
            return {
                ...state,
                createReport: { loading: true, error: null },
            };
        case Report.CREATE_REPORT_SUCCESS:
            return {
                ...state,
                createReport: { loading: false, error: null },
            };
        case Report.CREATE_REPORT_FAILURE:
            return {
                ...state,
                createReport: { loading: false, error: action.payload },
            };
        case Report.SAVE_REPORT_REQUEST:
            return {
                ...state,
                saveReport: { loading: true, error: null },
            };
        case Report.SAVE_REPORT_FAILURE:
            return {
                ...state,
                saveReport: { loading: false, error: action.payload.error },
            };
        case Report.SAVE_REPORT_SUCCESS:
            return {
                ...state,
                saveReport: { loading: false, error: null },
            };
        case Report.DELETE_REPORT_REQUEST:
            return {
                ...state,
                deleteReport: { loading: true, error: null },
            };
        case Report.DELETE_REPORT_FAILURE:
            return {
                ...state,
                deleteReport: { loading: false, error: action.payload.error },
            };
        case Report.DELETE_REPORT_SUCCESS:
            return {
                ...state,
                deleteReport: { loading: false, error: null },
            };
        case Report.UPDATE_REPORT_REQUEST:
            return {
                ...state,
                updateReport: { loading: true, error: null },
            };
        case Report.UPDATE_REPORT_FAILURE:
            return {
                ...state,
                updateReport: { loading: false, error: action.payload.error },
            };
        case Report.UPDATE_REPORT_SUCCESS:
            return {
                ...state,
                updateReport: { loading: false, error: null },
            };
        case Report.DUPLICATE_REPORT_REQUEST:
            return {
                ...state,
                duplicateReport: { loading: true, error: null },
            };
        case Report.DUPLICATE_REPORT_FAILURE:
            return {
                ...state,
                duplicateReport: {
                    loading: false,
                    error: action.payload.error,
                },
            };
        case Report.DUPLICATE_REPORT_SUCCESS:
            return {
                ...state,
                duplicateReport: {
                    loading: false,
                    error: null,
                    // Value is set in selectionReducer
                },
            };
        case Report.DOWNLOAD_EXCEL_REQUEST:
            return {
                ...state,
                downloadExcel: { loading: true, error: null },
            };
        case Report.DOWNLOAD_EXCEL_SUCCESS:
            return {
                ...state,
                downloadExcel: { loading: false, error: null },
            };
        case Report.DOWNLOAD_EXCEL_FAILURE:
            return {
                ...state,
                downloadExcel: { loading: false, error: action.payload.error },
            };
        case Report.DOWNLOAD_PDF_REQUEST:
            return {
                ...state,
                downloadPdf: { loading: true, error: null },
            };
        case Report.DOWNLOAD_PDF_SUCCESS:
            return {
                ...state,
                downloadPdf: { loading: false, error: null },
            };
        case Report.DOWNLOAD_PDF_FAILURE:
            return {
                ...state,
                downloadPdf: { loading: false, error: action.payload.error },
            };
        case Report.FETCH_REPORT_REQUEST:
            return {
                ...state,
                retrieveReport: { loading: true, error: null },
            };
        case Report.FETCH_REPORT_FAILURE:
            return {
                ...state,
                retrieveReport: { loading: false, error: action.payload },
            };
        case Report.FETCH_REPORT_SUCCESS:
        case Report.FETCH_REPORT_RESET:
            return {
                ...state,
                retrieveReport: { loading: false, error: null },
            };
        case Report.FETCH_CPI_REQUEST:
            return {
                ...state,
                retrieveCpi: { loading: true, error: null },
            };
        case Report.FETCH_CPI_SUCCESS:
            return {
                ...state,
                retrieveCpi: { loading: false, error: null },
            };
        case Report.FETCH_CPI_FAILURE:
            return {
                ...state,
                retrieveCpi: { loading: false, error: action.payload.error },
            };
        default:
            return state;
    }
};
