import { SET_NORMALIZED_SURVEYS } from '../constants/Survey';
import { ReportAppActions } from '../typescript/actions/actions';
import { SurveyBySurveyCode } from '../typescript/types';

const INITIAL_STATE: SurveyBySurveyCode = {};

export default (
    state = INITIAL_STATE,
    action: ReportAppActions,
): SurveyBySurveyCode => {
    switch (action.type) {
        case SET_NORMALIZED_SURVEYS: {
            const surveyBySurveyCode: SurveyBySurveyCode = {};
            action.payload.reduce((memo, survey) => {
                memo[survey.code] = survey;
                return memo;
            }, surveyBySurveyCode);
            return {
                ...surveyBySurveyCode,
            };
        }
        default:
            return state;
    }
};
