import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../../Buttons/Button';

import './FipsCodesTextarea.scss';

const FipsCodesTextarea = React.forwardRef(({ onChange, value }, ref) => {
    const setValue = (event) => onChange(event.target.value);
    const clearValue = () => onChange('');

    return (
        <div className="zoomable-content fips-codes-textarea-wrapper">
            <textarea
                data-hj-allow={true}
                className="fips-codes-textarea"
                value={value}
                onChange={setValue}
                ref={ref}
            />
            {value !== '' && (
                <Button
                    className="fips-codes-textarea-clear"
                    onClick={clearValue}
                >
                    <FormattedMessage id="clear" />
                </Button>
            )}
        </div>
    );
});

export default FipsCodesTextarea;
