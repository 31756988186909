export const SAVE_TEMPLATE_REQUEST = '@@template/SAVE_TEMPLATE_REQUEST';
export const SAVE_TEMPLATE_SUCCESS = '@@template/SAVE_TEMPLATE_SUCCESS';
export const SAVE_TEMPLATE_FAILURE = '@@template/SAVE_TEMPLATE_FAILURE';

export const EDIT_TEMPLATE_REQUEST = '@@template/EDIT_TEMPLATE_REQUEST';
export const EDIT_TEMPLATE_SUCCESS = '@@template/EDIT_TEMPLATE_SUCCESS';
export const EDIT_TEMPLATE_FAILURE = '@@template/EDIT_TEMPLATE_FAILURE';

export const FETCH_TEMPLATES_REQUEST = '@@template/FETCH_TEMPLATES_REQUEST';
export const FETCH_TEMPLATES_SUCCESS = '@@template/FETCH_TEMPLATES_SUCCESS';
export const FETCH_TEMPLATES_FAILURE = '@@template/FETCH_TEMPLATES_FAILURE';

export const ADD_TABLE_TEMPLATES_TO_STORE =
    '@@template/ADD_TABLE_TEMPLATES_TO_STORE';
export const ADD_TABLE_TEMPLATE_TO_STORE =
    '@@template/ADD_TABLE_TEMPLATE_TO_STORE';
export const FETCH_TABLE_TEMPLATE_REQUEST =
    '@@template/FETCH_TABLE_TEMPLATE_REQUEST';
export const FETCH_TABLE_TEMPLATE_SUCCESS =
    '@@template/FETCH_TABLE_TEMPLATE_SUCCESS';
export const FETCH_TABLE_TEMPLATE_FAILURE =
    '@@template/FETCH_TABLE_TEMPLATE_FAILURE';
export const SET_ACTIVE_TEMPLATE_ID = '@@template/SET_ACTIVE_TEMPLATE_ID';
export const SET_IS_EDITING_TEMPLATE = '@@template/SET_IS_EDITING_TEMPLATE';

export enum SaveTableTemplate {
    TEMPLATE_EXISTS = 'TEMPLATE_EXISTS',
    SAVE_TEMPLATE = 'SAVE_TEMPLATE',
    SAVE_TEMPLATE_LOADING = 'SAVE_TEMPLATE_LOADING',
    SAVE_TEMPLATE_ERROR = 'SAVE_TEMPLATE_ERROR',
    SET_IS_CREATING_TEMPLATE_FROM_DASHBOARD = 'SET_IS_CREATING_TEMPLATE_FROM_DASHBOARD',
}

export enum FetchTableTemplates {
    FETCH_TEMPLATES_LOADING = 'FETCH_TEMPLATES_LOADING',
    FETCH_TEMPLATES_ERROR = 'FETCH_TEMPLATES_ERROR',
}

export enum FetchTableTemplate {
    FETCH_TABLE_TEMPLATE_LOADING = 'FETCH_TABLE_TEMPLATE_LOADING',
    FETCH_TABLE_TEMPLATE_ERROR = 'FETCH_TABLE_TEMPLATE_ERROR',
    TABLE_TEMPLATE_LOADED = 'TABLE_TEMPLATE_LOADED',
}
