import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getShouldAllowDownload } from '../../../selectors/CurrentUser';

import GeoGroups from './GeoGroups';
import DownloadCsvOptions from './DownloadCsvOptions';
import Collapse from '../../base/Collapse/Collapse';
import TrialWarning from '../../TrialWarning/TrialWarning';

import './DownloadCsvPanel.scss';

const DownloadCsvPanel = () => {
    const intl = useIntl();
    const shouldAllowDownload = useSelector(getShouldAllowDownload);

    return (
        <div id="download-csv-panel">
            <div className="note">
                <FormattedMessage
                    id="report.sidebar.downloadCsv.guidelinesWarning"
                    values={{
                        b: (chunks: React.ReactNode) => (
                            <strong>{chunks}</strong>
                        ),
                    }}
                />
            </div>
            <Collapse
                label={intl.formatMessage({
                    id: 'report.sidebar.downloadCsv.options.label',
                })}
                expanded
            >
                <DownloadCsvOptions />
            </Collapse>
            <hr />
            {shouldAllowDownload ? (
                <>
                    <h5>
                        <FormattedMessage id="report.sidebar.downloadCsv.downloadByGeoType" />
                    </h5>
                    <GeoGroups />
                </>
            ) : (
                <TrialWarning />
            )}
        </div>
    );
};

export default DownloadCsvPanel;
