import React, { Fragment } from 'react';

import Icon from '../../../Icon/Icon';

import './PremadeReportsFilterableByTextListItemDetailList.scss';
/** @param {{ tables: import('../../../../typescript/types').TableForReportBySurveyCode[] }} param0*/
const PremadeReportsFilterableByTextListItemDetailList = ({ tables }) => {
    return (
        <Fragment>
            <hr />
            <ul className="premade-report-table-list">
                {tables.map(({ composeGuid, label }) => {
                    return (
                        <Fragment key={composeGuid}>
                            <li className="premade-report-table-list-item">
                                {label}
                                <Icon className="premade-report-table-list-item-icon">
                                    check
                                </Icon>
                            </li>
                            <hr />
                        </Fragment>
                    );
                })}
            </ul>
        </Fragment>
    );
};

export default PremadeReportsFilterableByTextListItemDetailList;
