import React from 'react';
import { useIntl } from 'react-intl';

import Button from '../../Buttons/Button';

import './FipsCodesDropdownFilterableByTextListItem.scss';

/**@param {{ geoType: import('../../../typescript/types').GeoType }} param0 */
const FipsCodesDropdownFilterableByTextListItem = ({ geoType }) => {
    const intl = useIntl();

    const listItemAriaLabel = intl.formatMessage(
        { id: 'geographiesPage.fipsSelection.listItemAriaLabel' },
        { geoTypeLabel: geoType.label },
    );

    return (
        <li className="fips-codes-geo-types-list-item">
            <Button title={listItemAriaLabel} aria-label={listItemAriaLabel}>
                <div className="label">
                    <span className="label-sumlev">{geoType.sumLev}</span>
                    {!!geoType.indent && (
                        <span className="label-indent">
                            {'… '.repeat(geoType.indent)}
                        </span>
                    )}
                    <div className="label-body">{geoType.label}</div>
                </div>
            </Button>
        </li>
    );
};

export default FipsCodesDropdownFilterableByTextListItem;
