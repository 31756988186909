import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import ReportActionsModal from '../ReportHeader/ReportActions/ReportActionsModal';
import { ReportActionModals } from '../../constants/ReportActionsEnum';
import { isCurrentTemplateEditInProgress } from '../../selectors/Table';

import SelectTablesPageModeSelector from './SelectTablesPageModeSelector';
import ModifyTablesToggle from './ModifyTablesToggle';

import { SurveyCode } from '../../typescript/types';

import './ModifyTemplateTables.scss';

type Props = {
    numberOfIntermittentTables: number;
    setModalContentStatus: (status: string) => void;
    surveyCodes: SurveyCode[];
};

const ModifyTemplateTables = ({
    numberOfIntermittentTables = 0,
    setModalContentStatus,
    surveyCodes,
}: Props) => {
    const [isModifyingTables, setIsModifyingTables] = useState(false);
    const [modalContent, setModalContent] = useState(
        ReportActionModals.NO_MODAL,
    );
    const currentTemplateEditInProgress = useSelector(
        isCurrentTemplateEditInProgress,
    );
    const showTablesModifier = useCallback(() => setIsModifyingTables(true), [
        setIsModifyingTables,
    ]);
    const hideTablesModifier = useCallback(() => setIsModifyingTables(false), [
        setIsModifyingTables,
    ]);
    const openSaveModal = useCallback(
        () => setModalContent(ReportActionModals.SAVE_TEMPLATE_MODAL),
        [setModalContent],
    );
    const closeModal = useCallback(
        () => setModalContent(ReportActionModals.NO_MODAL),
        [setModalContent],
    );
    return (
        <div className="modify-template-tables">
            <ModifyTablesToggle
                changeInProgress={currentTemplateEditInProgress}
                numberOfIntermittentTables={numberOfIntermittentTables}
                isModifyingTables={isModifyingTables}
                showTablesModifier={showTablesModifier}
                hideTablesModifier={hideTablesModifier}
                openSaveModal={openSaveModal}
            />
            {isModifyingTables && (
                <SelectTablesPageModeSelector
                    setModalContentStatus={setModalContentStatus}
                    surveyCodes={surveyCodes}
                />
            )}
            <ReportActionsModal
                closeModal={closeModal}
                modalContent={modalContent}
            />
        </div>
    );
};

export default ModifyTemplateTables;
