import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import BackendPaths from '../../../helpers/BackendPaths';
import { copyToClipboard } from '../../../helpers/HtmlHelper';
import { getReportId } from '../../../selectors/Selection';

import Button from '../../Buttons/Button';

import './SharePanel.scss';
import { ReactComponent as SharePanelVisualization } from './share-panel-illustration.svg';

const SharePanel = () => {
    const reportId = useSelector(getReportId);
    const reportLink = BackendPaths.reportUrl.getPath({ reportId });

    return (
        <div id="share-panel">
            <div className="link-to-share">
                <FormattedMessage id="report.sidebar.share.linkToShare" />
            </div>
            <div className="link-box">{reportLink}</div>
            <div className="copy-link-button-container">
                <Button
                    variant="flat"
                    className="copy-link-button"
                    // we should alert user when link is copied
                    onClick={() => copyToClipboard(reportLink)}
                >
                    <FormattedMessage id="report.sidebar.share.copyLink" />
                </Button>
            </div>
            <div className="visualization-box">
                <SharePanelVisualization title="Share report" />
            </div>
        </div>
    );
};

export default SharePanel;
