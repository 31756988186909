import { CounterEvenType } from '../constants/Counter';
import { CounterEvent } from '../typescript/types';

const itemRequest = (eventValue: string): CounterEvent => {
    return {
        event_type: CounterEvenType.ITEM_REQUEST,
        event_value: eventValue,
    };
};
const itemInvestigation = (eventValue: string): CounterEvent => {
    return {
        event_type: CounterEvenType.ITEM_INVESTIGATION,
        event_value: eventValue,
    };
};
const searchPlatform = (eventValue: string): CounterEvent => {
    return {
        event_type: CounterEvenType.SEARCH_PLATFORM,
        event_value: eventValue,
    };
};
const searchRegular = (eventValue: string): CounterEvent => {
    return {
        event_type: CounterEvenType.SEARCH_REGULAR,
        event_value: eventValue,
    };
};

export const prepareCounterPayload = (
    events: CounterEvent | CounterEvent[],
) => {
    if (events instanceof Array) {
        if (!events.length) {
            throw new Error('Counter events array cannot be empty');
        }
        return events.flat();
    }
    return events;
};

interface CounterEvents {
    (value: string): CounterEvent[];
}

export const getDownloadProgramEvents: CounterEvents = (value) => [
    itemRequest(value),
    itemInvestigation(value),
];

export const getReportViewEvents: CounterEvents = (value) => [
    itemRequest(value),
    itemInvestigation(value),
];

export const getSearchEvents: CounterEvents = (value) => [
    searchRegular(value),
    searchPlatform(value),
];

export const getGeoTypesFetchedEvent: CounterEvents = (value) => [
    searchRegular(value),
    searchPlatform(value),
];

export const getParentGeoTypesFetchedEvent: CounterEvents = (value) => [
    searchRegular(value),
    searchPlatform(value),
];
export const getFetchGeoItemsByFipsCodesEvent: CounterEvents = (value) => [
    searchRegular(value),
    searchPlatform(value),
];
export const getDatasetsFetchedEvent: CounterEvents = (value) => [
    searchRegular(value),
    searchPlatform(value),
];
