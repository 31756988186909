import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SIGN_OUT_REQUEST } from '../../../constants/CurrentUser';
import { getUserInfo } from '../../../selectors/CurrentUser';
import Button from '../../Buttons/Button';
import './UserActions.scss';

const UserActions = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector(getUserInfo);

    if (!userInfo) {
        return null;
    }

    const [largeAvatarUrl, firstName, lastName, email, licenceTitle] = userInfo;

    return (
        <div className="user-actions">
            <div className="user-info">
                <img
                    className="large-image"
                    src={largeAvatarUrl}
                    alt="user avatar"
                />
                <div className="user-info-text">
                    <div className="first-and-last-name">{`${firstName} ${lastName}`}</div>
                    <div className="user-email">{email}</div>
                    <div className="user-license-plan">{licenceTitle}</div>
                </div>
            </div>
            <Button
                className="link sign-out"
                onClick={() => dispatch({ type: SIGN_OUT_REQUEST })}
            >
                <FormattedMessage id="report.userMenu.signOut" />
            </Button>
        </div>
    );
};

export default UserActions;
