import {
    all,
    call,
    put,
    select,
    take,
    takeEvery,
    takeLatest,
    takeLeading,
} from 'redux-saga/effects';
import { eventChannel, EventChannel } from 'redux-saga';

import api from '../helpers/Api';
import { ApiError } from '../helpers/Error';
import BackendPaths from '../helpers/BackendPaths';
import * as Selection from '../selectors/Selection';
import * as Report from '../selectors/Report';
import * as ReportData from '../selectors/ReportData';
import * as ReportMetadata from '../selectors/ReportMetadata';
import * as WizardHeader from '../selectors/WizardHeader';
import * as ReportMetadataSaga from './ReportMetadata';
import * as ReportCreateSaga from './ReportCreate';
import * as ReportDataSaga from './ReportData';
import * as HelperSaga from './Helper';
import * as SelectionConstants from '../constants/Selection';
import * as ReportConstants from '../constants/Report';
/* eslint-disable import/no-webpack-loader-syntax */
import ExcelExportWorker from 'worker-loader!../workers/exportExcel.worker.js';
import PdfExportWorker from 'worker-loader!../workers/exportPdf.worker.js';
/* eslint-enable import/no-webpack-loader-syntax */
import * as ReportApp from '../typescript/types';
import * as ReportActions from '../typescript/actions/ReportActions';
import { COUNTER_LOG_REQUEST } from '../constants/Counter';
import * as counterEvents from '../helpers/CounterEvents';
import {
    getExcelDownloadEventValue,
    getPdfDownloadEventValue,
    getReportViewEventValues,
} from '../selectors/Counter';
import { ReportAppActions } from '../typescript/actions/actions';

function* createReport() {
    let definitionRequest: ReportApp.DefinitionRequest;
    const doesReportExists: boolean = yield select(
        WizardHeader.reportAlreadyExists,
    );
    if (doesReportExists) {
        const definition: ReportApp.Definition = yield select(
            ReportMetadata.getDefinition,
        );
        let responses: ReportApp.AspNetWebService.ReportSelectionResults[];
        try {
            responses = yield ReportCreateSaga.fetchReportInfosForDefinition(
                definition,
            );
        } catch (error) {
            return yield HelperSaga.handleError(
                ReportConstants.CREATE_REPORT_FAILURE,
                error,
            );
        }
        const reportInfoByOldReportId = ReportCreateSaga.getReportInfo(
            definition,
            responses,
        );
        try {
            definitionRequest = yield ReportCreateSaga.getDefinitionRequest(
                definition,
                reportInfoByOldReportId,
            );
        } catch (error) {
            return yield HelperSaga.handleError(
                ReportConstants.CREATE_REPORT_FAILURE,
                error,
            );
        }
    } else {
        definitionRequest = yield ReportCreateSaga.getDefinitionRequestForNew();
    }
    let newReportId: string;
    try {
        newReportId = yield ReportCreateSaga.createReport(definitionRequest);
    } catch (error) {
        return yield HelperSaga.handleError(
            ReportConstants.CREATE_REPORT_FAILURE,
            error,
        );
    }
    yield put({
        type: SelectionConstants.SET_NEW_REPORT_ID,
        payload: newReportId,
    });
    yield put({ type: ReportConstants.CREATE_REPORT_SUCCESS });
}

function* fetchReport({ payload: { reportId } }: ReportActions.FetchReport) {
    const shouldFetchReportMetadata = yield select(
        ReportMetadata.getShouldFetchMetadata,
    );
    if (shouldFetchReportMetadata) {
        let response;
        try {
            response = yield ReportMetadataSaga.fetchDefinition(reportId);
        } catch (errorResponse) {
            return yield HelperSaga.handleError(
                ReportConstants.FETCH_REPORT_FAILURE,
                errorResponse,
            );
        }
        yield ReportMetadataSaga.storeDefinition(response);
    }
    const definition: ReportApp.Definition = yield select(
        ReportMetadata.getDefinition,
    );
    if (shouldFetchReportMetadata) {
        let metadataResponses: ReportApp.FetchMetadataResponse;
        try {
            metadataResponses = yield ReportMetadataSaga.fetchMetadata(
                definition,
            );
        } catch (errorResponse) {
            return yield HelperSaga.handleError(
                ReportConstants.FETCH_REPORT_FAILURE,
                errorResponse,
            );
        }
        try {
            yield ReportMetadataSaga.storeMetadata(
                definition,
                metadataResponses,
            );
        } catch (e) {
            if (e instanceof ApiError) {
                return yield HelperSaga.handleError(
                    ReportConstants.FETCH_REPORT_FAILURE,
                    e.payload,
                );
            }
            throw e;
        }

        const canUserChangeMetadata = yield select(
            ReportMetadata.getShouldAllowAggregation,
        );
        if (canUserChangeMetadata) {
            yield put({
                type: ReportConstants.SET_AGGREGATION_TYPE,
                payload: definition.geoGroups[0].aggregationType,
            });
        }
    }

    const shouldFetchTotalNumberOfGeographies = yield select(
        ReportMetadata.getShouldFetchTotalNumberOfGeographies,
    );
    if (shouldFetchTotalNumberOfGeographies) {
        const selectedAggregationType = yield select(Report.getAggregationType);
        let totalNumberOfGeographiesResponses;
        try {
            totalNumberOfGeographiesResponses = yield ReportMetadataSaga.fetchTotalNumberOfGeographies(
                definition,
                selectedAggregationType,
            );
        } catch (errorResponse) {
            return yield HelperSaga.handleError(
                ReportConstants.FETCH_REPORT_FAILURE,
                errorResponse,
            );
        }
        yield ReportMetadataSaga.storeTotalNumberOfGeographies(
            definition,
            totalNumberOfGeographiesResponses,
            selectedAggregationType,
        );
    }

    const shouldFetchReportFipsCodes = yield select(
        ReportMetadata.getShouldFetchReportFipsCodes,
    );
    if (shouldFetchReportFipsCodes) {
        let responses;
        try {
            responses = yield ReportMetadataSaga.fetchFipsCodes(definition);
        } catch (errorResponse) {
            return yield HelperSaga.handleError(
                ReportConstants.FETCH_REPORT_FAILURE,
                errorResponse,
            );
        }
        yield ReportMetadataSaga.storeFipsCodes(responses);

        // Now that we have all info needed, we will build header only once
        // and store it in metadata
        const geographiesHeader: ReportApp.Geography[] = yield select(
            ReportMetadata.calculateGeographiesHeader,
        );

        yield put<ReportAppActions>({
            type: ReportConstants.SET_GEOGRAPHIES_HEADER,
            payload: geographiesHeader,
        });
    }

    const shouldFetchData = yield select(ReportData.getShouldFetchData);
    if (shouldFetchData) {
        let dataResponses;
        try {
            dataResponses = yield ReportDataSaga.fetchReportData();
        } catch (errorResponse) {
            return yield HelperSaga.handleError(
                ReportConstants.FETCH_REPORT_FAILURE,
                errorResponse,
            );
        }
        yield ReportDataSaga.storeReportData(dataResponses);
    }
    const eventValues = yield select(getReportViewEventValues);
    yield put<ReportAppActions>({
        type: COUNTER_LOG_REQUEST,
        payload: eventValues.map((value: string) =>
            counterEvents.getReportViewEvents(value),
        ),
    });
    yield put({ type: ReportConstants.FETCH_REPORT_SUCCESS });
}

function* saveReport({ payload }: ReportActions.SaveReport) {
    const reportId = yield select(Selection.getReportId);
    const { reportTitle, reportDescription } = payload;
    let response;
    try {
        response = yield call(api, BackendPaths.saveReport.getPath(), 'POST', {
            report_id: reportId,
            report_title: reportTitle,
            report_description: reportDescription,
            tenant: process.env.REACT_APP_TENANT,
        });
    } catch (errorResponse) {
        return yield HelperSaga.handleError(
            ReportConstants.SAVE_REPORT_FAILURE,
            errorResponse,
        );
    }

    yield put({
        type: ReportConstants.SET_SAVED_REPORT_INFO,
        payload: {
            isSaved: true,
            isOwnedByCurrentUser: true,
            reportTitle: response.title,
            reportDescription: response.description,
        },
    });
    yield put({ type: ReportConstants.SAVE_REPORT_SUCCESS });
}

function* deleteReport() {
    const reportId = yield select(Selection.getReportId);
    try {
        yield call(api, BackendPaths.deleteReport.getPath(), 'POST', {
            report_id: reportId,
        });
    } catch (errorResponse) {
        return yield HelperSaga.handleError(
            ReportConstants.DELETE_REPORT_FAILURE,
            errorResponse,
        );
    }

    yield put({
        type: ReportConstants.SET_SAVED_REPORT_INFO,
        payload: {
            isUserSavedReport: false,
            reportTitle: null,
            reportDescription: null,
        },
    });
    yield put({ type: ReportConstants.DELETE_REPORT_SUCCESS });
}

function* updateReport({ payload }: ReportActions.UpdateReport) {
    let reportId = yield select(Selection.getReportId);
    const surveyCode = yield select(Selection.getSurveyCode);
    const { reportTitle, reportDescription } = payload;

    try {
        yield call(api, BackendPaths.updateReport.getPath(), 'POST', {
            report_id: reportId,
            report_survey: surveyCode,
            report_title: reportTitle,
            report_description: reportDescription,
            tenant: process.env.REACT_APP_TENANT,
        });
    } catch (errorResponse) {
        return yield HelperSaga.handleError(
            ReportConstants.UPDATE_REPORT_FAILURE,
            errorResponse,
        );
    }

    yield put({
        type: ReportConstants.SET_SAVED_REPORT_INFO,
        payload: {
            isSaved: true,
            isOwnedByCurrentUser: true,
            reportTitle: reportTitle,
            reportDescription: reportDescription,
        },
    });
    yield put({ type: ReportConstants.UPDATE_REPORT_SUCCESS });
}

function* duplicateReport({ payload }: ReportActions.DuplicateReport) {
    let reportId = yield select(Selection.getReportId);
    let response;
    const { reportTitle, reportDescription } = payload;
    try {
        response = yield call(
            api,
            BackendPaths.duplicateReport.getPath(),
            'POST',
            {
                report_id: reportId,
                report_title: reportTitle,
                report_description: reportDescription,
                tenant: process.env.REACT_APP_TENANT,
            },
        );
    } catch (errorResponse) {
        return yield HelperSaga.handleError(
            ReportConstants.DUPLICATE_REPORT_FAILURE,
            errorResponse,
        );
    }

    yield put({
        type: ReportConstants.SET_SAVED_REPORT_INFO,
        payload: {
            isUserSavedReport: true,
            reportTitle: reportTitle,
            reportDescription: reportDescription,
        },
    });
    yield put({
        type: ReportConstants.DUPLICATE_REPORT_SUCCESS,
        payload: response.id,
    });
}

function* downloadPdf({ payload }: ReportActions.DownloadPdfRequest) {
    const reportId: string = yield select(Selection.getReportId);
    const state = yield select(ReportMetadata.getState);
    const worker = new PdfExportWorker();
    worker.postMessage({
        msg: 'export',
        payload: {
            reportId,
            state,
            orientation: payload.orientation,
        },
    });
    const channel: EventChannel<Blob> = yield call(createWorkerChannel, worker);
    while (true) {
        const payload: Blob = yield take(channel);
        yield call(handlePdfWorkerResponse, payload, reportId, channel);
    }
}

function* downloadExcel({ payload }: ReportActions.DownloadExcelRequest) {
    const reportId: string = yield select(Selection.getReportId);
    const state = yield select(ReportMetadata.getState);

    const worker = new ExcelExportWorker();
    worker.postMessage({
        msg: 'export',
        payload: {
            reportId,
            state,
            isTranspose: payload.isTranspose,
        },
    });
    const channel: EventChannel<Blob> = yield call(createWorkerChannel, worker);
    while (true) {
        const payload: Blob = yield take(channel);
        yield call(handleExcelWorkerResponse, payload, reportId, channel);
    }
}
function createWorkerChannel(worker: ExcelExportWorker) {
    return eventChannel((emit) => {
        const onMessage = (event: MessageEvent) => emit(event.data);
        worker.addEventListener('message', onMessage, false);

        return () => worker.terminate();
    });
}
function downloadBlob(blob: any, filename: string) {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(new Blob([blob]));
    a.href = url;
    a.download = filename;

    a.click();

    window.URL.revokeObjectURL(url);

    if (a?.parentElement) {
        a.parentElement.removeChild(a);
    }
}
function* handleExcelWorkerResponse(
    response: any,
    reportId: string,
    channel: EventChannel<Blob>,
) {
    const { error } = response;
    if (error) {
        yield put<ReportAppActions>({
            type: ReportConstants.DOWNLOAD_EXCEL_FAILURE,
            payload: { error: error.message.toString() },
        });
        return;
    }

    downloadBlob(response, `${reportId}.xlsx`);

    channel.close();
    const counterEventValue = yield select(getExcelDownloadEventValue);
    yield put<ReportAppActions>({
        type: COUNTER_LOG_REQUEST,
        payload: counterEvents.getDownloadProgramEvents(counterEventValue),
    });
    yield put({ type: ReportConstants.DOWNLOAD_EXCEL_SUCCESS });
}

function* handlePdfWorkerResponse(
    response: any,
    reportId: string,
    channel: EventChannel<Blob>,
) {
    const { error } = response;
    if (error) {
        yield put<ReportAppActions>({
            type: ReportConstants.DOWNLOAD_PDF_FAILURE,
            payload: { error: error.message.toString() },
        });
        return;
    }

    downloadBlob(response, `${reportId}.pdf`);

    channel.close();
    const counterEventValue = yield select(getPdfDownloadEventValue);
    yield put<ReportAppActions>({
        type: COUNTER_LOG_REQUEST,
        payload: counterEvents.getDownloadProgramEvents(counterEventValue),
    });
    yield put({ type: ReportConstants.DOWNLOAD_PDF_SUCCESS });
}

export default function* () {
    yield all([
        yield takeEvery(ReportConstants.CREATE_REPORT_REQUEST, createReport),
        yield takeLeading(ReportConstants.SAVE_REPORT_REQUEST, saveReport),
        yield takeLeading(ReportConstants.DELETE_REPORT_REQUEST, deleteReport),
        yield takeLeading(ReportConstants.UPDATE_REPORT_REQUEST, updateReport),
        yield takeLeading(
            ReportConstants.DUPLICATE_REPORT_REQUEST,
            duplicateReport,
        ),
        yield takeLeading(
            ReportConstants.DOWNLOAD_EXCEL_REQUEST,
            downloadExcel,
        ),
        yield takeLeading(ReportConstants.DOWNLOAD_PDF_REQUEST, downloadPdf),
        yield takeLatest(ReportConstants.FETCH_REPORT_REQUEST, fetchReport),
    ]);
}
