import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import * as Table from '../../selectors/Table';
import * as ReportMetadata from '../../selectors/ReportMetadata';
import * as TableConstants from '../../constants/Table';

import Button from '../Buttons/Button';
import SelectedTablesList from './SelectedTablesList';
import SelectionCounter from '../Selection/SelectionCounter';

import './SelectedTablesList.scss';

/** @param {{surveyCodes: string[]}} param0 */
const SelectedTables = ({ surveyCodes }) => {
    const dispatch = useDispatch();
    /** @type {import('../../typescript/types').TableForReportBySurveyCode[]} */
    const intermittentTables = useSelector(Table.getIntermittentTables);
    const shouldSetIntermittentTables = useSelector((state) =>
        Table.getShouldSetIntermittentTables(state, surveyCodes),
    );
    const selectedTables = useSelector(
        ReportMetadata.getTablesConstructedForReport,
    );

    useEffect(() => {
        if (shouldSetIntermittentTables) {
            // Intermittent selection is needed because we want to save current tables selection, even in case when user goes back
            // to geographies and then again to tables. But we do not want to save those temporary selected tables inside the
            // real selection - until the `Next` button is clicked
            dispatch({
                type: TableConstants.SET_INTERMITTENT_TABLES_SELECTION,
                payload: selectedTables,
            });
        }
    }, [shouldSetIntermittentTables, dispatch, selectedTables]);

    const intl = useIntl();

    if (!intermittentTables || intermittentTables.length === 0) {
        return null;
    }

    const onClearAll = () =>
        dispatch({
            type: TableConstants.SET_INTERMITTENT_TABLES_SELECTION,
            payload: [],
        });

    const clearListAriaLabel = intl.formatMessage(
        {
            id: `tablesPage.selection.clearListAriaLabel.${
                intermittentTables.length > 1 ? 'plural' : 'singular'
            }`,
        },
        { count: intermittentTables.length },
    );

    return (
        <div className="selected-tables-container">
            <div className="selected-tables-list">
                <div className="selected-tables-header">
                    <SelectionCounter
                        numberOfSelectedItems={intermittentTables.length}
                    />
                    <Button
                        variant="flat"
                        onClick={onClearAll}
                        title={clearListAriaLabel}
                        aria-label={clearListAriaLabel}
                    >
                        <FormattedMessage id="clear" />
                    </Button>
                </div>
                <SelectedTablesList selectedTables={intermittentTables} />
            </div>
        </div>
    );
};

export default SelectedTables;
