import { combineReducers } from 'redux';
import sidebar from './sidebarReducer';
import currentUser from './currentUserReducer';
import selectSurveyPageStatus from './selectSurveyPageStatusReducer';
import newSurvey from './newSurveyReducer';
import selectTablesPageStatus from './selectTablesPageStatusReducer';
import selectGeographyPageStatus from './selectGeographyPageStatusReducer';
import selection from './selectionReducer';
import reportStatus from './reportStatusReducer';
import downloadReport from './downloadReportReducer';
import reportNavigator from './reportNavigatorReducer';
import reportMetadata from './reportMetadataReducer';
import reportView from './reportViewReducer';
import tableGuidBySurveyCodeTableName from './tableGuidBySurveyCodeTableNameReducer';
import templateStatus from './templateStatusReducer';
import db from './dbReducer';
import reportTotalsMetadata from './reportTotalsMetadataReducer';

export default combineReducers({
    currentUser,
    selection,
    selectSurveyPageStatus,
    newSurvey,
    selectTablesPageStatus,
    selectGeographyPageStatus,
    reportStatus,
    downloadReport,
    reportNavigator,
    reportMetadata,
    reportTotalsMetadata,
    reportView,
    sidebar,
    tableGuidBySurveyCodeTableName,
    templateStatus,
    db,
});
