import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import * as Table from '../../../selectors/Table';
import * as SelectTablesPage from '../../../constants/SelectTablesPage';

import Modal from '../../base/Modal/Modal';
import Loader from '../../Loader/Loader';
import TablesSelector from './TablesSelector';

/** @param {{closeModal: () => void, surveyCodes: string[] }} param0 */
const TablesSelectorModal = ({ closeModal, surveyCodes }) => {
    const datasetsDisplayStatus = useSelector((state) =>
        Table.getDatasetsDisplayStatus(state, surveyCodes),
    );
    const error = useSelector(Table.getDatasetsError);
    let content;
    switch (datasetsDisplayStatus) {
        case SelectTablesPage.DATASETS_ERROR:
            content = (
                <FormattedMessage
                    id="tablesPage.tablesSelection.errorLoadingDatasets"
                    values={{
                        error: error,
                    }}
                />
            );
            break;
        case SelectTablesPage.DATASETS_LOADING:
            content = <Loader />;
            break;
        case SelectTablesPage.DATASETS_SELECTION:
        default:
            content = (
                <TablesSelector
                    closeModal={closeModal}
                    surveyCodes={surveyCodes}
                />
            );
            break;
    }
    return (
        <Modal className="full-width" onClickAway={closeModal}>
            {content}
        </Modal>
    );
};

export default TablesSelectorModal;
