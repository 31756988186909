import { combineReducers } from 'redux';
import surveys from './surveysReducer';
import surveyGroups from './surveyGroupsReducer';
import tables from './tableReducer';
import datasets from './datasetReducer';
import geoTypes from './geoTypesReducer';
import premadeReports from './premadeReportsReducer';
import reportData from './reportDataReducer';
import cpi from './cpiReducer';
import template from './templateReducer';
import * as ReportApp from '../typescript/types';

export interface DB {
    surveys: ReportApp.SurveyBySurveyCode;
    surveyGroups: ReportApp.SurveyGroupById;
    tables: ReportApp.TableByGuid;
    /** key is surveyCode */
    datasets: { [key: string]: ReportApp.Dataset[] | undefined };
    /** First key is surveyCode. Second key is SL level */
    geoTypes: { [key: string]: { [key: string]: ReportApp.GeoType } };
    /** key is surveyCode */
    premadeReports: { [key: string]: ReportApp.PremadeReport[] };
    /** key is oldReportId */
    reportData: { [key: string]: ReportApp.ReportData | undefined };
    /** key is year, and the value is the consumer price index for that year */
    cpi: ReportApp.ConsumerPriceIndex;
    template: ReportApp.Template[];
}

export default combineReducers({
    surveys,
    surveyGroups,
    tables,
    datasets,
    geoTypes,
    premadeReports,
    reportData,
    cpi,
    template,
});
