import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useSelector } from 'react-redux';
import * as ReportMetadata from '../../../selectors/ReportMetadata';

import DollarYearAdjustment from './DollarYearAdjustment';
import AggregationType from './AggregationType';
import ModifyTables from './ModifyGeoAndTable/ModifyTables';
import ModifyGeographies from './ModifyGeoAndTable/ModifyGeographies';
import ModifySurveys from './ModifySurvey/ModifySurveys';
import WarningMessage from '../../WarningMessage/WarningMessage';

import './ModifyReportPanel.scss';

const ModifyReportPanel = () => {
    const isDefinitionAndByOldReportIdLoaded = useSelector(
        ReportMetadata.getIsDefinitionAndByOldReportIdLoaded,
    );

    const enableGeographiesModification = useSelector(
        ReportMetadata.getShouldEnableGeosModification,
    );

    if (!isDefinitionAndByOldReportIdLoaded) {
        return null;
    }

    const modifyTablesAndGeographies = enableGeographiesModification ? (
        <>
            <h5>
                <FormattedMessage id="report.sidebar.modify.subheader.tablesAndGeos" />
            </h5>
            <ModifyTables />
            <ModifyGeographies />
        </>
    ) : (
        <>
            <h5>
                <FormattedMessage id="report.sidebar.modify.subheader.tables" />
            </h5>
            <ModifyTables />
            <WarningMessage
                messageId="report.sidebar.modify.modifyGeosWarning"
                values={{
                    b: (chunks) => <strong>{chunks}</strong>,
                }}
            />
        </>
    );

    return (
        <div id="modify-report-panel">
            <ModifySurveys />
            <div className="section">{modifyTablesAndGeographies}</div>
            <div className="section">
                <AggregationType />
                <DollarYearAdjustment />
            </div>
        </div>
    );
};

export default ModifyReportPanel;
