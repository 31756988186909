import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import * as WizardHeaderSelector from '../../selectors/WizardHeader';
import { getShouldProceedToReportCreation } from '../../selectors/NewSurvey';

import WizardHeaderControls, {
    Props as WizardHeaderControlsProps,
} from './WizardHeaderControls';
import WizardHeaderTitle from './WizardHeaderTitle';
import ProgressBar from '../ProgressBar/ProgressBar';

import './WizardHeader.scss';

type Props = {} & WizardHeaderControlsProps;

const WizardHeader = ({ ...rest }: Props) => {
    const shouldProceedToReportCreation = useSelector(
        getShouldProceedToReportCreation,
    );
    const modifyMode = useSelector(
        WizardHeaderSelector.getIsWizardInModifyMode,
    );

    if (shouldProceedToReportCreation) {
        return null;
    }

    const headerClasses = classNames('header', {
        'modify-mode': modifyMode,
    });

    return (
        <div className={headerClasses}>
            <div className="header-content">
                <WizardHeaderTitle modifyMode={modifyMode} {...rest} />
                <WizardHeaderControls {...rest} />
            </div>
            {!modifyMode && <ProgressBar {...rest} />}
        </div>
    );
};

export default WizardHeader;
