import React, { useState } from 'react';

import { NavigatorOptions } from '../../../constants/ReportNavigator';

import GeographiesTablesSwitch from './GeographiesTablesSwitch';
import GeosNavigatorParameter from './GeosNavigatorParameter';
import TablesNavigatorParameter from './TablesNavigatorParameter';
import VerticalDivider from '../../VerticalDivider/VerticalDivider';

const DetailedNavigator = () => {
    const [selectedNavigatorOption, setSelectedNavigatorOption] = useState(
        NavigatorOptions.GEOGRAPHIES,
    );

    let content;
    switch (selectedNavigatorOption) {
        case NavigatorOptions.TABLES:
            content = <TablesNavigatorParameter />;
            break;
        case NavigatorOptions.GEOGRAPHIES:
        default:
            content = <GeosNavigatorParameter />;
            break;
    }

    return (
        <div className="detailed-navigator">
            <GeographiesTablesSwitch
                selectedNavigatorOption={selectedNavigatorOption}
                setSelectedNavigatorOption={setSelectedNavigatorOption}
            />
            <div className="with-divider">
                {content}
                <VerticalDivider />
            </div>
        </div>
    );
};

export default DetailedNavigator;
