import { all } from 'redux-saga/effects';
import init from './Init';
import currentUser from './CurrentUser';
import survey from './Survey';
import tableApi from './TableApi';
import geographiesApi from './GeographiesApi';
import geographiesActions from './GeographiesActions';
import reportApi from './ReportApi';
import reportActions from './ReportActions';
import counter from './Counter';
import templateApi from './TemplateApi';

export default function* rootSaga() {
    yield all([
        init(),
        currentUser(),
        survey(),
        geographiesApi(),
        geographiesActions(),
        tableApi(),
        reportApi(),
        reportActions(),
        counter(),
        templateApi(),
    ]);
}
