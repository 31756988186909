import React, { useState } from 'react';
import CollapseInfoTrigger, {
    BaseProps as CollapseInfoTriggerBaseProps,
} from './CollapseInfoTrigger';
import Surface from '../../../base/Surface/Surface';

type Props = {
    renderOnExpand: () => React.ReactNode;
    className?: string;
} & CollapseInfoTriggerBaseProps;

const CollapseInfo = ({ renderOnExpand, className, ...rest }: Props) => {
    const [open, setOpen] = useState(false);
    const toggleOpen = () => setOpen((isOpen) => !isOpen);
    const element: React.ReactNode = open ? renderOnExpand() : null;
    return (
        <Surface level="1" className={className}>
            <CollapseInfoTrigger
                open={open}
                toggleOpen={toggleOpen}
                {...rest}
            />
            {element}
        </Surface>
    );
};

export default CollapseInfo;
