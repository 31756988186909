import * as ReportNavigator from '../constants/ReportNavigator';
import * as ReportApp from '../typescript/types';
import { ReportAppActions } from '../typescript/actions/actions';

const INITIAL_STATE: ReportApp.ReportNavigator = {
    tablePage: 1,
    tablesPerPage: ReportNavigator.DEFAULT_ITEMS_PER_PAGE,
    geoItemPage: 1,
    geoItemsPerPage: ReportNavigator.DEFAULT_ITEMS_PER_PAGE,
};

export default (state = INITIAL_STATE, action: ReportAppActions) => {
    switch (action.type) {
        case ReportNavigator.SET_TABLE_PAGE:
            return {
                ...state,
                tablePage: action.payload,
            };
        case ReportNavigator.SET_GEO_ITEM_PAGE:
            return {
                ...state,
                geoItemPage: action.payload,
            };
        case ReportNavigator.SET_TABLES_PER_PAGE:
            return {
                ...state,
                tablesPerPage: action.payload,
            };
        case ReportNavigator.SET_GEO_ITEMS_PER_PAGE:
            return {
                ...state,
                geoItemsPerPage: action.payload,
            };
        case ReportNavigator.RESET_TABLE_PAGE:
            return {
                ...state,
                tablePage: INITIAL_STATE.tablePage,
            };
        case ReportNavigator.RESET_GEO_ITEM_PAGE:
            return {
                ...state,
                geoItemPage: INITIAL_STATE.geoItemPage,
            };
        case ReportNavigator.RESET_REPORT_NAVIGATOR:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
