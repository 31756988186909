import React from 'react';
import classNames from 'classnames';
import './Icon.scss';

type Props = {} & React.HTMLAttributes<HTMLElement>;

const Icon = ({
    children,
    className = '',
    'aria-hidden': ariaHidden = true,
    ...rest
}: Props) => {
    const classes = classNames('material-icons', className);
    return (
        <i className={classes} {...rest} aria-hidden={ariaHidden}>
            {children}
        </i>
    );
};

export default Icon;
