import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Template } from '../../typescript/types';
import { getSurveyNameByCode } from '../../selectors/Survey';

import Surface from '../base/Surface/Surface';

import './SelectTemplateHeroSection.scss';

type Props = {
    tableTemplate: Template;
};

const SelectTemplateHeroSection = ({ tableTemplate }: Props) => {
    const intl = useIntl();
    const title = tableTemplate.title;
    const {
        comparabilityStartYear,
        comparabilityEndYear,
        surveyCode,
    } = tableTemplate;

    const surveyName = useSelector((state) =>
        getSurveyNameByCode(state, surveyCode),
    );

    const comparabilityYearsContainer =
        comparabilityStartYear && comparabilityEndYear ? (
            <div className="comparability-years">
                <FormattedMessage
                    id="tablesPage.templates.comparabilityYears"
                    values={{
                        comparabilityStartYear,
                        comparabilityEndYear,
                    }}
                />
            </div>
        ) : null;

    return (
        <Surface
            level="1"
            variant="green"
            className="select-template-hero-section"
        >
            <h1 className="title">
                {intl.formatMessage(
                    { id: 'header.title.noReport.withNameSimple' },
                    { name: title },
                )}
            </h1>
            <div className="survey-name">{surveyName}</div>
            {comparabilityYearsContainer}
        </Surface>
    );
};

export default SelectTemplateHeroSection;
