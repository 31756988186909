import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import AppScreen from '../../constants/AppScreen';
import { getBackwardButtonOptions } from '../../selectors/WizardHeader';
import LinkButton from '../LinkButton/LinkButton';

import './HeaderBackwardButton.scss';

import DOMElementID from '../../constants/DOMElementID';

type Props = { currentAppScreen: AppScreen };

const HeaderBackwardButton = ({ currentAppScreen }: Props) => {
    const intl = useIntl();
    const { text, link, shouldDisplay } = useSelector((state) =>
        getBackwardButtonOptions(state, currentAppScreen),
    );

    return shouldDisplay ? (
        <LinkButton
            role="button"
            id={DOMElementID.HEADER_BACK_BUTTON}
            variant="flat"
            className="header-back-button"
            url={link}
            aria-label={intl.formatMessage({
                id: 'layoutNavigation.back2previousScreen',
            })}
        >
            {text}
        </LinkButton>
    ) : null;
};

export default HeaderBackwardButton;
