import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { getRedirectURL } from '../selectors/AppScreen';

export const withSelectionSafeguard = (BaseComponent: React.ElementType) => ({
    ...props
}) => {
    const location = useLocation();
    const redirectURL = useSelector((state) =>
        getRedirectURL(
            state,
            location.pathname,
            new URLSearchParams(location.search),
        ),
    );
    if (redirectURL) {
        return <Redirect to={redirectURL} />;
    }
    return <BaseComponent {...props} />;
};
