import React, { useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import * as Report from '../../selectors/Report';
import * as ReportConstants from '../../constants/Report';
import * as Selection from '../../selectors/Selection';
import ApplicationPaths from '../../helpers/ApplicationPaths';
import * as ReportScreenConstants from '../../constants/ReportScreen';
import { withSelectionSafeguard } from '../../hoc/withSelectionSafeguard';
import { ReportAppDispatch } from '../../typescript/actions/actions';

import Loader from '../Loader/Loader';

function NewReport() {
    const dispatch = useDispatch<ReportAppDispatch>();
    const reportId = useSelector(Selection.getNewReportId);
    const shouldCreateReport = useSelector(Report.getShouldCreateReport);
    const createReportStatus = useSelector(Report.getCreateReportStatus);
    const createReportError = useSelector(Report.getCreateReportError);

    useEffect(() => {
        if (shouldCreateReport) {
            dispatch({ type: ReportConstants.CREATE_REPORT_REQUEST });
        }
    }, [dispatch, shouldCreateReport]);

    switch (createReportStatus) {
        case ReportScreenConstants.CREATE_REPORT_ERROR:
            return <div>{createReportError}</div>;
        case ReportScreenConstants.CREATE_REPORT_DONE:
            return (
                <Redirect
                    to={ApplicationPaths.existingReport.getPath({ reportId })}
                />
            );
        case ReportScreenConstants.CREATE_REPORT_LOADING:
        default:
            return <Loader />;
    }
}

export default withSelectionSafeguard(withRouter(NewReport));
