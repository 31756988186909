import React, { useState, ChangeEvent } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import { PremadeReportWithTables } from '../../../../typescript/types';

import PremadeReportsFilterableByTextListItemRow from './PremadeReportsFilterableByTextListItemRow';
import PremadeReportsFilterableByTextListItemDetailList from './PremadeReportsFilterableByTextListItemDetailList';
import PremadeReportsFilterableByTextListDividerItem from './PremadeReportsFilterableByTextListDividerItem';

import './PremadeReportsFilterableByTextListItem.scss';

type Props = {
    report: PremadeReportWithTables;
    isSelected: (id: number) => boolean;
};

const PremadeReportsFilterableByTextListItem = ({
    report: { id, name, tables, isDivider = false },
    isSelected,
}: Props) => {
    const [expanded, setExpanded] = useState(false);
    const intl = useIntl();

    if (isDivider) {
        return <PremadeReportsFilterableByTextListDividerItem id={id} />;
    }

    const toggleExpand = (event: ChangeEvent) => {
        event.stopPropagation();
        setExpanded((expanded) => !expanded);
    };

    const listItemClasses = classNames('premade-reports-item', {
        selected: isSelected(id),
    });

    const selectListItemAriaLabel = intl.formatMessage(
        {
            id: `tablesPage.premadeReports.listItemAriaLabel.${
                isSelected(id) ? 'selected' : 'deselected'
            }`,
        },
        {
            title: name,
            tableNameList: tables.reduce((a, c) => `${a}, ${c.title}`, ''),
        },
    );
    return (
        <li
            className={listItemClasses}
            aria-label={selectListItemAriaLabel}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role="button"
        >
            <PremadeReportsFilterableByTextListItemRow
                expanded={expanded}
                title={name}
                onClick={toggleExpand}
            />
            {expanded && (
                <PremadeReportsFilterableByTextListItemDetailList
                    tables={tables}
                />
            )}
        </li>
    );
};

export default PremadeReportsFilterableByTextListItem;
