import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
    getDeleteReportDisplayStatus,
    getDeleteReportError,
} from '../../../selectors/ReportHeader';
import * as ReportScreenConstants from '../../../constants/ReportScreen';
import { DELETE_REPORT_REQUEST } from '../../../constants/Report';

import Loader from '../../Loader/Loader';
import ReportActionsModalContent from './ReportActionsModalContent';

type Props = {
    onClose: () => void;
};

const DeleteReportModalContent = ({ onClose }: Props) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const deleteReportDisplayStatus = useSelector(getDeleteReportDisplayStatus);
    const deleteReportError = useSelector(getDeleteReportError);
    // used to trigger close modal after deleting is done
    const [requestDispatched, setRequestDispatched] = useState(false);

    const requestDeleteReport = () => {
        dispatch({
            type: DELETE_REPORT_REQUEST,
        });
        setRequestDispatched(true);
    };

    useEffect(() => {
        if (
            deleteReportDisplayStatus ===
                ReportScreenConstants.DELETE_REPORT_STATUS_AVAILABLE &&
            requestDispatched
        ) {
            onClose();
        }
    }, [onClose, requestDispatched, deleteReportDisplayStatus]);

    let content;
    switch (deleteReportDisplayStatus) {
        case ReportScreenConstants.DELETE_REPORT_STATUS_ERROR:
            content = <div>{deleteReportError}</div>;
            break;
        case ReportScreenConstants.DELETE_REPORT_STATUS_LOADING:
            content = <Loader />;
            break;
        case ReportScreenConstants.DELETE_REPORT_STATUS_AVAILABLE:
        default:
            content = intl.formatMessage({
                id: 'report.deleteReport.removeWarning',
            });
    }

    return (
        <ReportActionsModalContent
            header={intl.formatMessage({
                id: 'report.deleteReport.deleteHeader',
            })}
            onClose={onClose}
            mainButton={{
                action: requestDeleteReport,
                text: intl.formatMessage({
                    id: 'report.deleteReport.confirmDelete',
                }),
            }}
            withMinHeight={false}
        >
            {content}
        </ReportActionsModalContent>
    );
};

export default DeleteReportModalContent;
