import React from 'react';
import { FormattedMessage } from 'react-intl';

import './SelectYearsInfo.scss';

const SelectYearsInfo = () => {
    return (
        <div className="select-years-info">
            <FormattedMessage id="report.sidebar.modify.surveys.info" />
        </div>
    );
};

export default SelectYearsInfo;
