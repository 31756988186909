import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getIsUserLoggedIn } from '../../../selectors/CurrentUser';
import { ReportActionModals } from '../../../constants/ReportActionsEnum';

import Button from '../../Buttons/Button';
import Icon from '../../Icon/Icon';

type Props = {
    openModal: (modal: ReportActionModals) => void;
};

const CreateTemplateAction = ({ openModal }: Props) => {
    const intl = useIntl();
    const isUserLogged = useSelector(getIsUserLoggedIn);
    const buttonTitle = isUserLogged
        ? undefined
        : intl.formatMessage({
              id: 'report.reportActions.availableForLoggedUsers',
          });

    const openModalIfLogged = () => {
        if (isUserLogged) {
            openModal(ReportActionModals.SAVE_TEMPLATE_MODAL);
        }
    };

    return (
        <>
            <ul className="menu-list">
                <li className="menu-list-item">
                    <div className="menu-subtitle">
                        <FormattedMessage id="report.reportActions.createATemplate" />
                    </div>
                </li>
                <li
                    className="menu-list-item"
                    // `buttonTitle` is placed on `li` element, since if it was placed on the button,
                    // it would not be shown on hover since button is disabled
                    title={buttonTitle}
                    aria-label={buttonTitle}
                >
                    <Button
                        variant="flat"
                        className="menu-list-item-button"
                        onClick={openModalIfLogged}
                        disabled={!isUserLogged}
                    >
                        <Icon>list_alt</Icon>
                        <FormattedMessage id="report.reportActions.createTablesTemplate" />
                    </Button>
                </li>
            </ul>
            <hr />
        </>
    );
};

export default CreateTemplateAction;
