import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import {
    getCitationTableDataInReport,
    getSurveyCodesUsedInReport,
} from '../../selectors/ReportMetadata';
import * as Table from '../../selectors/Table';
import { copyToClipboard } from '../../helpers/HtmlHelper';
import * as SelectTablesPageConstants from '../../constants/SelectTablesPage';
import { CitationFormat } from '../../typescript/types';

import Button from '../Buttons/Button';
import Loader from '../Loader/Loader';

import './TablesCitationPanel.scss';

type Props = {
    citationFormat: CitationFormat;
};

const Citations = ({ citationFormat }: Props) => {
    const surveyCodesUsedInReport = useSelector(getSurveyCodesUsedInReport);

    const datasetsDisplayStatus = useSelector((state) =>
        Table.getDatasetsDisplayStatus(state, surveyCodesUsedInReport),
    );

    const datasetsLoadingError = useSelector(Table.getDatasetsError);

    const formattedDate = new Intl.DateTimeFormat(
        'en-AU',
        citationFormat.dateOptions,
    ).format(new Date());

    const citationData = useSelector(getCitationTableDataInReport).map(
        (citationTableData) =>
            citationFormat.template({
                ...citationTableData,
                date: formattedDate,
            }),
    );

    switch (datasetsDisplayStatus) {
        case SelectTablesPageConstants.DATASETS_ERROR:
            return (
                <div>
                    <FormattedMessage
                        id="tablesPage.tablesSelection.errorLoadingDatasets"
                        values={{
                            error: datasetsLoadingError,
                        }}
                    />
                </div>
            );
        case SelectTablesPageConstants.DATASETS_LOADING:
            return <Loader />;
        default: {
            return (
                <>
                    <div className="citation-box">
                        {citationData.map((citation, idx) => (
                            <p key={idx}>{citation}</p>
                        ))}
                    </div>
                    <div className="copy-link-button-container">
                        <Button
                            variant="flat"
                            className="copy-link-button"
                            onClick={() =>
                                copyToClipboard(citationData.join('\n\n'))
                            }
                        >
                            <FormattedMessage id="report.sidebar.citation.copyLabel" />
                        </Button>
                    </div>
                </>
            );
        }
    }
};

export default Citations;
