import React from 'react';
import { FormattedMessage } from 'react-intl';
import BackendPaths from '../../helpers/BackendPaths';
import VerticalDivider from '../VerticalDivider/VerticalDivider';
import './DocumentationAndDictionary.scss';

type Props = {
    surveyCode: string;
};

const DocumentationAndDictionary = ({ surveyCode }: Props) => {
    const documentationUrl = BackendPaths.documentationUrl.getPath({
        surveyCode,
    });
    const dataDictionaryUrl = BackendPaths.dataDictionaryUrl.getPath({
        surveyCode,
    });

    return (
        <div className="survey-info-buttons-container">
            <a
                className="survey-info-button"
                href={documentationUrl}
                target="_blank"
                rel="noopener noreferrer"
            >
                <FormattedMessage id="report.sidebar.info.documentationLink" />
            </a>
            <VerticalDivider className="info-panel-divider" />
            <a
                className="survey-info-button"
                href={dataDictionaryUrl}
                target="_blank"
                rel="noopener noreferrer"
            >
                <FormattedMessage id="report.sidebar.info.dataDictionaryLink" />
            </a>
        </div>
    );
};

export default DocumentationAndDictionary;
