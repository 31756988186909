export function isElementChildOf(
    element: HTMLElement | null,
    parent: HTMLElement,
): boolean {
    if (!element) {
        return false;
    }
    if (element === parent) {
        return true;
    }
    return isElementChildOf(element.parentElement, parent);
}

export const copyToClipboard = (str: string) => {
    if (!str) {
        return;
    }
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';

    document.body.appendChild(el);
    const selection = document.getSelection();
    if (!selection) {
        document.body.removeChild(el);
        return;
    }
    const range = selection.rangeCount ?? 0;
    const selected = range > 0 ? selection.getRangeAt(0) : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
        selection.removeAllRanges();
        selection.addRange(selected);
    }
};

export const getFirstChildOf = (
    parent: HTMLElement,
    start: HTMLElement | null,
): HTMLElement | null => {
    if (!start) {
        return null;
    }
    if (start.parentElement === parent) {
        return start;
    }
    return getFirstChildOf(parent, start.parentElement);
};

export const focusElementById = (id: string) => {
    if (!id) {
        return;
    }

    const element = document.getElementById(id);
    if (element) {
        element.focus();
    }
};

/**
 * Uses regex to extract URL and text from anchor tag. Assumes that input
 * contains one URL. Exec should return one match with two capture groups if
 * html is valid. Match is interpreted as follows: first entry (matches[0]) is
 * the match itself (as per the implementation of the exec method), followed by
 * capture groups. First capture group is the URL (matches[1]). Second capture
 * group is the text inside of the anchor tag (matches[2]). Use this function
 * only if you are sure that we control the input. Never use it for user inputs.
 *
 * Visualization of regex: https://jex.im/regulex/#!flags=img&re=%3C%5Cs*a%5Cs%2B.*href%3D%5B'%22%5D(%5B%5E'%22%5D%2B)%5B'%22%5D.*%3E(.%2B)%3C%5Cs*%5C%2F%5Cs*a%5Cs*%3E
 */
export const extractUrlFromHtmlTag = (input: string) => {
    const urlRegex = /<\s*a\s+.*href=['"]([^'"]+)['"].*>(.+)<\s*\/\s*a\s*>/gim;
    const matches = urlRegex.exec(input);
    if (matches == null || matches.length === 1) {
        return ['', ''];
    } else if (matches.length === 2) {
        const urlPath = matches[1];
        return [urlPath, urlPath];
    }
    const urlPath = matches[1];
    const urlText = matches[2];
    return [urlPath, urlText];
};

/**
 * Uses regex to remove html tags from the input. Use this function only if
 * you are sure that we control the input. Never use it for user inputs.
 */
export const removeHtmlTags = (input: string) =>
    input.replace(/<\/?[^>]+(>|$)/g, '');

export const htmlDecode = (input: string | null) => {
    if (!input) {
        return '';
    }
    const parsedContent = new DOMParser().parseFromString(input, 'text/html');
    return parsedContent.documentElement.textContent;
};
