import React from 'react';
import { FormattedMessage } from 'react-intl';

import HintArrowRight from './hintArrowRight';
import './FipsCodesHint.scss';
import { TRACTIQ_PALETTE } from '../../../typescript/enums';

const FipsCodesHint = () => {
    return (
        <div className="fips-codes-hint">
            <HintArrowRight color={TRACTIQ_PALETTE['--color-800']} />
            <p>
                <FormattedMessage id="geographiesPage.fipsSelection.hint" />
            </p>
        </div>
    );
};

export default FipsCodesHint;
