import React from 'react';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { sortByUndefined } from '../../../../helpers/Sort';
import { getPartsFromGeoFips } from '../../../../helpers/Geographies';
import * as ReportApp from '../../../../typescript/types';

import '../InfoTableForYear.scss';

type Props = {
    results: ReportApp.GeoAnalysisResultPair[];
    year: number;
};

const GeoInfoTableForYear = ({ results, year }: Props) => {
    const intl = useIntl();
    const geoNotFoundMsg = intl.formatMessage({
        id: 'surveysPage.changeSurvey.geoNotFound',
    });
    const rows = results
        .slice()
        .sort((resultA, resultB) =>
            sortByUndefined(
                resultA.foundGeoFips ?? undefined,
                resultB.foundGeoFips ?? undefined,
            ),
        )
        .map((result) => {
            const { fips, sumLev, label } = getPartsFromGeoFips(
                result.existingGeoFips,
            );
            const { foundGeoFips } = result;
            const labelClass = classNames('label', { empty: !foundGeoFips });
            const foundGeoItemLabel = foundGeoFips
                ? getPartsFromGeoFips(foundGeoFips).label
                : '';
            const title = foundGeoFips ? foundGeoItemLabel : geoNotFoundMsg;
            return (
                <tr key={fips}>
                    <td>{sumLev}</td>
                    <td>{fips}</td>
                    <td className="label" title={label}>
                        <span>{label}</span>
                    </td>
                    <td className={labelClass} title={title}>
                        <span>{foundGeoItemLabel}</span>
                    </td>
                </tr>
            );
        });

    return (
        <table className="info-table">
            <thead>
                <tr>
                    <th>SL</th>
                    <th>Fips</th>
                    <th />
                    <th>
                        {year} -{' '}
                        <span className="new-survey">
                            <FormattedMessage id="surveysPage.changeSurvey.newSurvey" />
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
    );
};

export default GeoInfoTableForYear;
