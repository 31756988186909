import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import './SelectionCounter.scss';

type Props = {
    numberOfSelectedItems: number;
    active?: boolean;
    className?: string;
};

const SelectionCounter = ({
    numberOfSelectedItems,
    active,
    className,
}: Props) => {
    const selectionCountClasses = classNames('selection-counter', className, {
        active,
    });

    return (
        <div className={selectionCountClasses}>
            <FormattedMessage
                id="selection.countSelected"
                values={{ count: numberOfSelectedItems }}
            />
        </div>
    );
};

export default SelectionCounter;
