import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import * as SurveySelectors from '../../selectors/Survey';
import ApplicationPaths from '../../helpers/ApplicationPaths';
import * as SurveyConstants from '../../constants/Survey';
import * as ReportApp from '../../typescript/types';
import { ReportAppDispatch } from '../../typescript/actions/actions';
import { getIsCreatingTemplateFromDashboard } from '../../selectors/Templates';

import SurveyGroup from './SurveyGroup/SurveyGroup';
import SearchSurvey from './SurveysFilterableByTextList/SearchSurvey';

import './SelectSurvey.scss';

const SelectSurvey = () => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const surveyGroups = useSelector(SurveySelectors.getAllSurveyGroups);
    const isCreatingTemplate = useSelector(getIsCreatingTemplateFromDashboard);
    const history = useHistory();

    const selectSurveyAndGoToTheNextStep = (survey: ReportApp.Survey) => {
        const surveyCode = survey.code;
        dispatch({
            type: SurveyConstants.SELECT_SURVEY,
            payload: surveyCode,
        });
        const link = isCreatingTemplate
            ? ApplicationPaths.tables.getPath({ surveyCodes: surveyCode })
            : ApplicationPaths.geographies.getPath({ surveyCode });
        history.push(link);
    };

    const headerClassnames = classNames({
        'survey-header-with-create-template-box': isCreatingTemplate,
    });
    return (
        <>
            <h1 className={headerClassnames}>
                <FormattedMessage id="surveysPage.mainHeader" />
            </h1>
            <SearchSurvey onSelect={selectSurveyAndGoToTheNextStep} />
            {surveyGroups.map((surveyGroup) => (
                <SurveyGroup
                    key={surveyGroup.id}
                    surveyGroup={surveyGroup}
                    onSelect={selectSurveyAndGoToTheNextStep}
                />
            ))}
        </>
    );
};

export default SelectSurvey;
