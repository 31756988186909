import { SET_NORMALIZED_PREMADE_REPORTS } from '../constants/Table';

export default (state = {}, action) => {
    switch (action.type) {
        case SET_NORMALIZED_PREMADE_REPORTS: {
            const { surveyCode, premadeReports } = action.payload;
            return {
                ...state,
                [surveyCode]: premadeReports,
            };
        }
        default:
            return state;
    }
};
