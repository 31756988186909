import * as Report from '../constants/Report';
import * as Selection from '../constants/Selection';
import * as ReportApp from '../typescript/types';
import { ReportAppActions } from '../typescript/actions/actions';
import * as Templates from '../constants/Templates';

export const getUpdateGeographiesAction = (
    geoFips: string[],
): ReportAppActions => ({
    type: Selection.SET_GEO_FIPS,
    payload: geoFips,
});

export const getUpdateTableAction = (
    tables: ReportApp.TableForReportBySurveyCode[],
): ReportAppActions => ({
    type: Selection.SET_TABLES,
    payload: tables,
});

export const getSetReportIdRequestAction = (
    reportId: string,
): ReportAppActions => ({
    type: Report.SET_REPORT_ID_REQUEST,
    payload: reportId,
});

export const getSetActiveTemplateIdAction = (
    templateId: number,
): ReportAppActions => ({
    type: Templates.SET_ACTIVE_TEMPLATE_ID,
    payload: templateId,
});

export const getSetIsEditingTemplateAction = (
    payload: boolean,
): ReportAppActions => ({
    type: Templates.SET_IS_EDITING_TEMPLATE,
    payload,
});
