import { DefaultRootState } from 'react-redux';
import {
    getNumberOfTablesInReport,
    getTotalNumberOfGeographies,
} from './ReportMetadata';
import * as ReportNavigator from '../constants/ReportNavigator';
import {
    TABLES_PER_PAGE_LIST,
    GEOGRAPHIES_PER_PAGE_LIST,
} from '../constants/Report';

export const getTablesNavigationStatus = (state: DefaultRootState) => {
    const { tablePage, tablesPerPage } = state.reportNavigator;
    return [tablePage, tablesPerPage];
};

export const getGeosNavigationStatus = (state: DefaultRootState) => {
    const { geoItemPage, geoItemsPerPage } = state.reportNavigator;
    return [geoItemPage, geoItemsPerPage];
};

export const getReportNavigatorDisplayStatus = (state: DefaultRootState) => {
    const totalNumberOfTables = getNumberOfTablesInReport(state);
    const totalNumberOfGeographies = getTotalNumberOfGeographies(state);
    const { geoItemsPerPage, tablesPerPage } = state.reportNavigator;

    if (
        totalNumberOfTables <= tablesPerPage &&
        totalNumberOfGeographies <= geoItemsPerPage
    ) {
        return ReportNavigator.NavigatorVariants.SIMPLIFIED_NAVIGATOR;
    }

    return ReportNavigator.NavigatorVariants.DETAILED_NAVIGATOR;
};

export const getItemsPerPage = (state: DefaultRootState) => {
    const { tablesPerPage, geoItemsPerPage } = state.reportNavigator;
    return [tablesPerPage, geoItemsPerPage];
};

export const getAvailableTablesPerPage = (state: DefaultRootState) => {
    const totalNumberOfTables = getNumberOfTablesInReport(state);
    return TABLES_PER_PAGE_LIST.filter(
        (item) => item.value < totalNumberOfTables || item.isDefault,
    );
};

export const getAvailableGeographiesPerPage = (state: DefaultRootState) => {
    const totalNumberOfGeographies = getTotalNumberOfGeographies(state);
    return GEOGRAPHIES_PER_PAGE_LIST.filter(
        (item) => item.value < totalNumberOfGeographies || item.isDefault,
    );
};
