import React from 'react';
import { useSelector } from 'react-redux';

import * as ReportApp from '../../../../typescript/types';
import * as ReportMetadata from '../../../../selectors/ReportMetadata';
import { getOldReportIdByGeoGroupIndexColumnGroupIndex } from '../../../../helpers/Report';

import SingleTableSubheaderForReport from './SingleTableSubheaderForReport';

import './SingleTableSubheader.scss';

type Props = {
    tableGuidByReportId: ReportApp.TableGuidByOldReportId;
};

const SingleTableSubheader = ({ tableGuidByReportId }: Props) => {
    const geos = useSelector(ReportMetadata.getPaginatedGeographies);
    const definition = useSelector(ReportMetadata.getDefinition);

    const shouldDisplayTableSubheader = useSelector((state) =>
        ReportMetadata.getHasOutputColumns(state, tableGuidByReportId),
    );

    if (!shouldDisplayTableSubheader || !definition) {
        return null;
    }

    const subheaders = geos.flatMap((geography, geoIndex) =>
        geography.sideBySide.map(({ colSpan, isDiff }, sideBySideIndex) => {
            if (isDiff) {
                return <td key={`${geoIndex}|diff`} />;
            }
            const oldReportId = getOldReportIdByGeoGroupIndexColumnGroupIndex(
                definition,
                tableGuidByReportId,
                geography.geoGroupIndex,
                sideBySideIndex,
            );
            if (!oldReportId) {
                return (
                    <td
                        key={`${geoIndex}|${sideBySideIndex}`}
                        colSpan={colSpan}
                    />
                );
            }
            const tableGuid = tableGuidByReportId[oldReportId];
            if (!tableGuid) {
                return (
                    <td
                        key={`${geoIndex}|${sideBySideIndex}`}
                        colSpan={colSpan}
                    />
                );
            }
            return (
                <SingleTableSubheaderForReport
                    key={`${geoIndex}|${sideBySideIndex}`}
                    tableGuid={tableGuid}
                    oldReportId={oldReportId}
                    maxNumberOfColumns={colSpan}
                />
            );
        }),
    );
    return (
        <tr className="sub-header">
            <th />
            {subheaders}
        </tr>
    );
};

export default SingleTableSubheader;
