export enum ReportActionModals {
    RENAME_REPORT_MODAL,
    COPY_REPORT_MODAL,
    DELETE_REPORT_MODAL,
    SAVE_REPORT_MODAL,
    SAVE_TEMPLATE_MODAL,
    NO_MODAL,
}

export enum ReportActionsMainButtonType {
    ALL_CHANGES_SAVED,
    SAVE_AS,
    MAKE_A_COPY,
}
