import React from 'react';
import { TRACTIQ_PALETTE } from '../../typescript/enums';

import HintArrowIcon from './ArrowIcon';

import './SelectionHint.scss';

const SelectionHint = ({ children }) => {
    return (
        <div className="selection-hint">
            <HintArrowIcon color={TRACTIQ_PALETTE['--color-800']} />
            {children}
        </div>
    );
};

export default SelectionHint;
