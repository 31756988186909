import {
    GEOGRAPHY_PAGE_ERROR,
    GEOGRAPHY_PAGE_LOADING,
} from '../constants/SelectGeographyScreen';
import { intl } from '../helpers/CreateIntl';
import { getPartsFromGeoFips } from '../helpers/Geographies';

/**@param {import('react-redux').DefaultRootState} state */
export const getShouldRetrieveGeoTypes = (state) =>
    state.selectGeographyPageStatus.geoTypeNames == null &&
    !getIsLoadingGeoTypes(state) &&
    getGeoTypesError(state) == null;

/**
 * @param {import('react-redux').DefaultRootState} state
 * @param {string} surveyCode
 */
export const getGeoTypes = (state, surveyCode) => {
    const { geoTypeNames } = state.selectGeographyPageStatus;
    if (geoTypeNames == null) {
        return null;
    }
    return geoTypeNames.map((geoTypeId) =>
        getGeoTypeById(state, geoTypeId, surveyCode),
    );
};
/**
 * @param {import('react-redux').DefaultRootState} state
 * @param {string} geoTypeId
 * @param {string} surveyCode
 */
export const getGeoTypeById = (state, geoTypeId, surveyCode) => {
    const { geoTypes } = state.db;
    return geoTypes[surveyCode][geoTypeId];
};

/**
 * @param {import('react-redux').DefaultRootState} state
 * @param {string} surveyCode
 */
export const getAllGeoTypes = (state, surveyCode) => {
    const { geoTypes } = state.db;
    return geoTypes[surveyCode];
};

/**@param {import('react-redux').DefaultRootState} state */
export const getGeoTypesError = (state) =>
    state.selectGeographyPageStatus.geoTypesError;

export const getIsLoadingGeoTypes = (state) =>
    !!state.selectGeographyPageStatus.geoTypesLoading;

export const getIsLoadingSelectedGeographies = (state) =>
    !!state.selectGeographyPageStatus.selectedGeographiesLoading;

export const getCurrentStep = (state) => {
    const { selectedScreen } = state.selectGeographyPageStatus;
    return selectedScreen;
};

export const getWhatToDisplay = (state) => {
    if (
        getIsLoadingGeoTypes(state) ||
        getShouldRetrieveGeoTypes(state) ||
        getIsLoadingSelectedGeographies(state)
    ) {
        return GEOGRAPHY_PAGE_LOADING;
    }
    if (!!getGeoTypesError(state)) {
        return GEOGRAPHY_PAGE_ERROR;
    }
    return getCurrentStep(state);
};

/**
 * @param {import('react-redux').DefaultRootState} state
 * @param {string} surveyCode
 */
export const getParentGeoTypes = (state, surveyCode) => {
    const { parentGeoTypes } = state.selectGeographyPageStatus;
    const { geoTypes } = state.db;
    if (parentGeoTypes == null) {
        return null;
    }
    return parentGeoTypes.map((geoTypeId) => geoTypes[surveyCode][geoTypeId]);
};

/**
 * @param {import('react-redux').DefaultRootState} state
 * @param {string} sumLev
 */
export const getGeoItems = (state, sumLev) => {
    const { geoItemsBySumLev } = state.selectGeographyPageStatus;
    if (geoItemsBySumLev?.hasOwnProperty(sumLev)) {
        return geoItemsBySumLev[sumLev];
    } else {
        return null;
    }
};

/** @param {import('react-redux').DefaultRootState} state */
export const getAllGeoItems = (state) =>
    state.selectGeographyPageStatus.geoItemsBySumLev;

/** @param {import('react-redux').DefaultRootState} state */
export const hasSelectedGeographies = (state) =>
    getSelectedGeographies(state)?.length > 0;

/**@param {import('react-redux').DefaultRootState} state */
export const getSelectedGeographies = (state) =>
    state.selectGeographyPageStatus.selectedGeoFipses;

/**@param {import('react-redux').DefaultRootState} state */
export const getShouldSetSelectedGeographies = (state) =>
    getSelectedGeographies(state) == null;

/**
 * @param {import('react-redux').DefaultRootState} state
 * @param {string} surveyCode
 */
export const constructSelectionsForGeoType = (state, surveyCode) => {
    /** @type {import('../typescript/types').GeoItemElement[]} */
    const selections = [];
    const headerSelections = [];
    const geoItems = getAllGeoItems(state);
    const geoType = getSelectedGeoType(state, surveyCode);
    const geoSelections = getGeoSelection(state);
    // Do not show All for root levels, for example, for SL010 don't show
    // All nations, for SL100 (canada and uk), don't show All Canada, etc.
    if (!geoItems || geoType.indent === 0) {
        return [];
    }
    const geoItemKeys = Object.keys(geoItems);
    const parentGeoTypes = getParentGeoTypes(state, surveyCode);
    const allGeoTypes = getAllGeoTypes(state, surveyCode);

    const label = intl.formatMessage(
        { id: 'geographiesPage.geoItems.allInType' },
        { geoType: geoType.pluralName },
    );
    const identifier = ['', geoType.sumLev, label].join(';');
    headerSelections.push({
        label,
        identifier,
        isDivider: false,
    });
    const summaryLevelsInGeoSelection = Object.keys(geoSelections);
    summaryLevelsInGeoSelection.forEach((summaryLevel, index) => {
        if (!geoItems[summaryLevel]) {
            return;
        }
        const currentlySelectedGeo = geoItems[summaryLevel].find(
            (item) => item.fips === geoSelections[summaryLevel],
        );
        if (currentlySelectedGeo) {
            const { qLabel, fips, sumLev } = currentlySelectedGeo;
            const label = intl.formatMessage(
                {
                    id: 'geographiesPage.geoItems.allGeosIn',
                },
                {
                    plural: geoType.pluralName,
                    geoType: qLabel,
                },
            );
            const identifier = [fips, sumLev, label].join(';');
            headerSelections.push({
                label: label,
                identifier,
                isDivider: false,
            });
        }
        const isLastGeoSelection =
            index === summaryLevelsInGeoSelection.length - 1;
        const nextGeoType = geoItemKeys[index + 1];
        const isNotFinalGeoType =
            parentGeoTypes[parentGeoTypes.length - 1] &&
            summaryLevel !== parentGeoTypes[parentGeoTypes.length - 1].name;

        if (isLastGeoSelection && nextGeoType && isNotFinalGeoType) {
            selections.push({
                label: intl.formatMessage(
                    { id: 'geographiesPage.geoItems.within' },
                    {
                        label: allGeoTypes[nextGeoType].pluralName,
                    },
                ),
                isDivider: true,
            });
            selections.push(
                ...geoItems[nextGeoType].map(({ qLabel, fips }) => {
                    const identifier = [fips, geoType.sumLev, qLabel].join(';');
                    return {
                        geoType: allGeoTypes[nextGeoType],
                        fips,
                        label: qLabel,
                        identifier,
                        isDivider: false,
                    };
                }),
            );
        }
    });
    selections.unshift(headerSelections.pop()); // Use only the last header element
    return selections;
};

/**
 * @param {import('react-redux').DefaultRootState} state
 * @param {string} surveyCode
 */
export const constructIndividualSelections = (state, surveyCode) => {
    /** @type {import('../typescript/types').GeoItemElement[]} */
    const selections = [];
    const geoItems = getAllGeoItems(state);
    const selectedGeoType = getSelectedGeoTypeName(state);
    const finalSelection = getFinalSelection(state);

    if (geoItems && finalSelection !== undefined) {
        if (geoItems.hasOwnProperty(selectedGeoType)) {
            const geoType = getSelectedGeoType(state, surveyCode);
            // if indent is 0, we will not show element "All ${geoType}"
            // (eg. All Nations for SL010). In that case, there is no need to
            // show this message
            if (geoType.indent !== 0) {
                selections.push({
                    label: intl.formatMessage(
                        {
                            id: 'geographiesPage.geoItems.individuals',
                        },
                        {
                            plural: geoType.pluralName,
                        },
                    ),
                    isDivider: true,
                });
            }
            geoItems[selectedGeoType].forEach(({ fips, sumLev, qLabel }) => {
                const identifier = [fips, sumLev, qLabel].join(';');
                selections.push({
                    label: qLabel,
                    identifier: identifier,
                    isDivider: false,
                });
            });
        } else {
            selections.push({ label: 'loading', isLoading: true });
        }
    }
    return selections;
};

/**@param {import('react-redux').DefaultRootState} state */
export const getSelectedGeoTypeName = (state) => {
    return state.selectGeographyPageStatus.selectedGeoType;
};

/**
 * @param {import('react-redux').DefaultRootState} state
 * @param {string} surveyCode
 */
export const getSelectedGeoType = (state, surveyCode) => {
    const geoTypeName = getSelectedGeoTypeName(state);
    const { geoTypes } = state.db;
    return geoTypes[surveyCode][geoTypeName];
};

/**
 * @param {import('react-redux').DefaultRootState} state
 * @param {string} surveyCode
 */
export const getInitialGeoSelectionsState = (state, surveyCode) => {
    const parentGeoTypes = getParentGeoTypes(state, surveyCode);
    let initialGeoSelectionsState;
    if (parentGeoTypes.length > 1) {
        initialGeoSelectionsState = {
            [parentGeoTypes[0].name]: '',
        };
    } else {
        initialGeoSelectionsState = {};
    }
    return initialGeoSelectionsState;
};

/**
 * @param {import('react-redux').DefaultRootState} state
 * @param {string} surveyCode
 */
export const shouldInitializeGeoSelectionsState = (state, surveyCode) => {
    const geoSelections = getGeoSelection(state);
    const parentGeoTypes = getParentGeoTypes(state, surveyCode);
    return !Object.keys(geoSelections).length && parentGeoTypes.length > 1;
};

/** @param {import('react-redux').DefaultRootState} state */
export const getGeoSelection = (state) =>
    state.selectGeographyPageStatus.geoSelection;

/** @param {import('react-redux').DefaultRootState} state */
export const getFinalSelection = (state) => {
    let finalSelection;
    const { parentGeoTypes } = state.selectGeographyPageStatus;
    const geoSelections = getGeoSelection(state);
    if (parentGeoTypes.length > 1) {
        finalSelection =
            geoSelections[parentGeoTypes[parentGeoTypes.length - 1]];
    } else {
        finalSelection = '';
    }
    return finalSelection;
};
/**
 * @param {import('react-redux').DefaultRootState} state
 * @param {import('../typescript/types').GeoType} geoType
 */
export const getParentGeoSelectionFips = (state, geoType) => {
    const geoSelections = getGeoSelection(state);
    const { parentGeoTypes } = state.selectGeographyPageStatus;
    const index = parentGeoTypes.indexOf(geoType.name);
    return geoSelections[parentGeoTypes[index - 1]];
};

export const shouldRenderFipsSearchResults = (state) => {
    const {
        geoSelection,
        notMatched,
        warningLabel,
    } = state.selectGeographyPageStatus.geoItemsByFips;
    return !!(geoSelection.length || notMatched || warningLabel);
};

export const getGeoItemsByFipsResults = (state) => {
    const {
        geoSelection,
        notMatched,
        warningLabel,
    } = state.selectGeographyPageStatus.geoItemsByFips;
    return [geoSelection, notMatched, warningLabel];
};

/**@param {import('react-redux').DefaultRootState} state
 * @param {string} surveyCode
 */
export const getSelectedGeographiesSplitBySummaryLevel = (
    state,
    surveyCode,
) => {
    const selectedGeographies = getSelectedGeographies(state);
    if (
        !getGeoTypes(state, surveyCode) ||
        selectedGeographies == null ||
        selectedGeographies.length === 0
    ) {
        return [];
    }
    const summaryLevelsInSelection = selectedGeographies
        .map((geo) => getPartsFromGeoFips(geo).sumLev)
        .sort();
    const result = {};
    summaryLevelsInSelection.forEach((SL) => {
        const geoType = getGeoTypeById(state, `SL${SL}`, surveyCode);
        const filteredGeographies = selectedGeographies.filter(
            (geo) => getPartsFromGeoFips(geo).sumLev === SL,
        );
        const key =
            filteredGeographies.length > 1
                ? `${SL} ${geoType.pluralName}`
                : `${SL} ${geoType.label}`;
        result[key] = filteredGeographies;
    });
    return result;
};

/**@param {import('react-redux').DefaultRootState} state */
export const getIntermittentFipsSelection = (state) =>
    state.selectGeographyPageStatus.intermittentFipsSelection;

/**
 * @param {import('react-redux').DefaultRootState} state
 * @param {import('../typescript/types').GeoType} geoType
 */
export const shouldFetchGeoItemsForGeoType = (state, geoType) => {
    const geoItems = getGeoItems(state, geoType.name);
    const geoSelectionFips = getParentGeoSelectionFips(state, geoType);
    return !geoItems && geoSelectionFips !== undefined;
};

/**
 * @param {import('react-redux').DefaultRootState} state
 * @param {import('../typescript/types').GeoType} geoType
 */
export const getSelectedGeoItemByGeoType = (state, geoType) => {
    const geoSelections = getGeoSelection(state);
    const selectedGeoTypeFips = geoSelections[geoType.name];
    if (selectedGeoTypeFips == null) {
        return null;
    }

    const geoItems = getGeoItems(state, geoType.name);
    if (!geoItems) {
        return null;
    }
    return geoItems.find((item) => item.fips === selectedGeoTypeFips);
};

/**@param {import('react-redux').DefaultRootState} state */
export const shouldDisplayGeoTypesSelector = (state) =>
    getIntermittentFipsSelection(state) != null;

/**@param {import('react-redux').DefaultRootState} state */
export const shouldSetIntermittentFips = (state) =>
    getIntermittentFipsSelection(state) == null &&
    getSelectedGeographies(state) != null;

/**@param {import('react-redux').DefaultRootState} state */
export const isSelectedGeoTypeLevel = (state) => {
    const {
        selectedGeoType,
        geoItemsBySumLev,
    } = state.selectGeographyPageStatus;
    return geoItemsBySumLev && !!geoItemsBySumLev[selectedGeoType];
};
