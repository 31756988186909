import React from 'react';
import classNames from 'classnames';

import Button from '../../Buttons/Button';

import './ModesSelector.scss';

import DOMElementID from '../../../constants/DOMElementID';

const ModeSelector = ({ actions }) => {
    return (
        <div
            className="modes-selector-group"
            id={DOMElementID.SELECTOR_CONTAINER}
        >
            {actions.map(({ className, content, disabled, ...rest }, index) => {
                const buttonClasses = classNames(
                    'modes-selector-button',
                    { 'modes-selector-button--disabled': disabled },
                    className,
                );
                return (
                    <Button
                        key={index}
                        disabled={disabled}
                        className={buttonClasses}
                        {...rest}
                    >
                        {content}
                    </Button>
                );
            })}
        </div>
    );
};

export default ModeSelector;
