import * as React from 'react';

const SurveyIcon = ({ color }) => (
    <svg
        width="24px"
        height="25px"
        viewBox="0 0 24 25"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="CR" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
                id="CR-|-S1---Select-Survey"
                transform="translate(-418.000000, -467.000000)"
                fill={color}
            >
                <g id="Group-7" transform="translate(400.000000, 322.000000)">
                    <g id="Group-3">
                        <g
                            id="Group-6"
                            transform="translate(0.000000, 132.500000)"
                        >
                            <path
                                d="M30,13 C36.627417,13 42,18.372583 42,25 C42,31.627417 36.627417,37 30,37 C23.372583,37 18,31.627417 18,25 C18,18.372583 23.372583,13 30,13 Z M30,19.1666666 C29.2416667,19.1666666 28.6,19.6566666 28.355,20.3333333 L25.9166666,20.3333333 C25.275,20.3333333 24.75,20.8583333 24.75,21.5 L24.75,29.6666666 C24.75,30.3083334 25.275,30.8333334 25.9166666,30.8333334 L34.0833334,30.8333334 C34.725,30.8333334 35.25,30.3083334 35.25,29.6666666 L35.25,21.5 C35.25,20.8583333 34.725,20.3333333 34.0833334,20.3333333 L31.645,20.3333333 C31.4,19.6566666 30.7583333,19.1666666 30,19.1666666 Z M31.1666667,27.3333333 L31.1666667,28.5 L27.0833333,28.5 L27.0833333,27.3333333 L31.1666667,27.3333333 Z M32.9166667,25 L32.9166667,26.1666667 L27.0833333,26.1666667 L27.0833333,25 L32.9166667,25 Z M32.9166667,22.6666667 L32.9166667,23.8333333 L27.0833333,23.8333333 L27.0833333,22.6666667 L32.9166667,22.6666667 Z M30,20.3333333 C30.3208333,20.3333333 30.5833333,20.5958333 30.5833333,20.9166666 C30.5833333,21.2375 30.3208333,21.5 30,21.5 C29.6791667,21.5 29.4166667,21.2375 29.4166667,20.9166666 C29.4166667,20.5958333 29.6791667,20.3333333 30,20.3333333 Z"
                                id="survey-icon"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
export default SurveyIcon;
