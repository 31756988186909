import React from 'react';
import BackendPaths from '../../helpers/BackendPaths';
import WarningMessage from '../WarningMessage/WarningMessage';
import './TrialWarning.scss';

const TrialWarning = () => {
    const contact = BackendPaths.paidPlan.getPath();

    return (
        <WarningMessage
            messageId="report.sidebar.trialContactUs"
            values={{
                a: (message: string): React.ReactNode => (
                    <p className="trial-warning-link">
                        <a
                            href={contact}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {message}
                        </a>
                    </p>
                ),
            }}
        />
    );
};

export default TrialWarning;
