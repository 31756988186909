import React, { useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { getHasPro } from '../../selectors/CurrentUser';
import {
    getActivatedSidebarPanel,
    getShouldOpenModifyReportPanel,
} from '../../selectors/Sidebar';
import * as SidebarConstants from '../../constants/Sidebar';
import { ReportAppDispatch } from '../../typescript/actions/actions';

import UpgradePlanBanner from './UpgradePlanBanner';
import ReportSidebar from '../ReportSidebar/ReportSidebar';

const ReportSidebarAndNavigation = () => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const hasPro = useSelector(getHasPro);
    const currentSidebarPanel: SidebarConstants.SidebarOptions = useSelector(
        getActivatedSidebarPanel,
    );
    const shouldOpenModifyReportPanel = useSelector(
        getShouldOpenModifyReportPanel,
    );

    const setCurrentSidebarPanel = useCallback(
        (panel: SidebarConstants.SidebarOptions) => {
            dispatch({
                type: SidebarConstants.SET_ACTIVATED_SIDEBAR_TAB,
                payload: panel,
            });
        },
        [dispatch],
    );

    useEffect(() => {
        if (shouldOpenModifyReportPanel) {
            setCurrentSidebarPanel(SidebarConstants.MODIFY_REPORT);
        }
    }, [setCurrentSidebarPanel, shouldOpenModifyReportPanel]);

    const shrinkedBySidebarWidth = classnames({
        'shrinked-by-sidebar-width':
            currentSidebarPanel !== SidebarConstants.EMPTY,
    });
    return (
        <>
            {!hasPro && (
                <UpgradePlanBanner className={shrinkedBySidebarWidth} />
            )}
            <ReportSidebar
                setCurrentSidebarPanel={setCurrentSidebarPanel}
                currentSidebarPanel={currentSidebarPanel}
            />
        </>
    );
};

export default ReportSidebarAndNavigation;
