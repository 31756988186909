import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getTablesNavigationStatus } from '../../../selectors/ReportNavigator';
import * as ReportMetadata from '../../../selectors/ReportMetadata';

import SingleTableEmptyRow from './SingleTable/SingleTableEmptyRow';

import './ShowMoreRows.scss';

const ShowMoreRows = () => {
    const [tablePage, tablesPerPage] = useSelector(getTablesNavigationStatus);
    const numberOfSelectedTables = useSelector(
        ReportMetadata.getNumberOfTablesInReport,
    );
    const colSpan = useSelector(ReportMetadata.getNumberOfValueColumnsInReport);

    if (numberOfSelectedTables <= tablePage * tablesPerPage) {
        return <SingleTableEmptyRow />;
    }

    return (
        <tr className="show-more-tables">
            <td>
                <FormattedMessage id="report.table.showMoreTables" />
            </td>
            <td colSpan={colSpan}></td>
        </tr>
    );
};

export default ShowMoreRows;
