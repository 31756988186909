import React, { useRef } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { getForwardButtonOptions } from '../../selectors/WizardHeader';
import { useAddGtmAttributes } from '../../customHooks/useAddGtmAttributes';
import { ButtonVariant } from '../../typescript/types';

import ButtonLink from '../Buttons/ButtonLink';
import Button from '../Buttons/Button';

import AppScreen from '../../constants/AppScreen';
import DOMElementID from '../../constants/DOMElementID';

import './HeaderForwardButton.scss';

export type Props = {
    currentAppScreen: AppScreen;
    isForwardButtonEnabled: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const HeaderForwardButton = ({
    currentAppScreen,
    isForwardButtonEnabled,
    onClick,
}: Props) => {
    const { text, link, gtmAttributes } = useSelector((state) =>
        getForwardButtonOptions(state, currentAppScreen),
    );
    const forwardButton = useRef(null);
    useAddGtmAttributes(forwardButton, gtmAttributes);

    const classes = classNames('header-next-button', {
        'disabled-gray-button': !isForwardButtonEnabled,
    });

    if (
        currentAppScreen === AppScreen.SURVEY_SELECTOR &&
        !isForwardButtonEnabled
    ) {
        return null;
    }

    const props = {
        id: DOMElementID.HEADER_NEXT_BUTTON,
        ref: forwardButton,
        variant: 'contained' as ButtonVariant,
        disabled: !isForwardButtonEnabled,
        className: classes,
        onClick,
    };

    if (link) {
        return (
            <ButtonLink {...props} to={link}>
                {text}
            </ButtonLink>
        );
    } else {
        return <Button {...props}>{text}</Button>;
    }
};
export default HeaderForwardButton;
