import React from 'react';

import { WithDivider } from '../../../typescript/types';

import FilterableByTextList, {
    Props as FilterableByTextListProps,
} from './FilterableByTextList';

interface Props<T> extends FilterableByTextListProps<T> {
    onChange: (actionItem: T, affectedItems: T[]) => void;
}

function DividersFilterableByTextList<T extends WithDivider>({
    onChange,
    ...rest
}: Props<T>) {
    const onChangeEvent = (actionItem: T, affectedItems: T[]) => {
        const firstAffectedItem = affectedItems[0];
        const lastAffectedItem = affectedItems[affectedItems.length - 1];

        if (actionItem.isDivider) {
            return;
        } else if (firstAffectedItem.isDivider || lastAffectedItem.isDivider) {
            // Shift-click on divider item should not trigger multiple selection
            onChange(actionItem, [actionItem]);
        } else {
            onChange(
                actionItem,
                affectedItems.filter((item) => !item.isDivider),
            );
        }
    };

    return <FilterableByTextList onChange={onChangeEvent} {...rest} />;
}

export default DividersFilterableByTextList;
