import { TOGGLE_DOWNLOAD_CSV_OPTION } from '../constants/Report';
import { ReportAppActions } from '../typescript/actions/actions';
import { CsvDownloadOptions } from '../typescript/types';

const INIT_STATE: CsvDownloadOptions = {
    tabSeparated: false,
    outputLabels: false,
    outputAllGeoIds: false,
    percents: false,
    dbfFriendlyNames: false,
};

export default (
    state: CsvDownloadOptions = INIT_STATE,
    action: ReportAppActions,
) => {
    switch (action.type) {
        case TOGGLE_DOWNLOAD_CSV_OPTION: {
            const option = action.payload;
            const currentValue = state[option];
            return {
                ...state,
                [option]: !currentValue,
            };
        }
        default:
            return state;
    }
};
