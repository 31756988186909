import React from 'react';
import { useHistory } from 'react-router-dom';

import Button, { Props as ButtonProps } from './Button';

type Props = { to: string } & ButtonProps;

const ButtonLink = React.forwardRef<HTMLButtonElement, Props>(
    ({ to, onClick, ...rest }, ref) => {
        const history = useHistory();

        const triggerActionAndPushToHistory = (
            event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        ) => {
            if (typeof onClick === 'function') {
                onClick(event);
            }
            history.push(to);
        };
        return (
            <Button
                ref={ref}
                onClick={triggerActionAndPushToHistory}
                {...rest}
            ></Button>
        );
    },
);

export default ButtonLink;
