import React, { cloneElement, useCallback, useRef, useState } from 'react';
import classNames from 'classnames';

import Popover from '../base/Popover/Popover';
import DropdownMenuButton from './DropdownMenuButton';

import { GeoItem } from '../../typescript/types';

import './DropdownMenu.scss';

type Props = {
    label: string;
    selected: boolean;
    disabled: boolean;
    className?: string;
    onChange: (item: GeoItem | undefined) => void;
    onClear: () => void;
    children: React.ReactElement;
};

const DropdownMenu = ({
    label,
    selected,
    disabled,
    className,
    onChange,
    onClear,
    children,
}: Props) => {
    const root = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const openPopover = () => setIsOpen(true);
    const closePopover = useCallback(() => setIsOpen(false), [setIsOpen]);

    if (disabled) {
        return null;
    }

    const closePopoverAndCallOnSelect = (item: GeoItem) => {
        onChange(item);
        closePopover();
    };

    const dropdownClasses = classNames(className, {
        selected: selected,
    });

    const childElement = cloneElement(children, {
        onChange: closePopoverAndCallOnSelect,
    });

    return (
        <div ref={root} className={dropdownClasses}>
            <DropdownMenuButton
                label={label}
                selected={selected}
                open={isOpen}
                onClick={openPopover}
                onClear={onClear}
            />
            {isOpen && (
                <Popover
                    className="dropdown-menu-popover"
                    parent={root.current}
                    onClickAway={closePopover}
                >
                    {childElement}
                </Popover>
            )}
        </div>
    );
};

export default DropdownMenu;
