import * as Sentry from '@sentry/react';
import { takeEvery } from 'redux-saga/effects';
// import api from '../helpers/Api';
// import BackendPaths from '../helpers/BackendPaths';
import * as CounterConstants from '../constants/Counter';
import { CounterLogRequest } from '../typescript/actions/CounterActions';

// import { prepareCounterPayload } from '../helpers/CounterEvents';

function* logCounterEvent({ payload }: CounterLogRequest) {
    try {
        // No need for counter events
        // yield call(api, BackendPaths.counterEvent.getPath(), 'POST', {
        //     events: prepareCounterPayload(payload),
        // });
    } catch (errorResponse) {
        // fail silently, but log to sentry
        Sentry.captureException(errorResponse);
        yield console.error(errorResponse);
    }
}
export default function* () {
    yield takeEvery(CounterConstants.COUNTER_LOG_REQUEST, logCounterEvent);
}
