import React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import IconButton from '../IconButton/IconButton';
import Icon from '../Icon/Icon';
import Button from '../Buttons/Button';

import './DropdownMenuButton.scss';

type Props = {
    label: string;
    open: boolean;
    selected: boolean;
    onClick: () => void;
    onClear: () => void;
};

const DropdownMenuButton = ({
    label,
    open,
    selected,
    onClick,
    onClear,
}: Props) => {
    const intl = useIntl();

    const containerClasses = classNames('dropdown-menu-buttons-container', {
        selected: selected,
    });

    const stopPropagationAndTriggerClear = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        event.stopPropagation();
        onClear();
    };

    const closeMessage = intl.formatMessage({ id: 'close' });
    return (
        <div className={containerClasses}>
            {onClear && selected && (
                <IconButton
                    className="clear-button"
                    size="small"
                    variant="contained"
                    container="small"
                    theme="primary"
                    onClick={stopPropagationAndTriggerClear}
                    title={closeMessage}
                    aria-label={closeMessage}
                >
                    clear
                </IconButton>
            )}
            <Button
                className="select-button"
                onClick={onClick}
                aria-label={label}
                title={label}
            >
                <span className="label">{label}</span>
                <Icon className="icon">
                    {open ? 'arrow_drop_up' : 'arrow_drop_down'}
                </Icon>
            </Button>
        </div>
    );
};

export default DropdownMenuButton;
