type ApiMethods =
    | 'GET'
    | 'POST'
    | 'DELETE'
    /**
     * Sets Request `method` to GET and cache to `force-cache`
     */
    | 'GET-CACHE';

export default function api(url: string, method?: ApiMethods, params?: object) {
    const request: RequestInit = {
        credentials: 'include',
    };
    if (method === 'GET-CACHE') {
        request.cache = 'force-cache';
    } else if (method) {
        request.method = method;
    }

    if (params) {
        request.body = JSON.stringify(params);
    }
    request.headers = {
        'content-type': 'application/json',
    };

    return new Promise((resolve, reject) => {
        fetch(url, request).then((response) => {
            if (!response.ok) {
                return reject(response);
            }
            if (response.status === 204) {
                return resolve();
            }
            return resolve(response.json());
        }, reject);
    });
}
