import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as ReportConstants from '../../../constants/Report';
import * as ReportNavigator from '../../../selectors/ReportNavigator';
import * as ReportMetadata from '../../../selectors/ReportMetadata';
import { ReportAppDispatch } from '../../../typescript/actions/actions';

import NavigatorParameter from './NavigatorParameter';

const GeosNavigatorParameter = () => {
    const dispatch = useDispatch<ReportAppDispatch>();

    const [geoItemPage, geoItemsPerPage] = useSelector(
        ReportNavigator.getGeosNavigationStatus,
    );
    const totalNumberOfGeographies = useSelector(
        ReportMetadata.getTotalNumberOfGeographies,
    );

    return (
        <NavigatorParameter
            itemPage={geoItemPage}
            setItemPage={(page: number) =>
                dispatch({
                    type: ReportConstants.SET_GEO_ITEM_PAGE_REQUEST,
                    payload: { page },
                })
            }
            itemsPerPage={geoItemsPerPage}
            itemsTotalCount={totalNumberOfGeographies}
        />
    );
};

export default GeosNavigatorParameter;
