import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { BrowserRouter as Router } from 'react-router-dom';
import { applyMiddleware, compose, createStore } from 'redux';

import rootSaga from './sagas/sagas';
import rootReducer from './reducers/rootReducer';
import * as serviceWorker from './serviceWorker';

import App from './components/App/App';
import SentryErrorBoundary from './components/base/SentryErrorBoundary/SentryErrorBoundary';

import messages, { locale } from './languages/languageConfig';
import * as AccessabilityHelper from './helpers/AccessabilityHelper';
import * as Sentry from './helpers/Sentry';
import * as BrowserUpdate from './helpers/BrowseUpdate';

import './index.scss';

Sentry.init();

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(sagaMiddleware),
        Sentry.getSentryReduxEnhancer(),
    ),
);
sagaMiddleware.run(rootSaga);

AccessabilityHelper.initialize();

BrowserUpdate.init();

ReactDOM.render(
    <Provider store={store}>
        <IntlProvider locale={locale} messages={messages}>
            {/*
                basename is required since this app on dev, dev2 and production
                environment is not served from root while on local it is. That
                is why we use .env variable to specify basename
             */}
            <Router basename={process.env.REACT_APP_BASENAME}>
                <SentryErrorBoundary>
                    <App />
                </SentryErrorBoundary>
            </Router>
        </IntlProvider>
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
