import React from 'react';

import * as ReportApp from '../../../../typescript/types';

import SingleTableVariableLabel from './SingleTableVariableLabel';
import SingleTableVariableValues from './SingleTableVariableValues';

import './SingleTableVariable.scss';

type Props = {
    tableGuidByReportId: ReportApp.TableGuidByOldReportId;
    variableIndex: number;
};

const SingleTableVariable = ({ tableGuidByReportId, variableIndex }: Props) => {
    return (
        <tr className="variable-row">
            <SingleTableVariableLabel
                tableGuidByReportId={tableGuidByReportId}
                variableIndex={variableIndex}
            />
            <SingleTableVariableValues
                tableGuidByReportId={tableGuidByReportId}
                variableIndex={variableIndex}
            />
        </tr>
    );
};

export default SingleTableVariable;
