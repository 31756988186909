import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';

import * as Table from '../../../selectors/Table';

import ToggleButton from '../../base/ToggleButton/ToggleButton';

import './DatasetSelector.scss';

/**
 * @param {{
 *  selectedDatasetIndex: number,
 *  setSelectedDatasetIndex: (index: number) => void,
 *  surveyCodes: string[]
 * }} param0
 */
const DatasetSelector = ({
    selectedDatasetIndex,
    setSelectedDatasetIndex,
    surveyCodes,
}) => {
    const datasets = useSelector((state) =>
        Table.getDatasetsForAllSurveys(state, surveyCodes),
    );

    const intl = useIntl();

    if (datasets.length === 1) {
        return null;
    }
    const datasetActionsArray = datasets.map((dataset, index) => {
        const isSelected = index === selectedDatasetIndex;
        return {
            onClick: () => setSelectedDatasetIndex(index),
            label: isSelected ? dataset.longName : dataset.shortName,
            title: dataset.name,
            isSelected,
        };
    });
    const toggleButtonClassName = classNames(
        `buttons-${datasetActionsArray.length}`,
        {
            'more-than-5-buttons': datasetActionsArray.length > 5,
        },
    );

    const buttonAriaLabel = intl.formatMessage({
        id: 'report.sidebar.modify.toggle.datasetSelector',
    });

    return (
        <div className="datasets-toggle">
            <span className="datasets-toggle-label">
                <FormattedMessage id="tablesPage.tablesSelection.selectDataset" />
            </span>
            <ToggleButton
                className={toggleButtonClassName}
                buttons={datasetActionsArray}
                aria-label={buttonAriaLabel}
            />
        </div>
    );
};

export default DatasetSelector;
