import React from 'react';
import classNames from 'classnames';

import Button, { Props as ButtonProps } from '../Buttons/Button';
import Icon from '../Icon/Icon';

type Props = {
    size?: 'small' | 'standard';
    container?: 'small' | 'medium' | 'large';
    theme?: 'primary';
} & ButtonProps;

const IconButton = React.forwardRef<HTMLButtonElement, Props>(
    (
        {
            className,
            size = 'standard',
            variant = 'flat',
            container = 'small',
            theme,
            children,
            ...rest
        }: Props,
        ref,
    ) => {
        const classes = classNames(
            'button-icon',
            variant,
            size,
            theme,
            className,
            {
                [`container-${container}`]: variant === 'contained',
            },
        );

        return (
            <Button className={classes} ref={ref} {...rest}>
                <Icon className="icon">{children}</Icon>
            </Button>
        );
    },
);

export default IconButton;
