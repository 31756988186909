import React from 'react';
import { FormattedMessage } from 'react-intl';
import BackendPaths from '../../../../helpers/BackendPaths';

import Icon from '../../../Icon/Icon';
import LinkButton from '../../../LinkButton/LinkButton';

const CreateReportFromMap = () => {
    const exploreMaps = BackendPaths.exploreMaps.getPath();
    return (
        <li className="menu-list-item">
            <LinkButton
                url={exploreMaps}
                variant="flat"
                className="menu-list-item-button"
                target="_blank"
                rel="noopener noreferrer"
            >
                <Icon>explore</Icon>
                <FormattedMessage id="report.reportActions.startFromMap" />
            </LinkButton>
        </li>
    );
};

export default CreateReportFromMap;
