import Route from './Route';

export default {
    root: new Route('/'),
    surveys: new Route('/surveys'),
    geographies: new Route('/geographies/:surveyCode'),
    tables: new Route('/tables/:surveyCodes'),
    newReport: new Route('/report'),
    existingReport: new Route('/report/:reportId'),
};
