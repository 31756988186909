import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';

import './Input.scss';

const InputComponent = ({ multiline, ...rest }) => {
    if (multiline) {
        return <textarea data-hj-allow={true} {...rest} />;
    }
    return <input type="text" data-hj-allow={true} {...rest} />;
};

const Input = ({ id, label, value, errorText, multiline, ...rest }) => {
    const wrapperRef = useRef(null);
    useEffect(() => {
        const element = wrapperRef.current;
        if (value === '') {
            element.classList.remove('has-content');
        } else {
            element.classList.add('has-content');
        }
    }, [value]);
    const borderWrapperClasses = classnames('border-wrapper', { multiline });
    return (
        <div className={borderWrapperClasses}>
            <div className="input-wrapper" ref={wrapperRef}>
                <label className="input-label" htmlFor={id}>
                    {label}
                </label>
                <InputComponent
                    id={id}
                    multiline={multiline}
                    value={value}
                    className="input"
                    {...rest}
                />
                {errorText && <span>{errorText}</span>}
            </div>
        </div>
    );
};

export default Input;
