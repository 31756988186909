import React from 'react';
import { FormattedMessage } from 'react-intl';

import JumpButton from './JumpButton';

import DOMElementID from '../../constants/DOMElementID';

const SkipToHeaderJumpButtons = ({ shouldRenderNextButton = false }) => {
    const elements = [
        <JumpButton
            key="back2previousScreen"
            variant="flat"
            targetElementID={DOMElementID.HEADER_BACK_BUTTON}
        >
            <FormattedMessage id={'layoutNavigation.back2previousScreen'} />
        </JumpButton>,
    ];
    if (shouldRenderNextButton) {
        elements.unshift(
            <JumpButton
                key="go2nextScreen"
                variant="flat"
                targetElementID={DOMElementID.HEADER_NEXT_BUTTON}
            >
                <FormattedMessage id={'layoutNavigation.go2nextScreen'} />
            </JumpButton>,
        );
    }
    return <>{elements}</>;
};

export default SkipToHeaderJumpButtons;
