import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as SidebarConstants from '../../constants/Sidebar';
import Button from '../Buttons/Button';

type Props = {
    currentSidebarPanel: string;
    activateTab: (item: string) => void;
};

const TabControls = ({ currentSidebarPanel, activateTab }: Props) => {
    return (
        <>
            <Button
                variant="flat"
                className={classnames('sidebar-panel-tab-button', {
                    active: currentSidebarPanel === SidebarConstants.INFO,
                })}
                onClick={() => activateTab(SidebarConstants.INFO)}
            >
                <FormattedMessage id="report.sidebar.info.title" />
            </Button>
            <Button
                variant="flat"
                className={classnames('sidebar-panel-tab-button', {
                    active: currentSidebarPanel === SidebarConstants.CITATION,
                })}
                onClick={() => activateTab(SidebarConstants.CITATION)}
            >
                <FormattedMessage id="report.sidebar.citation.title" />
            </Button>
        </>
    );
};

export default TabControls;
