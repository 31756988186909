import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getNewYears } from '../../../selectors/NewSurvey';

import './ChangeSurveyTitle.scss';

const ChangeSurveyTitle = () => {
    const newSurveyCode = useSelector(getNewYears)?.slice().sort();

    if (!newSurveyCode) {
        return null;
    }

    return (
        <div className="change-survey-title">
            <h1>
                <FormattedMessage id="surveysPage.changeSurvey.pageTitle" />
            </h1>
            <span>
                <FormattedMessage
                    id="surveysPage.changeSurvey.pageDescription"
                    values={{
                        count: newSurveyCode.length,
                        years: newSurveyCode.join(', '),
                    }}
                />
            </span>
        </div>
    );
};

export default ChangeSurveyTitle;
