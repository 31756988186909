import React from 'react';

import Button from '../../Buttons/Button';
import SurveyIcon from '../SurveyIcon';

import './SurveysFilterableByTextListItem.scss';

const SurveysFilterableByTextListItem = ({ children }) => {
    return (
        <li className="surveys-filterable-by-text-list-item">
            <Button>
                <div className="survey-icon">
                    <SurveyIcon color="rgba(18, 87, 221, 0.46)" />
                </div>
                {children}
            </Button>
        </li>
    );
};

export default SurveysFilterableByTextListItem;
