import React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import Button from '../../../Buttons/Button';
import Icon from '../../../Icon/Icon';

import './TablesFilterableByTextListItem.scss';
import { TableForReportBySurveyCode } from '../../../../typescript/types';

type Props = {
    table: TableForReportBySurveyCode;
    isSelected: (table: TableForReportBySurveyCode) => boolean;
};

const TablesFilterableByTextListItem = ({ table, isSelected }: Props) => {
    const intl = useIntl();

    const listItemClasses = classNames('table-selection-item', {
        selected: isSelected(table),
    });

    const listItemAriaLabel = intl.formatMessage(
        {
            id: `tablesPage.tablesSelection.listItemAriaLabel.${
                isSelected(table) ? 'selected' : 'deselected'
            }`,
        },
        { title: table.title },
    );

    return (
        <li className={listItemClasses}>
            <Button title={listItemAriaLabel} aria-label={listItemAriaLabel}>
                <span>
                    {table.displayName}. {table.title}
                </span>
                <Icon className="table-selection-list-item-arrow">check</Icon>
            </Button>
        </li>
    );
};

export default TablesFilterableByTextListItem;
