import React from 'react';
import { FormattedMessage } from 'react-intl';

import ParameterPageNavigator from './ParameterPageNavigator';

import './NavigatorParameter.scss';

type Props = {
    itemPage: number;
    setItemPage: (page: number) => void;
    itemsPerPage: number;
    itemsTotalCount: number;
};

const NavigatorParameter = ({
    itemPage,
    setItemPage,
    itemsPerPage,
    itemsTotalCount,
}: Props) => {
    const pageFactor = itemPage * itemsPerPage;
    const currentPageFirstItemIndex = pageFactor - (itemsPerPage - 1);
    const currentPageLastItemIndex =
        itemsTotalCount < pageFactor ? itemsTotalCount : pageFactor;

    return (
        <div className="navigator-parameter">
            <span>
                <FormattedMessage
                    id="navigator.currentPage"
                    values={{
                        firstItemIndex: currentPageFirstItemIndex,
                        lastItemIndex: currentPageLastItemIndex,
                        numberOfPages: itemsTotalCount,
                        b: (chunks: string) => <strong>{chunks}</strong>,
                    }}
                />
            </span>
            <ParameterPageNavigator
                itemPage={itemPage}
                setItemPage={setItemPage}
                numberOfPages={Math.ceil(itemsTotalCount / itemsPerPage)}
            />
        </div>
    );
};

export default NavigatorParameter;
