import React from 'react';
import classNames from 'classnames';

import { formatValue } from '../../../../helpers/ValueFormatter';
import ValueFormat from '../../../../constants/ValueFormat';

import './CollapseInfoTriggerChart.scss';

export type Props = {
    found: number;
    total: number;
};

const CollapseInfoTriggerChart = ({ found, total }: Props) => {
    const percent =
        formatValue(
            found / total,
            ValueFormat.FORMAT_REAL_PERCENT_NO_DECIMAL,
        ) ??
        // formatValue function will return null if user is only removing years
        '100%';

    const chartClasses = classNames('collapse-info-trigger-chart', {
        'collapse-info-trigger-chart-match': found / total === 1,
    });

    // replace to make sure that we get value% and not value %
    // since formatValue is language dependant
    const barChartStyle: React.CSSProperties = {
        width: percent.replace(/\s+/g, ''),
    };
    return (
        <div className={chartClasses}>
            <div
                className="collapse-info-trigger-chart-bar"
                style={barChartStyle}
            />
            <div className="collapse-info-trigger-chart-value">{percent}</div>
        </div>
    );
};

export default CollapseInfoTriggerChart;
