import * as ReportApp from '../typescript/types';

export const getUserFromResponse = (
    response: ReportApp.OmniWebService.UserSession,
): ReportApp.SessionInfo => ({
    licenceTitle: response.hasTractIQSelfStorageSubscription
        ? 'Professional plan'
        : 'Basic plan',
    hasPro: !!response.hasTractIQSelfStorageSubscription,
    isTrialing: !!response.isTrialingTractIQSelfStorage,
});
