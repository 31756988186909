import React, { useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getAllSurveys } from '../../../selectors/Survey';

import Popover from '../../base/Popover/Popover';
import SurveysFilterableByTextListButton from './SurveysFilterableByTextListButton';
import SurveysFilterableByTextList from './SurveysFilterableByTextList';

/** @param {{onSelect: Function}} param0 */
const SearchSurvey = ({ onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const surveys = useSelector(getAllSurveys);

    const openPopover = () => setIsOpen(true);
    const closePopover = useCallback(() => setIsOpen(false), [setIsOpen]);

    const root = useRef(null);

    return (
        <div ref={root}>
            <SurveysFilterableByTextListButton onClick={openPopover} />
            {isOpen && (
                <Popover onClickAway={closePopover} parent={root.current}>
                    <SurveysFilterableByTextList
                        list={surveys}
                        onChange={onSelect}
                    />
                </Popover>
            )}
        </div>
    );
};

export default SearchSurvey;
