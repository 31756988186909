import * as ReportConstants from '../constants/Report';
import * as ReportApp from '../typescript/types';
import { AggregationTypes } from '../typescript/enums';
import { ReportAppActions } from '../typescript/actions/actions';

const INITIAL_STATE: ReportApp.ReportView = {
    selectedDollarAdjustment: 0,
    aggregationType: AggregationTypes.SHOW,
};

export default (state = INITIAL_STATE, action: ReportAppActions) => {
    switch (action.type) {
        case ReportConstants.SET_AGGREGATION_TYPE: {
            return {
                ...state,
                aggregationType: action.payload,
            };
        }
        case ReportConstants.SET_DOLLAR_YEAR: {
            // action.payload can be:
            // - translated 'none' string when user doesn't want any adjustments
            // - actual year
            const selectedDollarAdjustment = Number.isInteger(action.payload)
                ? action.payload
                : 0;
            return {
                ...state,
                selectedDollarAdjustment,
            };
        }
        case ReportConstants.RESET_REPORT_VIEW: {
            return { ...INITIAL_STATE };
        }
        default:
            return state;
    }
};
