import React from 'react';

import { YearsBySurveyGroupId } from '../../../../typescript/types';
import ModifySurveyDropdown from '../ModifySurvey/ModifySurveyDropdown';
import SurveyTitle from './SurveyTitle';
import CompareHint from './CompareHint/CompareHint';

import './ModifySurvey.scss';

type Props = {
    surveyGroupId: string;
    yearsBySurveyGroupId: YearsBySurveyGroupId;
};

const ModifySurvey = ({ surveyGroupId, yearsBySurveyGroupId }: Props) => {
    return (
        <>
            <div className="modify-survey">
                <SurveyTitle
                    surveyName={yearsBySurveyGroupId[+surveyGroupId].name}
                    messageId="report.sidebar.modify.titles.surveyYear"
                />
                <ModifySurveyDropdown
                    surveyGroupId={+surveyGroupId}
                    {...yearsBySurveyGroupId[+surveyGroupId]}
                />
            </div>
            <CompareHint />
        </>
    );
};

export default ModifySurvey;
