import * as SidebarConstants from '../constants/Sidebar';
import { Sidebar } from '../typescript/types';
import { ReportAppActions } from '../typescript/actions/actions';

const INITIAL_STATE: Sidebar = {
    activatedPanel: SidebarConstants.EMPTY,
};

export default (state = INITIAL_STATE, action: ReportAppActions) => {
    switch (action.type) {
        case SidebarConstants.SET_ACTIVATED_SIDEBAR_TAB:
            return {
                ...state,
                activatedPanel: action.payload,
            };
        default:
            return { ...state };
    }
};
