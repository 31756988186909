import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as GeoTypes from '../../selectors/GeoTypes';
import { getIsEditingTemplateFromDashboard } from '../../selectors/Templates';
import AppScreen from '../../constants/AppScreen';
import {
    getSetIsEditingTemplateAction,
    getUpdateGeographiesAction,
} from '../../actionCreators/reportActions';

import WizardHeader from '../WizardHeader/WizardHeader';

const GeographyPageHeader = () => {
    const dispatch = useDispatch();
    const areGeographiesSelected = useSelector(GeoTypes.hasSelectedGeographies);
    const selectedGeoFips = useSelector(GeoTypes.getSelectedGeographies);
    const isEditingTemplateFromDashboard = useSelector(
        getIsEditingTemplateFromDashboard,
    );

    const submitGeographies = () => {
        dispatch(getUpdateGeographiesAction(selectedGeoFips));
        // In case of application in template edit mode, this is the finishing
        // moment of report creation which should turf off that mode in order
        // the application to "behave" as normal in created report state
        if (isEditingTemplateFromDashboard) {
            dispatch(getSetIsEditingTemplateAction(false));
        }
    };
    return (
        <WizardHeader
            currentAppScreen={AppScreen.GEOGRAPHY_SELECTOR}
            isForwardButtonEnabled={areGeographiesSelected}
            onForwardButtonClick={submitGeographies}
        />
    );
};

export default GeographyPageHeader;
