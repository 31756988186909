import React, { useRef, useCallback, useState } from 'react';
import classNames from 'classnames';

import Popover from '../Popover/Popover';
import Button from '../../Buttons/Button';
import Icon from '../../Icon/Icon';
import SelectItem from './SelectItem';

import './Select.scss';

type Props<T> = {
    value: T;
    list: T[];
    className?: string;
    onChange: (item: T) => void;
};

const Select = <T extends number | string>({
    value,
    list,
    className,
    onChange,
}: Props<T>) => {
    const dropdownMenuContainer = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const showPopover = useCallback(() => setIsOpen(true), [setIsOpen]);
    const hidePopover = useCallback(() => setIsOpen(false), [setIsOpen]);

    const dropdownClassNames = classNames('dropdown-menu-popover', className);
    return (
        <div className="dropdown-menu" ref={dropdownMenuContainer}>
            <Button onClick={showPopover} className="dropdown-trigger">
                {value}
                <Icon className="trigger-icon">arrow_drop_down</Icon>
            </Button>
            {isOpen && (
                <Popover
                    parent={dropdownMenuContainer.current}
                    onClickAway={hidePopover}
                    className={dropdownClassNames}
                >
                    <ul>
                        {list.map((item) => (
                            <SelectItem
                                key={item}
                                item={item}
                                onClick={() => {
                                    hidePopover();
                                    onChange(item);
                                }}
                                selected={item === value}
                            />
                        ))}
                    </ul>
                </Popover>
            )}
        </div>
    );
};

export default Select;
