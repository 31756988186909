import React from 'react';
import { useSelector } from 'react-redux';

import * as NewSurvey from '../../../../selectors/NewSurvey';

import TableInfoForYear from './TableInfoForYear';

const TableInfo = () => {
    const foundTables = useSelector(NewSurvey.getTableAnalysis);

    const elements = Object.keys(foundTables).map((geoGroupIndex) => {
        const geoGroupAnalysis = foundTables[+geoGroupIndex];
        const byYearElements = Object.keys(
            geoGroupAnalysis.byYear,
        ).map((year) => (
            <TableInfoForYear
                key={year}
                year={+year}
                analysis={geoGroupAnalysis.byYear[+year]}
            />
        ));
        return (
            <React.Fragment key={geoGroupIndex}>
                <h6 className="change-survey-collapse-info-section-heading">
                    {geoGroupAnalysis.name}
                </h6>
                {byYearElements}
            </React.Fragment>
        );
    });

    return <>{elements}</>;
};

export default TableInfo;
