import React from 'react';
import { FormattedMessage } from 'react-intl';

const FilterableByTextListNoResults = () => {
    return (
        <div className="no-item">
            <FormattedMessage id="notFound" />
        </div>
    );
};

export default FilterableByTextListNoResults;
