import React from 'react';
import { FormattedMessage } from 'react-intl';

import TablesCitationPanel from '../../Sources/TablesCitationPanel';

import './CitationPanel.scss';

const CitationPanel = () => {
    return (
        <div id="citation-panel">
            <TablesCitationPanel />
            <div className="disclaimer">
                <p className="disclaimer__title">
                    <FormattedMessage id="report.sidebar.citation.disclaimer" />
                </p>
                <p className="disclaimer__content">
                    <FormattedMessage id="report.sidebar.citation.disclaimerContent" />
                </p>
            </div>
        </div>
    );
};

export default CitationPanel;
