import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as GeoTypes from '../../../selectors/GeoTypes';
import { CLEAR_SELECTED_SCREEN } from '../../../constants/SelectGeographyScreen';
import {
    SELECT_GEO_TYPE_ITEM,
    SET_INTERMITTENT_FIPS_SELECTION,
} from '../../../constants/Geographies';
import { ReportAppDispatch } from '../../../typescript/actions/actions';
import { SurveyCode, GeoType } from '../../../typescript/types';

import GeoTypesActions from './GeoTypesActions';
import Modal from '../../base/Modal/Modal';
import { GeographiesModalHeader } from '../GeographiesModalHeader';
import GeoTypesFilterableByTextList from './GeoTypesFilterableByTextList/GeoTypesFilterableByTextList';

type Props = {
    surveyCode: SurveyCode;
};

export const GeoTypesSelector = ({ surveyCode }: Props) => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const shouldDisplay = useSelector(GeoTypes.shouldDisplayGeoTypesSelector);
    const shouldSet = useSelector(GeoTypes.shouldSetIntermittentFips);
    const fipsSelection = useSelector(GeoTypes.getSelectedGeographies);

    useEffect(() => {
        if (shouldSet) {
            dispatch({
                type: SET_INTERMITTENT_FIPS_SELECTION,
                payload: fipsSelection,
            });
        }
    }, [dispatch, fipsSelection, shouldSet]);

    if (!shouldDisplay) {
        return null;
    }
    /** @param {import('../../../typescript/types').GeoType} geoType */
    const selectGeoType = (geoType: GeoType) => {
        dispatch({
            type: SELECT_GEO_TYPE_ITEM,
            payload: { surveyCode, geoTypeName: geoType.name },
        });
    };

    const showPreviousScreen = () => {
        dispatch({
            type: CLEAR_SELECTED_SCREEN,
        });
    };

    return (
        <Modal className="full-width" onClickAway={showPreviousScreen}>
            <GeographiesModalHeader
                surveyCode={surveyCode}
                geoTypeSelected={false}
            />
            <hr />
            <GeoTypesFilterableByTextList
                onSelect={selectGeoType}
                surveyCode={surveyCode}
            />
            <GeoTypesActions />
        </Modal>
    );
};
