import * as Sentry from '@sentry/react';

export function init(): void {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_ENVIRONMENT,
        release: process.env.REACT_APP_OMNI_VERSION,
        debug: process.env.REACT_APP_ENVIRONMENT === 'local',
        normalizeDepth: 10,
        initialScope: {
            tags: { tenant: process.env.REACT_APP_TENANT },
        },
    });
    Sentry.setTag('application', 'report');
}

export function getSentryReduxEnhancer(): any {
    return Sentry.createReduxEnhancer({
        stateTransformer: (state) => {
            return {
                ...state,
                db: null,
            };
        },
    });
}
