import * as Survey from '../constants/Survey';
import * as ReportApp from '../typescript/types';
import { ReportAppActions } from '../typescript/actions/actions';

const INITIAL_STATE: ReportApp.NewSurvey = {
    oldYears: null,
    newYears: null,
    surveyGroupId: null,
    loading: false,
    error: null,
    matchedGeoItemsByYear: {},
    reportInfoByOldReportId: null,
    comparabilityGroup: null,
};

export default (
    state: ReportApp.NewSurvey = INITIAL_STATE,
    action: ReportAppActions,
): ReportApp.NewSurvey => {
    switch (action.type) {
        case Survey.ANALYSE_CHANGE_REQUEST: {
            const {
                oldYears,
                newYears,
                surveyGroupId,
                comparabilityGroup,
            } = action.payload;
            return {
                ...state,
                oldYears,
                newYears,
                surveyGroupId,
                comparabilityGroup,
                loading: true,
            };
        }
        case Survey.ANALYSE_CHANGE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case Survey.ANALYSE_CHANGE_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case Survey.SET_REPORT_INFO: {
            const reportInfoByOldReportId = action.payload;
            return {
                ...state,
                reportInfoByOldReportId,
            };
        }
        case Survey.SET_FOUND_GEOGRAPHIES: {
            const { year, geoItemsBySl } = action.payload;
            const { matchedGeoItemsByYear } = state;
            return {
                ...state,
                matchedGeoItemsByYear: {
                    ...matchedGeoItemsByYear,
                    [year]: geoItemsBySl,
                },
            };
        }
        case Survey.CLEAR_NEW_SURVEY_CHANGE:
            return {
                ...INITIAL_STATE,
            };
        default:
            return state;
    }
};
