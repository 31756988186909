const enableFocusOutline = (event) => {
    if (event.key === 'Tab') {
        document.getElementById('root').classList.remove('hide-outline');
    }
};

const disableFocusOutline = () => {
    document.getElementById('root').classList.add('hide-outline');
};

export const initialize = () => {
    document.addEventListener('keydown', enableFocusOutline);
    document.addEventListener('mousedown', disableFocusOutline);
};
