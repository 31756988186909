import * as ReportConstants from '../constants/Report';

export default (state = {}, action) => {
    switch (action.type) {
        case ReportConstants.SET_NORMALIZED_REPORT_DATA: {
            const { oldReportId, data } = action.payload;
            return {
                ...state,
                [oldReportId]: data,
            };
        }
        case ReportConstants.RESET_REPORT_DATA: {
            return {};
        }
        default:
            return state;
    }
};
