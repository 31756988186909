import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { getOnSuccessfulDuplicationURL } from '../../../selectors/Report';
import { ReportActionModals } from '../../../constants/ReportActionsEnum';

import Modal from '../../base/Modal/Modal';
import UpdateReportModalContent from './UpdateReportModalContent';
import DeleteReportModalContent from './DeleteReportModalContent';
import SaveReportModalContent from './SaveReportModalContent';
import DuplicateReportModalContent from './DuplicateReportModalContent';
import TableTemplateModalContent from './TableTemplateModalContent';

type Props = {
    closeModal: () => void;
    modalContent: ReportActionModals;
};

const ReportActionsModal = ({ closeModal, modalContent }: Props) => {
    let content;
    const onSuccessfulDuplicationURL = useSelector(
        getOnSuccessfulDuplicationURL,
    );

    let modalClassname = 'report-action-modal full-width';
    switch (modalContent) {
        case ReportActionModals.RENAME_REPORT_MODAL:
            content = <UpdateReportModalContent onClose={closeModal} />;
            break;
        case ReportActionModals.COPY_REPORT_MODAL:
            content = <DuplicateReportModalContent onClose={closeModal} />;
            break;
        case ReportActionModals.DELETE_REPORT_MODAL:
            content = <DeleteReportModalContent onClose={closeModal} />;
            break;
        case ReportActionModals.SAVE_REPORT_MODAL:
            content = <SaveReportModalContent onClose={closeModal} />;
            break;
        case ReportActionModals.SAVE_TEMPLATE_MODAL:
            content = <TableTemplateModalContent onClose={closeModal} />;
            modalClassname = 'template-action-modal';
            break;
        case ReportActionModals.NO_MODAL:
            content = undefined;
            break;
    }

    if (onSuccessfulDuplicationURL) {
        return <Redirect to={onSuccessfulDuplicationURL} />;
    }

    return (
        <>
            {modalContent !== ReportActionModals.NO_MODAL && (
                <Modal
                    onClickAway={closeModal}
                    focusFirstChild
                    className={modalClassname}
                >
                    {content}
                </Modal>
            )}
        </>
    );
};

export default ReportActionsModal;
