import React from 'react';
import classNames from 'classnames';

import DOMElementID from '../../constants/DOMElementID';
import { focusElementOrFirstChild } from '../../customHooks/useLockFocus';

import Button, { Props as ButtonProps } from '../Buttons/Button';

import './JumpButton.scss';

type Props = {
    targetElementID: DOMElementID;
} & ButtonProps;

const JumpButton = ({ className, targetElementID, ...rest }: Props) => {
    const classes = classNames('jump-button', className);
    return (
        <Button
            className={classes}
            onClick={() => {
                focusElementOrFirstChild(targetElementID);
            }}
            {...rest}
        />
    );
};

export default JumpButton;
