import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { CitationFormats } from '../../constants/CitationFormat';

import Citations from './Citations';
import Select from '../base/Select/Select';

import './TablesCitationPanel.scss';

const TablesCitationPanel = () => {
    const intl = useIntl();
    const [indexOfSelectedFormat, setIndexOfSelectedFormat] = useState(0);

    const formatTexts = CitationFormats.map((format) =>
        intl.formatMessage({
            id: format.id,
        }),
    );

    const changeSelectedFormat = (formatText: string) => {
        const index = formatTexts.indexOf(formatText);
        setIndexOfSelectedFormat(index);
    };

    return (
        <>
            <div className="citation-format-picker">
                <div className="citation-format-picker__label">
                    <FormattedMessage id="report.sidebar.citation.citationFormat.label" />
                </div>
                <div className="citation-format-picker__dropdown">
                    <Select
                        list={formatTexts}
                        value={formatTexts[indexOfSelectedFormat]}
                        onChange={changeSelectedFormat}
                        className="citation-formats-dropdown"
                    />
                </div>
            </div>
            <Citations
                citationFormat={CitationFormats[indexOfSelectedFormat]}
            />
        </>
    );
};

export default TablesCitationPanel;
