import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getIsCreatingTemplateFromDashboard } from '../../selectors/Templates';

import PageContent from '../base/PageContent/PageContent';

const CreateTemplateMessage = () => {
    const isCreatingTemplateFromDashboard = useSelector(
        getIsCreatingTemplateFromDashboard,
    );

    if (!isCreatingTemplateFromDashboard) {
        return null;
    }

    return (
        <div className="create-template-message-container">
            <PageContent>
                <p>
                    <FormattedMessage id="surveysPage.templates.templatesHeader" />
                </p>
            </PageContent>
        </div>
    );
};

export default CreateTemplateMessage;
