import React from 'react';
import { useIntl } from 'react-intl';

import SelectedTablesListItem from './SelectedTablesListItem';

import './SelectedTablesList.scss';

/** @param {{selectedTables: import('../../typescript/types').TableForReportBySurveyCode[]}} param0 */
const SelectedTablesList = ({ selectedTables }) => {
    const intl = useIntl();

    const listDescription = intl.formatMessage(
        { id: 'tablesPage.selection.listDescription' },
        { count: selectedTables.length },
    );

    return (
        <ul className="selection-group" aria-label={listDescription}>
            {selectedTables.map((table, index) => (
                <SelectedTablesListItem
                    key={Object.values(table.guidBySurveyCode)[0]}
                    label={table.label}
                    index={index}
                />
            ))}
        </ul>
    );
};

export default SelectedTablesList;
