import React from 'react';

import AppScreen from '../../constants/AppScreen';

import HeaderForwardButton from '../HeaderForwardButton/HeaderForwardButton';
import HeaderBackwardButton from '../HeaderBackwardButton/HeaderBackwardButton';

export type Props = {
    currentAppScreen: AppScreen;
    isForwardButtonEnabled: boolean;
    onForwardButtonClick?: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => void;
};

const WizardHeaderControls = ({
    currentAppScreen,
    isForwardButtonEnabled,
    onForwardButtonClick,
}: Props) => {
    return (
        <div className="header-content-right">
            <HeaderBackwardButton currentAppScreen={currentAppScreen} />
            <HeaderForwardButton
                currentAppScreen={currentAppScreen}
                isForwardButtonEnabled={isForwardButtonEnabled}
                onClick={onForwardButtonClick}
            />
        </div>
    );
};

export default WizardHeaderControls;
