import React from 'react';
import classNames from 'classnames';
import { useIntl, FormattedMessage } from 'react-intl';

import SelectionCounter from '../Selection/SelectionCounter';
import Button from '../Buttons/Button';
import UpdateCurrentTemplateControl from './UpdateCurrentTemplateControl';

type Props = {
    isModifyingTables: boolean;
    changeInProgress: boolean;
    numberOfIntermittentTables: number;
    showTablesModifier: () => void;
    hideTablesModifier: () => void;
    openSaveModal: () => void;
};

const ModifyTablesToggle = ({
    isModifyingTables,
    changeInProgress,
    numberOfIntermittentTables,
    showTablesModifier,
    hideTablesModifier,
    openSaveModal,
}: Props) => {
    const intl = useIntl();

    const title = isModifyingTables ? (
        <h2>
            <FormattedMessage id="header.title.reportExists.table" />
        </h2>
    ) : null;

    const className = classNames('modify-template-tables-toggle', {
        expanded: isModifyingTables,
    });
    return (
        <div className={className}>
            {title}
            <div className="selected-tables-header">
                <SelectionCounter
                    numberOfSelectedItems={numberOfIntermittentTables}
                />
                {isModifyingTables && (
                    <>
                        <UpdateCurrentTemplateControl
                            hideTablesModifier={hideTablesModifier}
                            disabled={!changeInProgress}
                        >
                            <FormattedMessage id="update" />
                        </UpdateCurrentTemplateControl>
                        <Button
                            variant="flat"
                            onClick={() => {
                                hideTablesModifier();
                                openSaveModal();
                            }}
                            disabled={!changeInProgress}
                            title={intl.formatMessage({ id: 'saveAs' })}
                            aria-label={intl.formatMessage({ id: 'saveAs' })}
                        >
                            <FormattedMessage id="saveAs" />
                        </Button>
                        <Button
                            variant="flat"
                            onClick={hideTablesModifier}
                            title={intl.formatMessage({ id: 'done' })}
                            aria-label={intl.formatMessage({ id: 'done' })}
                        >
                            <FormattedMessage id="done" />
                        </Button>
                    </>
                )}
                {!isModifyingTables && (
                    <Button
                        variant="flat"
                        onClick={showTablesModifier}
                        title={intl.formatMessage({ id: 'edit' })}
                        aria-label={intl.formatMessage({ id: 'edit' })}
                    >
                        <FormattedMessage id="edit" />
                    </Button>
                )}
            </div>
        </div>
    );
};

export default ModifyTablesToggle;
