import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { ItemsPerPageListItem } from '../typescript/types';
import {
    getAvailableTablesPerPage,
    getAvailableGeographiesPerPage,
} from '../selectors/ReportNavigator';

/**
 * This function will take a list which looks like:
 * [
 *      { value: 4, isDefault: true },
 *      { value: 2, isDefault: false }
 *      ...
 * ]
 *
 * and return it in the following form:
 * [
 *      { value: 4, label: "Default (4)"},
 *      { value: 2, label: "2" }
 *      ...
 * ]
 */
export const useTranslatedConstantsForNavigator = () => {
    const intl = useIntl();
    const availableTablesPerPage = useSelector(getAvailableTablesPerPage);
    const availableGeographiesPerPage = useSelector(
        getAvailableGeographiesPerPage,
    );

    const mapTranslationsForNavigator = ({
        value,
        isDefault,
    }: ItemsPerPageListItem) => {
        const label = isDefault
            ? intl.formatMessage({ id: 'navigator.popover.default' }, { value })
            : value.toString();

        return { value, label };
    };

    const tablesPerPageList = availableTablesPerPage.map(
        mapTranslationsForNavigator,
    );
    const geographiesPerPageList = availableGeographiesPerPage.map(
        mapTranslationsForNavigator,
    );

    return { tablesPerPageList, geographiesPerPageList };
};
