import React, { useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReportActionModals } from '../../../constants/ReportActionsEnum';
import Popover from '../../base/Popover/Popover';
import Button from '../../Buttons/Button';
import Icon from '../../Icon/Icon';
import CreateReportLinks from './CreateReport/CreateReportLinks';
import CreateTemplateAction from './CreateTemplateAction';
import './MoreOptions.scss';
import MoreOptionsReportActions from './MoreOptionsReportActions';
import ReportActionsModal from './ReportActionsModal';

type Props = {
    openModal: (type: ReportActionModals) => void;
    closeModal: () => void;
    modalContent: ReportActionModals;
};

const MoreOptions = ({ closeModal, openModal, modalContent }: Props) => {
    const root = useRef<HTMLDivElement>(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const openPopover = useCallback(() => setIsPopoverOpen(true), []);
    const closePopover = useCallback(() => setIsPopoverOpen(false), []);

    const closeModalAndPopover = useCallback(() => {
        closeModal();
        closePopover();
    }, [closePopover, closeModal]);

    const iconContent = isPopoverOpen ? 'arrow_drop_up' : 'arrow_drop_down';
    return (
        <div ref={root}>
            <Button
                variant="flat"
                onClick={openPopover}
                className="with-icon-right"
                aria-haspopup={true}
                aria-expanded={isPopoverOpen}
            >
                <FormattedMessage id="report.reportActions.moreOptions" />
                <Icon>{iconContent}</Icon>
            </Button>
            {isPopoverOpen && (
                <Popover
                    parent={root.current}
                    onClickAway={closePopover}
                    className="more-options-popover"
                >
                    <MoreOptionsReportActions openModal={openModal} />
                    <CreateTemplateAction openModal={openModal} />
                    <CreateReportLinks />
                </Popover>
            )}
            <ReportActionsModal
                closeModal={closeModalAndPopover}
                modalContent={modalContent}
            />
        </div>
    );
};

export default MoreOptions;
