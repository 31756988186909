import React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import Button from '../../Buttons/Button';

import './ToggleButton.scss';

export type ToggleOneButton = {
    onClick: () => void;
    label: string;
    isSelected: boolean;
    title?: string;
};

type Props = {
    buttons: ToggleOneButton[];
} & React.ButtonHTMLAttributes<HTMLDivElement>;

const ToggleButton = ({ buttons, className, ...rest }: Props) => {
    const classes = classNames('toggle-button-group', className);
    const intl = useIntl();
    return (
        <div className={classes} role="feed" {...rest}>
            {buttons.map(({ onClick, label, title, isSelected }, index) => {
                const buttonClasses = classNames('toggle-button', {
                    'toggle-button-selected': isSelected,
                });
                const listItemAriaLabel = intl.formatMessage(
                    {
                        id: `report.sidebar.modify.toggle.${
                            isSelected ? 'active' : 'inactive'
                        }`,
                    },
                    { title: title || label },
                );
                return (
                    <Button
                        key={index}
                        onClick={onClick}
                        className={buttonClasses}
                        title={listItemAriaLabel}
                        aria-label={listItemAriaLabel}
                    >
                        {label}
                    </Button>
                );
            })}
        </div>
    );
};

export default ToggleButton;
