import React from 'react';
import classNames from 'classnames';
import { useIntl, FormattedMessage } from 'react-intl';

import { sortByUndefined } from '../../../../helpers/Sort';
import * as ReportApp from '../../../../typescript/types';

import '../InfoTableForYear.scss';

type Props = {
    results: ReportApp.TableAnalysisResultPair[];
    year: number;
};

const TableInfoTableForYear = ({ results, year }: Props) => {
    const intl = useIntl();
    const tableNotFoundMsg = intl.formatMessage({
        id: 'surveysPage.changeSurvey.tableNotFound',
    });
    const rows = results
        .slice()
        .sort((resultA, resultB) =>
            sortByUndefined(
                resultA.foundTableLabel ?? undefined,
                resultB.foundTableLabel ?? undefined,
            ),
        )
        .map(({ existingTableLabel, foundTableLabel }) => {
            const labelClass = classNames('label', { empty: !foundTableLabel });
            const title = foundTableLabel ?? tableNotFoundMsg;

            return (
                <tr key={existingTableLabel}>
                    <td className="label" title={existingTableLabel}>
                        <span>{existingTableLabel}</span>
                    </td>
                    <td className={labelClass} title={title}>
                        <span>{foundTableLabel}</span>
                    </td>
                </tr>
            );
        });
    return (
        <table className="info-table">
            <thead>
                <tr>
                    <th></th>
                    <th>
                        {year} -{' '}
                        <span className="new-survey">
                            <FormattedMessage id="surveysPage.changeSurvey.newSurvey" />
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
    );
};

export default TableInfoTableForYear;
