import React from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import Button from '../../Buttons/Button';
import Icon from '../../Icon/Icon';

import './ReportActionModalContent.scss';

type Props = {
    header: string;
    onClose: () => void;
    children: React.ReactNode;
    mainButton: {
        action: () => void;
        text: string;
        disabled?: boolean;
    };
    withMinHeight?: boolean;
};

const ReportActionsModalContent = ({
    header,
    onClose,
    children,
    mainButton: { action, text, disabled = false },
    withMinHeight = true,
}: Props) => {
    const intl = useIntl();
    const contentClasses = classNames('report-action-modal-content', {
        'zoomable-content': withMinHeight,
    });
    const closeMessage = intl.formatMessage({ id: 'close' });
    return (
        <>
            <div className="report-action-modal-header zoomable-header">
                <h4>{header}</h4>
                <Button
                    onClick={onClose}
                    title={closeMessage}
                    aria-label={closeMessage}
                >
                    <Icon>close</Icon>
                </Button>
            </div>
            <div className={contentClasses}>{children}</div>
            <div className="report-action-modal-footer zoomable-actions">
                <Button variant="flat" onClick={onClose} className="small">
                    {intl.formatMessage({ id: 'cancel' })}
                </Button>
                <Button
                    variant="contained"
                    onClick={action}
                    disabled={disabled}
                    className="small"
                >
                    {text}
                </Button>
            </div>
        </>
    );
};

export default ReportActionsModalContent;
