import React from 'react';
import { useSelector } from 'react-redux';

import { getOldYears } from '../../../selectors/NewSurvey';

import WarningMessage from '../../WarningMessage/WarningMessage';
import ChangeSurveyMatchMessage from './ChangeSurveyMatchMessage';

type Props = {
    isMatch: boolean;
};

const ChangeSurveyDescription = ({ isMatch }: Props) => {
    const years = useSelector(getOldYears)?.slice().sort();

    if (!years) {
        return null;
    }

    if (isMatch) {
        return <ChangeSurveyMatchMessage years={years} />;
    }

    return (
        <WarningMessage
            messageId="surveysPage.changeSurvey.description"
            values={{
                b: (chunk) => <strong>{chunk}</strong>,
                count: years.length,
                years: years.join(', '),
            }}
        />
    );
};

export default ChangeSurveyDescription;
