import React from 'react';
import { useSelector } from 'react-redux';

import * as ReportApp from '../../../../typescript/types';
import * as ReportMetadata from '../../../../selectors/ReportMetadata';

type Props = {
    tableGuid: ReportApp.TableGuid;
    oldReportId: ReportApp.OldReportId;
    maxNumberOfColumns: number;
};

const SingleTableSubheaderForReport = ({
    tableGuid,
    oldReportId,
    maxNumberOfColumns,
}: Props) => {
    const outputColumns = useSelector((state) =>
        ReportMetadata.getOutputColumns(state, oldReportId, tableGuid),
    );
    const elements = outputColumns.map(({ caption, colSpan }, index) => (
        <td key={index} colSpan={colSpan}>
            {caption}
        </td>
    ));
    const numberOfOutputColumns = outputColumns.reduce(
        (acc, column) => acc + column.colSpan,
        0,
    );
    if (maxNumberOfColumns !== numberOfOutputColumns) {
        elements.push(
            <td
                key={elements.length}
                colSpan={maxNumberOfColumns - numberOfOutputColumns}
            />,
        );
    }
    return <>{elements}</>;
};

export default SingleTableSubheaderForReport;
