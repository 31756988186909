import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as ModalStatusConstants from '../../constants/TableModalContentStatus';
import { focusElementOrFirstChild } from '../../customHooks/useLockFocus';
import { SurveyCode } from '../../typescript/types';

import * as PremadeReport from '../../selectors/PremadeReport';
import { FETCH_PREMADE_REPORTS_REQUEST } from '../../constants/Table';
import { FETCH_TEMPLATES_REQUEST } from '../../constants/Templates';
import { getShouldFetchTemplates } from '../../selectors/Templates';

import TablesSelectorModal from './TablesSelector/TablesSelectorModal';
import PremadeReportsSelectorModal from './PremadeReportsSelector/PremadeReportsSelectorModal';

import DOMElementID from '../../constants/DOMElementID';

type Props = {
    modalContentStatus: string;
    setModalContentStatus: (status: string) => void;
    surveyCodes: SurveyCode[];
};

const SelectTablesModal = ({
    modalContentStatus,
    setModalContentStatus,
    surveyCodes,
}: Props) => {
    const closeModal = () => setModalContentStatus(ModalStatusConstants.EMPTY);
    let modal: React.ReactNode = null;
    const dispatch = useDispatch();
    const shouldLoadPremadeReports = useSelector((state) =>
        PremadeReport.getShouldLoadPremadeReports(state, surveyCodes),
    );
    const shouldFetchTemplates = useSelector(getShouldFetchTemplates);

    useEffect(() => {
        if (shouldLoadPremadeReports) {
            dispatch({
                type: FETCH_PREMADE_REPORTS_REQUEST,
                payload: surveyCodes,
            });
        }
    }, [dispatch, shouldLoadPremadeReports, surveyCodes]);

    useEffect(() => {
        if (shouldFetchTemplates) {
            dispatch({
                type: FETCH_TEMPLATES_REQUEST,
            });
        }
    }, [dispatch, shouldFetchTemplates]);

    switch (modalContentStatus) {
        case ModalStatusConstants.SEARCH_ALL_REPORTS:
            modal = (
                <TablesSelectorModal
                    closeModal={closeModal}
                    surveyCodes={surveyCodes}
                />
            );
            break;
        case ModalStatusConstants.SEARCH_PREMADE_REPORTS:
            modal = (
                <PremadeReportsSelectorModal
                    closeModal={closeModal}
                    surveyCodes={surveyCodes}
                />
            );
            break;
        case ModalStatusConstants.EMPTY:
        default:
            modal = null;
    }

    useEffect(() => {
        if (!modal) {
            focusElementOrFirstChild(DOMElementID.SELECTOR_CONTAINER);
        }
    }, [modal]);

    return <>{modal}</>;
};

export default SelectTablesModal;
