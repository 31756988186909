import React, { useRef } from 'react';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import * as ReportMetadata from '../../../selectors/ReportMetadata';
import { MAX_GEOGRAPHIES_ALLOWED_FOR_DOWNLOAD } from '../../../helpers/Geographies';
import { getDownloadExcelButtonGtmAttributes } from '../../../constants/GtmAttributes';
import { useAddGtmAttributes } from '../../../customHooks/useAddGtmAttributes';

import Button from '../../Buttons/Button';
import Icon from '../../Icon/Icon';

type Props = {
    messageId: string;
    onClick: () => void;
};

const DownloadExcelButton = ({ messageId, onClick }: Props) => {
    const intl = useIntl();

    const oldReportId = useSelector(ReportMetadata.getSingleReportId);
    const totalNumberOfGeographies =
        useSelector((state) =>
            ReportMetadata.getTotalNumberOfGeographiesByOldReportId(
                state,
                oldReportId,
            ),
        ) || 0;
    const shouldAllowExcelDownload = useSelector(
        ReportMetadata.getShouldAllowExcelDownload,
    );

    const downloadExcelButtonRef = useRef(null);
    useAddGtmAttributes(
        downloadExcelButtonRef,
        getDownloadExcelButtonGtmAttributes(),
    );

    const disabledButtonMessage = intl.formatMessage(
        { id: 'report.sidebar.downloadExcel.buttonDisabled' },
        {
            max: MAX_GEOGRAPHIES_ALLOWED_FOR_DOWNLOAD,
            selected: totalNumberOfGeographies,
        },
    );

    return (
        <Button
            className={classNames('download-excel-button', {
                inactive: !shouldAllowExcelDownload,
            })}
            onClick={onClick}
            disabled={!shouldAllowExcelDownload}
            title={shouldAllowExcelDownload ? undefined : disabledButtonMessage}
            ref={downloadExcelButtonRef}
        >
            <Icon>file_download</Icon>
            <span className="download-text">
                <FormattedMessage id={messageId} />
            </span>
        </Button>
    );
};

export default DownloadExcelButton;
