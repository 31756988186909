import { DefaultRootState } from 'react-redux';
import { DownloadPdfScreen } from '../constants/DownloadPdfScreen';
import * as ReportMetadata from './ReportMetadata';
import { MAX_COLUMNS_ALLOWED_FOR_PDF_DOWNLOAD } from '../constants/Report';

const getIsPdfColumnsLimitExceeded = (state: DefaultRootState) => {
    const numberOfSelectedColumnsPerGeography = ReportMetadata.getNumberOfSelectedColumns(
        state,
    );

    return (
        numberOfSelectedColumnsPerGeography >
        MAX_COLUMNS_ALLOWED_FOR_PDF_DOWNLOAD
    );
};
export const getDownloadPdfWhatToRender = (state: DefaultRootState) => {
    const { error, loading } = state.reportStatus.downloadPdf;
    if (error) {
        return DownloadPdfScreen.DOWNLOAD_PDF_ERROR;
    } else if (
        loading ||
        !ReportMetadata.getIsDefinitionAndByOldReportIdLoaded(state) ||
        !ReportMetadata.getGeographies(state).length
    ) {
        return DownloadPdfScreen.DOWNLOAD_PDF_LOADING;
    } else if (getIsPdfColumnsLimitExceeded(state)) {
        return DownloadPdfScreen.DOWNLOAD_PDF_TOO_MANY_COLUMNS;
    }
    return DownloadPdfScreen.DOWNLOAD_PDF_AVAILABLE;
};

export const getDownloadPdfError = (state: DefaultRootState) =>
    state.reportStatus.downloadPdf.error;
