import React from 'react';
import { useSelector } from 'react-redux';

import { getGeoGroupsWithGeoTypes } from '../../../selectors/ReportMetadata';

import ReportCsvAndProgramsDownload from './ReportCsvAndProgramsDownload';

const GeoGroups = () => {
    const geoGroupsWithGeoTypes = useSelector(getGeoGroupsWithGeoTypes);

    const mappedGeoGroupsWithGeoTypes = geoGroupsWithGeoTypes.map(
        (geoGroup, geoGroupIndex) => {
            return (
                <div key={geoGroupIndex}>
                    {geoGroup.name}
                    {geoGroup.columnGroups.map((columnGroup, columnIndex) => {
                        return (
                            <div key={`${geoGroupIndex}-${columnIndex}`}>
                                {columnGroup.name}
                                {
                                    <ReportCsvAndProgramsDownload
                                        columnGroup={columnGroup}
                                    />
                                }
                            </div>
                        );
                    })}
                </div>
            );
        },
    );

    return <>{mappedGeoGroupsWithGeoTypes}</>;
};

export default GeoGroups;
