import { put, call, all, select } from 'redux-saga/effects';
import api from '../helpers/Api';
import BackendPaths from '../helpers/BackendPaths';
import * as ReportConstants from '../constants/Report';
import * as ReportMetadata from '../selectors/ReportMetadata';
import * as ReportNavigator from '../selectors/ReportNavigator';
import * as Report from '../selectors/Report';
import * as Geographies from '../helpers/Geographies';
import * as ReportApp from '../typescript/types';
import { intl } from '../helpers/CreateIntl';
import { AggregationTypes } from '../typescript/enums';

const appendSlInData = (
    sl: string,
    data: ReportApp.ReportDataValue[][],
    geoFipsIndex: number,
) => {
    data.forEach((dataForOneGeography) => {
        const fips = dataForOneGeography[geoFipsIndex];
        if (fips == null) {
            return;
        }
        if (typeof fips !== 'string') {
            throw new Error(`Fips ${fips} of unknown type`);
        }
        dataForOneGeography[
            geoFipsIndex
        ] = Geographies.getFipsCodeForFipsAndSumLev(fips, sl);
    });
};

export const formatReportData = (
    response: ReportApp.AspNetWebService.ReportDataResults,
    geoTypeSelections: ReportApp.GeoTypeSelection[],
    aggregationType: number,
) => {
    const headers = response.d.Headers;

    const geoFipsIndex = headers.findIndex(
        (header) => header.toLowerCase() === ReportConstants.GEO_FIPS,
    );
    const qNameIndex = headers.findIndex(
        (header) => header.toLowerCase() === ReportConstants.GEO_COLUMN_NAME,
    );

    const rawData = response.d.Data.map((dataChunk) => {
        const data = dataChunk.Data;

        const sl = geoTypeSelections[dataChunk.Index].sumLev;
        appendSlInData(sl, data, geoFipsIndex);

        const totals = dataChunk.Totals;
        if (totals.length) {
            totals[qNameIndex] = intl.formatMessage(
                { id: 'report.table.total' },
                {
                    geoTypePlural:
                        geoTypeSelections[dataChunk.Index].pluralName,
                },
            );

            if (aggregationType === AggregationTypes.ALL_SLS_TOTAL) {
                totals[geoFipsIndex] =
                    ReportConstants.ALL_SLS_TOTAL_COLUMN_NAME;
            } else {
                const totalFips = Geographies.getTotalFipsCodeForSl(
                    geoTypeSelections[dataChunk.Index].sumLev,
                );
                totals[geoFipsIndex] = Geographies.getFipsCodeForFipsAndSumLev(
                    totalFips,
                    sl,
                );
            }

            return [...data, totals];
        } else {
            return data;
        }
    }).flat();

    if (!rawData.length || !rawData[0].length) {
        return null;
    }

    const data: ReportApp.ReportData = {};

    headers.forEach((column, index) => {
        if (index === qNameIndex) {
            column = ReportConstants.GEO_COLUMN_NAME;
        }
        if (index === geoFipsIndex) {
            column = ReportConstants.GEO_FIPS;
        }
        data[column] = rawData.map((row) => {
            return row[index];
        });
    });

    return data;
};

export function getTotalsWithNullValues(
    response: ReportApp.AspNetWebService.ReportDataResults,
) {
    const headers: string[] = response.d.Headers;

    const rowsMap: ReportApp.ReportNullValueRows = {};
    let columnIndex = 0;
    response.d.Data.forEach((dataChunk) => {
        columnIndex += dataChunk.Data.length;
        if (dataChunk.Totals.length) {
            if (dataChunk.IndexOfTotalsWithNullValues.length) {
                rowsMap[
                    columnIndex
                ] = dataChunk.IndexOfTotalsWithNullValues.map(
                    (variableIndex) => headers[variableIndex],
                );
            }
            columnIndex += 1;
        }
    });

    const reportTotalsData: ReportApp.ReportTotalsData = {
        nullValueRows: rowsMap,
    };
    return reportTotalsData;
}

export function* fetchReportData() {
    const adjustDollarValuesToYear: number = yield select(
        Report.getSelectedDollarAdjustment,
    );
    const [tablePage, tablesPerPage]: number[] = yield select(
        ReportNavigator.getTablesNavigationStatus,
    );
    const [geoItemPage, geoItemsPerPage]: number[] = yield select(
        ReportNavigator.getGeosNavigationStatus,
    );

    const oldReportSegments: ReportApp.ReportSegments[] = yield select(
        ReportMetadata.getReportDataSegmentsByGeoGroups,
        geoItemPage,
        geoItemsPerPage,
        tablePage,
        tablesPerPage,
    );

    const calls = oldReportSegments.map(
        ({
            oldReportId,
            aggregationType,
            startIndex,
            geoItemsRange,
            tableGuids,
        }) => {
            return call(
                api,
                BackendPaths.getDataSlicedByGeoItemsRange.getPath({
                    oldReportId,
                    geoItemStartIndex: startIndex,
                    geoItemSlice: geoItemsRange,
                    aggregationType,
                    adjustDollarValuesToYear,
                    tableGuids: tableGuids.join('|'),
                }),
                'GET-CACHE',
            );
        },
    );
    const responses: ReportApp.AspNetWebService.ReportDataResults[] = yield all(
        calls,
    );

    return responses;
}

export function* storeReportData(
    dataResponses: ReportApp.AspNetWebService.ReportDataResults[],
) {
    const [tablePage, tablesPerPage]: number[] = yield select(
        ReportNavigator.getTablesNavigationStatus,
    );
    const [geoItemPage, geoItemsPerPage]: number[] = yield select(
        ReportNavigator.getGeosNavigationStatus,
    );

    const oldReportSegments: ReportApp.ReportSegments[] = yield select(
        (state) =>
            ReportMetadata.getReportDataSegmentsByGeoGroups(
                state,
                geoItemPage,
                geoItemsPerPage,
                tablePage,
                tablesPerPage,
            ),
    );
    for (let i = 0; i < oldReportSegments.length; i += 1) {
        const { oldReportId, aggregationType } = oldReportSegments[i];
        const geoTypeSelections: ReportApp.GeoTypeSelection[] = yield select(
            ReportMetadata.getGeoTypeSelections,
            oldReportId,
        );

        const data = formatReportData(
            dataResponses[i],
            geoTypeSelections,
            aggregationType,
        );

        const totalsData = getTotalsWithNullValues(dataResponses[i]);

        yield put({
            type: ReportConstants.SET_NORMALIZED_REPORT_DATA,
            payload: { oldReportId, data },
        });

        yield put({
            type: ReportConstants.SET_REPORT_TOTALS_DATA,
            payload: { oldReportId, totalsData },
        });
    }
}
