export const SET_NORMALIZED_GEO_TYPES = '@@geoTypes/SET_NORMALIZED_GEO_TYPES';
export const SET_SELECTABLE_GEO_TYPES = '@@geoTypes/SET_SELECTABLE_GEO_TYPES';
export const SELECT_GEO_TYPE_ITEM = '@@geoTypes/SELECT_GEO_TYPE_ITEM';
export const FETCH_GEO_TYPES_REQUEST = '@@geoTypes/FETCH_GEO_TYPES_REQUEST';
export const FETCH_GEO_TYPES_SUCCESS = '@@geoTypes/FETCH_GEO_TYPES_SUCCESS';
export const FETCH_GEO_TYPES_FAILURE = '@@geoTypes/FETCH_GEO_TYPES_FAILURE';
export const FETCH_PARENT_GEO_TYPES_REQUEST =
    '@@geoTypes/FETCH_PARENT_GEO_TYPES_REQUEST';
export const FETCH_PARENT_GEO_TYPES_SUCCESS =
    '@@geoTypes/FETCH_PARENT_GEO_TYPES_SUCCESS';
export const FETCH_PARENT_GEO_TYPES_FAILURE =
    '@@geoTypes/FETCH_PARENT_GEO_TYPES_FAILURE';
export const SET_SELECTABLE_PARENT_GEO_TYPES =
    '@@geoTypes/SET_SELECTABLE_PARENT_GEO_TYPES';
export const CLEAR_SELECTABLE_PARENT_GEO_TYPES =
    '@@geoTypes/CLEAR_SELECTABLE_PARENT_GEO_TYPES';
export const CLEAR_GEO_ITEMS_BELOW_SUMMARY_LEVEL =
    '@@geoTypes/CLEAR_GEO_ITEMS_BELOW_SUMMARY_LEVEL';
export const FETCH_GEO_ITEMS_REQUEST = '@@geoTypes/FETCH_GEO_ITEMS_REQUEST';
export const FETCH_GEO_ITEMS_SUCCESS = '@@geoTypes/FETCH_GEO_ITEMS_SUCCESS';
export const FETCH_GEO_ITEMS_FAILURE = '@@geoTypes/FETCH_GEO_ITEMS_FAILURE';
export const SET_SELECTABLE_GEO_ITEMS = '@@geoTypes/SET_SELECTABLE_GEO_ITEMS';
export const SET_SELECTED_GEO_FIPSES = '@@geoTypes/SET_SELECTED_GEO_FIPSES';
export const REMOVE_FIPS_FROM_SELECTION =
    '@@geoTypes/REMOVE_FIPS_FROM_SELECTION';
export const SET_SELECTED_GEO_TYPE = '@@geoTypes/SET_SELECTED_GEO_TYPE';
export const CLEAR_SELECTED_GEO_TYPE = '@@geoTypes/CLEAR_SELECTED_GEO_TYPE';
export const SET_GEO_SELECTION = '@@geoTypes/SET_GEO_SELECTION';
export const CLEAR_GEO_SELECTION_BELOW_SUMMARY_LEVEL =
    '@@geoTypes/CLEAR_GEO_SELECTION_BELOW_SUMMARY_LEVEL';
export const CLEAR_BELOW_SUMMARY_LEVEL = '@@geoTypes/CLEAR_BELOW_SUMMARY_LEVEL';
export const EXIT_GEO_ITEMS_SELECTION = '@@geoTypes/EXIT_GEO_ITEMS_SELECTION';
export const MODIFY_FIPS_SELECTION = '@@geoTypes/MODIFY_FIPS_SELECTION';
export const APPEND_FIPS_SELECTION = '@@geoTypes/APPEND_FIPS_SELECTION';
export const FETCH_GEO_ITEMS_BY_FIPS_REQUEST =
    '@@geoTypes/FETCH_GEO_ITEMS_BY_FIPS_REQUEST';
export const FETCH_GEO_ITEMS_BY_FIPS_SUCCESS =
    '@@geoTypes/FETCH_GEO_ITEMS_BY_FIPS_SUCCESS';
export const FETCH_GEO_ITEMS_BY_FIPS_FAILURE =
    '@@geoTypes/FETCH_GEO_ITEMS_BY_FIPS_FAILURE';
export const SET_GEO_ITEMS_BY_FIPS_RESULTS =
    '@@geoTypes/SET_GEO_ITEMS_BY_FIPS_RESULTS';
export const CLEAR_GEO_ITEMS_BY_FIPS_RESULTS =
    '@@geoTypes/CLEAR_GEO_ITEMS_BY_FIPS_RESULTS';
export const SET_INTERMITTENT_FIPS_SELECTION =
    '@@geoTypes/SET_INTERMITTENT_FIPS_SELECTION';
export const RESET_VALUES = '@@geoTypes/RESET_VALUES';

export const FETCH_SELECTED_GEOGRAPHIES_REQUEST =
    '@@geoTypes/FETCH_SELECTED_GEOGRAPHIES_REQUEST';
export const FETCH_SELECTED_GEOGRAPHIES_SUCCESS =
    '@@geoTypes/FETCH_SELECTED_GEOGRAPHIES_SUCCESS';
export const FETCH_SELECTED_GEOGRAPHIES_FAILURE =
    '@@geoTypes/FETCH_SELECTED_GEOGRAPHIES_FAILURE';
