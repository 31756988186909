import React from 'react';
import DownloadCardAction from './DownloadCardAction';
import './DownloadCardHeaderWithActions.scss';

const DownloadCardHeaderWithActions = ({ title, actions }) => {
    return (
        <div className="download-card-header">
            <span>{title}</span>
            <div className="download-card-actions">
                {actions.map((actionProps, index) => (
                    <DownloadCardAction key={index} {...actionProps} />
                ))}
            </div>
        </div>
    );
};

export default DownloadCardHeaderWithActions;
