import React from 'react';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import ToggleButton from '../../base/ToggleButton/ToggleButton';

import './SurveySelector.scss';

/**
 * @param {{
 *  selectedSurveyCode: string,
 *  setSelectedSurveyCode: (surveyCode: string) => void,
 *  surveyCodes: string[]
 * }} param0
 */
const SurveySelector = ({
    selectedSurveyCode,
    setSelectedSurveyCode,
    surveyCodes,
}) => {
    const intl = useIntl();
    if (surveyCodes.length === 1) {
        return null;
    }
    const buttons = surveyCodes.map((surveyCode) => {
        const isSelected = selectedSurveyCode === surveyCode;
        return {
            onClick: () => setSelectedSurveyCode(surveyCode),
            label: surveyCode,
            title: surveyCode,
            isSelected,
        };
    });
    const buttonAriaLabel = intl.formatMessage({
        id: 'report.sidebar.modify.toggle.datasetSelector',
    });
    const toggleButtonClassName = classNames(`buttons-${surveyCodes.length}`, {
        'more-than-5-buttons': surveyCodes.length > 5,
    });
    return (
        <div className="survey-selector">
            <span className="survey-selector-label">
                <FormattedMessage id="tablesPage.tablesSelection.selectDataset" />
            </span>
            <ToggleButton
                buttons={buttons}
                aria-label={buttonAriaLabel}
                className={toggleButtonClassName}
            />
        </div>
    );
};

export default SurveySelector;
