import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import * as Table from '../../../selectors/Table';
import * as ReportMetadata from '../../../selectors/ReportMetadata';
import * as DownloadPdfSelector from '../../../selectors/DownloadPdf';
import { getShouldAllowDownload } from '../../../selectors/CurrentUser';
import { getFetchDatasetsAction } from '../../../actionCreators/tableActions';
import { ReportAppDispatch } from '../../../typescript/actions/actions';
import { DownloadPdfScreen } from '../../../constants/DownloadPdfScreen';

import Loader from '../../Loader/Loader';
import DownloadPdfButton from './DownloadPdfButton';
import TrialWarning from '../../TrialWarning/TrialWarning';
import ToggleButton from '../../base/ToggleButton/ToggleButton';
import PdfLimitWarning from './PdfLimitWarning';

import './DownloadPdfPanel.scss';

const orientations = ['portrait', 'landscape'];

const DownloadPdfPanel = () => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const intl = useIntl();
    const [selectedOrientation, setSelectedOrientation] = useState(
        orientations[0],
    );

    const downloadPdfWhatToRender = useSelector(
        DownloadPdfSelector.getDownloadPdfWhatToRender,
    );
    const downloadPdfError = useSelector(
        DownloadPdfSelector.getDownloadPdfError,
    );
    const shouldAllowDownload = useSelector(getShouldAllowDownload);
    const surveyCodesUsedInReport = useSelector(
        ReportMetadata.getSurveyCodesUsedInReport,
    );
    const shouldLoadDatasets = useSelector((state) =>
        Table.getShouldLoadDatasets(state, surveyCodesUsedInReport),
    );
    useEffect(() => {
        if (shouldLoadDatasets) {
            dispatch(getFetchDatasetsAction(surveyCodesUsedInReport));
        }
    }, [shouldLoadDatasets, dispatch, surveyCodesUsedInReport]);

    let content;
    switch (downloadPdfWhatToRender) {
        case DownloadPdfScreen.DOWNLOAD_PDF_ERROR:
            content = <div>{downloadPdfError}</div>;
            break;
        case DownloadPdfScreen.DOWNLOAD_PDF_LOADING:
            content = (
                <Loader
                    text={intl.formatMessage({
                        id: 'report.sidebar.downloadPdf.preparingDownload',
                    })}
                />
            );
            break;
        case DownloadPdfScreen.DOWNLOAD_PDF_TOO_MANY_COLUMNS:
            content = <PdfLimitWarning />;
            break;
        case DownloadPdfScreen.DOWNLOAD_PDF_AVAILABLE:
        default:
            if (shouldAllowDownload) {
                content = (
                    <>
                        <FormattedMessage id="report.sidebar.downloadPdf.chooseOrientation" />
                        <ToggleButton
                            buttons={orientations.map((orientation) => {
                                return {
                                    onClick: () =>
                                        setSelectedOrientation(orientation),
                                    isSelected:
                                        selectedOrientation === orientation,
                                    label: orientation,
                                    title: orientation,
                                };
                            })}
                            className="orientation-toggle"
                        />
                        <DownloadPdfButton orientation={selectedOrientation} />
                    </>
                );
            } else {
                content = <TrialWarning />;
            }
    }

    return <div id="download-pdf-panel">{content}</div>;
};

export default DownloadPdfPanel;
