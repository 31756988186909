import React from 'react';
import { useIntl } from 'react-intl';

import Button from '../../../Buttons/Button';
import Icon from '../../../Icon/Icon';

import './GeoTypesFilterableByTextListItem.scss';

const GeoTypesFilterableByTextListItem = ({ geoType }) => {
    const intl = useIntl();
    const listItemAriaLabel = intl.formatMessage(
        { id: 'geographiesPage.geoTypes.listItemAriaLabel' },
        { label: geoType.label },
    );
    return (
        <li className="geo-type-list-item">
            <Button title={listItemAriaLabel} aria-label={listItemAriaLabel}>
                <span className="label">
                    <span className="label-sumlev">{geoType.sumLev}</span>{' '}
                    {!!geoType.indent && (
                        <span className="label-indent">
                            {'… '.repeat(geoType.indent)}
                        </span>
                    )}
                    <span className="label-body">{geoType.label}</span>
                </span>
                <Icon className="geo-type-list-item-arrow">
                    keyboard_arrow_right
                </Icon>
            </Button>
        </li>
    );
};

export default GeoTypesFilterableByTextListItem;
