import React, { useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getGeoTypes } from '../../../selectors/GeoTypes';
import { CLEAR_SELECTED_SCREEN } from '../../../constants/SelectGeographyScreen';

import Modal from '../../base/Modal/Modal';
import FipsCodesHint from './FipsCodesHint';
import FipsCodesTextarea from './FipsCodesTextarea';
import FipsCodesSelectorHeader from './FipsCodesSelectorHeader';
import FipsCodesSelectorActions from './FipsCodesSelectorActions';
import FipsCodesGeoTypeDropdown from './FipsCodesGeoTypeDropdown';
import FipsCodesSearchResult from './FipsCodesResults/FipsCodesSearchResult';

import './FipsCodesSelector.scss';

/**@param {{surveyCode: string}} param0 */
const FipsCodesSelector = ({ surveyCode }) => {
    const dispatch = useDispatch();
    /** @type {React.MutableRefObject<HTMLTextAreaElement>} */
    const textareaRef = useRef(null); // used for focus after geoType change

    /** @type {[import('../../../typescript/types').GeoType, React.Dispatch<React.SetStateAction<import('../../../typescript/types').GeoType>>]} */
    const [selectedGeoType, setSelectedGeoType] = useState(null);
    const [fipsInput, setFipsInput] = useState('');
    const [isResultsModalOpen, setIsResultsModalOpen] = useState(false);

    const geoTypes = useSelector((state) => getGeoTypes(state, surveyCode));

    const closeModal = useCallback(
        () => dispatch({ type: CLEAR_SELECTED_SCREEN }),
        [dispatch],
    );
    const setGeoTypeAndFocusTextarea = (geoType) => {
        setSelectedGeoType(geoType);
        textareaRef.current.focus();
    };

    const onCloseModal = () => setIsResultsModalOpen(false);

    return (
        <>
            <Modal
                onClickAway={closeModal}
                className="fips-codes-selector"
                focusFirstChild
            >
                <FipsCodesSelectorHeader closeModal={closeModal} />
                <FipsCodesGeoTypeDropdown
                    onChange={setGeoTypeAndFocusTextarea}
                    selectedGeoType={selectedGeoType}
                    list={geoTypes}
                />
                <hr />
                <FipsCodesTextarea
                    onChange={setFipsInput}
                    value={fipsInput}
                    ref={textareaRef}
                />
                {!selectedGeoType && <FipsCodesHint />}
                <hr />
                <span className="fips-codes-tip">
                    <FormattedMessage id="geographiesPage.fipsSelection.tip" />
                </span>
                <FipsCodesSelectorActions
                    onCancel={closeModal}
                    selectedGeoType={selectedGeoType}
                    fipsInput={fipsInput}
                    setIsResultsModalOpen={setIsResultsModalOpen}
                    surveyCode={surveyCode}
                />
            </Modal>
            {isResultsModalOpen && (
                <FipsCodesSearchResult onCloseModal={onCloseModal} />
            )}
        </>
    );
};

export default FipsCodesSelector;
