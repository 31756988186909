import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import Button from '../../../Buttons/Button';
import ApplicationPaths from '../../../../helpers/ApplicationPaths';
import IconButton from '../../../IconButton/IconButton';

import './noSelectYears.scss';

type Props = {
    onClose: () => void;
};

const NoSelectYears = ({ onClose }: Props) => {
    const intl = useIntl();
    const closeMessage = intl.formatMessage({ id: 'close' });
    return (
        <div className="no-select-years">
            <div className="no-select-years-title">
                <h5>
                    <FormattedMessage id="report.sidebar.modify.surveys.titleNoSelect" />
                </h5>
                <IconButton
                    onClick={onClose}
                    title={closeMessage}
                    aria-label={closeMessage}
                    className="default"
                >
                    close
                </IconButton>
            </div>
            <div className="no-select-years-info">
                <FormattedMessage
                    id="report.sidebar.modify.surveys.infoNoSelect"
                    values={{
                        a: (chunk: string) => (
                            <Link
                                to={ApplicationPaths.root.getPath()}
                                target="_blank"
                            >
                                {chunk}
                            </Link>
                        ),
                    }}
                />
            </div>
            <div className="select-survey-done">
                <Button variant="flat" onClick={onClose}>
                    <FormattedMessage id="ok" />
                </Button>
            </div>
        </div>
    );
};

export default NoSelectYears;
