import React from 'react';
import { useSelector } from 'react-redux';

import { getParentGeoTypes } from '../../../selectors/GeoTypes';
import { SurveyCode } from '../../../typescript/types';

import ParentGeoItemSelector from './ParentSelector/ParentGeoItemSelector';

import './GeoItemParentSelector.scss';

type Props = {
    surveyCode: SurveyCode;
};

const GeoItemParentSelector = ({ surveyCode }: Props) => {
    const parentGeoTypes = useSelector((state) =>
        getParentGeoTypes(state, surveyCode),
    );

    if (!parentGeoTypes) {
        return null;
    }

    return (
        <>
            {parentGeoTypes.length > 1 && (
                <div className="geo-parents-wrapper">
                    {parentGeoTypes.map((parent, index) => {
                        const geoType = parentGeoTypes[index + 1] || {
                            guid: null,
                        };
                        return (
                            <ParentGeoItemSelector
                                key={geoType.guid}
                                geoType={geoType}
                                parentGeoType={parent}
                                surveyCode={surveyCode}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default GeoItemParentSelector;
