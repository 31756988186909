import React from 'react';
import { useIntl } from 'react-intl';

import { DOWNLOAD_PDF } from '../../../constants/Sidebar';

import SidebarIcon from '../SidebarIcon';

import { ReactComponent as DownloadPdfIcon } from '../../../icons/downloadPdf.svg';

type Props = {
    activatedTabId: string;
    focusedTabId: string;
    activateTab: (item: string) => void;
};

const DownloadPdfTab = ({
    activateTab,
    activatedTabId,
    focusedTabId,
}: Props) => {
    const intl = useIntl();

    return (
        <SidebarIcon
            onClick={() => activateTab(DOWNLOAD_PDF)}
            active={activatedTabId === DOWNLOAD_PDF}
            icon={
                <DownloadPdfIcon
                    title={intl.formatMessage({
                        id: 'report.sidebar.hints.pdf',
                    })}
                />
            }
            id={DOWNLOAD_PDF}
            tabIndex={focusedTabId === DOWNLOAD_PDF ? undefined : -1}
        />
    );
};

export default DownloadPdfTab;
