import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { DOWNLOAD_CSV } from '../../../constants/Sidebar';
import { preventCSVDownload } from '../../../selectors/ReportMetadata';

import SidebarIcon from '../SidebarIcon';

import { ReactComponent as DownloadCsvIcon } from '../../../icons/downloadCsv.svg';

const DownloadCsvIconTab = ({ activateTab, activatedTabId, focusedTabId }) => {
    const intl = useIntl();
    const disableCSVDownload = useSelector(preventCSVDownload);
    if (disableCSVDownload) {
        return null;
    }
    return (
        <SidebarIcon
            onClick={() => activateTab(DOWNLOAD_CSV)}
            active={activatedTabId === DOWNLOAD_CSV}
            icon={
                <DownloadCsvIcon
                    title={intl.formatMessage({
                        id: 'report.sidebar.hints.csv',
                    })}
                />
            }
            id={DOWNLOAD_CSV}
            tabIndex={focusedTabId === DOWNLOAD_CSV ? undefined : -1}
        />
    );
};

export default DownloadCsvIconTab;
