import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getSurveyByCode } from '../../selectors/Survey';
import DocumentationAndDictionary from './DocumentationAndDictionary';
import './SourceNode.scss';

type Props = {
    isSingle: boolean;
    abbreviation: string;
    displayName: string;
    publisher: string;
    name: string;
    surveyCode: string;
    notes: string;
};

const SourceNode = ({
    isSingle,
    abbreviation,
    displayName,
    publisher,
    name,
    surveyCode,
    notes,
}: Props) => {
    const intl = useIntl();
    const { name: surveyName } = useSelector((state) =>
        getSurveyByCode(state, surveyCode),
    );
    let datasetTitle;
    if (isSingle) {
        datasetTitle = `${name}(${abbreviation}), ${surveyName}, ${publisher}`;
    } else {
        datasetTitle = intl.formatMessage(
            { id: 'report.sidebar.info.datasetTitle' },
            {
                abbreviation,
                displayName,
                publisher,
            },
        );
    }
    return (
        <div className="source">
            <div className="dataset-title">{datasetTitle}</div>
            {!!notes.length && (
                <>
                    <div className="notes-title">
                        <FormattedMessage id="report.sidebar.info.notes" />
                    </div>
                    <div
                        className="notes-content"
                        dangerouslySetInnerHTML={{
                            __html: notes,
                        }}
                    />
                </>
            )}
            <DocumentationAndDictionary surveyCode={surveyCode} />
        </div>
    );
};

export default SourceNode;
