import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import * as Cpi from '../../../../selectors/Cpi';
import * as Report from '../../../../selectors/Report';

import ValueFormat from '../../../../constants/ValueFormat';
import { formatValue } from '../../../../helpers/ValueFormatter';

import './SingleTableDollarAdjustmentInfo.scss';

type Props = {
    dollarYear: number;
};

const SingleTableDollarAdjustmentInfo = ({ dollarYear }: Props) => {
    const selectedDollarAdjustmentYear = useSelector((state) =>
        Report.getSelectedDollarAdjustment(state),
    );
    const cpi = useSelector((state) =>
        Cpi.getConsumerPriceIndexForTable(
            state,
            dollarYear,
            selectedDollarAdjustmentYear,
        ),
    );

    return (
        <>
            <br />
            <span className="dollar-adjustment-info">
                <FormattedMessage
                    id="report.table.dollarAdjustmentInfo"
                    values={{
                        year: selectedDollarAdjustmentYear,
                        cpi: formatValue(
                            cpi,
                            ValueFormat.FORMAT_NUMBER_3_DECIMAL,
                        ),
                    }}
                />
            </span>
        </>
    );
};

export default SingleTableDollarAdjustmentInfo;
