import * as Report from '../constants/Report';
import * as ReportApp from '../typescript/types';
import { ReportAppActions } from '../typescript/actions/actions';

const INITIAL_STATE: ReportApp.ReportMetadata = {
    isSaved: null,
    isOwnedByCurrentUser: null,
    reportTitle: null,
    definition: undefined,
    reportDescription: null,
    byOldReportId: {},
    geographiesHeader: [],
};

export default (
    state = INITIAL_STATE,
    action: ReportAppActions,
): ReportApp.ReportMetadata => {
    const { definition } = state;

    switch (action.type) {
        case Report.SET_DEFINITION:
            return {
                ...state,
                definition: action.payload,
            };
        case Report.SET_METADATA_BY_OLD_REPORT_ID:
            return {
                ...state,
                byOldReportId: action.payload,
            };
        case Report.SET_TOTAL_NUMBER_OF_GEOGRAPHIES: {
            const { oldReportId, totalNumberOfGeographies } = action.payload;
            return {
                ...state,
                byOldReportId: {
                    ...state.byOldReportId,
                    [oldReportId]: {
                        ...state.byOldReportId[oldReportId],
                        totalNumberOfGeographies,
                    },
                },
            };
        }
        case Report.SET_GEOGRAPHIES_HEADER: {
            return {
                ...state,
                geographiesHeader: action.payload,
            };
        }
        case Report.SET_SAVED_REPORT_INFO:
            return {
                ...state,
                isSaved: action.payload.isSaved,
                isOwnedByCurrentUser: action.payload.isOwnedByCurrentUser,
                reportTitle: action.payload.reportTitle,
                reportDescription: action.payload.reportDescription,
            };
        case Report.SET_FIPS_CODES_FOR_OLD_REPORT_ID:
            const { oldReportId, fipsCodes } = action.payload;
            return {
                ...state,
                byOldReportId: {
                    ...state.byOldReportId,
                    [oldReportId]: {
                        ...state.byOldReportId[oldReportId],
                        fipsCodes,
                    },
                },
            };
        case Report.RESET_FIPS_CODES:
            if (!definition) {
                return { ...state };
            }

            // for each oldReportId set fipsCodes to null
            const byOldReportIdWithResetFipsCodes = Object.keys(
                state.byOldReportId,
            ).reduce(
                (
                    memo: ReportApp.OldReportMetadataByOldReportId,
                    oldReportId: ReportApp.OldReportId,
                ) => {
                    memo[oldReportId] = {
                        ...state.byOldReportId[oldReportId],
                        fipsCodes: null,
                    };
                    return memo;
                },
                {},
            );

            return {
                ...state,
                byOldReportId: byOldReportIdWithResetFipsCodes,
            };
        case Report.RESET_REPORT_METADATA:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
