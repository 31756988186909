import React from 'react';
import { FormattedMessage } from 'react-intl';
import '../MenuList.scss';
import CreateReportFromMap from './CreateReportFromMap';

const CreateReportLinks = () => {
    return (
        <ul className="menu-list">
            <li className="menu-list-item">
                <div className="menu-subtitle">
                    <FormattedMessage id="report.reportActions.createANewReport" />
                </div>
            </li>
            <CreateReportFromMap />
        </ul>
    );
};

export default CreateReportLinks;
