import React, { useState } from 'react';
import classNames from 'classnames';

import Button from '../../Buttons/Button';
import Icon from '../../Icon/Icon';

import './Collapse.scss';

type Props = {
    children: React.ReactNode;
    label: string;
    className?: string;
    expanded?: boolean;
};

const Collapse = ({ children, label, className, expanded = false }: Props) => {
    const [isExpanded, setIsExpanded] = useState(expanded);
    const classes = classNames('collapse', className);

    let icon, body;
    if (isExpanded) {
        icon = 'keyboard_arrow_up';
        body = <div className="body">{children}</div>;
    } else {
        icon = 'keyboard_arrow_down';
    }

    return (
        <div className={classes}>
            <Button
                onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
                className="title"
            >
                <span>{label}</span>
                <Icon>{icon}</Icon>
            </Button>
            {body}
        </div>
    );
};

export default Collapse;
