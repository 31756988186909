import Route from './Route';

const reportsService =
    process.env.REACT_APP_URL_REPORTS_SERVICE_ROOT + '/SeWebService.asmx/';
const reportsServiceRoot = process.env.REACT_APP_URL_REPORTS_SERVICE_ROOT;
const accountsEndpoint = process.env.REACT_APP_URL_BACKEND_ACCOUNTS;
const railsEndpoint = process.env.REACT_APP_URL_BACKEND_RAILS;
const docsEndpoint = process.env.REACT_APP_URL_BACKEND_DOCS;
const publicUrl = process.env.PUBLIC_URL;
const tenant = process.env.REACT_APP_TENANT;

export default {
    whoami: new Route(accountsEndpoint + '/whoami'),
    surveys: new Route(
        railsEndpoint +
            '/explore-tables?tenant=:tenant&preview_datasets_not_included_in_current_subscription=:previewAllDatasets',
    ),
    getDatasets: new Route(
        reportsService +
            'GetDatasets?sSurveyName=":surveyName"&bIncludeTables=":includeTables"',
    ),
    getTables: new Route(
        reportsService +
            'GetTables?sDatasetName=":datasetName"&sSurveyName=":surveyName"&bIncludeVariables=":includeVariables"',
    ),
    getPremadeReports: new Route(
        reportsService + 'GetSystemReports?sSurveyName=":surveyCode"',
    ),
    getGeoTypes: new Route(
        reportsService +
            'GetGeoTypes?sSurveyName=":surveyCode"&bOnlyMajorGeographies=":onlyMajorGeographies"',
    ),
    getGeoSelectionsCount: new Route(
        reportsService +
            'GetReportGeoSelectionsCount?' +
            'sReportId=":oldReportId"&' +
            'aggregationType=:aggregationType',
    ),
    getParentGeoTypes: new Route(
        reportsService +
            'GetParentGeoTypes?sGeoTypeName=":geoTypeName"&sSurveyName=":surveyCode"',
    ),
    getGeoItemsByGeoType: new Route(
        reportsService +
            'GetGeoItemsByGeoType?' +
            'sGeoTypeSelectionName=":geoTypeSelectionName"&' +
            'sGeoSelectionFips=":geoSelectionFips"&' +
            'sSurveyName=":surveyName"',
    ),
    getGeoItemsByFipsCodes: new Route(
        reportsService + 'GetGeoItemsByFipsCodes',
    ),
    createReport: new Route(railsEndpoint + '/report/create'),
    getReportInfo: new Route(
        reportsService + 'GetHtmlReportByReportId?sReportId=":oldReportId"',
    ),
    getTablesByReportId: new Route(
        reportsService +
            'GetTablesByReportId?' +
            'sReportId=":oldReportId"&' +
            'bIncludeVariables=":includeVariables"',
    ),
    getReportMetadata: new Route(
        railsEndpoint + '/report/:reportId/metadata?tenant=' + tenant,
    ),
    getReportMetadataV2: new Route(
        railsEndpoint + '/report/:reportId/metadata-v2?tenant=' + tenant,
    ),
    getConsumerPriceIndices: new Route(
        reportsService + 'GetConsumerPriceIndices',
    ),
    getFipsCodesOfReport: new Route(
        reportsService +
            'GetFipsCodesOfReport2?sReportId=":oldReportId"&aggregationType=:aggregationType',
    ),
    allowDollarAdjustment: new Route(
        reportsService + 'AllowDollarAdjustment?sReportId=":oldReportId"',
    ),
    saveReport: new Route(railsEndpoint + '/report/save_report'),
    deleteReport: new Route(railsEndpoint + '/report/delete_report'),
    updateReport: new Route(railsEndpoint + '/report/update_report'),
    duplicateReport: new Route(railsEndpoint + '/report/duplicate_report'),
    licensesUrl: new Route(railsEndpoint + '/licenses'),
    helpUrl: new Route(railsEndpoint + '/help'),
    railsSessionUrl: new Route(railsEndpoint + '/session'),
    accountsSessionUrl: new Route(accountsEndpoint + '/session'),
    reportUrl: new Route(publicUrl + '/report/:reportId'),
    documentationUrl: new Route(
        docsEndpoint + '/data/:surveyCode/documentation/',
    ),
    dataDictionaryUrl: new Route(docsEndpoint + '/data/:surveyCode'),
    createDownloadExcelUrlRequestUrl: new Route(
        reportsService +
            'GetExcelFileDownloadLink?' +
            'sSurveyName=":surveyCode"&' +
            'sReportId=":reportId"&' +
            'sReportType=":reportType"&' +
            'percents=":percents"&' +
            'aggregatesOnly=":aggregatesOnly"&' +
            'outputChange=":outputChange"&' +
            'wrapVarLabels=":wrapVarLabels"&' +
            'freezeGeoRowAndVarCol=":freezeGeoRowAndVarCol"&' +
            'varHighlightPercentAndAgg=":varHighlightPercentAndAgg"&' +
            'marginsToHalfIn=":marginsToHalfIn"&' +
            'printZoomTo75=":printZoomTo75"&' +
            'varLablesGeoNamesOnEachPage=":varLablesGeoNamesOnEachPage"',
    ),
    csvDownload: new Route(
        reportsServiceRoot + '/WsDownloadCsvFile.aspx?ReportId=:reportId',
    ),
    getGeoTypeSelections: new Route(
        reportsService + 'GetGeoTypeSelections?sReportId=":oldReportId"',
    ),
    getDataSlicedByGeoItemsRange: new Route(
        reportsService +
            'GetDataForReport2' +
            '?sReportId=":oldReportId"' +
            '&iGeoItemStartIndex=:geoItemStartIndex' +
            '&iGeoItemSlice=:geoItemSlice' +
            '&aggregationType=:aggregationType' +
            '&nAdjustDollarValuesToYear=:adjustDollarValuesToYear' +
            '&sTableGuids=":tableGuids"',
    ),
    getTablesByGuids: new Route(reportsService + 'GetTablesByGuids'),
    template: new Route(railsEndpoint + '/report-tables-template'),
    getTableTemplateById: new Route(
        railsEndpoint + '/report-tables-template/:id',
    ),
    updateTableTemplateById: new Route(
        railsEndpoint + '/report-tables-template/update/:id',
    ),
    metadataLink: new Route(
        docsEndpoint +
            '/data/:surveyCode/metadata?' +
            'ds=:datasetAbbreviation' +
            '&table=:name',
    ),
    counterEvent: new Route(railsEndpoint + '/counter/event'),

    dashboardUrl: new Route(railsEndpoint + '/selfstorage/dashboard'),
    myTemplates: new Route(railsEndpoint + '/selfstorage/dashboard'),
    exploreMaps: new Route(railsEndpoint + '/selfstorage/dashboard'),
    paidPlan: new Route(
        'mailto:support@tractiq.com?subject=Trial - Subscription inquiry',
    ),
    loginUrl: new Route(railsEndpoint + '/selfstorage/login'),
    signUpUrl: new Route(railsEndpoint + '/selfstorage/contact'),
};
