import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    ChangeEvent,
} from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { tokenize } from '../../../helpers/Search';
import { debounce } from '../../../helpers/Debounce';
import { COUNTER_LOG_REQUEST } from '../../../constants/Counter';
import * as counterEvents from '../../../helpers/CounterEvents';
import { ReportAppDispatch } from '../../../typescript/actions/actions';

import Icon from '../../Icon/Icon';
import FilterableByTextListItems, {
    BaseProps as ListItemProps,
} from './FilterableByTextListItems';

import './FilterableByTextList.scss';

export interface Props<T> extends ListItemProps<T> {
    placeholder: string;
    showSearchIcon?: boolean;
    filterableByTextListClassNames?: string;
    counterEventValue?: Function;
}

function FilterableByTextList<T>({
    placeholder,
    showSearchIcon = true,
    filterableByTextListClassNames,
    counterEventValue,
    ...rest
}: Props<T>) {
    const dispatch = useDispatch<ReportAppDispatch>();
    const [searchText, changeSearchText] = useState('');
    const input = useRef<HTMLInputElement | null>(null);
    const logCounterEvent = useCallback(
        debounce((value) => {
            dispatch({
                type: COUNTER_LOG_REQUEST,
                payload: counterEvents.getSearchEvents(value),
            });
        }, 1000),
        [dispatch],
    );

    const updateInputAndLogCounterEvent = (event: ChangeEvent) => {
        const target = event.target as HTMLInputElement;
        if (counterEventValue && target.value.trim().length) {
            logCounterEvent(counterEventValue(target.value));
        }
        changeSearchText(target.value);
    };

    const lowerCaseTokens = tokenize(searchText);

    useEffect(() => {
        if (!input.current) {
            return;
        }
        input.current.focus();
    }, []);

    const className = classNames(
        'zoomable-content filterable-by-text',
        filterableByTextListClassNames,
    );

    return (
        <div className={className}>
            <div className="input-text-section">
                {showSearchIcon && <Icon className="search-icon">search</Icon>}
                <input
                    type="text"
                    data-hj-allow={true}
                    value={searchText}
                    onChange={updateInputAndLogCounterEvent}
                    placeholder={placeholder}
                    ref={input}
                />
            </div>
            <hr />
            <FilterableByTextListItems
                lowerCaseTokens={lowerCaseTokens}
                {...rest}
            />
        </div>
    );
}

export default FilterableByTextList;
