import { all, call, put, takeLeading } from 'redux-saga/effects';
import api from '../helpers/Api';
import BackendPaths from '../helpers/BackendPaths';
import * as Templates from '../constants/Templates';
import {
    SaveTemplate,
    FetchTableTemplate,
} from '../typescript/actions/TemplateActions';
import * as HelperSaga from './Helper';
import { Template } from '../typescript/types';
import { ReportAppActions } from '../typescript/actions/actions';

function* getUserTemplates() {
    let response: Template[];
    try {
        response = yield call(api, BackendPaths.template.getPath());
    } catch (error) {
        return yield HelperSaga.handleError(
            Templates.FETCH_TEMPLATES_FAILURE,
            error,
        );
    }
    yield put<ReportAppActions>({
        type: Templates.ADD_TABLE_TEMPLATES_TO_STORE,
        payload: response,
    });
    yield put<ReportAppActions>({
        type: Templates.FETCH_TEMPLATES_SUCCESS,
    });
}

function* saveTemplate({ payload }: SaveTemplate) {
    let response: Template;
    try {
        response = yield call(
            api,
            BackendPaths.template.getPath(),
            'POST',
            payload,
        );
    } catch (error) {
        return yield HelperSaga.handleError(
            Templates.SAVE_TEMPLATE_FAILURE,
            error,
        );
    }
    yield put<ReportAppActions>({
        type: Templates.SET_ACTIVE_TEMPLATE_ID,
        payload: response.id,
    });
    yield put<ReportAppActions>({
        type: Templates.ADD_TABLE_TEMPLATE_TO_STORE,
        payload: response,
    });
    yield put<ReportAppActions>({
        type: Templates.SAVE_TEMPLATE_SUCCESS,
    });
}

function* editTemplate({ payload }: SaveTemplate) {
    let response: Template;
    const { templateId } = payload;
    if (!templateId) {
        return;
    }
    try {
        response = yield call(
            api,
            BackendPaths.updateTableTemplateById.getPath({
                id: templateId,
            }),
            'POST',
            payload,
        );
    } catch (error) {
        return yield HelperSaga.handleError(
            Templates.EDIT_TEMPLATE_FAILURE,
            error,
        );
    }
    yield put<ReportAppActions>({
        type: Templates.SET_ACTIVE_TEMPLATE_ID,
        payload: response.id,
    });
    yield put<ReportAppActions>({
        type: Templates.ADD_TABLE_TEMPLATE_TO_STORE,
        payload: response,
    });
    yield put<ReportAppActions>({
        type: Templates.EDIT_TEMPLATE_SUCCESS,
    });
}

function* fetchTableTemplate({ payload: id }: FetchTableTemplate) {
    let response: Template;
    try {
        response = yield call(
            api,
            BackendPaths.getTableTemplateById.getPath({ id }),
        );
    } catch (error) {
        return yield HelperSaga.handleError(
            Templates.FETCH_TABLE_TEMPLATE_FAILURE,
            error,
        );
    }
    yield put<ReportAppActions>({
        type: Templates.ADD_TABLE_TEMPLATE_TO_STORE,
        payload: response,
    });
    yield put<ReportAppActions>({
        type: Templates.FETCH_TABLE_TEMPLATE_SUCCESS,
    });
}

export default function* () {
    yield all([
        yield takeLeading(Templates.FETCH_TEMPLATES_REQUEST, getUserTemplates),
        yield takeLeading(Templates.SAVE_TEMPLATE_REQUEST, saveTemplate),
        yield takeLeading(Templates.EDIT_TEMPLATE_REQUEST, editTemplate),
        yield takeLeading(
            Templates.FETCH_TABLE_TEMPLATE_REQUEST,
            fetchTableTemplate,
        ),
    ]);
}
