import React from 'react';

import * as SidebarConstants from '../../constants/Sidebar';

import SidebarPanel from './SidebarPanel';
import SidebarTabsContainer from './SidebarTabsContainer';

import './ReportSidebar.scss';

type Props = {
    currentSidebarPanel: SidebarConstants.SidebarOptions;
    setCurrentSidebarPanel: (panel: SidebarConstants.SidebarOptions) => void;
};

const ReportSidebar = ({
    currentSidebarPanel,
    setCurrentSidebarPanel,
}: Props) => {
    const showSidebarPanel = currentSidebarPanel !== SidebarConstants.EMPTY;
    return (
        <aside id="sidebar-container">
            <SidebarTabsContainer
                activateTab={setCurrentSidebarPanel}
                activatedTabId={currentSidebarPanel}
            />
            {showSidebarPanel && (
                <SidebarPanel
                    onClose={() =>
                        setCurrentSidebarPanel(SidebarConstants.EMPTY)
                    }
                    currentSidebarPanel={currentSidebarPanel}
                    activateTab={setCurrentSidebarPanel}
                />
            )}
        </aside>
    );
};

export default ReportSidebar;
