import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Table from '../../../selectors/Table';
import { SET_INTERMITTENT_TABLES_SELECTION } from '../../../constants/Table';

import TablesSelectorHeader from './TablesSelectorHeader';
import DatasetSelector from './DatasetSelector';
import TablesSelectorActions from './TablesSelectorActions';
import TablesFilterableByTextList from './TablesFilterableByTextList/TablesFilterableByTextList';

/** @param {{closeModal: () => void, surveyCodes: string[] }} param0 */
const TablesSelector = ({ closeModal, surveyCodes }) => {
    const dispatch = useDispatch();

    /** @type {import('../../../typescript/types').TableForReportBySurveyCode[]} */
    const intermittentTables = useSelector(Table.getIntermittentTables);
    const [selectedDatasetIndex, setSelectedDatasetIndex] = useState(0);
    const tables = useSelector((state) =>
        Table.getTablesForDataset(state, selectedDatasetIndex, surveyCodes),
    );
    const [selectedTables, setSelectedTables] = useState(intermittentTables);

    const isTableSelected = useCallback(
        /** @param {import('../../../typescript/types').TableForReportBySurveyCode} table*/
        (table) =>
            !!selectedTables.find(
                ({ composeGuid }) => table.composeGuid === composeGuid,
            ),
        [selectedTables],
    );

    const addTableAndCloseModal = () => {
        dispatch({
            type: SET_INTERMITTENT_TABLES_SELECTION,
            payload: selectedTables,
        });
        closeModal();
    };

    /**
     * @param {import('../../../typescript/types').TableForReportBySurveyCode} actionItem
     * @param {import('../../../typescript/types').TableForReportBySurveyCode[]} affectedItems
     */
    const modifyTablesSelection = (actionItem, affectedItems) => {
        let newSelection;
        if (!isTableSelected(actionItem)) {
            newSelection = [...selectedTables];
            affectedItems.forEach((table) => {
                if (!isTableSelected(table)) {
                    newSelection.push(table);
                }
            });
        } else {
            newSelection = selectedTables.filter(
                (selectedTable) =>
                    !affectedItems.some(
                        ({ composeGuid }) =>
                            composeGuid === selectedTable.composeGuid,
                    ),
            );
        }

        setSelectedTables(newSelection);
    };

    return (
        <>
            <TablesSelectorHeader
                onClose={closeModal}
                numberOfSelectedItems={selectedTables.length}
            />
            <DatasetSelector
                selectedDatasetIndex={selectedDatasetIndex}
                setSelectedDatasetIndex={setSelectedDatasetIndex}
                surveyCodes={surveyCodes}
            />
            <hr />
            <TablesFilterableByTextList
                tables={tables}
                isSelected={isTableSelected}
                onChange={modifyTablesSelection}
            />
            <hr />
            <TablesSelectorActions
                onAddTables={addTableAndCloseModal}
                onClose={closeModal}
            />
        </>
    );
};

export default TablesSelector;
