import { TOGGLE_DOWNLOAD_EXCEL_OPTION } from '../constants/Report';
import { ReportAppActions } from '../typescript/actions/actions';
import { ExcelDownloadOptions } from '../typescript/types';

const INIT_STATE: ExcelDownloadOptions = {
    percents: true,
    aggregatesOnly: false,
    wrapVarLabels: true,
    freezeGeoRowAndVarCol: true,
    varHighlightPercentAndAgg: false,
    marginsToHalfIn: true,
    printZoomTo75: true,
    varLablesGeoNamesOnEachPage: true,
};

export default (
    state: ExcelDownloadOptions = INIT_STATE,
    action: ReportAppActions,
) => {
    switch (action.type) {
        case TOGGLE_DOWNLOAD_EXCEL_OPTION: {
            const option = action.payload;
            const currentValue = state[option];
            return {
                ...state,
                [option]: !currentValue,
            };
        }
        default:
            return state;
    }
};
