import { SET_NORMALIZED_DATASETS } from '../constants/Table';

export default (state = {}, action) => {
    switch (action.type) {
        case SET_NORMALIZED_DATASETS:
            const { surveyCode, datasets } = action.payload;
            return {
                ...state,
                [surveyCode]: datasets,
            };
        default:
            return state;
    }
};
