import { SET_NORMALIZED_SURVEY_GROUPS } from '../constants/Survey';
import { ReportAppActions } from '../typescript/actions/actions';
import { SurveyGroupById } from '../typescript/types';

const INITIAL_STATE: SurveyGroupById = {};

export default (
    state = INITIAL_STATE,
    action: ReportAppActions,
): SurveyGroupById => {
    switch (action.type) {
        case SET_NORMALIZED_SURVEY_GROUPS: {
            const surveyGroupsById: SurveyGroupById = {};
            action.payload.reduce((memo, surveyGroup) => {
                memo[surveyGroup.id] = surveyGroup;
                return memo;
            }, surveyGroupsById);
            return {
                ...surveyGroupsById,
            };
        }
        default:
            return state;
    }
};
