import React, { useRef, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';

import IconButton from '../../../IconButton/IconButton';
import Popover from '../../../base/Popover/Popover';
import NavigatorPopoverContent from './NavigatorPopoverContent';

const NavigatorPopover = () => {
    const intl = useIntl();

    const root = useRef<HTMLDivElement>(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const openPopover = useCallback(() => setIsPopoverOpen(true), []);
    const closePopover = useCallback(() => setIsPopoverOpen(false), []);

    const buttonLabel = intl.formatMessage({
        id: 'report.reportActions.moreOptions',
    });

    return (
        <div ref={root} className="dropdown-menu">
            <IconButton
                onClick={openPopover}
                title={buttonLabel}
                aria-label={buttonLabel}
                aria-haspopup={true}
                aria-expanded={isPopoverOpen}
                className="navigator-popover-trigger"
            >
                more_horiz
            </IconButton>
            {isPopoverOpen && (
                <Popover
                    parent={root.current}
                    onClickAway={closePopover}
                    className="dropdown-menu-popover bottom-up-direction navigator-popover"
                >
                    <NavigatorPopoverContent onClosePopover={closePopover} />
                </Popover>
            )}
        </div>
    );
};

export default NavigatorPopover;
