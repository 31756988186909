import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import * as GeoTypes from '../../selectors/GeoTypes';
import { MODIFY_FIPS_SELECTION } from '../../constants/Geographies';

import Button from '../Buttons/Button';

const AddToSelection = (props) => {
    const dispatch = useDispatch();
    const intermittedFips = useSelector(GeoTypes.getIntermittentFipsSelection);
    const selectedGeographies = useSelector(GeoTypes.getSelectedGeographies);

    const fipsCombined = Array.from(
        new Set(intermittedFips.concat(selectedGeographies)),
    );

    return (
        <Button
            variant="contained small"
            disabled={intermittedFips.length === 0}
            onClick={() => {
                dispatch({
                    type: MODIFY_FIPS_SELECTION,
                    payload: {
                        fipsArray: fipsCombined,
                    },
                });
            }}
        >
            <FormattedMessage id="add" />
        </Button>
    );
};

export default AddToSelection;
