import React from 'react';
import { useIntl } from 'react-intl';

import Button from '../../Buttons/Button';
import Icon from '../../Icon/Icon';

import './ParameterPageNavigator.scss';

type Props = {
    itemPage: number;
    setItemPage: (page: number) => void;
    numberOfPages: number;
};

const ParameterPageNavigator = ({
    itemPage,
    setItemPage,
    numberOfPages,
}: Props) => {
    const intl = useIntl();
    const incrementPage = () => setItemPage(itemPage + 1);
    const decrementPage = () => setItemPage(itemPage - 1);
    const goToFirstPage = () => setItemPage(1);
    const goToLastPage = () => setItemPage(numberOfPages);

    const disableIncrement = itemPage === numberOfPages;
    const disableDecrement = itemPage === 1;
    const buttonLabel = {
        first: intl.formatMessage({ id: 'pagination.first' }),
        previous: intl.formatMessage({ id: 'pagination.previous' }),
        next: intl.formatMessage({ id: 'pagination.next' }),
        last: intl.formatMessage({ id: 'pagination.last' }),
    };
    return (
        <div className="parameter-page-navigator">
            <Button
                variant="icon"
                onClick={goToFirstPage}
                disabled={disableDecrement}
                title={buttonLabel.first}
                aria-label={buttonLabel.first}
            >
                <Icon>first_page</Icon>
            </Button>
            <Button
                variant="icon"
                onClick={decrementPage}
                disabled={disableDecrement}
                title={buttonLabel.previous}
                aria-label={buttonLabel.previous}
            >
                <Icon>keyboard_arrow_left</Icon>
            </Button>
            <Button
                variant="icon"
                onClick={incrementPage}
                disabled={disableIncrement}
                title={buttonLabel.next}
                aria-label={buttonLabel.next}
            >
                <Icon>keyboard_arrow_right</Icon>
            </Button>
            <Button
                variant="icon"
                onClick={goToLastPage}
                disabled={disableIncrement}
                title={buttonLabel.last}
                aria-label={buttonLabel.last}
            >
                <Icon>last_page</Icon>
            </Button>
        </div>
    );
};

export default ParameterPageNavigator;
