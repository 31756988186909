import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as Template from '../../../selectors/Templates';

import ExistingTemplate from './Templates/ExistingTemplate';
import SaveTemplate from './Templates/SaveTemplate';
import * as TemplateConstants from '../../../constants/Templates';
import Loader from '../../Loader/Loader';

import { ReportAppDispatch } from '../../../typescript/actions/actions';

type Props = {
    onClose: () => void;
};

const TableTemplateModalContent = ({ onClose }: Props) => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const templateModalDisplayStatus = useSelector(
        Template.getTemplateModalDisplayStatus,
    );
    const shouldFetchTemplates = useSelector(Template.getShouldFetchTemplates);
    const loadingTablesError = useSelector(Template.getFetchTablesError);
    const saveTablesError = useSelector(Template.getSaveTemplatesError);

    useEffect(() => {
        if (shouldFetchTemplates) {
            dispatch({
                type: TemplateConstants.FETCH_TEMPLATES_REQUEST,
            });
        }
    }, [dispatch, shouldFetchTemplates]);

    let content;
    switch (templateModalDisplayStatus) {
        case TemplateConstants.FetchTableTemplates.FETCH_TEMPLATES_LOADING:
        case TemplateConstants.SaveTableTemplate.SAVE_TEMPLATE_LOADING:
            content = <Loader />;
            break;
        case TemplateConstants.FetchTableTemplates.FETCH_TEMPLATES_ERROR:
            content = <div>{loadingTablesError}</div>;
            break;
        case TemplateConstants.SaveTableTemplate.SAVE_TEMPLATE_ERROR:
            content = <div>{saveTablesError}</div>;
            break;
        case TemplateConstants.SaveTableTemplate.SAVE_TEMPLATE:
            content = <SaveTemplate onClose={onClose} />;
            break;
        case TemplateConstants.SaveTableTemplate.TEMPLATE_EXISTS:
            content = <ExistingTemplate onClose={onClose} />;
            break;
        default:
            content = null;
    }
    return content;
};

export default TableTemplateModalContent;
