import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
    getGeoItemsByFipsResults,
    shouldRenderFipsSearchResults,
} from '../../../../selectors/GeoTypes';

import Modal from '../../../base/Modal/Modal';
import Loader from '../../../Loader/Loader';
import NotFoundList from './NotFoundList';
import FoundList from './FoundList';

import './FipsCodesSearchResult.scss';

const FipsCodesSearchResult = ({ onCloseModal }) => {
    const shouldRenderResults = useSelector(shouldRenderFipsSearchResults);
    const [geoSelection] = useSelector(getGeoItemsByFipsResults);
    const [showNotFoundList, setShowNotFoundList] = useState(false);
    const onOpenNotFoundList = () => setShowNotFoundList(true);
    const onCloseNotFoundList = () => setShowNotFoundList(false);
    let content;
    if (!shouldRenderResults) {
        content = <Loader />;
    } else if (
        (showNotFoundList && shouldRenderResults) ||
        geoSelection.length === 0
    ) {
        content = (
            <NotFoundList
                onCloseModal={onCloseModal}
                onCloseNotFoundList={onCloseNotFoundList}
            />
        );
    } else if (!showNotFoundList && shouldRenderResults) {
        content = (
            <FoundList
                onCloseModal={onCloseModal}
                onOpenNotFoundList={onOpenNotFoundList}
            />
        );
    }
    return (
        <Modal onClickAway={onCloseModal} className="results-wrapper">
            {content}
        </Modal>
    );
};

export default FipsCodesSearchResult;
