/** @param {import('react-redux').DefaultRootState} state */
export const getSurveyCode = (state) => state.selection.surveyCode;

/** @param {import('react-redux').DefaultRootState} state */
export const getGeoFips = (state) => state.selection.geoFips;

/** @param {import('react-redux').DefaultRootState} state */
export const hasSurveyCodeSelected = (state) => !!getSurveyCode(state);

/** @param {import('react-redux').DefaultRootState} state */
export const hasGeographiesSelected = (state) => {
    const geoFips = getGeoFips(state);
    return geoFips.length > 0;
};

/** @param {import('react-redux').DefaultRootState} state */
export const hasTablesSelected = (state) => getTableGuids(state).length > 0;

/** @param {import('react-redux').DefaultRootState} state */
export const getTableGuids = (state) => state.selection.tables;

/** @param {import('react-redux').DefaultRootState} state */
export const getTables = (state) => state.selection.tables;

/** @param {import('react-redux').DefaultRootState} state */
export const getReportId = (state) => state.selection.reportId;

/** @param {import('react-redux').DefaultRootState} state */
export const getNewReportId = (state) => state.selection.newReportId;

/**
 * @param {import('react-redux').DefaultRootState} state
 * @param {string} newReportId
 */
export const getShouldUpdateSelectedReportId = (state, newReportId) =>
    state.selection.reportId !== newReportId;
