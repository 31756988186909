import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import * as Report from '../../selectors/Report';
import * as ReportActions from '../../actionCreators/reportActions';
import { getShouldUpdateSelectedReportId } from '../../selectors/Selection';
import { getActivatedSidebarPanel } from '../../selectors/Sidebar';
import { getHasPro } from '../../selectors/CurrentUser';
import { RouteParams } from '../../typescript/types';
import { ReportAppDispatch } from '../../typescript/actions/actions';
import * as ReportConstants from '../../constants/Report';
import * as ReportScreen from '../../constants/ReportScreen';
import { EMPTY } from '../../constants/Sidebar';

import ReportNavigator from './ReportNavigator/ReportNavigator';
import Loader from '../Loader/Loader';
import ReportHeader from '../ReportHeader/ReportHeader';
import TableRenderer from './TableRenderer/TableRenderer';
import ReportSidebarAndNavigation from './ReportSidebarAndNavigation';

import './ExistingReport.scss';

function ExistingReport() {
    const dispatch = useDispatch<ReportAppDispatch>();
    const currentSidebarPanel = useSelector(getActivatedSidebarPanel);
    const hasPro = useSelector(getHasPro);
    const { reportId } = useParams<RouteParams.ExistingReport>();
    const shouldUpdateSelectedReportId = useSelector((state) =>
        getShouldUpdateSelectedReportId(state, reportId),
    );
    const shouldFetchReport = useSelector((state) =>
        Report.getShouldFetchReport(state, reportId),
    );
    const existingReportScreen = useSelector((state) =>
        Report.getExistingReportScreen(state, reportId),
    );
    const errorMessage = useSelector(Report.getRetrieveReportError);
    useEffect(() => {
        if (shouldUpdateSelectedReportId) {
            dispatch(ReportActions.getSetReportIdRequestAction(reportId));
        }
    }, [dispatch, shouldUpdateSelectedReportId, reportId]);

    useEffect(() => {
        if (shouldFetchReport) {
            dispatch({
                type: ReportConstants.FETCH_REPORT_REQUEST,
                payload: { reportId },
            });
        }
    }, [dispatch, reportId, shouldFetchReport]);

    useEffect(() => {
        dispatch({ type: ReportConstants.CLEAR_MODIFY_STATE });
    }, [dispatch]);

    const shrinkedBySidebarWidth = classnames({
        'shrinked-by-sidebar-width': currentSidebarPanel !== EMPTY,
    });

    let component;
    switch (existingReportScreen) {
        case ReportScreen.REPORT_LOADING:
            component = <Loader />;
            break;
        case ReportScreen.REPORT_ERROR:
            component = <div>{errorMessage}</div>;
            break;
        default:
            component = <TableRenderer />;
            break;
    }

    return (
        <div id="report-view">
            <ReportHeader />
            <div id="report-page-content">
                <ReportSidebarAndNavigation />
                {component}
                {hasPro && (
                    <ReportNavigator className={shrinkedBySidebarWidth} />
                )}
            </div>
        </div>
    );
}

export default ExistingReport;
