import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import * as ReportMetadata from '../../../../selectors/ReportMetadata';
import * as Report from '../../../../selectors/Report';

import ModifyOptionLink from './ModifyOptionLink';

const ModifyGeographies = () => {
    const intl = useIntl();
    const links = useSelector(ReportMetadata.getModifyGeographies);
    const geos = useSelector(
        ReportMetadata.getGeographyNamesForModifyGeographyButton,
    );
    const isRetrievingReport = useSelector(Report.getIsRetrievingReport);

    const geoTitles = isRetrievingReport
        ? intl.formatMessage({
              id: 'report.sidebar.modify.loadingGeographies',
          })
        : geos.map((geo) => geo.geoName).join('; ');

    return (
        <>
            {links.map(({ label, link }) => (
                <ModifyOptionLink
                    key={label}
                    link={link}
                    hint={intl.formatMessage({
                        id: 'report.sidebar.modify.hint.geography',
                    })}
                    highlightIcon="place"
                    title={intl.formatMessage({
                        id: 'report.sidebar.modify.titles.geography',
                    })}
                    description={geoTitles}
                />
            ))}
        </>
    );
};

export default ModifyGeographies;
