import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import * as Report from '../../../selectors/Report';
import * as ReportMetadata from '../../../selectors/ReportMetadata';
import { openDownloadCsvUrlInNewTab } from '../../../helpers/OpenUrlOutsideOfApp';
import { getDownloadProgramsEventValue } from '../../../selectors/Counter';
import { COUNTER_LOG_REQUEST } from '../../../constants/Counter';
import * as counterHelper from '../../../helpers/CounterEvents';
import { GeoTypeSelection } from '../../../typescript/types';
import { ReportAppDispatch } from '../../../typescript/actions/actions';
import { getDownloadCsvButtonGtmAttributes } from '../../../constants/GtmAttributes';

import DownloadPrograms from './DownloadPrograms';
import DownloadCard from './DownloadCard/DownloadCard';
import GeoTypeSelectionDescription from './GeoTypeSelectionDescription';

import './ReportCsvAndProgramsDownload.scss';

type Props = {
    columnGroup: {
        name: string | undefined;
        tables: {
            reportId: string;
            geoTypeSelections: GeoTypeSelection[];
        }[];
    };
};
const ReportCsvAndProgramsDownload = ({ columnGroup }: Props) => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const downloadOptions = useSelector(Report.getCsvDownloadOptions);
    const hasSingleOldReport = useSelector(ReportMetadata.hasSingleOldReport);
    const getCounterEventValue = useSelector(getDownloadProgramsEventValue);

    const downloadByProgramMessage = hasSingleOldReport ? (
        <h5>
            <FormattedMessage id="report.sidebar.downloadCsv.downloadByProgram" />
        </h5>
    ) : undefined;

    const format = downloadOptions.tabSeparated ? 'TAB' : 'CSV';

    const columnGroupElements = columnGroup.tables.map(
        ({ reportId, geoTypeSelections }) => {
            return (
                <div
                    className="report-csv-and-programs-download"
                    key={reportId}
                >
                    {geoTypeSelections.map((selection, index) => {
                        const gtmAttributes = getDownloadCsvButtonGtmAttributes(
                            selection.label,
                        );
                        return (
                            <div key={`${reportId}${selection.sumLev}`}>
                                <DownloadCard
                                    key={selection.sumLev}
                                    className="summary-level-download-card"
                                    title={`${selection.label} (${format})`}
                                    actions={[
                                        {
                                            onClick: () => {
                                                const counterEventValue = getCounterEventValue(
                                                    selection.label,
                                                );
                                                dispatch({
                                                    type: COUNTER_LOG_REQUEST,
                                                    payload: counterHelper.getDownloadProgramEvents(
                                                        counterEventValue,
                                                    ),
                                                });
                                                openDownloadCsvUrlInNewTab({
                                                    reportId,
                                                    ...downloadOptions,
                                                    geoTypeIndex: index,
                                                });
                                            },
                                            gtmAttributes,
                                        },
                                    ]}
                                    supportingText={
                                        <GeoTypeSelectionDescription
                                            selection={selection}
                                        />
                                    }
                                />
                            </div>
                        );
                    })}
                    {downloadByProgramMessage}
                    <DownloadPrograms reportId={reportId} />
                </div>
            );
        },
    );

    return <>{columnGroupElements}</>;
};

export default ReportCsvAndProgramsDownload;
