import React from 'react';
import classNames from 'classnames';

import { ButtonVariant } from '../../typescript/types';

import Link, { Props as LinkProps } from '../base/Link/Link';

type Props = {
    variant: ButtonVariant;
} & LinkProps;

/** A link which looks like a button */
const LinkButton = ({ className, variant, ...rest }: Props) => {
    const linkClasses = classNames(
        'button',
        { [`button-${variant}`]: variant },
        className,
    );

    return <Link className={linkClasses} {...rest} />;
};

export default LinkButton;
