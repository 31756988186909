import * as CurrentUser from '../constants/CurrentUser';
import { ReportAppActions } from '../typescript/actions/actions';
import * as ReportApp from '../typescript/types';

export default (
    state: ReportApp.CurrentUser = {
        loading: false,
        user: null,
        errorMessage: null,
        loaded: false,
        hasPro: false,
        licenceTitle: 'Basic plan',
        isTrialing: false,
    },
    action: ReportAppActions,
): ReportApp.CurrentUser => {
    switch (action.type) {
        case CurrentUser.FETCH_ME_REQUEST:
            return {
                ...state,
                loading: true,
                errorMessage: null,
            };
        case CurrentUser.FETCH_ME_SUCCESS: {
            return {
                ...state,
                user: action.payload,
                loading: false,
                errorMessage: null,
                loaded: true,
            };
        }
        case CurrentUser.FETCH_ME_FAILURE: {
            const errorMessage = action.payload;
            return {
                ...state,
                loading: false,
                errorMessage,
            };
        }
        case CurrentUser.SIGN_OUT_REQUEST:
            return {
                ...state,
                loading: true,
                errorMessage: null,
            };
        case CurrentUser.SIGN_OUT_FAILURE:
            return {
                ...state,
                errorMessage: action.payload,
                loading: false,
            };
        case CurrentUser.SET_SESSION_INFO:
            return {
                ...state,
                licenceTitle: action.payload.licenceTitle,
                hasPro: action.payload.hasPro,
                isTrialing: action.payload.isTrialing,
            };
        default:
            return state;
    }
};
