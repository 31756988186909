import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { ReportActionModals } from '../../../constants/ReportActionsEnum';
import { shouldShowAdditionalActions } from '../../../selectors/ReportHeader';

import Button from '../../Buttons/Button';

import './MenuList.scss';

type Props = {
    openModal: (modal: ReportActionModals) => void;
};

const MoreOptionsReportActions = ({ openModal }: Props) => {
    const canUserSaveReport = useSelector(shouldShowAdditionalActions);

    if (!canUserSaveReport) {
        return null;
    }

    return (
        <>
            <ul className="menu-list">
                <li className="menu-list-item">
                    <Button
                        variant="flat"
                        className="menu-list-item-button"
                        onClick={() =>
                            openModal(ReportActionModals.COPY_REPORT_MODAL)
                        }
                    >
                        <FormattedMessage id="makeACopy" />
                    </Button>
                </li>
                <li className="menu-list-item">
                    <Button
                        variant="flat"
                        className="menu-list-item-button"
                        onClick={() =>
                            openModal(ReportActionModals.RENAME_REPORT_MODAL)
                        }
                    >
                        <FormattedMessage id="rename" />
                    </Button>
                </li>
                <li className="menu-list-item">
                    <Button
                        variant="flat"
                        className="menu-list-item-button"
                        onClick={() =>
                            openModal(ReportActionModals.DELETE_REPORT_MODAL)
                        }
                    >
                        <FormattedMessage id="delete" />
                    </Button>
                </li>
            </ul>
            <hr />
        </>
    );
};

export default MoreOptionsReportActions;
