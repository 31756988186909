import React from 'react';

import * as ReportApp from '../../../../typescript/types';

import GeoInfoTableForYear from './GeoInfoTableForYear';
import WarningMessage from '../../../WarningMessage/WarningMessage';

type Props = {
    year: number;
    analysis: ReportApp.GeoAnalysisForYear | null;
};

const GeoInfoForYear = ({ year, analysis }: Props) => {
    if (analysis == null) {
        // This year has been removed from report. We don't want to show any
        // info about this year
        return null;
    }

    return (
        <>
            {!analysis.hasFoundAny && (
                <WarningMessage messageId="surveysPage.changeSurvey.zeroGeosFound" />
            )}
            <GeoInfoTableForYear results={analysis.elements} year={year} />
        </>
    );
};

export default GeoInfoForYear;
