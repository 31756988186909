import React, { useState, useCallback, useRef } from 'react';

import { useSelector } from 'react-redux';

import Popover from '../../../base/Popover/Popover';
import NoSelectYears from './NoSelectYears';
import SurveyTitle from '../ModifySurvey/SurveyTitle';
import IconButton from '../../../IconButton/IconButton';
import { getSurveyOrGroupName } from '../../../../selectors/ReportMetadata';

import './NoSurveyModification.scss';

const NoSurveyModification = () => {
    const [isOpen, setIsOpen] = useState(false);
    const popoverMenuContainer = useRef<HTMLDivElement>(null);

    const showPopover = useCallback(() => setIsOpen(true), [setIsOpen]);
    const hidePopover = useCallback(() => setIsOpen(false), [setIsOpen]);
    const surveyName = useSelector(getSurveyOrGroupName);

    // If there is no survey name, use survey group name (should not happen)
    if (!surveyName) {
        return null;
    }
    return (
        <div className="no-cot-info">
            <SurveyTitle
                surveyName={surveyName}
                messageId="report.sidebar.modify.titles.survey"
            />
            <div ref={popoverMenuContainer} className="no-cot-info-popover">
                <IconButton onClick={showPopover} className="default">
                    info_outline
                </IconButton>
                {isOpen && (
                    <Popover
                        onClickAway={hidePopover}
                        parent={popoverMenuContainer.current}
                    >
                        <NoSelectYears onClose={hidePopover} />
                    </Popover>
                )}
            </div>
        </div>
    );
};

export default NoSurveyModification;
