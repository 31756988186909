import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { FETCH_GEO_ITEMS_BY_FIPS_REQUEST } from '../../../constants/Geographies';

import Button from '../../Buttons/Button';

import './FipsCodesSelectorActions.scss';

/**
 * @param {{
 *   onCancel: Function,
 *   selectedGeoType: import('../../../typescript/types').GeoType,
 *   fipsInput: string,
 *   setIsResultsModalOpen: Function,
 *   surveyCode: string
 * }} param0
 */
const FipsCodesSelectorActions = ({
    onCancel,
    selectedGeoType,
    fipsInput,
    setIsResultsModalOpen,
    surveyCode,
}) => {
    const dispatch = useDispatch();

    return (
        <div className="zoomable-actions fips-codes-selector-actions">
            <Button variant="flat small" onClick={onCancel}>
                <FormattedMessage id="cancel" />
            </Button>
            <Button
                variant="contained small"
                disabled={!fipsInput || !selectedGeoType?.name}
                onClick={() => {
                    dispatch({
                        type: FETCH_GEO_ITEMS_BY_FIPS_REQUEST,
                        payload: {
                            requestedGeoType: selectedGeoType.name,
                            fipsCodes: fipsInput,
                            surveyName: surveyCode,
                        },
                    });
                    setIsResultsModalOpen(true);
                }}
            >
                <FormattedMessage id="geographiesPage.fipsSelection.find" />
            </Button>
        </div>
    );
};

export default FipsCodesSelectorActions;
