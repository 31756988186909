import React from 'react';
import { FormattedMessage } from 'react-intl';

import DOMElementID from '../../../constants/DOMElementID';

import JumpButton from '../../JumpButton/JumpButton';

const NavigatorJumpButtons = () => {
    const jumpActions = [
        <JumpButton
            key="back2header"
            variant="flat"
            targetElementID={DOMElementID.REPORT_HEADER}
        >
            <FormattedMessage id={'layoutNavigation.back2header'} />
        </JumpButton>,
        <JumpButton
            key="back2menu"
            variant="flat"
            targetElementID={DOMElementID.SIDEBAR_MODIFY_REPORT}
        >
            <FormattedMessage id={'layoutNavigation.back2menu'} />
        </JumpButton>,
        <JumpButton
            key="back2reportContent"
            variant="flat"
            targetElementID={DOMElementID.CONTENT_TABLE_WRAPPER}
        >
            <FormattedMessage id={'layoutNavigation.back2reportContent'} />
        </JumpButton>,
    ];

    return <>{jumpActions}</>;
};

export default NavigatorJumpButtons;
