import { DefaultRootState } from 'react-redux';
import * as Selection from './Selection';
import * as WizardHeader from './WizardHeader';
import ApplicationPaths from '../helpers/ApplicationPaths';
import { URLSearchParams } from 'url';
import { TABLE_TEMPLATE_ID_PARAM_NAME } from '../constants/Table';
import { getIsCreatingTemplateFromDashboard, getTemplateId } from './Templates';

export const getRedirectURL = (
    state: DefaultRootState,
    currentURL: string,
    urlParams: URLSearchParams,
) => {
    const surveyCode = Selection.getSurveyCode(state);
    switch (true) {
        case ApplicationPaths.tables.isExactPath(currentURL):
            const templateIdInUrlParams = urlParams.get(
                TABLE_TEMPLATE_ID_PARAM_NAME,
            );
            const storedTableTemplateId = getTemplateId(state);

            if (WizardHeader.reportAlreadyExists(state)) {
                return undefined;
            }
            if (!Selection.hasSurveyCodeSelected(state)) {
                return ApplicationPaths.surveys.path;
            } else if (
                templateIdInUrlParams ||
                storedTableTemplateId ||
                getIsCreatingTemplateFromDashboard(state)
            ) {
                return undefined;
            } else if (!Selection.hasGeographiesSelected(state)) {
                return ApplicationPaths.geographies.getPath({ surveyCode });
            }
            return undefined;
        case ApplicationPaths.newReport.isExactPath(currentURL):
            if (WizardHeader.reportAlreadyExists(state)) {
                return undefined;
            }
            if (!Selection.hasSurveyCodeSelected(state)) {
                return ApplicationPaths.surveys.path;
            } else if (!Selection.hasGeographiesSelected(state)) {
                return ApplicationPaths.geographies.getPath({ surveyCode });
            } else if (!Selection.hasTablesSelected(state)) {
                return ApplicationPaths.tables.getPath({
                    surveyCodes: surveyCode,
                });
            }
            return undefined;
        case ApplicationPaths.geographies.isExactPath(currentURL):
        case ApplicationPaths.existingReport.isExactPath(currentURL):
        case ApplicationPaths.root.isExactPath(currentURL):
        case ApplicationPaths.surveys.isExactPath(currentURL):
            return undefined;
        default:
            return ApplicationPaths.surveys.path;
    }
};
