import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { getCsvDownloadOptions } from '../../../selectors/Report';
import { COUNTER_LOG_REQUEST } from '../../../constants/Counter';
import { openDownloadCsvUrlInNewTab } from '../../../helpers/OpenUrlOutsideOfApp';
import * as counterHelper from '../../../helpers/CounterEvents';
import { getDownloadProgramsEventValue } from '../../../selectors/Counter';
import * as GtmAttributes from '../../../constants/GtmAttributes';

import DownloadCard from './DownloadCard/DownloadCard';

import './DownloadPrograms.scss';

const DownloadPrograms = ({ reportId }) => {
    const dispatch = useDispatch();
    const downloadOptions = useSelector(getCsvDownloadOptions);
    const getCounterEventValue = useSelector(getDownloadProgramsEventValue);

    const openDownloadUrl = (programType) => {
        const counterEventValue = getCounterEventValue(programType);
        dispatch({
            type: COUNTER_LOG_REQUEST,
            payload: counterHelper.getDownloadProgramEvents(counterEventValue),
        });
        openDownloadCsvUrlInNewTab({
            reportId,
            ...downloadOptions,
            downloadProgram: true,
            programType,
        });
    };
    const intl = useIntl();

    return (
        <div className="download-programs-wrapper">
            <DownloadCard
                title={intl.formatMessage({
                    id: 'report.sidebar.downloadCsv.programs.sas',
                })}
                actions={[
                    {
                        onClick: () => openDownloadUrl('sas'),
                        gtmAttributes: GtmAttributes.getDownloadSasButtonGtmAttributes(),
                    },
                ]}
            />
            <DownloadCard
                title={intl.formatMessage({
                    id: 'report.sidebar.downloadCsv.programs.spss',
                })}
                actions={[
                    {
                        onClick: () => openDownloadUrl('spss'),
                        gtmAttributes: GtmAttributes.getDownloadSpssButtonGtmAttributes(),
                    },
                ]}
            />
            <DownloadCard
                title={intl.formatMessage({
                    id: 'report.sidebar.downloadCsv.programs.stata',
                })}
                actions={[
                    {
                        label: '.DO',
                        onClick: () => openDownloadUrl('StataDoFile'),
                        disabled: !downloadOptions.tabSeparated,
                        gtmAttributes: GtmAttributes.getDownloadStataDoFileGtmAttributes(),
                    },
                    {
                        label: '.DCT',
                        onClick: () => openDownloadUrl('StataDctFile'),
                        disabled: !downloadOptions.tabSeparated,
                        gtmAttributes: GtmAttributes.getDownloadStataDctFileGtmAttributes(),
                    },
                ]}
                supportingText={intl.formatMessage({
                    id: 'report.sidebar.downloadCsv.stataSupportingText',
                })}
            />
            <DownloadCard
                title={intl.formatMessage({
                    id: 'report.sidebar.downloadCsv.programs.dataDictionary',
                })}
                actions={[
                    {
                        onClick: () => openDownloadUrl('datadict'),
                        gtmAttributes: GtmAttributes.getDownloadDataDictionaryGtmAttributes(),
                    },
                ]}
            />
        </div>
    );
};

export default DownloadPrograms;
