import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as GtmAttributes from '../../../constants/GtmAttributes';
import { useAddGtmAttributes } from '../../../customHooks/useAddGtmAttributes';
import { getSurveyByCode } from '../../../selectors/Survey';
import { Survey as SurveyType, SurveyCode } from '../../../typescript/types';
import Tag from '../../base/Tag/Tag';
import Button from '../../Buttons/Button';
import Icon from '../../Icon/Icon';
import SurveyIcon from '../SurveyIcon';
import './Survey.scss';

type Props = {
    onSelect: (survey: SurveyType) => void;
    surveyCode: SurveyCode;
};

const Survey = ({ onSelect, surveyCode }: Props) => {
    const survey = useSelector((state) => getSurveyByCode(state, surveyCode));
    const surveyButton = useRef(null);

    useAddGtmAttributes(
        surveyButton,
        GtmAttributes.getSurveyButtonGtmAttributes(surveyCode),
    );

    const intl = useIntl();

    const selectSurvey = () => onSelect(survey);

    const listItemAriaLabel = intl.formatMessage(
        { id: 'surveysPage.listItemAriaLabel' },
        { name: survey.name },
    );

    return (
        <li className="surveys-list-item">
            <Button
                ref={surveyButton}
                onClick={selectSurvey}
                title={listItemAriaLabel}
                aria-label={listItemAriaLabel}
            >
                <div className="surveys-list-item-section">
                    <div className="survey-icon">
                        <SurveyIcon color="rgba(18, 87, 221, 0.46)" />
                    </div>
                    {survey.name}
                </div>
                <div className="surveys-list-item-section">
                    {survey.isNew && (
                        <Tag>
                            <FormattedMessage id="new" />
                        </Tag>
                    )}
                    <Icon>navigate_next</Icon>
                </div>
            </Button>
        </li>
    );
};

export default Survey;
