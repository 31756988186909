import React from 'react';
import { useEffect } from 'react';
import { subscribe, unsubscribe } from '../helpers/ClickAway';

export const useClickAway = (
    ref: React.MutableRefObject<HTMLElement> | React.RefObject<HTMLElement>,
    callback: (e: MouseEvent | KeyboardEvent) => void,
) => {
    useEffect(() => {
        subscribe(ref, callback);
        return () => {
            unsubscribe(ref, callback);
        };
    }, [ref, callback]);
};
