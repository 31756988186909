import React from 'react';
import { useIntl } from 'react-intl';

import { NavigatorOptions } from '../../../constants/ReportNavigator';

import ToggleButton from '../../base/ToggleButton/ToggleButton';

type Props = {
    selectedNavigatorOption: number;
    setSelectedNavigatorOption: (navigatorOption: number) => void;
};

const GeographiesTablesSwitch = ({
    setSelectedNavigatorOption,
    selectedNavigatorOption,
}: Props) => {
    const intl = useIntl();

    const createButton = (label: string, navigatorOption: number) => {
        return {
            onClick: () => {
                setSelectedNavigatorOption(navigatorOption);
            },
            label,
            isSelected: selectedNavigatorOption === navigatorOption,
        };
    };

    const buttons = [
        createButton(
            intl.formatMessage({ id: 'navigator.geographies' }),
            NavigatorOptions.GEOGRAPHIES,
        ),
        createButton(
            intl.formatMessage({ id: 'navigator.tables' }),
            NavigatorOptions.TABLES,
        ),
    ];
    const buttonAriaLabel = intl.formatMessage({
        id: 'navigator.toggleNavigatorOption',
    });

    return <ToggleButton buttons={buttons} aria-label={buttonAriaLabel} />;
};

export default GeographiesTablesSwitch;
