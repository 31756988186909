import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReportAppDispatch } from '../../typescript/actions/actions';

import { getIsCreatingTemplateFromDashboard } from '../../selectors/Templates';
import {
    getHasIntermittentTables,
    getIntermittentTables,
} from '../../selectors/Table';
import { getUpdateTableAction } from '../../actionCreators/reportActions';
import AppScreen from '../../constants/AppScreen';

import WizardHeader from '../WizardHeader/WizardHeader';

type Props = {
    openModal: () => void;
};

const TablesWizardHeader = ({ openModal }: Props) => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const hasIntermittentTablesSelection = useSelector(
        getHasIntermittentTables,
    );
    const isCreatingTemplateFromDashboard = useSelector(
        getIsCreatingTemplateFromDashboard,
    );
    const intermittentTables = useSelector(getIntermittentTables);

    const handleForwardAction = () => {
        if (isCreatingTemplateFromDashboard) {
            openModal();
        } else if (intermittentTables) {
            dispatch(getUpdateTableAction(intermittentTables));
        }
    };

    return (
        <WizardHeader
            currentAppScreen={AppScreen.TABLE_SELECTOR}
            isForwardButtonEnabled={hasIntermittentTablesSelection}
            onForwardButtonClick={handleForwardAction}
        />
    );
};

export default TablesWizardHeader;
