import React from 'react';
import { useSelector } from 'react-redux';
import DOMElementID from '../../constants/DOMElementID';
import BackendPaths from '../../helpers/BackendPaths';
import { getHeaderTitleContent } from '../../selectors/ReportHeader';
import Link from '../base/Link/Link';
import { ReactComponent as Logo } from '../Logo/Logo.svg';
import VerticalDivider from '../VerticalDivider/VerticalDivider';
import HelpMenu from './HelpMenu/HelpMenu';
import ReportActions from './ReportActions/ReportActions';
import './ReportHeader.scss';
import SessionInfo from './SessionInfo/SessionInfo';

const ReportHeader = () => {
    const reportTitle = useSelector(getHeaderTitleContent);
    const dashboardUrl = BackendPaths.dashboardUrl.getPath();

    const leftPart = (
        <div className="report-header-content-left">
            <div className="se-logo">
                <Link url={dashboardUrl}>
                    <Logo />
                </Link>
            </div>
            <VerticalDivider />
            <h1 className="report-header-report-title">{reportTitle}</h1>
        </div>
    );

    const rightPart = (
        <div className="report-header-content-right">
            <ReportActions />
            <HelpMenu />
            <VerticalDivider />
            <SessionInfo />
        </div>
    );
    return (
        <>
            <header className="report-header" id={DOMElementID.REPORT_HEADER}>
                {leftPart}
                {rightPart}
            </header>
        </>
    );
};

export default ReportHeader;
