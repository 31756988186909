import React from 'react';
import { useIntl } from 'react-intl';

import { DOWNLOAD_EXCEL } from '../../../constants/Sidebar';

import SidebarIcon from '../SidebarIcon';

import { ReactComponent as DownloadExcelIcon } from '../../../icons/downloadExcel.svg';

const DownloadExcelIconTab = ({
    activateTab,
    activatedTabId,
    focusedTabId,
}) => {
    const intl = useIntl();

    return (
        <SidebarIcon
            onClick={() => activateTab(DOWNLOAD_EXCEL)}
            active={activatedTabId === DOWNLOAD_EXCEL}
            icon={
                <DownloadExcelIcon
                    title={intl.formatMessage({
                        id: 'report.sidebar.hints.excel',
                    })}
                />
            }
            id={DOWNLOAD_EXCEL}
            tabIndex={focusedTabId === DOWNLOAD_EXCEL ? undefined : -1}
        />
    );
};

export default DownloadExcelIconTab;
