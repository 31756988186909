import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { copyToClipboard } from '../../../../helpers/HtmlHelper';
import { getGeoItemsByFipsResults } from '../../../../selectors/GeoTypes';

import Button from '../../../Buttons/Button';
import Icon from '../../../Icon/Icon';

import './NotFoundList.scss';

const NotFoundList = ({ onCloseModal, onCloseNotFoundList }) => {
    const intl = useIntl();
    const [geoSelection, notMatched] = useSelector(getGeoItemsByFipsResults);
    let headerTitle, backAction;
    if (geoSelection.length === 0) {
        headerTitle = intl.formatMessage({
            id: 'geographiesPage.fipsSelection.results.foundNone',
        });
        backAction = onCloseModal;
    } else {
        headerTitle = intl.formatMessage({
            id: 'geographiesPage.fipsSelection.results.notFound',
        });
        backAction = onCloseNotFoundList;
    }
    const buttonLabel = intl.formatMessage({
        id: 'geographiesPage.fipsSelection.results.back2search',
    });
    return (
        <>
            <div className="not-found-header">
                <Button
                    onClick={backAction}
                    title={buttonLabel}
                    aria-label={buttonLabel}
                >
                    <Icon>arrow_back</Icon>
                </Button>
                <h4>{headerTitle}</h4>
            </div>
            <div className="not-found-list-warning">
                <div className="not-found-list-warning-icon">
                    <Icon>warning</Icon>
                </div>
                <div className="not-found-list-warning-label">
                    <FormattedMessage id="geographiesPage.fipsSelection.results.warningLabel" />
                </div>
            </div>
            <div className="not-found-list-wrapper">
                <textarea
                    data-hj-allow={true}
                    className="not-found-list-textarea"
                    value={notMatched}
                    readOnly={true}
                />
                <Button
                    className="copy-codes"
                    onClick={() => copyToClipboard(notMatched)}
                >
                    <FormattedMessage id="geographiesPage.fipsSelection.results.copyCodes" />
                </Button>
            </div>
        </>
    );
};

export default NotFoundList;
