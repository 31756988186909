import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';

import IconButton from '../../IconButton/IconButton';
import Popover from '../../base/Popover/Popover';

import './DownloadCsvHeaderInfo.scss';

type Props = {
    parent: HTMLElement;
};

const DownloadCsvHeaderInfo = ({ parent }: Props) => {
    const intl = useIntl();

    const [isOpen, setIsOpen] = useState(false);
    const showPopover = useCallback(() => setIsOpen(true), [setIsOpen]);
    const hidePopover = useCallback(() => setIsOpen(false), [setIsOpen]);

    return (
        <div id="download-csv-header-info">
            <IconButton
                className={classNames('info-button', {
                    selected: isOpen,
                })}
                size="small"
                onClick={showPopover}
                title={intl.formatMessage({ id: 'help' })}
                aria-label={intl.formatMessage({ id: 'help' })}
            >
                info_outline
            </IconButton>

            {isOpen && (
                <Popover
                    className="info-popover"
                    onClickAway={hidePopover}
                    parent={parent}
                >
                    <FormattedMessage
                        id="report.sidebar.downloadCsv.description"
                        values={{
                            b: (ch: React.ReactNode) => <strong>{ch}</strong>,
                            div: (ch: React.ReactNode) => <div>{ch}</div>,
                        }}
                    />
                </Popover>
            )}
        </div>
    );
};

export default DownloadCsvHeaderInfo;
