import React, { useState, useCallback } from 'react';

import Modal from '../base/Modal/Modal';
import TableTemplateModalContent from '../ReportHeader/ReportActions/TableTemplateModalContent';
import TablesWizardHeader from './TablesWizardHeader';

const TablesPageHeader = () => {
    const [isModalOpened, setIsModalOpened] = useState(false);
    const closeModal = useCallback(() => setIsModalOpened(false), [
        setIsModalOpened,
    ]);
    const openModal = useCallback(() => setIsModalOpened(true), [
        setIsModalOpened,
    ]);

    return (
        <>
            <TablesWizardHeader openModal={openModal} />
            {isModalOpened && (
                <Modal
                    onClickAway={() => {}}
                    focusFirstChild
                    className="template-action-modal"
                >
                    <TableTemplateModalContent onClose={closeModal} />
                </Modal>
            )}
        </>
    );
};

export default TablesPageHeader;
