import * as Survey from '../constants/Survey';

/**
 * @typedef SelectSurveyPageStatus
 * @property {number[]} surveyGroupIds
 * @property {boolean} loading
 * @property {Object} error
 * @property {boolean} loaded
 */
export default (
    state = {
        surveyGroupIds: null,
        loading: false,
        error: null,
        loaded: false,
    },
    action,
) => {
    switch (action.type) {
        case Survey.SET_SELECTABLE_SURVEY_GROUPS: {
            const surveyGroupIds = action.payload;
            return {
                ...state,
                surveyGroupIds: [...surveyGroupIds],
            };
        }
        case Survey.FETCH_SURVEYS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case Survey.FETCH_SURVEYS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                loaded: true,
            };
        case Survey.FETCH_SURVEYS_FAILURE: {
            const error = action.payload;
            return {
                ...state,
                loading: false,
                error,
                loaded: true,
            };
        }
        default:
            return state;
    }
};
