import React from 'react';
import { useIntl } from 'react-intl';

import { CITATION, INFO } from '../../../constants/Sidebar';

import SidebarIcon from '../SidebarIcon';

import { ReactComponent as InfoIcon } from '../../../icons/info.svg';

type Props = {
    activatedTabId: string;
    focusedTabId: string;
    activateTab: (item: string) => void;
};

const InfoIconTab = ({ activateTab, activatedTabId, focusedTabId }: Props) => {
    const intl = useIntl();

    return (
        <SidebarIcon
            onClick={() => activateTab(INFO)}
            active={activatedTabId === INFO || activatedTabId === CITATION}
            icon={
                <InfoIcon
                    title={intl.formatMessage({
                        id: 'report.sidebar.hints.info',
                    })}
                />
            }
            id={INFO}
            tabIndex={focusedTabId === INFO ? undefined : -1}
        />
    );
};

export default InfoIconTab;
