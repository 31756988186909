import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import * as TableApiSaga from './TableApi';
import * as HelperSaga from './Helper';
import * as ReportCreateSaga from './ReportCreate';

import api from '../helpers/Api';
import BackendPaths from '../helpers/BackendPaths';
import * as GeographiesHelper from '../helpers/Geographies';
import * as SurveysHelper from '../helpers/Surveys';

import * as SurveyConstants from '../constants/Survey';
import * as SelectionConstants from '../constants/Selection';
import * as TableConstants from '../constants/Table';
import * as GeographiesConstants from '../constants/Geographies';

import * as Selection from '../selectors/Selection';
import * as TableSelector from '../selectors/Table';
import * as ReportMetadata from '../selectors/ReportMetadata';
import * as Survey from '../selectors/Survey';
import * as CurrentUser from '../selectors/CurrentUser';

import { ReportAppActions } from '../typescript/actions/actions';
import * as ReportApp from '../typescript/types';
import * as SurveyActions from '../typescript/actions/SurveyActions';
import * as NewSurveyActions from '../typescript/actions/NewSurveyActions';
import * as TableActionCreator from '../actionCreators/tableActions';

function* fetchSurveys() {
    const hasPro: boolean = yield select(CurrentUser.getHasPro);
    let dataGroups: ReportApp.OmniWebService.ExploreTablesResponse;
    try {
        dataGroups = yield call(
            api,
            BackendPaths.surveys.getPath({
                tenant: process.env.REACT_APP_TENANT,
                previewAllDatasets: !hasPro,
            }),
        );
    } catch (e) {
        return yield HelperSaga.handleError(
            SurveyConstants.FETCH_SURVEYS_FAILURE,
            e,
        );
    }
    // remove disabled dataGroups and dataSets
    dataGroups.forEach(
        (dg) =>
            (dg.dataSets = dg.dataSets.filter(
                (dataset) => dataset.enabled && dataset.availableInReports,
            )),
    );
    dataGroups = dataGroups.filter((dg) => dg.enabled && !!dg.dataSets.length);

    // extract all surveys from dataGroups and normalize
    const surveys = dataGroups.flatMap((dataGroup) =>
        dataGroup.dataSets.map(SurveysHelper.parseDataSet),
    );
    yield put<ReportAppActions>({
        type: SurveyConstants.SET_NORMALIZED_SURVEYS,
        payload: surveys,
    });

    // normalize surveyGroups values in each survey
    const surveyGroups = dataGroups.map(SurveysHelper.parseDataGroup);
    yield put<ReportAppActions>({
        type: SurveyConstants.SET_NORMALIZED_SURVEY_GROUPS,
        payload: surveyGroups,
    });

    // set what surveys to display on survey selection screen
    const surveyGroupIds = surveyGroups.map((surveyGroup) => surveyGroup.id);
    yield put({
        type: SurveyConstants.SET_SELECTABLE_SURVEY_GROUPS,
        payload: surveyGroupIds,
    });
    yield put({ type: SurveyConstants.FETCH_SURVEYS_SUCCESS });
}

function* selectSurvey({ payload }: SurveyActions.SelectSurvey) {
    const newSurveyCode = payload;
    const currentSurveyCode: string = yield select(Selection.getSurveyCode);

    if (newSurveyCode === currentSurveyCode) {
        // if survey code is the same, then do nothing
        return;
    }

    yield put({
        type: SelectionConstants.SET_SURVEY_CODE,
        payload: newSurveyCode,
    });
    yield put({ type: SelectionConstants.CLEAR_REPORT_ID });
    yield put<ReportAppActions>({ type: GeographiesConstants.RESET_VALUES });
    yield put<ReportAppActions>({
        type: TableConstants.CLEAR_TEMPORARY_TABLES_SELECTION,
    });
}

function* getSurveyCodesByYearsInSurveyGroupId(
    years: number[],
    surveyGroupId: number,
    comparabilityGroup: ReportApp.ComparabilityGroup,
) {
    const surveyCodes: ReportApp.SurveyCode[] = [];
    for (const year of years) {
        const survey: ReportApp.Survey | null = yield select(
            Survey.getSurveyByYearSurveyGroupIdComparabilityGroup,
            year,
            surveyGroupId,
            comparabilityGroup,
        );
        if (!survey) {
            throw new Error(`Survey for year ${year} doesn't exist`);
        }
        surveyCodes.push(survey.code);
    }
    return surveyCodes;
}

function* analyseChange({ payload }: NewSurveyActions.AnalyseChangeRequest) {
    const { newYears, oldYears, surveyGroupId, comparabilityGroup } = payload;
    const allYears = Array.from(new Set(oldYears.concat(newYears)));
    const newYearsToBeAdded = newYears.filter(
        (year) => !oldYears.includes(year),
    );
    const oldReportIds: ReportApp.OldReportId[] = yield select(
        ReportMetadata.getOldReportIdsBySurveyGroupId,
        surveyGroupId,
    );
    let responses: ReportApp.AspNetWebService.ReportSelectionResults[];
    try {
        responses = yield ReportCreateSaga.fetchReportInfos(oldReportIds);
    } catch (error) {
        return yield HelperSaga.handleError(
            SurveyConstants.ANALYSE_CHANGE_ERROR,
            error,
        );
    }
    const reportInfoByOldReportId = ReportCreateSaga.getReportInfoByOldReportId(
        oldReportIds,
        responses,
    );

    yield put<ReportAppActions>({
        type: SurveyConstants.SET_REPORT_INFO,
        payload: reportInfoByOldReportId,
    });

    let newSurveyCodes: ReportApp.SurveyCode[];
    let allSurveyCodes: ReportApp.SurveyCode[];
    try {
        newSurveyCodes = yield getSurveyCodesByYearsInSurveyGroupId(
            newYearsToBeAdded,
            surveyGroupId,
            comparabilityGroup,
        );
        allSurveyCodes = yield getSurveyCodesByYearsInSurveyGroupId(
            allYears,
            surveyGroupId,
            comparabilityGroup,
        );
    } catch (error) {
        return yield HelperSaga.handleError(
            SurveyConstants.ANALYSE_CHANGE_ERROR,
            error,
        );
    }

    // analyse geographies start
    const geosBySl = GeographiesHelper.getGeoFipsBySummaryLevel(
        reportInfoByOldReportId,
    );
    const summaryLevels = Object.keys(geosBySl);
    const fipsCalls = newSurveyCodes.flatMap((newSurveyCode) =>
        summaryLevels.map((summaryLevel) =>
            call(api, BackendPaths.getGeoItemsByFipsCodes.getPath(), 'POST', {
                bOnlyMajorGeographies: false,
                sFipsCodes: Array.from(geosBySl[summaryLevel]).join(' '),
                sRequestedGeoType: `SL${summaryLevel}`,
                sSurveyName: newSurveyCode,
            }),
        ),
    );
    let fipsResponse: ReportApp.AspNetWebService.FipsResults[];
    try {
        fipsResponse = yield all(fipsCalls);
    } catch (error) {
        return yield HelperSaga.handleError(
            SurveyConstants.ANALYSE_CHANGE_ERROR,
            error,
        );
    }

    for (let i = 0; i < newYearsToBeAdded.length; i += 1) {
        const geoItemsBySl: ReportApp.GeoItemsBySumLevel = {};
        summaryLevels.forEach((summaryLevel, j) => {
            const index = i * summaryLevels.length + j;
            const [
                geoSelection,
            ] = GeographiesHelper.parseGeoItemsByFipsResponse(
                fipsResponse[index],
            );
            geoItemsBySl[summaryLevel] = geoSelection ?? [];
        });
        const year = newYearsToBeAdded[i];
        yield put<ReportAppActions>({
            type: SurveyConstants.SET_FOUND_GEOGRAPHIES,
            payload: { geoItemsBySl, year },
        });
    }
    // analyse geographies end

    // analyse tables start
    // load datasets and tables for surveys
    for (const surveyCode of allSurveyCodes) {
        const shouldLoad: boolean = yield select(
            TableSelector.getShouldLoadDatasetsOfSurvey,
            surveyCode,
        );
        if (shouldLoad) {
            yield call(
                TableApiSaga.fetchDatasetsWithTables,
                TableActionCreator.getFetchDatasetsAction([surveyCode]),
            );
        }
    }
    // analyse tables end
    yield put<ReportAppActions>({
        type: SurveyConstants.ANALYSE_CHANGE_SUCCESS,
    });
}

export default function* () {
    yield all([
        yield takeEvery(SurveyConstants.FETCH_SURVEYS_REQUEST, fetchSurveys),
        yield takeEvery(SurveyConstants.SELECT_SURVEY, selectSurvey),
        yield takeEvery(SurveyConstants.ANALYSE_CHANGE_REQUEST, analyseChange),
    ]);
}
