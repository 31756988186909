import { DefaultRootState } from 'react-redux';

export const getHasPro = (state: DefaultRootState) => state.currentUser.hasPro;

export const getSmallAvatarUrl = (state: DefaultRootState) => {
    const { user } = state.currentUser;
    return user && user.pictureSmallURL;
};

export const getUserInfo = (state: DefaultRootState) => {
    const { user } = state.currentUser;
    if (!user) {
        return null;
    }

    const { pictureLargeURL, firstName, lastName, email } = user;
    return [pictureLargeURL, firstName, lastName, email, getLicenceTitle(state)];
};

export const getLicenceTitle = (state: DefaultRootState) =>
    state.currentUser.licenceTitle;

export const getUser = (state: DefaultRootState) => state.currentUser.user;

/**
 * It could happen that user is not logged in, but still has pro account. This can
 * be achieved using institutional login (by referral, using ip address, etc.).
 * @param state Global redux state
 */
export const getIsUserLoggedIn = (state: DefaultRootState) => !!getUser(state);

export const getShouldAllowDownload = (state: DefaultRootState) =>
    !state.currentUser.isTrialing && getHasPro(state);
