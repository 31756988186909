import React, { ChangeEventHandler } from 'react';

import LabeledCheckbox from './LabeledCheckbox';

import './LabeledCheckboxesList.scss';

type Props = {
    options: {
        id: string;
        label: string;
        checked: boolean;
        onChange: ChangeEventHandler<HTMLInputElement>;
    }[];
} & React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
>;

const LabeledCheckboxesList = ({ options, ...rest }: Props) => (
    <ul className="labeled-checkboxes-list">
        {options.map((option) => {
            const { id, label, checked, onChange } = option;
            return (
                <li key={id}>
                    <LabeledCheckbox
                        id={id}
                        label={label}
                        checked={checked}
                        onChange={onChange}
                        {...rest}
                    />
                </li>
            );
        })}
    </ul>
);

export default LabeledCheckboxesList;
