import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { UPDATE_REPORT_REQUEST } from '../../../constants/Report';
import {
    getUpdateReportDisplayStatus,
    getUpdateReportError,
} from '../../../selectors/ReportHeader';
import * as ReportMetadata from '../../../selectors/ReportMetadata';
import * as ReportScreenConstants from '../../../constants/ReportScreen';

import Loader from '../../Loader/Loader';
import ReportAttributesInputs from './ReportAttributesInputs';
import ReportActionsModalContent from './ReportActionsModalContent';

type Props = {
    onClose: () => void;
};

const UpdateReportModalContent = ({ onClose }: Props) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const updateReportDisplayStatus = useSelector(getUpdateReportDisplayStatus);
    const updateReportError = useSelector(getUpdateReportError);
    const initialReportTitle = useSelector(ReportMetadata.getReportTitle);
    const initialReportDescription = useSelector(
        ReportMetadata.getReportDescription,
    );
    const [reportTitle, setReportTitle] = useState(initialReportTitle);
    const [reportDescription, setReportDescription] = useState(
        initialReportDescription,
    );
    // used to trigger close modal after updating is done
    const [requestDispatched, setRequestDispatched] = useState(false);

    const requestUpdateReport = () => {
        dispatch({
            type: UPDATE_REPORT_REQUEST,
            payload: {
                reportTitle: reportTitle,
                reportDescription: reportDescription,
            },
        });
        setRequestDispatched(true);
    };

    useEffect(() => {
        if (
            updateReportDisplayStatus ===
                ReportScreenConstants.UPDATE_REPORT_STATUS_AVAILABLE &&
            requestDispatched
        ) {
            onClose();
        }
    }, [onClose, updateReportDisplayStatus, requestDispatched]);

    let content;
    switch (updateReportDisplayStatus) {
        case ReportScreenConstants.UPDATE_REPORT_STATUS_ERROR:
            content = <div>{updateReportError}</div>;
            break;
        case ReportScreenConstants.UPDATE_REPORT_STATUS_LOADING:
            content = <Loader />;
            break;
        case ReportScreenConstants.UPDATE_REPORT_STATUS_AVAILABLE:
        default:
            content = (
                <ReportAttributesInputs
                    reportTitle={reportTitle}
                    onReportTitleChange={setReportTitle}
                    reportDescription={reportDescription}
                    onReportDescriptionChange={setReportDescription}
                />
            );
    }

    return (
        <ReportActionsModalContent
            header={intl.formatMessage({
                id: 'report.renameReport.renameHeader',
            })}
            onClose={onClose}
            mainButton={{
                action: requestUpdateReport,
                text: intl.formatMessage({ id: 'done' }),
                disabled:
                    initialReportTitle === reportTitle &&
                    initialReportDescription === reportDescription,
            }}
        >
            {content}
        </ReportActionsModalContent>
    );
};

export default UpdateReportModalContent;
