import React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import { GeoItemElement } from '../../../typescript/types';

import Loader from '../../Loader/Loader';
import Button from '../../Buttons/Button';
import Icon from '../../Icon/Icon';

import './GeoItemFilterableByTextListItem.scss';

type Props = {
    isSelected: boolean;
    selection: GeoItemElement;
};

export const GeoItemFilterableByTextListItem = ({
    isSelected,
    selection: { label, isDivider = false, isLoading = false, geoType },
}: Props) => {
    const intl = useIntl();

    if (isLoading) {
        return <Loader />;
    }

    const classes = classNames('geo-items-selection-item', {
        selected: isSelected,
        divider: isDivider,
    });

    if (isDivider) {
        return (
            <li className={classes} aria-hidden={true}>
                <span>{label}</span>
            </li>
        );
    }

    const listItemAriaLabel = intl.formatMessage(
        {
            id: `geographiesPage.geoItems.listItemAriaLabel.${
                isSelected ? 'selected' : 'deselected'
            }`,
        },
        { label },
    );

    const icon = geoType ? (
        <Icon className="geo-items-selection-item-arrow">
            keyboard_arrow_right
        </Icon>
    ) : (
        <Icon className="geo-items-selection-item-check">check</Icon>
    );

    return (
        <li className={classes}>
            <Button title={listItemAriaLabel} aria-label={listItemAriaLabel}>
                <span className="geo-items-selection-item-label">{label}</span>
                {icon}
            </Button>
        </li>
    );
};
