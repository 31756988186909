import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    ReportActionModals,
    ReportActionsMainButtonType,
} from '../../../constants/ReportActionsEnum';
import { getWhatMainButtonToRender } from '../../../selectors/ReportHeader';
import Button from '../../Buttons/Button';
import SaveReportButton from './SaveReportButton';

type Props = {
    onClick: (action: ReportActionModals) => void;
};

const ReportActionsMainButton = ({ onClick }: Props) => {
    const intl = useIntl();
    const whatMainButtonToRender = useSelector(getWhatMainButtonToRender);

    switch (whatMainButtonToRender) {
        case ReportActionsMainButtonType.ALL_CHANGES_SAVED:
            return (
                <>
                    <Button
                        variant="contained"
                        disabled
                        title={intl.formatMessage({
                            id: 'report.reportActions.noChangesTitle',
                        })}
                    >
                        <FormattedMessage id="report.reportActions.noChanges" />
                    </Button>
                </>
            );
        case ReportActionsMainButtonType.SAVE_AS:
            return <SaveReportButton onClick={onClick} />;
        case ReportActionsMainButtonType.MAKE_A_COPY:
            return (
                <Button
                    variant="flat"
                    className="save-report"
                    onClick={() =>
                        onClick(ReportActionModals.COPY_REPORT_MODAL)
                    }
                >
                    <FormattedMessage id="makeACopy" />
                </Button>
            );
    }
};

export default ReportActionsMainButton;
