import React from 'react';

import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import * as SelectTablesPageConstants from '../../../constants/SelectTablesPage';
import * as PremadeReport from '../../../selectors/PremadeReport';

import Modal from '../../base/Modal/Modal';
import Loader from '../../Loader/Loader';
import PremadeReportsSelector from './PremadeReportsSelector';

type Props = {
    closeModal: () => void;
    surveyCodes: string[];
};

const PremadeReportsSelectorModal = ({ closeModal, surveyCodes }: Props) => {
    const error = useSelector(PremadeReport.getPremadeReportsError);
    const displayStatus = useSelector((state) =>
        PremadeReport.getPremadeReportsDisplayStatus(state, surveyCodes),
    );

    let content;
    switch (displayStatus) {
        case SelectTablesPageConstants.PREMADE_REPORTS_ERROR:
            content = (
                <div>
                    <FormattedMessage
                        id="tablesPage.premadeReports.errorLoadingPremades"
                        values={{ error: error }}
                    />
                </div>
            );
            break;
        case SelectTablesPageConstants.PREMADE_REPORTS_LOADING:
            content = <Loader />;
            break;
        case SelectTablesPageConstants.PREMADE_REPORTS_SELECTION: {
            content = (
                <PremadeReportsSelector
                    closeModal={closeModal}
                    surveyCodes={surveyCodes}
                />
            );
            break;
        }
        default:
            return null;
    }

    return (
        <Modal className="full-width" onClickAway={closeModal}>
            {content}
        </Modal>
    );
};

export default PremadeReportsSelectorModal;
