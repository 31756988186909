import React from 'react';
import { useSelector } from 'react-redux';

import { getDatasetsUsedInReportBySurveyCode } from '../../selectors/ReportMetadata';

import SourceNode from './SourceNode';

const SourceNodes = ({ hasMultipleDatasets }) => {
    const datasetsBySurveyCode = useSelector(
        getDatasetsUsedInReportBySurveyCode,
    );

    return Object.keys(datasetsBySurveyCode).map((surveyCode) => {
        const datasetsOfSurvey = datasetsBySurveyCode[surveyCode];
        return Array.from(
            datasetsOfSurvey,
        ).map(
            ({ abbreviation, displayName, publisher, notes, name }, index) => (
                <SourceNode
                    key={index}
                    abbreviation={abbreviation}
                    displayName={displayName}
                    publisher={publisher}
                    notes={notes}
                    name={name}
                    isSingle={hasMultipleDatasets}
                    surveyCode={surveyCode}
                />
            ),
        );
    });
};

export default SourceNodes;
