import React from 'react';
import { useSelector } from 'react-redux';

import * as ReportMetadata from '../../../../selectors/ReportMetadata';
import { truncateLongString } from '../../../../helpers/ValueFormatter';

type Props = {
    leftOffset: number;
};

const TableGeoColumnNames = React.forwardRef<HTMLTableHeaderCellElement, Props>(
    ({ leftOffset }, ref) => {
        const paginatedGeoGroupNames = useSelector(
            ReportMetadata.getPaginatedGeoGroupNames,
        );
        const shouldDisplayGeoGroupNames = useSelector(
            ReportMetadata.shouldDisplayGeoGroupNames,
        );
        if (!shouldDisplayGeoGroupNames) {
            return null;
        }
        return (
            <tr>
                <th ref={ref} />
                {paginatedGeoGroupNames.map(
                    ({ geoName, colSpan }, geoIndex) => (
                        <th colSpan={colSpan} key={geoIndex} scope="col">
                            <span
                                className="table-header"
                                style={{ left: leftOffset }}
                                title={geoName}
                                aria-label={geoName}
                            >
                                {truncateLongString(geoName)}
                            </span>
                        </th>
                    ),
                )}
            </tr>
        );
    },
);

export default TableGeoColumnNames;
