import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { score } from '../../../helpers/Search';
import * as ReportApp from '../../../typescript/types';
import * as GeoTypes from '../../../selectors/GeoTypes';
import { ReportAppDispatch } from '../../../typescript/actions/actions';
import { filterFalsy } from '../../../typescript/helper';
import {
    CLEAR_BELOW_SUMMARY_LEVEL,
    SET_INTERMITTENT_FIPS_SELECTION,
} from '../../../constants/Geographies';

import DividersFilterableByTextList from '../../base/FilterableByTextList/DividersFilterableByTextList';
import { GeoItemFilterableByTextListItem } from './GeoItemFilterableByTextListItem';

import './GeoItemFilterableByTextList.scss';

type Props = {
    surveyCode: ReportApp.SurveyCode;
};

/**
 * Used by FilterableByTextList to score out surveys
 *
 * Function will split geo item's label by geo types. Score will boost matches
 * in lower geo levels.
 *
 * @example
 * If geo item is Bronx County, New York, it will be split in two parts:
 * Bronx County and New York. Any `lowerCaseTokens` found in Bronx County will
 * be weighed more than anything found in New York.
 *
 * This is done to solve following issue: If you search counties in New York state,
 * every element in list will have string New York in it. But if you search for
 * New York county, it will be scored same as every other county, and the list
 * wouldn't be filtered. This approach will fix this and New York county in
 * New York state will bubble up in the list
 */
const scoreCallback = (
    geoItem: ReportApp.GeoItemElement,
    lowerCaseTokens: string[],
) => {
    const geoItemParts = geoItem.label.split(',');
    const numberOfParts = geoItemParts.length;
    return geoItemParts.reduce(
        (memo, value, index) =>
            memo + score(value, lowerCaseTokens) * (numberOfParts - index),
        0,
    );
};

export const GeoItemFilterableByTextList = ({ surveyCode }: Props) => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const selectionsForGeoType = useSelector((state) =>
        GeoTypes.constructSelectionsForGeoType(state, surveyCode),
    );
    const individualSelections = useSelector((state) =>
        GeoTypes.constructIndividualSelections(state, surveyCode),
    );
    const selections = [...selectionsForGeoType, ...individualSelections];
    const fipsSelection = useSelector(GeoTypes.getIntermittentFipsSelection);

    const intl = useIntl();
    const isFipsSelected = useCallback(
        (identifier) => fipsSelection.includes(identifier),
        [fipsSelection],
    );
    const setGeoSelectionAndClearBelow = useCallback(
        (geoItem) => {
            dispatch({
                type: CLEAR_BELOW_SUMMARY_LEVEL,
                payload: {
                    geoType: geoItem.geoType,
                    value: geoItem?.fips,
                },
            });
        },
        [dispatch],
    );
    const itemRenderer = (selection: ReportApp.GeoItemElement) => (
        <GeoItemFilterableByTextListItem
            key={selection.identifier || selection.label}
            selection={selection}
            isSelected={isFipsSelected(selection.identifier)}
        />
    );

    const handleGeoItemClick = (
        actionItem: ReportApp.GeoItemElement,
        affectedItems: ReportApp.GeoItemElement[],
    ) => {
        if (actionItem.geoType) {
            // This occurs when user clicks on a geoItem which leads him down in SL hierarchy
            // to the children of that clicked geoItem
            setGeoSelectionAndClearBelow(actionItem);
        } else {
            modifyIntermittentFipsSelection(actionItem, affectedItems);
        }
    };

    const modifyIntermittentFipsSelection = (
        actionItem: ReportApp.GeoItemElement,
        affectedItems: ReportApp.GeoItemElement[],
    ) => {
        const geoItems = affectedItems
            .map((item) => item.identifier)
            .filter(filterFalsy);

        let newSelection;
        if (!isFipsSelected(actionItem.identifier)) {
            newSelection = Array.from(new Set([...fipsSelection, ...geoItems]));
        } else {
            newSelection = fipsSelection.filter(
                (el) => !affectedItems.some((item) => item.identifier === el),
            );
        }

        dispatch({
            type: SET_INTERMITTENT_FIPS_SELECTION,
            payload: newSelection,
        });
    };

    const placeholder = intl.formatMessage({
        id: 'geographiesPage.geoItems.searchPlaceholder',
    });

    const ariaListDescription = intl.formatMessage({
        id: 'geographiesPage.geoItems.listDescription',
    });

    return (
        <DividersFilterableByTextList
            list={selections}
            scoreCallback={scoreCallback}
            render={itemRenderer}
            placeholder={placeholder}
            resultsClassName="geo-items-selection"
            onChange={handleGeoItemClick}
            description={ariaListDescription}
        />
    );
};
