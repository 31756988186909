import * as React from 'react';

const HintArrowIcon = ({ color }) => (
    <svg width={28} height={67} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M27.3 64.3C7.6 54.1 7.3 30.4 12.9 11.5c-.2 4 0 8 .8 12.1.4 1.9 3.2 1.1 2.8-.8-1.3-6.7-1-13.4.8-20 .3-1.2-1.1-2.4-2.2-1.7C10.3 4.1 5.5 7 .7 10c-1.6 1-.1 3.6 1.5 2.6 3-1.9 6-3.7 9-5.6-7.1 20.7-7.5 48.3 14.6 59.8 1.7.9 3.1-1.7 1.5-2.5Z"
            fill={color}
            fillRule="nonzero"
        />
    </svg>
);

export default HintArrowIcon;
