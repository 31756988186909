import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { ReportActionModals } from '../../../constants/ReportActionsEnum';
import { getIsUserLoggedIn } from '../../../selectors/CurrentUser';

import Button from '../../Buttons/Button';

type Props = {
    onClick: (action: ReportActionModals) => void;
};

const SaveReportButton = ({ onClick }: Props) => {
    const intl = useIntl();
    const isUserLogged = useSelector(getIsUserLoggedIn);
    const buttonTitle = isUserLogged
        ? undefined
        : intl.formatMessage({
              id: 'report.reportActions.availableForLoggedUsers',
          });

    const button = (
        <Button
            variant="flat"
            className="save-report"
            onClick={() => onClick(ReportActionModals.SAVE_REPORT_MODAL)}
            disabled={!isUserLogged}
        >
            <FormattedMessage id="saveAs" />
        </Button>
    );

    if (isUserLogged) {
        return button;
    }

    return (
        // `buttonTitle` is placed on div since if it was placed on the button,
        // it would not be shown on hover since button is disabled
        <div title={buttonTitle} aria-label={buttonTitle}>
            {button}
        </div>
    );
};

export default SaveReportButton;
