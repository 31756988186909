import { SET_NORMALIZED_GEO_TYPES } from '../constants/Geographies';

export default (state = {}, action) => {
    switch (action.type) {
        case SET_NORMALIZED_GEO_TYPES: {
            const geographies = action.payload.geoTypes.reduce(
                (acc, geoType) => {
                    acc[geoType.name] = geoType;
                    return acc;
                },
                {},
            );
            return {
                ...state,
                [action.payload.surveyCode]: {
                    ...state[action.payload.surveyCode],
                    ...geographies,
                },
            };
        }
        default:
            return state;
    }
};
