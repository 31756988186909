import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
    surveyName: string;
    messageId: string;
};

const SurveyTitle = ({ surveyName, messageId }: Props) => {
    return (
        <div className="modify-survey-title">
            <h5>
                <FormattedMessage id={messageId} />
            </h5>
            <span title={surveyName}>{surveyName}</span>
        </div>
    );
};

export default SurveyTitle;
