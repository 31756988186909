import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../../Buttons/Button';
import Icon from '../../Icon/Icon';
import SelectionCounter from '../../Selection/SelectionCounter';

import './PremadeReportsSelectorHeader.scss';

type Props = {
    onClose: () => void;
    numberOfSelectedItems: number;
};

const PremadeReportsSelectorHeader = ({
    onClose,
    numberOfSelectedItems,
}: Props) => {
    return (
        <div className="premade-reports-header">
            <div className="premade-reports-header-main">
                <h4>
                    <FormattedMessage id="tablesPage.premadeReports.header" />
                </h4>
                <Button variant="icon" onClick={onClose}>
                    <Icon>close</Icon>
                </Button>
            </div>
            <SelectionCounter
                numberOfSelectedItems={numberOfSelectedItems}
                active={numberOfSelectedItems > 0}
            />
        </div>
    );
};

export default PremadeReportsSelectorHeader;
