import React from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';

export type Props = {
    children: React.ReactNode;
    url: string;
    // this props is required since ReactRouterDomLink doesn't use same definition
    // for React.AnchorHTMLAttributes (from react). It uses the one from react-dom.
    defaultValue?: string | number;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

const Link = ({ children, url, ...rest }: Props) => {
    if (/^https?:\/\//.test(url) || /^mailto?:/.test(url)) {
        // If link is absolute, react-router-dom Link component does not work
        // So we have to use native anchor
        return (
            <a href={url} {...rest}>
                {children}
            </a>
        );
    }
    return (
        <ReactRouterDomLink to={url} {...rest}>
            {children}
        </ReactRouterDomLink>
    );
};

export default Link;
