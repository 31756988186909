import React from 'react';
import { useSelector } from 'react-redux';

import * as ReportApp from '../../../../typescript/types';
import * as ReportMetadata from '../../../../selectors/ReportMetadata';

import SingleTableVariable from './SingleTableVariable';

type Props = {
    tableGuidByReportId: ReportApp.TableGuidByOldReportId;
};

const SingleTableVariables = ({ tableGuidByReportId }: Props) => {
    const numberOfVariables = useSelector((state) =>
        ReportMetadata.getNumberOfVariablesForTables(
            state,
            tableGuidByReportId,
        ),
    );

    const variables = [];
    for (
        let variableIndex = 0;
        variableIndex < numberOfVariables;
        variableIndex += 1
    ) {
        variables.push(
            <SingleTableVariable
                key={variableIndex}
                variableIndex={variableIndex}
                tableGuidByReportId={tableGuidByReportId}
            />,
        );
    }

    return <>{variables}</>;
};

export default SingleTableVariables;
