import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import * as PremadeReport from '../../selectors/PremadeReport';
import { SurveyCode } from '../../typescript/types';

import Icon from '../Icon/Icon';
import ModeSelector from '../base/ModeSelector/ModeSelector';

import {
    SEARCH_ALL_REPORTS,
    SEARCH_PREMADE_REPORTS,
} from '../../constants/TableModalContentStatus';

import './SelectTablesPageModeSelector.scss';

type Props = {
    setModalContentStatus: (status: string) => void;
    surveyCodes: SurveyCode[];
};

const SelectTablesPageModeSelector = ({
    setModalContentStatus,
    surveyCodes,
}: Props) => {
    const getPremadeReportsAndTemplatesIsEmpty = useSelector((state) =>
        PremadeReport.getPremadeReportsAndTemplatesIsEmpty(state, surveyCodes),
    );

    return (
        <ModeSelector
            actions={[
                {
                    className: 'search-tables',
                    autoFocus: true,
                    role: 'search',
                    onClick: () => setModalContentStatus(SEARCH_ALL_REPORTS),
                    content: (
                        <>
                            <Icon>search</Icon>
                            <span>
                                <FormattedMessage id="tablesPage.modeSelector.search" />
                            </span>
                        </>
                    ),
                },
                {
                    className: 'search-premade-reports',
                    role: 'search',
                    disabled: getPremadeReportsAndTemplatesIsEmpty,
                    onClick: () =>
                        setModalContentStatus(SEARCH_PREMADE_REPORTS),
                    content: (
                        <>
                            <Icon>playlist_add</Icon>
                            <span>
                                <FormattedMessage id="tablesPage.modeSelector.premade" />
                            </span>
                        </>
                    ),
                },
            ]}
        />
    );
};

export default SelectTablesPageModeSelector;
