import React, { useCallback, useState } from 'react';

import { ReportActionModals } from '../../../constants/ReportActionsEnum';

import MoreOptions from './MoreOptions';
import ReportActionsJumpButtons from './ReportActionsJumpButtons';
import ReportActionsMainButton from './ReportActionsMainButton';

import './ReportActions.scss';

const ReportActions = () => {
    const [modalContent, setModalContent] = useState(
        ReportActionModals.NO_MODAL,
    );
    const openModal = useCallback(
        (type: ReportActionModals) => setModalContent(type),
        [],
    );
    const closeModal = useCallback(() => {
        setModalContent(ReportActionModals.NO_MODAL);
    }, []);

    return (
        <>
            <div className="actions-root">
                <ReportActionsMainButton onClick={openModal} />
                <MoreOptions
                    closeModal={closeModal}
                    modalContent={modalContent}
                    openModal={openModal}
                />
                <ReportActionsJumpButtons />
            </div>
        </>
    );
};

export default ReportActions;
