import React from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from '../Icon/Icon';
import Surface from '../base/Surface/Surface';

import './WarningMessage.scss';

type Props = {
    messageId: string;
    values?: {
        [key: string]:
            | string
            | number
            | ((value: string) => React.ReactNode)
            | React.ReactNode
            | null;
    };
};

const WarningMessage = ({ messageId, values }: Props) => {
    return (
        <Surface level="1" variant="orange" className="warning-container">
            <div className="warning-icon">
                <Icon>warning</Icon>
            </div>
            <span>
                <FormattedMessage id={messageId} values={values} />
            </span>
        </Surface>
    );
};

export default WarningMessage;
