import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import * as Report from '../../../selectors/Report';
import * as ReportMetadata from '../../../selectors/ReportMetadata';
import * as ReportConstants from '../../../constants/Report';
import { AggregationTypes } from '../../../typescript/enums';
import { ReportAppDispatch } from '../../../typescript/actions/actions';

import ToggleButton, {
    ToggleOneButton,
} from '../../base/ToggleButton/ToggleButton';

import './AggregationType.scss';

const AggregationType = () => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const selectedAggregationType = useSelector(Report.getAggregationType);
    const shouldShowAggregationSwitcher = useSelector(
        ReportMetadata.getShouldAllowAggregation,
    );
    const intl = useIntl();

    if (!shouldShowAggregationSwitcher) {
        return null;
    }

    const createActionFromAggregationType = (
        label: string,
        aggregationType: AggregationTypes,
    ): ToggleOneButton => {
        return {
            onClick: () => {
                dispatch({
                    type: ReportConstants.SET_AGGREGATION_TYPE_REQUEST,
                    payload: aggregationType,
                });
            },
            label,
            isSelected: selectedAggregationType === aggregationType,
        };
    };

    const buttons = [
        createActionFromAggregationType(
            intl.formatMessage({ id: 'report.sidebar.modify.totals.show' }),
            AggregationTypes.SHOW,
        ),
        createActionFromAggregationType(
            intl.formatMessage({ id: 'report.sidebar.modify.totals.hide' }),
            AggregationTypes.NONE,
        ),
        createActionFromAggregationType(
            intl.formatMessage({
                id: 'report.sidebar.modify.totals.onlyTotals',
            }),
            AggregationTypes.ONLY_TOTALS,
        ),
    ];

    const buttonAriaLabel = intl.formatMessage({
        id: 'report.sidebar.modify.toggle.aggregationType',
    });

    return (
        <div className="aggregation-type">
            <h5>
                <FormattedMessage id="report.sidebar.modify.totals.title" />
            </h5>
            <span className="aggregation-type-description">
                <FormattedMessage id="report.sidebar.modify.totals.description" />
            </span>
            <ToggleButton buttons={buttons} aria-label={buttonAriaLabel} />
        </div>
    );
};

export default AggregationType;
