import React from 'react';
import classNames from 'classnames';

import Icon from '../../../Icon/Icon';
import CollapseInfoTriggerDescription, {
    Props as DescriptionProps,
} from './CollapseInfoTriggerDescription';
import CollapseInfoTriggerChart, {
    Props as ChartProps,
} from './CollapseInfoTriggerChart';

import './CollapseInfoTrigger.scss';

/**
 * Props that are passed as rest in parent component
 */
export type BaseProps = {
    title: React.ReactNode;
} & DescriptionProps &
    ChartProps;

type Props = {
    open: boolean;
    toggleOpen: () => void;
} & BaseProps;

const CollapseInfoTrigger = ({ open, title, toggleOpen, ...rest }: Props) => {
    const classnames = classNames('collapse-info-trigger', { open });
    return (
        <button className={classnames} onClick={toggleOpen}>
            <div className="collapse-info-trigger-left">
                <CollapseInfoTriggerChart {...rest} />
                <div className="collapse-info-trigger-left-text">
                    <h4 className="collapse-info-trigger-left-text-heading">
                        {title}
                    </h4>
                    <CollapseInfoTriggerDescription {...rest} />
                </div>
            </div>
            <div className="collapse-info-trigger-right">
                <div className="collapse-info-trigger-right-icon">
                    <Icon>{open ? 'expand_less' : 'expand_more'}</Icon>
                </div>
            </div>
        </button>
    );
};

export default CollapseInfoTrigger;
