import React from 'react';
import { useIntl } from 'react-intl';

import { MODIFY_REPORT } from '../../../constants/Sidebar';

import SidebarIcon from '../SidebarIcon';

import { ReactComponent as ModifyReportIcon } from '../../../icons/modify.svg';

const ModifyReportIconTab = ({ activateTab, activatedTabId, focusedTabId }) => {
    const intl = useIntl();

    return (
        <SidebarIcon
            onClick={() => activateTab(MODIFY_REPORT)}
            active={activatedTabId === MODIFY_REPORT}
            icon={
                <ModifyReportIcon
                    title={intl.formatMessage({
                        id: 'report.sidebar.hints.modify',
                    })}
                />
            }
            id={MODIFY_REPORT}
            tabIndex={focusedTabId === MODIFY_REPORT ? undefined : -1}
        />
    );
};

export default ModifyReportIconTab;
