import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import * as Report from '../../../selectors/Report';
import * as ReportMetadata from '../../../selectors/ReportMetadata';
import { ReportAppDispatch } from '../../../typescript/actions/actions';
import { SET_SELECTED_DOLLAR_YEAR_ADJUSTMENT_REQUEST } from '../../../constants/Report';

import Select from '../../base/Select/Select';

import './DollarYearAdjustment.scss';

const DollarYearAdjustment = () => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const availableDollarAdjustmentYears = useSelector(
        ReportMetadata.getAvailableDollarAdjustmentYears,
    );
    const selectedDollarYear = useSelector(
        Report.getSelectedDollarAdjustmentLabel,
    );
    if (!availableDollarAdjustmentYears) {
        return null;
    }
    const selectYear = (dollarYearValue: number | string) =>
        dispatch({
            type: SET_SELECTED_DOLLAR_YEAR_ADJUSTMENT_REQUEST,
            payload: { dollarYearValue },
        });

    return (
        <div className="inflation-box">
            <h5>
                <FormattedMessage id="report.sidebar.modify.inflation.title" />
            </h5>
            <Select
                list={availableDollarAdjustmentYears}
                value={selectedDollarYear}
                onChange={selectYear}
                className="allow-dollar-year-adjustment-dropdown"
            />
        </div>
    );
};

export default DollarYearAdjustment;
