import * as ReportApp from '../typescript/types';

export const getOldReportIdByGeoGroupIndexColumnGroupIndex = (
    definition: ReportApp.Definition,
    tableGuidByReportId: ReportApp.TableGuidByOldReportId,
    geoGroupIndex: number,
    columnGroupIndex: number,
) =>
    definition.geoGroups[geoGroupIndex].columnGroups[
        columnGroupIndex
    ].tables.find((oldReportId) =>
        Object.keys(tableGuidByReportId).includes(oldReportId),
    );

export const getOldReportIdsFromDefinition = (
    definition: ReportApp.Definition,
) =>
    definition.geoGroups.flatMap((geo) =>
        geo.columnGroups.flatMap((column) => column.tables),
    );

export const findParentVariableForPercent = (
    table: ReportApp.Table,
    variable: ReportApp.Variable,
) => {
    if (variable.percentBaseUniverseVarIndex === -1) {
        return null;
    }
    return Object.values(table.variables)[variable.percentBaseUniverseVarIndex];
};
