import { taggedTemplate } from '../helpers/StringUtil';
import { CitationFormat } from '../typescript/types';

export const SIMPLE_DATE = {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
} as const;

export const LONG_DATE = {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
    hour12: false,
} as const;

export const CitationFormats: CitationFormat[] = [
    {
        id: 'report.sidebar.citation.citationFormat.cms',
        template: taggedTemplate`${'publisher'}. ${'tableName'}, ${'year'}. Prepared by Social Explorer. ${'link'} (accessed ${'date'}).`,
        dateOptions: LONG_DATE,
    },
    {
        id: 'report.sidebar.citation.citationFormat.mla',
        template: taggedTemplate`${'publisher'}. "${'tableName'}, ${'year'}". Social Explorer. Web. ${'date'}.`,
        dateOptions: LONG_DATE,
    },
    {
        id: 'report.sidebar.citation.citationFormat.apa',
        template: taggedTemplate`${'tableName'} (${'surveyName'}). In SocialExplorer.com. Retrieved ${'date'} from ${'link'}.`,
        dateOptions: SIMPLE_DATE,
    },
];
