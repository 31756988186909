import * as Geographies from '../constants/Geographies';
import {
    CLEAR_SELECTED_SCREEN,
    SET_SELECTED_SCREEN,
} from '../constants/SelectGeographyScreen';

/**
 * @typedef GeoItemsByFips
 * @property {import('../typescript/types').GeoItem[]} geoSelection Found geographies
 * @property {string} notMatched Space separated list of fips not found by last search
 * @property {string} warningLabel Warning message received from backend.
 *
 * @typedef SelectGeographiesPage
 * @property {string} selectedScreen What screen are we on (select geographies, add fips, etc)
 * @property {string[]} geoTypeNames geo types available for selected survey
 * @property {string[]} parentGeoTypes Geo types that are parent of the selected geo type
 * @property {Object.<string, import('../typescript/types').GeoItem[]>} geoItemsBySumLev
 * @property {boolean} geoTypesLoading
 * @property {string} geoTypesError
 * @property {boolean} parentGeoTypesLoading
 * @property {Object} parentGeoTypesError
 * @property {string[]} selectedGeoFipses Fipses in format `${fips};${slLvl};${name}`. Indicate elements that are confirmed selected and will be passed to report selection
 * @property {string[]} intermittentFipsSelection Fipses in format `${fips};${slLvl};${name}`. Indicate elements that user hasn't still confirmed they want to select.
 * @property {string} selectedGeoType SL level that user has selected
 * @property {Object.<string, string>} geoSelection Key is SL level. Value is fips code for that SL level that user has selected
 * @property {boolean} selectedGeographiesLoading
 * @property {string} selectedGeographiesError
 * @property {GeoItemsByFips} geoItemsByFips
 */
const INITIAL_STATE = {
    selectedScreen: undefined,
    geoTypeNames: null,
    parentGeoTypes: null,
    geoItemsBySumLev: null,
    geoTypesLoading: false,
    geoTypesError: null,
    parentGeoTypesLoading: false,
    parentGeoTypesError: null,
    selectedGeoFipses: null,
    intermittentFipsSelection: null,
    selectedGeoType: undefined,
    geoSelection: {},
    selectedGeographiesLoading: false,
    selectedGeographiesError: null,
    geoItemsByFips: {
        geoSelection: [],
        notMatched: undefined,
        warningLabel: undefined,
    },
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Geographies.SET_SELECTABLE_GEO_TYPES: {
            const geoTypeNames = action.payload;
            return {
                ...state,
                geoTypeNames: [...geoTypeNames],
            };
        }
        case Geographies.SET_SELECTABLE_PARENT_GEO_TYPES: {
            const parentGeoTypes = action.payload;
            return {
                ...state,
                parentGeoTypes: [...parentGeoTypes],
            };
        }
        case Geographies.SET_SELECTABLE_GEO_ITEMS: {
            const { items, geoTypeSelectionName } = action.payload;
            return {
                ...state,
                geoItemsBySumLev: {
                    ...state.geoItemsBySumLev,
                    [geoTypeSelectionName]: [...items],
                },
            };
        }
        case Geographies.SET_SELECTED_GEO_TYPE: {
            const selectedGeoType = action.payload;
            return {
                ...state,
                selectedGeoType,
            };
        }
        case Geographies.SET_GEO_SELECTION: {
            const geoSelection = action.payload;
            return { ...state, geoSelection };
        }
        case Geographies.CLEAR_GEO_SELECTION_BELOW_SUMMARY_LEVEL: {
            const { value, geoType } = action.payload;
            const prevState = Object.assign({}, state.geoSelection);
            prevState[geoType.name] = value;
            const oldKeys = Object.keys(prevState);
            const newKeys = oldKeys.slice(0, oldKeys.indexOf(geoType.name) + 1);
            const newState = {};
            newKeys.forEach((key) => {
                newState[key] = prevState[key];
            });
            if (!value) {
                delete newState[geoType.name];
            }
            return { ...state, geoSelection: newState };
        }
        case Geographies.CLEAR_GEO_ITEMS_BELOW_SUMMARY_LEVEL: {
            const { sumLev } = action.payload;
            const geoItemsSummaryLevels = Object.keys(state.geoItemsBySumLev);
            const currentPosition = geoItemsSummaryLevels.indexOf(sumLev);
            if (currentPosition === -1) {
                return state;
            }
            const summaryLevels = geoItemsSummaryLevels.slice(
                0,
                currentPosition + 1,
            );
            const geoItemsBySumLev = {};
            summaryLevels.forEach((level) => {
                geoItemsBySumLev[level] = state.geoItemsBySumLev[level];
            });
            return {
                ...state,
                geoItemsBySumLev: geoItemsBySumLev,
            };
        }
        case Geographies.CLEAR_SELECTABLE_PARENT_GEO_TYPES: {
            return {
                ...state,
                parentGeoTypes: null,
                geoItemsBySumLev: null,
                geoSelection: {},
                selectedGeoType: undefined,
            };
        }
        case Geographies.CLEAR_SELECTED_GEO_TYPE: {
            return {
                ...state,
                selectedGeoType: undefined,
            };
        }
        case Geographies.FETCH_GEO_TYPES_REQUEST:
            return {
                ...state,
                geoTypesLoading: true,
                geoTypesError: null,
            };
        case Geographies.FETCH_PARENT_GEO_TYPES_REQUEST:
            return {
                ...state,
                parentGeoTypesLoading: true,
                parentGeoTypesError: null,
            };
        case Geographies.FETCH_GEO_TYPES_SUCCESS:
            return {
                ...state,
                geoTypesLoading: false,
                geoTypesError: null,
            };
        case Geographies.FETCH_PARENT_GEO_TYPES_SUCCESS:
            return {
                ...state,
                parentGeoTypesLoading: false,
                parentGeoTypesError: null,
            };
        case Geographies.FETCH_GEO_TYPES_FAILURE: {
            const geoTypesError = action.payload;
            return {
                ...state,
                geoTypesLoading: false,
                geoTypesError,
            };
        }
        case Geographies.FETCH_PARENT_GEO_TYPES_FAILURE:
            const parentGeoTypesError = action.payload;
            return {
                ...state,
                parentGeoTypesLoading: false,
                parentGeoTypesError,
            };
        case Geographies.SET_SELECTED_GEO_FIPSES: {
            const fips = action.payload;
            return {
                ...state,
                selectedGeoFipses: fips,
            };
        }
        case Geographies.REMOVE_FIPS_FROM_SELECTION: {
            const fipsToRemove = action.payload;
            return {
                ...state,
                selectedGeoFipses: state.selectedGeoFipses.filter(
                    (fips) => fips !== fipsToRemove,
                ),
            };
        }
        case SET_SELECTED_SCREEN: {
            const selectedScreen = action.payload;
            return {
                ...state,
                selectedScreen,
            };
        }
        case CLEAR_SELECTED_SCREEN: {
            return {
                ...state,
                selectedScreen: undefined,
                geoSelection: {},
                intermittentFipsSelection: [],
                geoItemsBySumLev: null,
            };
        }
        case Geographies.SET_GEO_ITEMS_BY_FIPS_RESULTS: {
            const { geoSelection, notMatched, warningLabel } = action.payload;
            const geoItemsByFips = {
                geoSelection,
                notMatched,
                warningLabel,
            };
            return { ...state, geoItemsByFips };
        }
        case Geographies.CLEAR_GEO_ITEMS_BY_FIPS_RESULTS: {
            const geoItemsByFips = {
                geoSelection: [],
                notMatched: undefined,
                warningLabel: undefined,
            };
            return { ...state, geoItemsByFips };
        }
        case Geographies.SET_INTERMITTENT_FIPS_SELECTION: {
            const intermittentFipsSelection = action.payload;
            return { ...state, intermittentFipsSelection };
        }
        case Geographies.FETCH_SELECTED_GEOGRAPHIES_REQUEST: {
            return {
                ...state,
                selectedGeographiesLoading: true,
            };
        }
        case Geographies.FETCH_SELECTED_GEOGRAPHIES_SUCCESS: {
            return {
                ...state,
                selectedGeographiesLoading: false,
            };
        }
        case Geographies.FETCH_SELECTED_GEOGRAPHIES_FAILURE: {
            return {
                ...state,
                selectedGeographiesLoading: false,
                selectedGeographiesError: action.payload,
            };
        }
        case Geographies.RESET_VALUES:
            return {
                ...INITIAL_STATE,
            };
        default:
            return state;
    }
};
