import React from 'react';

import Button from '../../../Buttons/Button';
import Icon from '../../../Icon/Icon';

import './ModifySurveyTrigger.scss';

type Props = {
    selectedYears: number[];
    onClick: () => void;
};

const ModifySurveyTrigger = ({ selectedYears, onClick }: Props) => {
    return (
        <Button onClick={onClick} className="modify-survey-trigger">
            <span className="modify-survey-trigger-selected-years">
                {selectedYears.join(', ')}
            </span>
            <span className="modify-survey-trigger-icon">
                <Icon>expand_more</Icon>
            </span>
        </Button>
    );
};

export default ModifySurveyTrigger;
