import React, { useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getSmallAvatarUrl } from '../../selectors/CurrentUser';

import Button from '../Buttons/Button';
import UserActions from './UserActions/UserActions';
import Avatar from './Avatar/Avatar';
import Popover from '../base/Popover/Popover';

import './UserMenu.scss';

const UserMenu = () => {
    const smallAvatarUrl = useSelector(getSmallAvatarUrl);
    const [showPopover, setShowPopover] = useState(false);
    const root = useRef(null);

    const closePopover = useCallback(() => setShowPopover(false), [
        setShowPopover,
    ]);
    const openPopover = useCallback(() => setShowPopover(true), [
        setShowPopover,
    ]);

    return (
        <div ref={root}>
            <Button
                className="user-menu-button"
                onClick={openPopover}
                aria-haspopup={true}
                aria-expanded={showPopover}
            >
                <Avatar avatarUrl={smallAvatarUrl} />
            </Button>
            {showPopover && (
                <Popover
                    onClickAway={closePopover}
                    parent={root.current}
                    className="user-actions-container"
                >
                    <UserActions />
                </Popover>
            )}
        </div>
    );
};

export default UserMenu;
