import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import api from '../helpers/Api';
import BackendPaths from '../helpers/BackendPaths';
import * as GeographiesConstants from '../constants/Geographies';
import { reportAlreadyExists } from '../selectors/WizardHeader';
import * as GeographiesHelper from '../helpers/Geographies';
import {
    GEO_ITEMS_SELECTION,
    SET_SELECTED_SCREEN,
} from '../constants/SelectGeographyScreen';
import * as ReportMetadata from '../selectors/ReportMetadata';
import * as HelperSaga from './Helper';
import * as ReportCreateSaga from './ReportCreate';
import { COUNTER_LOG_REQUEST } from '../constants/Counter';
import * as counterEvents from '../helpers/CounterEvents';

function* fetchGeoTypes({ payload: { surveyCode } }) {
    let response;
    try {
        response = yield call(
            api,
            BackendPaths.getGeoTypes.getPath({
                surveyCode,
                onlyMajorGeographies: false,
            }),
        );
    } catch (error) {
        return yield HelperSaga.handleError(
            GeographiesConstants.FETCH_GEO_TYPES_FAILURE,
            error,
        );
    }
    yield put({
        type: COUNTER_LOG_REQUEST,
        payload: counterEvents.getGeoTypesFetchedEvent(surveyCode),
    });
    const geoTypes = GeographiesHelper.parseGeoTypesResponse(response);

    yield put({
        type: GeographiesConstants.SET_NORMALIZED_GEO_TYPES,
        payload: { geoTypes, surveyCode },
    });
    const geoTypeNames = geoTypes.map((geoType) => geoType.name);
    yield put({
        type: GeographiesConstants.SET_SELECTABLE_GEO_TYPES,
        payload: geoTypeNames,
    });

    yield put({ type: GeographiesConstants.FETCH_GEO_TYPES_SUCCESS });
}

function* fetchParentGeoTypes({ payload: { surveyCode, geoTypeName } }) {
    let parentGeoTypes;
    try {
        const parentGeoTypesResponse = yield call(
            api,
            BackendPaths.getParentGeoTypes.getPath({
                surveyCode,
                geoTypeName,
            }),
        );
        parentGeoTypes = GeographiesHelper.parseGeoTypesResponse(
            parentGeoTypesResponse,
        );
    } catch (error) {
        return yield HelperSaga.handleError(
            GeographiesConstants.FETCH_PARENT_GEO_TYPES_FAILURE,
            error,
        );
    }
    const counterEvent = `${surveyCode}|${geoTypeName}`;
    yield put({
        type: COUNTER_LOG_REQUEST,
        payload: counterEvents.getParentGeoTypesFetchedEvent(counterEvent),
    });

    yield put({
        type: GeographiesConstants.SET_NORMALIZED_GEO_TYPES,
        payload: { geoTypes: parentGeoTypes, surveyCode },
    });
    const firstParent = parentGeoTypes && parentGeoTypes[0];
    if (firstParent) {
        yield put({
            type: GeographiesConstants.FETCH_GEO_ITEMS_REQUEST,
            payload: {
                geoType: firstParent,
                geoTypeSelectionName: firstParent.name,
                geoSelectionFips: '',
            },
        });
    }

    yield put({
        type: GeographiesConstants.SET_SELECTABLE_PARENT_GEO_TYPES,
        payload: parentGeoTypes.map((geoType) => geoType.name),
    });
    yield put({ type: GeographiesConstants.FETCH_PARENT_GEO_TYPES_SUCCESS });
    yield put({ type: SET_SELECTED_SCREEN, payload: GEO_ITEMS_SELECTION });
}

function* fetchGeoItems({
    payload: { geoType, geoTypeSelectionName, geoSelectionFips },
}) {
    let geoItems;
    try {
        const geoItemsResponse = yield call(
            api,
            BackendPaths.getGeoItemsByGeoType.getPath({
                surveyName: geoType.surveyName,
                geoTypeSelectionName: geoTypeSelectionName,
                geoSelectionFips: geoSelectionFips,
            }),
        );
        geoItems = GeographiesHelper.parseGeoItemsResponse(geoItemsResponse);
    } catch (error) {
        return yield HelperSaga.handleError(
            GeographiesConstants.FETCH_GEO_ITEMS_FAILURE,
            error,
        );
    }

    yield put({
        type: GeographiesConstants.SET_SELECTABLE_GEO_ITEMS,
        payload: {
            items: geoItems,
            geoTypeSelectionName: geoType.name,
        },
    });
    yield put({ type: GeographiesConstants.FETCH_GEO_ITEMS_SUCCESS });
}

function* fetchGeoItemsByFipsCodes({
    payload: { fipsCodes, requestedGeoType, surveyName },
}) {
    let response;
    try {
        response = yield call(
            api,
            BackendPaths.getGeoItemsByFipsCodes.getPath(),
            'POST',
            {
                bOnlyMajorGeographies: true,
                sFipsCodes: fipsCodes,
                sRequestedGeoType: requestedGeoType,
                sSurveyName: surveyName,
            },
        );
    } catch (error) {
        return yield HelperSaga.handleError(
            GeographiesConstants.FETCH_GEO_ITEMS_BY_FIPS_FAILURE,
            error,
        );
    }
    const counterEvent = `${surveyName}|${requestedGeoType}`;
    yield put({
        type: COUNTER_LOG_REQUEST,
        payload: counterEvents.getFetchGeoItemsByFipsCodesEvent(counterEvent),
    });
    const [
        geoSelection,
        notMatched,
        warningLabel,
    ] = GeographiesHelper.parseGeoItemsByFipsResponse(response);
    yield put({
        type: GeographiesConstants.SET_GEO_ITEMS_BY_FIPS_RESULTS,
        payload: { geoSelection, notMatched, warningLabel },
    });
    yield put({ type: GeographiesConstants.FETCH_GEO_ITEMS_BY_FIPS_SUCCESS });
}

function* fetchSelectedGeographies() {
    const isExistingReport = yield select(reportAlreadyExists);
    let selectedGeoFipses;
    if (isExistingReport) {
        let responses;
        const definition = yield select(ReportMetadata.getDefinition);
        try {
            responses = yield ReportCreateSaga.fetchReportInfosForDefinition(
                definition,
            );
        } catch (errorResponse) {
            return yield HelperSaga.handleError(
                GeographiesConstants.FETCH_SELECTED_GEOGRAPHIES_FAILURE,
                errorResponse,
            );
        }
        const reportInfos = ReportCreateSaga.getReportInfo(
            definition,
            responses,
        );
        // right now geography edit is enabled only if one report is present
        selectedGeoFipses = Object.values(reportInfos)[0].geoFips;
    } else {
        selectedGeoFipses = [];
    }
    yield put({
        type: GeographiesConstants.SET_SELECTED_GEO_FIPSES,
        payload: selectedGeoFipses,
    });
    yield put({
        type: GeographiesConstants.FETCH_SELECTED_GEOGRAPHIES_SUCCESS,
    });
}

export default function* () {
    yield all([
        yield takeEvery(
            GeographiesConstants.FETCH_GEO_TYPES_REQUEST,
            fetchGeoTypes,
        ),
        yield takeEvery(
            GeographiesConstants.FETCH_PARENT_GEO_TYPES_REQUEST,
            fetchParentGeoTypes,
        ),
        yield takeEvery(
            GeographiesConstants.FETCH_GEO_ITEMS_REQUEST,
            fetchGeoItems,
        ),
        yield takeEvery(
            GeographiesConstants.FETCH_GEO_ITEMS_BY_FIPS_REQUEST,
            fetchGeoItemsByFipsCodes,
        ),
        yield takeEvery(
            GeographiesConstants.FETCH_SELECTED_GEOGRAPHIES_REQUEST,
            fetchSelectedGeographies,
        ),
    ]);
}
