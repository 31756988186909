import { useEffect } from 'react';
import { GtmAttribute } from '../typescript/types';

export const useAddGtmAttributes = (
    ref: React.MutableRefObject<HTMLElement> | React.RefObject<HTMLElement>,
    gtmAttributes?: GtmAttribute[],
) => {
    useEffect(() => {
        if (gtmAttributes) {
            gtmAttributes.forEach(({ name, value }) => {
                ref.current?.setAttribute(name, value);
            });
        }
    }, [ref, gtmAttributes]);
};
