import React from 'react';
import { useIntl } from 'react-intl';

import * as ReportApp from '../../../typescript/types';
import { score } from '../../../helpers/Search';

import SurveysFilterableByTextListItem from './SurveysFilterableByTextListItem';
import FilterableByTextList from '../../base/FilterableByTextList/FilterableByTextList';

type Props = {
    onChange: (
        selectedItem: ReportApp.Survey,
        affectedItems: ReportApp.Survey[],
    ) => void;
    list: ReportApp.Survey[];
};

/**
 * Used by FilterableByTextList to score out surveys
 */
const scoreCallBack = (survey: ReportApp.Survey, lowerCaseTokens: string[]) =>
    score(`${survey.name} ${survey.code}`, lowerCaseTokens);

const SurveysFilterableByTextList = ({ onChange, list }: Props) => {
    const intl = useIntl();

    const placeholder = intl.formatMessage({
        id: 'surveysPage.searchPlaceholder',
    });

    const ariaListDescription = intl.formatMessage({
        id: 'surveysPage.listDescription',
    });

    /**
     * Render method used by FilterableByTextList to display each result element
     */
    const itemRender = (survey: ReportApp.Survey) => (
        <SurveysFilterableByTextListItem key={survey.code}>
            {survey.name}
        </SurveysFilterableByTextListItem>
    );

    return (
        <FilterableByTextList
            list={list}
            scoreCallback={scoreCallBack}
            render={itemRender}
            placeholder={placeholder}
            onChange={onChange}
            description={ariaListDescription}
        />
    );
};

export default SurveysFilterableByTextList;
