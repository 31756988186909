import { DefaultRootState } from 'react-redux';
import * as ReportMetadata from './ReportMetadata';
import * as Survey from './Survey';
import * as Table from './Table';
import * as ReportApp from '../typescript/types';
import { mapTableGuidToPremadeReportTables } from './PremadeReport';
import * as TemplateConstants from '../constants/Templates';
import { filterFalsy } from '../typescript/helper';
import { areSurveysComparable } from './Survey';
import { getSurveyCode, getReportId } from './Selection';

const getTablesFromSelection = (state: DefaultRootState) => {
    const selectedSurveyCode = getSurveyCode(state);
    const intermittentTables = Table.getIntermittentTables(state);
    if (!intermittentTables) {
        return [];
    }

    return intermittentTables.map(({ guidBySurveyCode }) => {
        const tableGuid = guidBySurveyCode[selectedSurveyCode];
        if (tableGuid) {
            return Table.getTableByGuid(state, tableGuid);
        }
        return null;
    });
};

const getReportTables = (state: DefaultRootState) =>
    ReportMetadata.getTablesUsedInReport(state).map((table) => {
        const oldReportId = Object.keys(table.guidByOldReportId)[0];
        const tableGuid = table.guidByOldReportId[oldReportId];
        if (!tableGuid) {
            return null;
        }
        return ReportMetadata.getTableByGuid(state, oldReportId, tableGuid);
    });

const getTablesPreparedForTemplateCreation = (state: DefaultRootState) => {
    let tables;
    if (getReportId(state)) {
        tables = getReportTables(state);
    } else {
        tables = getTablesFromSelection(state);
    }
    return tables
        .filter(filterFalsy)
        .map(({ name, variableCount, datasetAbbreviation }) => ({
            name,
            variableCount,
            datasetAbbreviation,
        }));
};

const getFirstSurveyGroupOfReport = (state: DefaultRootState) => {
    const surveyCodesBySurveyGroup = ReportMetadata.getSurveyCodesInReportBySurveyGroup(
        state,
    );
    const surveyGroupId = +Object.keys(surveyCodesBySurveyGroup)[0];
    return Survey.getSurveyGroupById(state, surveyGroupId);
};

const getSurveyCodeForTemplateCreation = (state: DefaultRootState) => {
    if (getReportId(state)) {
        // get survey code of first found survey from report
        const surveyGroup = getFirstSurveyGroupOfReport(state);
        const surveyCodesBySurveyGroup = ReportMetadata.getSurveyCodesInReportBySurveyGroup(
            state,
        );
        const surveyCodesSet = surveyCodesBySurveyGroup[surveyGroup.id];
        if (!surveyCodesSet?.size) {
            return null;
        }
        const surveyCodes = Array.from(surveyCodesSet);
        return surveyCodes[0];
    }
    return getSurveyCode(state);
};

export const getSaveTemplateModalProperties = (state: DefaultRootState) => {
    const surveyCode = getSurveyCodeForTemplateCreation(state);
    if (!surveyCode) {
        return null;
    }

    const survey = Survey.getSurveyByCode(state, surveyCode);
    const surveysInSurveyGroup = Survey.getSurveysInSurveyGroupBySurveyCode(
        state,
        surveyCode,
    );
    const comparableSurveyYears = surveysInSurveyGroup
        .filter(
            ({ year, comparabilityGroup }) =>
                !!year &&
                comparabilityGroup &&
                comparabilityGroup === survey.comparabilityGroup,
        )
        .map((survey) => survey.year);
    const comparabilityStartYear = Math.min(...comparableSurveyYears);
    const comparabilityEndYear = Math.max(...comparableSurveyYears);
    const surveyGroup = Survey.getSurveyGroupBySurveyCode(state, surveyCode);
    return {
        comparabilityStartYear,
        comparabilityEndYear,
        surveyGroupName: surveyGroup?.name,
    };
};

/**
 * Return bunch of information needed for saving a template (in SaveTemplate component)
 */
export const getSaveTemplatePayload = (state: DefaultRootState) => {
    const surveyCode = getSurveyCodeForTemplateCreation(state);
    if (!surveyCode) {
        return null;
    }

    const survey = Survey.getSurveyByCode(state, surveyCode);
    const surveyGroup = Survey.getSurveyGroupBySurveyCode(state, surveyCode);
    if (!surveyGroup) {
        return null;
    }

    return {
        surveyGroupId: surveyGroup.id,
        surveyCode,
        comparabilityGroup: survey.comparabilityGroup,
        tables: getTablesPreparedForTemplateCreation(state),
    };
};

const getAllTemplates = (state: DefaultRootState) => state.db.template;

const areTemplateTablesEqual = (
    firstTable: ReportApp.TemplateTable,
    secondTable: ReportApp.TemplateTable,
) => {
    return (
        firstTable.name === secondTable.name &&
        firstTable.variableCount === secondTable.variableCount &&
        firstTable.datasetAbbreviation === secondTable.datasetAbbreviation
    );
};

/**
 * Check if there is already a saved template which contains tables present in current report
 * in the same specified order.
 */
export const currentTablesExistInTemplates = (state: DefaultRootState) => {
    const currentTablesInReport = getTablesPreparedForTemplateCreation(state);
    const templates = getAllTemplates(state);

    return templates.some((template: ReportApp.Template) => {
        if (
            !template.tables ||
            template.tables.length !== currentTablesInReport.length
        ) {
            return false;
        }
        return template.tables.every((templateTable, index) => {
            return areTemplateTablesEqual(
                currentTablesInReport[index],
                templateTable,
            );
        });
    });
};

export const getTemplateModalDisplayStatus = (state: DefaultRootState) => {
    const { fetchTemplatesRequest, saveTemplateRequest } = state.templateStatus;

    if (fetchTemplatesRequest.loading || !fetchTemplatesRequest.isLoaded) {
        return TemplateConstants.FetchTableTemplates.FETCH_TEMPLATES_LOADING;
    } else if (fetchTemplatesRequest.error) {
        return TemplateConstants.FetchTableTemplates.FETCH_TEMPLATES_ERROR;
    }

    if (saveTemplateRequest.loading) {
        return TemplateConstants.SaveTableTemplate.SAVE_TEMPLATE_LOADING;
    } else if (saveTemplateRequest.error) {
        return TemplateConstants.SaveTableTemplate.SAVE_TEMPLATE_ERROR;
    }

    if (currentTablesExistInTemplates(state)) {
        return TemplateConstants.SaveTableTemplate.TEMPLATE_EXISTS;
    } else if (fetchTemplatesRequest.isLoaded) {
        return TemplateConstants.SaveTableTemplate.SAVE_TEMPLATE;
    }
};

export const getShouldFetchTemplates = (state: DefaultRootState) =>
    !state.templateStatus.fetchTemplatesRequest.isLoaded;

export const getFetchTablesError = (state: DefaultRootState) =>
    state.templateStatus.fetchTemplatesRequest.error;

export const getSaveTemplatesError = (state: DefaultRootState) =>
    state.templateStatus.saveTemplateRequest.error;

export const findTableGuidUsingTemplateTableProperties = (
    state: DefaultRootState,
    { name, variableCount, datasetAbbreviation }: ReportApp.TemplateTable,
    surveyCode: ReportApp.SurveyCode,
): string | undefined => {
    const tables = Table.getNormalizedTables(state);
    if (!tables) {
        return undefined;
    }
    const matchingTables = Object.values(tables).filter((table) => {
        return (
            !!table &&
            table.variableCount === variableCount &&
            table.name === name &&
            table.surveyCode === surveyCode
        );
    });

    if (matchingTables.length === 1) {
        return matchingTables[0]?.guid;
    }

    // If an edge case occurs, where there is more than one table with same
    // table name and number of variables, (check comment in selectors/ReportMetadata.ts
    // function: `getTablesUsedInReport` for more details) we will pick the right one
    // based on datasetAbbreviation.
    return matchingTables.find((table) => {
        return !!table && table.datasetAbbreviation === datasetAbbreviation;
    })?.guid;
};

/**
 * Get templates of provided survey, and of all surveys from the same surveyGroup with
 * same comparabilityGroup
 */
export const getTemplatesForPremadeReports = (
    state: DefaultRootState,
    selectedSurveyCode: ReportApp.SurveyCode,
    surveyCodes: ReportApp.SurveyCode[],
): ReportApp.PremadeReportWithTables[] => {
    return getAllTemplates(state)
        .filter((template) =>
            areSurveysComparable(
                state,
                selectedSurveyCode,
                template.surveyCode,
            ),
        )
        .map(({ id, title, tables }) => ({
            id,
            name: title,
            tables: tables
                .map((table) => {
                    const tableGuid = findTableGuidUsingTemplateTableProperties(
                        state,
                        table,
                        selectedSurveyCode,
                    );
                    return mapTableGuidToPremadeReportTables(
                        state,
                        tableGuid,
                        surveyCodes,
                    );
                })
                .filter(filterFalsy),
        }));
};

const getTableTemplateStatus = (state: DefaultRootState) =>
    state.selectTablesPageStatus.tableTemplate;

export const getTableTemplateById = (
    state: DefaultRootState,
    tableTemplateId: number | null,
) => getAllTemplates(state).find((template) => template.id === tableTemplateId);

export const getTableTemplateTitleById = (
    state: DefaultRootState,
    tableTemplateId: number | null,
) => getTableTemplateById(state, tableTemplateId)?.title ?? null;

export const getShouldLoadTableTemplateById = (
    state: DefaultRootState,
    tableTemplateId: number | null,
) => {
    if (!tableTemplateId) {
        return false;
    }

    const { loading, error } = getTableTemplateStatus(state);
    return !loading && !error && !getTableTemplateById(state, tableTemplateId);
};

export const getTableTemplateLoadingError = (state: DefaultRootState) =>
    getTableTemplateStatus(state).error;

export const getTableTemplateDisplayStatusById = (
    state: DefaultRootState,
    tableTemplateId: number | null,
) => {
    const { loading: loadingStatus, error } = getTableTemplateStatus(state);
    const tableTemplate = getTableTemplateById(state, tableTemplateId);

    const isTemplateBeingLoaded =
        loadingStatus ||
        getShouldLoadTableTemplateById(state, tableTemplateId) ||
        !Table.getHasIntermittentTables(state);

    if (
        tableTemplate &&
        !loadingStatus &&
        !error &&
        Table.getHasIntermittentTables(state)
    ) {
        return TemplateConstants.FetchTableTemplate.TABLE_TEMPLATE_LOADED;
    } else if (!error && isTemplateBeingLoaded) {
        return TemplateConstants.FetchTableTemplate
            .FETCH_TABLE_TEMPLATE_LOADING;
    }
    return TemplateConstants.FetchTableTemplate.FETCH_TABLE_TEMPLATE_ERROR;
};

/**
 * isCreatingTemplateFromDashboard - the flag which signals that application is in
 * template CREATION mode, triggered from dashboard using URL parameter. This mode
 * has intention to define and save set of tables and finishes before new report is
 * created, returning the user back to SE dashboard
 */
export const getIsCreatingTemplateFromDashboard = (state: DefaultRootState) =>
    state.templateStatus.isCreatingTemplateFromDashboard;

/**
 * isEditingTemplateFromDashboard - the flag which signals that application is in
 * template EDIT mode, triggered from dashboard using URL parameter. This mode starts
 * with existing template which could be modified before generating the report. This
 * indicator determines the render logic in couple of components
 */
export const getIsEditingTemplateFromDashboard = (state: DefaultRootState) =>
    state.templateStatus.isEditingTemplateFromDashboard;

/**
 * Return templateId passed from dashboard as url parameter
 */
export const getTemplateId = (state: DefaultRootState) =>
    state.templateStatus.templateId;
