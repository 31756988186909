import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import * as GeoTypes from '../../selectors/GeoTypes';

import SelectionHint from '../SelectionHint/SelectionHint';

const SelectGeographiesHint = () => {
    const hasSelectedGeographies = useSelector(GeoTypes.hasSelectedGeographies);

    return hasSelectedGeographies ? null : (
        <SelectionHint>
            <p>
                <FormattedMessage id="geographiesPage.selection.hint" />
            </p>
        </SelectionHint>
    );
};

export default SelectGeographiesHint;
