import React from 'react';
import { useIntl } from 'react-intl';
import BackendPaths from '../../helpers/BackendPaths';
import LinkButton from '../LinkButton/LinkButton';

const UpgradePlanActionButton = () => {
    const intl = useIntl();
    const actionUrl = BackendPaths.paidPlan.getPath();
    return (
        <LinkButton
            variant="contained"
            url={actionUrl}
            target="_blank"
            rel="noopener noreferrer"
        >
            {intl.formatMessage({
                id: 'upgradePlanBanner.contactUs',
            })}
        </LinkButton>
    );
};

export default UpgradePlanActionButton;
