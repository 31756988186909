import { useEffect, useCallback } from 'react';
import { focusElementById } from '../helpers/HtmlHelper';

/**
 * Check out https://www.w3.org/TR/wai-aria-practices/examples/tabs/tabs-1/tabs.html for more
 * info on accessible tabs.
 */

export const useTabsKeyboardNavigation = ({
    ref,
    focusedTabId,
    activateTab,
    tabsOrder,
}) => {
    const handleKeydownEvents = useCallback(
        (event) => {
            const order = tabsOrder;
            let idOfTabToFocus;

            switch (event.keyCode) {
                case 38: // up arrow
                case 37: // left arrow
                    idOfTabToFocus = order[order.indexOf(focusedTabId) - 1];
                    break;
                case 40: // down arrow
                case 39: // right arrow
                    idOfTabToFocus = order[order.indexOf(focusedTabId) + 1];
                    break;
                case 35: // end key
                    idOfTabToFocus = order[order.length - 1];
                    break;
                case 36: // home key
                    idOfTabToFocus = order[0];
                    break;
                default:
            }

            if (idOfTabToFocus) {
                if (typeof activateTab === 'function') {
                    activateTab(idOfTabToFocus);
                }
                focusElementById(idOfTabToFocus);
            }
        },
        [activateTab, focusedTabId, tabsOrder],
    );

    useEffect(() => {
        const tabListElement = ref.current;
        tabListElement.addEventListener('keydown', handleKeydownEvents);
        return () =>
            tabListElement.removeEventListener('keydown', handleKeydownEvents);
    });
};
