import React from 'react';
import classnames from 'classnames';

import './Surface.scss';

type Props = {
    level?: '1' | '2' | '3';
    variant?: 'white' | 'gray' | 'orange' | 'green';
} & React.HTMLAttributes<HTMLDivElement>;

const Surface = React.forwardRef<HTMLDivElement, Props>(
    ({ children, className, level, variant = 'white', ...rest }, ref) => {
        if (process.env.NODE_ENV !== 'production') {
            if (level == null) {
                console.error('Please pass correct level to Surface component');
            }
        }
        const classes = classnames(
            `surface-${level}`,
            `surface-${variant}`,
            className,
        );
        return (
            <div {...rest} className={classes} ref={ref}>
                {children}
            </div>
        );
    },
);

export default Surface;
