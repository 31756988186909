import { getReportId } from './Selection';
import { DefaultRootState } from 'react-redux';
import { getGeoTypeSelections, getTables } from './ReportMetadata';

export const getExcelDownloadEventValue = (state: DefaultRootState) => {
    const reportId = getReportId(state);
    return `${reportId}|Excel download`;
};

export const getPdfDownloadEventValue = (state: DefaultRootState) => {
    const reportId = getReportId(state);
    return `${reportId}|Pdf download`;
};

export const getDownloadProgramsEventValue = (state: DefaultRootState) => {
    const reportId = getReportId(state);
    return (programType: string) =>
        `${reportId}|CSV Download|${programType.toUpperCase()}`;
};

export const getReportViewEventValues = (state: DefaultRootState) => {
    const { byOldReportId } = state.reportMetadata;
    return Object.keys(byOldReportId).flatMap((oldReportId) => {
        const { surveyCode } = getTables(state, oldReportId)[0];
        return getGeoTypeSelections(state, oldReportId).map((selection) => {
            return `${oldReportId}|${surveyCode}|${selection.sumLev}`;
        });
    });
};
