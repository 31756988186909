import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import {
    GEO_FIPS_SELECTION,
    GEO_TYPE_SELECTION,
    SET_SELECTED_SCREEN,
} from '../../constants/SelectGeographyScreen';
import { ReportAppDispatch } from '../../typescript/actions/actions';

import ModeSelector from '../base/ModeSelector/ModeSelector';
import Icon from '../Icon/Icon';

import './SelectGeographiesModeSelector.scss';

export const SelectGeographiesModeSelector = () => {
    const dispatch = useDispatch<ReportAppDispatch>();
    return (
        <ModeSelector
            actions={[
                {
                    className: 'geo-types',
                    autoFocus: true,
                    role: 'search',
                    onClick: () => {
                        dispatch({
                            type: SET_SELECTED_SCREEN,
                            payload: GEO_TYPE_SELECTION,
                        });
                    },
                    content: (
                        <>
                            <Icon>add</Icon>
                            <span>
                                <FormattedMessage id="geographiesPage.modeSelector.byType" />
                            </span>
                        </>
                    ),
                },
                {
                    className: 'fips-codes',
                    role: 'search',
                    onClick: () => {
                        dispatch({
                            type: SET_SELECTED_SCREEN,
                            payload: GEO_FIPS_SELECTION,
                        });
                    },
                    content: (
                        <>
                            <Icon>playlist_add</Icon>
                            <span>
                                <FormattedMessage id="geographiesPage.modeSelector.byFIPS" />
                            </span>
                        </>
                    ),
                },
            ]}
        />
    );
};
