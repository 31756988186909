import React from 'react';
import { FormattedMessage } from 'react-intl';
import BackendPaths from '../../helpers/BackendPaths';
import LinkButton from '../LinkButton/LinkButton';

const HelpMenuItems = () => {
    const helpUrl = BackendPaths.helpUrl.getPath();
    return (
        <>
            <ul className="menu-list">
                <li className="menu-list-item">
                    <LinkButton
                        variant="flat"
                        className="menu-list-item-button"
                        url={`${helpUrl}/using-reports/reports-getting-started/getting-started-with-reports`}
                        target="_blank"
                    >
                        <FormattedMessage id="header.help.introduction" />
                    </LinkButton>
                </li>
                <li className="menu-list-item">
                    <LinkButton
                        variant="flat"
                        className="menu-list-item-button"
                        url={`${helpUrl}/guides-videos/downloading-and-exporting-data-guides`}
                        target="_blank"
                    >
                        <FormattedMessage id="header.help.downloading" />
                    </LinkButton>
                </li>
                <li className="menu-list-item">
                    <LinkButton
                        variant="flat"
                        className="menu-list-item-button"
                        url={`${helpUrl}/guides-videos`}
                        target="_blank"
                    >
                        <FormattedMessage id="header.help.guides" />
                    </LinkButton>
                </li>
            </ul>
            <hr />
            <ul className="menu-list">
                <li className="menu-list-item">
                    <LinkButton
                        variant="flat"
                        className="menu-list-item-button"
                        url={helpUrl}
                        target="_blank"
                    >
                        <FormattedMessage id="header.help.helpCenter" />
                    </LinkButton>
                </li>
            </ul>
        </>
    );
};

export default HelpMenuItems;
