import React from 'react';
import { useIntl } from 'react-intl';

import { score } from '../../../helpers/Search';
import * as ReportApp from '../../../typescript/types';

import FilterableByTextList from '../../base/FilterableByTextList/FilterableByTextList';
import FipsCodesDropdownFilterableByTextListItem from './FipsCodesDropdownFilterableByTextListItem';

type Props = {
    list: ReportApp.GeoType[];
    onChange: (item: ReportApp.GeoType) => void;
};

/**
 * Used by FilterableByTextList to score out surveys
 */
const scoreCallback = (geoType: ReportApp.GeoType, lowerCaseTokens: string[]) =>
    score(`${geoType.sumLev} ${geoType.label}`, lowerCaseTokens);

const itemRenderer = (geoType: ReportApp.GeoType) => (
    <FipsCodesDropdownFilterableByTextListItem
        key={geoType.sumLev}
        geoType={geoType}
    />
);

const FipsCodesDropdownFilterableByTextList = ({ list, onChange }: Props) => {
    const intl = useIntl();

    const placeholder = intl.formatMessage({
        id: 'geographiesPage.fipsSelection.searchPlaceholder',
    });
    const ariaListDescription = intl.formatMessage({
        id: 'geographiesPage.fipsSelection.listDescription',
    });

    return (
        <FilterableByTextList
            list={list}
            scoreCallback={scoreCallback}
            render={itemRenderer}
            resultsClassName="fips-codes-geo-types-list"
            placeholder={placeholder}
            showSearchIcon={false}
            onChange={onChange}
            description={ariaListDescription}
        />
    );
};

export default FipsCodesDropdownFilterableByTextList;
