import React from 'react';

import Icon from '../../../Icon/Icon';
import Link from '../../../base/Link/Link';

import './ModifyOptionLink.scss';

type Props = {
    link: string;
    title: string;
    highlightIcon: string;
    editIcon?: string;
    hint: string;
    description: string;
};

const ModifyOptionLink = ({
    link,
    title,
    highlightIcon,
    editIcon = 'edit',
    hint,
    description,
}: Props) => {
    return (
        <Link className="modify-option" url={link} title={hint}>
            <Icon className="highlight-icon small">{highlightIcon}</Icon>
            <div className="modify-option-text">
                <h5>{title}</h5>
                <div className="modify-option-text-description">
                    {description}
                </div>
            </div>
            <Icon className="edit-icon small">{editIcon}</Icon>
        </Link>
    );
};

export default ModifyOptionLink;
