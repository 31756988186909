import React from 'react';
import { useIntl } from 'react-intl';

import Icon from '../Icon/Icon';
import JumpButton from '../JumpButton/JumpButton';

import DOMElementID from '../../constants/DOMElementID';
import * as SidebarConstants from '../../constants/Sidebar';

type Props = {
    activatedTabId: string;
};

const SidebarJumpButtons = ({ activatedTabId }: Props) => {
    const intl = useIntl();

    const jumpButtonsTabIndex =
        activatedTabId === SidebarConstants.EMPTY ? 0 : -1;

    return (
        <>
            <JumpButton
                key="skip2reportContent"
                variant="icon"
                tabIndex={jumpButtonsTabIndex}
                targetElementID={DOMElementID.CONTENT_TABLE_WRAPPER}
                title={intl.formatMessage({
                    id: 'layoutNavigation.skip2reportContent',
                })}
                aria-label={intl.formatMessage({
                    id: 'layoutNavigation.skip2reportContent',
                })}
            >
                <Icon>toc</Icon>
            </JumpButton>
            <JumpButton
                key="skip2pagination"
                variant="icon"
                tabIndex={jumpButtonsTabIndex}
                targetElementID={DOMElementID.FOOTER_PAGINATION_CONTAINER}
                title={intl.formatMessage({
                    id: 'layoutNavigation.skip2pagination',
                })}
                aria-label={intl.formatMessage({
                    id: 'layoutNavigation.skip2pagination',
                })}
            >
                <Icon>more_horiz</Icon>
            </JumpButton>
            <JumpButton
                key="back2header"
                variant="icon"
                tabIndex={jumpButtonsTabIndex}
                targetElementID={DOMElementID.REPORT_HEADER}
                title={intl.formatMessage({
                    id: 'layoutNavigation.back2header',
                })}
                aria-label={intl.formatMessage({
                    id: 'layoutNavigation.back2header',
                })}
            >
                <Icon>save</Icon>
            </JumpButton>
        </>
    );
};

export default SidebarJumpButtons;
