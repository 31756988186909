import { all, call, put, select } from 'redux-saga/effects';

import api from '../helpers/Api';
import BackendPaths from '../helpers/BackendPaths';
import { getOldReportIdsFromDefinition } from '../helpers/Report';
import * as TablesHelper from '../helpers/Tables';
import * as GeographiesHelper from '../helpers/Geographies';
import * as ReportHelper from '../helpers/Report';
import { ApiError } from '../helpers/Error';
import * as ReportConstants from '../constants/Report';

import getDefinition from './ReportDefinitionRetrieval';
import * as ReportApp from '../typescript/types';
import { filterFalsy } from '../typescript/helper';
import { ReportAppActions } from '../typescript/actions/actions';
import {
    getGeoGroupOfOldReportId,
    getShouldAllowAggregation,
} from '../selectors/ReportMetadata';
import { getAggregationType } from '../selectors/Report';

function* getOldReportIdAggregationTypeFromDefinition(
    definition: ReportApp.Definition,
    selectedAggregationType: number,
) {
    const shouldAllowAggregationSelection: boolean = yield select(
        getShouldAllowAggregation,
    );
    const requestParams: ReportApp.AggregationTypeForOldReport[] = definition.geoGroups.flatMap(
        (geoGroup) => {
            const { aggregationType: aggregationTypeOfGeoGroup } = geoGroup;
            return geoGroup.columnGroups.flatMap((columnGroup) =>
                columnGroup.tables.map((oldReportId) => ({
                    oldReportId,
                    aggregationType: shouldAllowAggregationSelection
                        ? selectedAggregationType
                        : aggregationTypeOfGeoGroup,
                })),
            );
        },
    );
    return requestParams;
}

export function* fetchDefinition(reportId: ReportApp.OldReportId) {
    // this will be part of metadata call, but now we handle it on frontend
    const definition = getDefinition(reportId);
    if (definition) {
        // we are working with custom reports or old reports
        const oldReportId = definition.geoGroups[0].columnGroups[0].tables[0];
        const response = yield call(
            api,
            BackendPaths.getReportMetadata.getPath({ reportId: oldReportId }),
        );
        return {
            isSaved: response.reportTitle != null,
            isOwnedByCurrentUser: response.isUserSavedReport,
            reportTitle: response.reportTitle,
            reportDescription: response.reportDescription,
            reportDefinition: definition,
        };
    }
    return yield call(
        api,
        BackendPaths.getReportMetadataV2.getPath({ reportId }),
    );
}

export function* storeDefinition(
    response: ReportApp.OmniWebService.MetadataResponse,
) {
    yield put<ReportAppActions>({
        type: ReportConstants.SET_SAVED_REPORT_INFO,
        payload: {
            isSaved: response.isSaved,
            isOwnedByCurrentUser: response.isOwnedByCurrentUser,
            reportTitle: response.reportTitle,
            reportDescription: response.reportDescription,
        },
    });

    yield put<ReportAppActions>({
        type: ReportConstants.SET_DEFINITION,
        payload: response.reportDefinition,
    });
}

export function* fetchMetadata(definition: ReportApp.Definition) {
    const oldReportIds = getOldReportIdsFromDefinition(definition);
    const calls = oldReportIds.flatMap((oldReportId) => [
        call(api, BackendPaths.allowDollarAdjustment.getPath({ oldReportId })),
        call(api, BackendPaths.getGeoTypeSelections.getPath({ oldReportId })),
        call(
            api,
            BackendPaths.getTablesByReportId.getPath({
                oldReportId,
                includeVariables: true,
            }),
        ),
    ]);
    // Group them in an array of 3 responses to fit
    // ReportApp.FetchMetadataResponse definition
    const responses: Object[] = yield all(calls);
    return responses.reduce((memo: Object[][], value: Object) => {
        let last = memo[memo.length - 1];
        if (!last || last.length === 3) {
            last = [];
            memo.push(last);
        }
        last.push(value);
        return memo;
    }, []);
}

export function* fetchTotalNumberOfGeographies(
    definition: ReportApp.Definition,
    selectedAggregationType: number,
) {
    let oldReportIdAndAggregationTypes: ReportApp.AggregationTypeForOldReport[];
    oldReportIdAndAggregationTypes = yield getOldReportIdAggregationTypeFromDefinition(
        definition,
        selectedAggregationType,
    );

    const calls = oldReportIdAndAggregationTypes.map(
        ({ oldReportId, aggregationType }) =>
            call(
                api,
                BackendPaths.getGeoSelectionsCount.getPath({
                    oldReportId,
                    aggregationType,
                }),
            ),
    );
    return yield all(calls);
}

export function* storeTotalNumberOfGeographies(
    definition: ReportApp.Definition,
    totalNumberOfGeographiesResponses: ReportApp.AspNetWebService.TotalNumberOfGeographiesResults[],
    selectedAggregationType: number,
) {
    let oldReportIdAndAggregationTypes: ReportApp.AggregationTypeForOldReport[];
    oldReportIdAndAggregationTypes = yield getOldReportIdAggregationTypeFromDefinition(
        definition,
        selectedAggregationType,
    );
    const puts = oldReportIdAndAggregationTypes.map(({ oldReportId }, index) =>
        put<ReportAppActions>({
            type: ReportConstants.SET_TOTAL_NUMBER_OF_GEOGRAPHIES,
            payload: {
                totalNumberOfGeographies:
                    totalNumberOfGeographiesResponses[index].d,
                oldReportId,
            },
        }),
    );
    yield all(puts);
}

export function* fetchFipsCodes(definition: ReportApp.Definition) {
    const oldReportIds = getOldReportIdsFromDefinition(definition);
    const shouldAllowAggregationSelection: boolean = yield select(
        getShouldAllowAggregation,
    );

    const selectedAggregation: number = yield select(getAggregationType);
    const geoGroupsAggregationTypes: number[] = [];
    if (!shouldAllowAggregationSelection) {
        for (let i = 0; i < oldReportIds.length; i += 1) {
            const geoGroup:
                | ReportApp.DefinitionGeoGroup
                | undefined = yield select(
                getGeoGroupOfOldReportId,
                oldReportIds[i],
            );
            if (!geoGroup) {
                throw new Error(`Can't fetch geo group for ${oldReportIds[i]}`);
            }
            geoGroupsAggregationTypes.push(geoGroup.aggregationType);
        }
    }

    const calls = oldReportIds.flatMap((oldReportId, index) =>
        call(
            api,
            BackendPaths.getFipsCodesOfReport.getPath({
                oldReportId,
                aggregationType: shouldAllowAggregationSelection
                    ? selectedAggregation
                    : geoGroupsAggregationTypes[index],
            }),
            'GET-CACHE',
        ),
    );
    const responses: ReportApp.AspNetWebService.FipsCodeAndSumLevResults[] = yield all(
        calls,
    );
    // Here we match oldReportId to raw fips codes which belong to each oldReportId,
    // since we will need that info later on in `storeFipsCode`
    return oldReportIds.map((oldReportId, index) => ({
        oldReportId,
        fipsCodesRaw: responses[index].d,
    }));
}

export function* storeFipsCodes(
    responses: {
        oldReportId: ReportApp.OldReportId;
        fipsCodesRaw: ReportApp.AspNetWebService.FipsCodeAndSumLev[];
    }[],
) {
    for (let { fipsCodesRaw, oldReportId } of responses) {
        const geoGroup: ReportApp.DefinitionGeoGroup | undefined = yield select(
            getGeoGroupOfOldReportId,
            oldReportId,
        );
        if (!geoGroup) {
            throw new Error(`Can't find geo Group for report: ${oldReportId}`);
        }

        const fipsCodes = GeographiesHelper.prepareFipsCodes(
            fipsCodesRaw,
            geoGroup.aggregationType,
        );
        yield put<ReportAppActions>({
            type: ReportConstants.SET_FIPS_CODES_FOR_OLD_REPORT_ID,
            payload: {
                oldReportId,
                fipsCodes,
            },
        });
    }
}

export function* storeMetadata(
    definition: ReportApp.Definition,
    metadataResponses: ReportApp.FetchMetadataResponse,
) {
    const metadata: ReportApp.OldReportMetadataByOldReportId = {};
    const oldReportIds = ReportHelper.getOldReportIdsFromDefinition(definition);
    for (const oldReportId of oldReportIds) {
        const response = metadataResponses.shift();
        if (response == null) {
            throw new Error('No response for report');
        }
        const [
            allowDollarAdjustmentResponse,
            geoTypesSelectionResponse,
            tablesByReportIdResponse,
        ] = response;

        let tablesByGuid: ReportApp.TableByGuid = {};
        let tableGuids: ReportApp.TableGuid[] = [];
        tablesByReportIdResponse.d.forEach((table) => {
            tablesByGuid[table.GUID] = TablesHelper.formatTable(table);
            tableGuids.push(table.GUID);
        });

        // now, let's see if we need to fetch tables for output columns
        const outputColumnTableGuids: ReportApp.TableGuid[] = [];
        let surveyCode;
        Object.values(tablesByGuid)
            .filter(filterFalsy)
            .forEach((table) => {
                surveyCode = table.surveyCode;
                table.outputColumns.forEach((column) => {
                    if (column.tableGuid !== table.guid) {
                        outputColumnTableGuids.push(column.tableGuid);
                    }
                });
            });
        if (outputColumnTableGuids.length) {
            let responseTablesByGuids: ReportApp.AspNetWebService.TablesResults;
            try {
                responseTablesByGuids = yield call(
                    api,
                    BackendPaths.getTablesByGuids.getPath(),
                    'POST',
                    {
                        sTableGuids: outputColumnTableGuids.join('|'),
                        sSurveyName: surveyCode,
                        bIncludeVariables: true,
                    },
                );
            } catch (errorResponse) {
                throw new ApiError(errorResponse);
            }
            responseTablesByGuids.d.forEach((table) => {
                tablesByGuid[table.GUID] = TablesHelper.formatTable(table);
            });
        }

        metadata[oldReportId] = {
            availableDollarAdjustmentYears: allowDollarAdjustmentResponse.d,
            tables: tablesByGuid,
            tableGuids,
            geoTypeSelections: GeographiesHelper.parseGeoTypeSelections(
                geoTypesSelectionResponse,
            ),
            totalNumberOfGeographies: null,
            fipsCodes: null,
        };
    }

    yield put<ReportAppActions>({
        type: ReportConstants.SET_METADATA_BY_OLD_REPORT_ID,
        payload: metadata,
    });
}
