import { DefaultRootState } from 'react-redux';

import * as ReportMetadata from './ReportMetadata';
import * as Selection from './Selection';
import { getSurveyByCode, getSurveyNameByCode } from './Survey';

import AppScreen from '../constants/AppScreen';
import * as GtmAttributes from '../constants/GtmAttributes';
import * as Header from '../constants/Header';
import { getNewYears } from './NewSurvey';

import { intl } from '../helpers/CreateIntl';
import ApplicationPaths from '../helpers/ApplicationPaths';
import * as Templates from './Templates';

export const getProgressBarWidth = (
    state: DefaultRootState,
    currentAppScreen: AppScreen,
) => {
    switch (currentAppScreen) {
        case AppScreen.SURVEY_SELECTOR:
            return Header.ONE_THIRD_PROGRESS;
        case AppScreen.GEOGRAPHY_SELECTOR:
            if (Selection.hasTablesSelected(state)) {
                return Header.THREE_THIRDS_PROGRESS;
            } else {
                return Header.TWO_THIRDS_PROGRESS;
            }
        case AppScreen.TABLE_SELECTOR:
            return Header.THREE_THIRDS_PROGRESS;
    }
};

export const reportAlreadyExists = (state: DefaultRootState) =>
    ReportMetadata.getDefinition(state) != null;

export const getHeaderSubtitle = (
    state: DefaultRootState,
    currentAppScreen: AppScreen,
) => {
    if (reportAlreadyExists(state)) {
        return null;
    }

    switch (currentAppScreen) {
        case AppScreen.SURVEY_SELECTOR:
            return intl.formatMessage({ id: 'header.subtitle.survey' });
        case AppScreen.GEOGRAPHY_SELECTOR:
            if (Selection.hasTablesSelected(state)) {
                return intl.formatMessage({
                    id: 'header.subtitle.geography.hasTables',
                });
            } else {
                return intl.formatMessage({
                    id: 'header.subtitle.geography.noTables',
                });
            }
        case AppScreen.TABLE_SELECTOR:
            return intl.formatMessage({ id: 'header.subtitle.table' });
    }
};

export const getHeaderTitle = (
    state: DefaultRootState,
    currentAppScreen: AppScreen,
) => {
    if (reportAlreadyExists(state)) {
        switch (currentAppScreen) {
            case AppScreen.SURVEY_SELECTOR:
                return intl.formatMessage({
                    id: 'header.title.reportExists.survey',
                });
            case AppScreen.GEOGRAPHY_SELECTOR:
                return intl.formatMessage({
                    id: 'header.title.reportExists.geography',
                });
            case AppScreen.TABLE_SELECTOR:
                return intl.formatMessage({
                    id: 'header.title.reportExists.table',
                });
        }
    } else {
        const selectedSurvey = getSurveyByCode(
            state,
            state.selection.surveyCode,
        );
        if (
            currentAppScreen === AppScreen.GEOGRAPHY_SELECTOR &&
            selectedSurvey
        ) {
            return intl.formatMessage(
                { id: 'header.title.noReport.withName' },
                { name: selectedSurvey.name },
            );
        } else if (Templates.getIsCreatingTemplateFromDashboard(state)) {
            return intl.formatMessage({
                id: 'header.title.noReport.templateReport',
            });
        } else {
            return intl.formatMessage({
                id: 'header.title.noReport.noName',
            });
        }
    }
};

export const getForwardButtonOptions = (
    state: DefaultRootState,
    currentAppScreen: AppScreen,
) => {
    let text, link, gtmAttributes;
    const reportExists = reportAlreadyExists(state);
    const surveyCode = Selection.getSurveyCode(state);

    switch (currentAppScreen) {
        case AppScreen.SURVEY_SELECTOR:
            if (reportExists) {
                const surveyName = ReportMetadata.getNameOfFirstSurveyUsedInReport(
                    state,
                );
                const years = getNewYears(state) || [];
                gtmAttributes = GtmAttributes.getModifyReportButtonGtmAttributes(
                    surveyName,
                    years,
                );
                link = ApplicationPaths.newReport.path;
                text = intl.formatMessage({ id: 'confirm' });
            } else {
                // You can't click on forward button when you create report
                // Also, URL is constructed at the moment user selects
                // survey (https://github.com/SocialExplorer/omni/blob/9cfc2e14d7a74840c2ab10fc97a2d059c290949e/frontend-neo/report/src/components/Surveys/SelectSurvey.js#L25)
                // So, those are the two reasons why link is set to null here
                link = null;
                text = intl.formatMessage({ id: 'next' });
            }
            break;
        case AppScreen.GEOGRAPHY_SELECTOR:
            if (Templates.getIsEditingTemplateFromDashboard(state)) {
                link = ApplicationPaths.newReport.path;
                text = intl.formatMessage({
                    id: 'header.actions.createReport',
                });
            } else if (
                reportExists ||
                (Selection.hasSurveyCodeSelected(state) &&
                    Selection.hasTablesSelected(state))
            ) {
                const surveyName = ReportMetadata.getNameOfFirstSurveyUsedInReport(
                    state,
                );
                const years = ReportMetadata.getSurveyYearsUsedInReport(state);

                gtmAttributes = GtmAttributes.getModifyReportButtonGtmAttributes(
                    surveyName,
                    years,
                );
                link = ApplicationPaths.newReport.path;
                text = intl.formatMessage({ id: 'done' });
            } else {
                link = ApplicationPaths.tables.getPath({
                    surveyCodes: surveyCode,
                });
                text = intl.formatMessage({ id: 'next' });
                gtmAttributes = GtmAttributes.getProceedToTablesButtonGtmAttributes(
                    surveyCode,
                );
            }
            break;
        case AppScreen.TABLE_SELECTOR:
            link = ApplicationPaths.newReport.path;
            if (reportAlreadyExists(state)) {
                const surveyName = ReportMetadata.getNameOfFirstSurveyUsedInReport(
                    state,
                );
                const years = ReportMetadata.getSurveyYearsUsedInReport(state);

                gtmAttributes = GtmAttributes.getModifyReportButtonGtmAttributes(
                    surveyName,
                    years,
                );
                text = intl.formatMessage({ id: 'done' });
            } else if (Templates.getIsCreatingTemplateFromDashboard(state)) {
                text = intl.formatMessage({
                    id: 'tablesPage.header.saveTemplate',
                });
                link = null;
            } else if (
                Templates.getTemplateId(state) &&
                !Templates.getIsEditingTemplateFromDashboard(state)
            ) {
                text = intl.formatMessage({ id: 'next' });
                link = ApplicationPaths.geographies.getPath({
                    surveyCode,
                });
            } else {
                text = intl.formatMessage({
                    id: 'header.actions.createReport',
                });
                const surveyName = getSurveyNameByCode(state, surveyCode);
                gtmAttributes = GtmAttributes.getCreateReportButtonGtmAttributes(
                    surveyName,
                );
            }
            break;
        default:
            link = ApplicationPaths.root.path;
    }

    return {
        text,
        link,
        gtmAttributes,
    };
};

const getBackwardsOptionsForExistingReport = (state: DefaultRootState) => ({
    text: intl.formatMessage({ id: 'cancel' }),
    link: ApplicationPaths.existingReport.getPath({
        reportId: Selection.getReportId(state),
    }),
    shouldDisplay: true,
});

const getBackwardsOptionsForReportCreation = (
    state: DefaultRootState,
    currentAppScreen: AppScreen,
) => {
    const templateId = Templates.getTemplateId(state);
    let link, shouldDisplay;
    const surveyCode = Selection.getSurveyCode(state);
    switch (currentAppScreen) {
        case AppScreen.TABLE_SELECTOR:
            if (Templates.getIsCreatingTemplateFromDashboard(state)) {
                link = ApplicationPaths.root.path;
                shouldDisplay = false;
            } else if (templateId) {
                link = ApplicationPaths.geographies.getPath({ surveyCode });
                shouldDisplay = false;
            } else {
                link = ApplicationPaths.geographies.getPath({ surveyCode });
                shouldDisplay = true;
            }
            return {
                link,
                shouldDisplay,
                text: intl.formatMessage({ id: 'back' }),
            };
        case AppScreen.GEOGRAPHY_SELECTOR:
            if (templateId) {
                const surveyCode = Selection.getSurveyCode(state);
                link = ApplicationPaths.tables.getPath({
                    surveyCodes: surveyCode,
                });
            } else {
                link = ApplicationPaths.surveys.path;
            }
            return {
                link,
                shouldDisplay: true,
                text: intl.formatMessage({ id: 'back' }),
            };
        case AppScreen.SURVEY_SELECTOR:
        default:
            return {
                text: '',
                link: ApplicationPaths.root.path,
                shouldDisplay: false,
            };
    }
};

export const getBackwardButtonOptions = (
    state: DefaultRootState,
    currentAppScreen: AppScreen,
) => {
    const reportExists = reportAlreadyExists(state);
    if (reportExists) {
        return getBackwardsOptionsForExistingReport(state);
    }
    return getBackwardsOptionsForReportCreation(state, currentAppScreen);
};

export const getIsWizardInModifyMode = reportAlreadyExists;
