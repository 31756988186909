import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import * as ReportConstants from '../../../constants/Report';
import { getCsvDownloadOptions } from '../../../selectors/Report';
import { ReportAppDispatch } from '../../../typescript/actions/actions';
import { CsvDownloadOptions } from '../../../typescript/types';

import LabeledCheckboxesList from '../../base/LabeledCheckbox/LabeledCheckboxesList';

const DownloadCsvOptions = () => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const {
        tabSeparated,
        outputLabels,
        outputAllGeoIds,
        percents,
        dbfFriendlyNames,
    } = useSelector(getCsvDownloadOptions);
    const dispatchChange = (label: keyof CsvDownloadOptions) => {
        dispatch({
            type: ReportConstants.TOGGLE_DOWNLOAD_CSV_OPTION,
            payload: label,
        });
    };
    const intl = useIntl();

    const options = [
        {
            id: 'outputLabels',
            label: intl.formatMessage({
                id: 'report.sidebar.downloadCsv.options.outputLabels',
            }),
            checked: outputLabels,
            onChange: () => dispatchChange('outputLabels'),
        },
        {
            id: 'outputAllGeoIds',
            label: intl.formatMessage({
                id: 'report.sidebar.downloadCsv.options.outputAllGeoIds',
            }),
            checked: outputAllGeoIds,
            onChange: () => dispatchChange('outputAllGeoIds'),
        },
        {
            id: 'percents',
            label: intl.formatMessage({
                id: 'report.sidebar.downloadCsv.options.percents',
            }),
            checked: percents,
            onChange: () => dispatchChange('percents'),
        },
        {
            id: 'dbfFriendlyNames',
            label: intl.formatMessage({
                id: 'report.sidebar.downloadCsv.options.dbfFriendlyNames',
            }),
            checked: dbfFriendlyNames,
            onChange: () => dispatchChange('dbfFriendlyNames'),
        },
        {
            id: 'tabSeparated',
            label: intl.formatMessage({
                id: 'report.sidebar.downloadCsv.options.tabSeparated',
            }),
            checked: tabSeparated,
            onChange: () => dispatchChange('tabSeparated'),
        },
    ];
    return <LabeledCheckboxesList options={options} />;
};

export default DownloadCsvOptions;
