export const SET_NORMALIZED_SURVEY_GROUPS =
    '@@survey/SET_NORMALIZED_SURVEY_GROUPS';
export const SET_NORMALIZED_SURVEYS = '@@survey/SET_NORMALIZED_SURVEYS';

export const SET_SELECTABLE_SURVEY_GROUPS =
    '@@survey/SET_SELECTABLE_SURVEY_GROUPS';
export const SET_FOUND_GEOGRAPHIES = '@@survey/SET_FOUND_GEOGRAPHIES';
export const SET_REPORT_INFO = '@@survey/SET_REPORT_INFO';

export const FETCH_SURVEYS_REQUEST = '@@survey/FETCH_SURVEYS_REQUEST';
export const FETCH_SURVEYS_SUCCESS = '@@survey/FETCH_SURVEYS_SUCCESS';
export const FETCH_SURVEYS_FAILURE = '@@survey/FETCH_SURVEYS_FAILURE';

export const SELECT_SURVEY = '@@survey/SELECT_SURVEY';
export const CLEAR_NEW_SURVEY_CHANGE = '@@survey/CLEAR_NEW_SURVEY_CHANGE';

export const ANALYSE_CHANGE_REQUEST = '@@survey/ANALYSE_CHANGE_REQUEST';
export const ANALYSE_CHANGE_SUCCESS = '@@survey/ANALYSE_CHANGE_SUCCESS';
export const ANALYSE_CHANGE_ERROR = '@@survey/ANALYSE_CHANGE_ERROR';
