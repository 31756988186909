import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getIsCreatingTemplateFromDashboard } from '../selectors/Templates';
import { ReportAppDispatch } from '../typescript/actions/actions';
import { SaveTableTemplate } from '../constants/Templates';
import { CREATE_TEMPLATE_PARAM_NAME } from '../constants/Table';
import { useHistory } from 'react-router-dom';
import ApplicationPaths from '../helpers/ApplicationPaths';

const isCreateTemplateDefinedInUrlParams = (locationSearch: string) => {
    const createTemplateUrlParam = new URLSearchParams(locationSearch).get(
        CREATE_TEMPLATE_PARAM_NAME,
    );
    return createTemplateUrlParam === 'true';
};

export const useCreateTemplate = () => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const history = useHistory();
    const location = useLocation();
    const isCreateTemplate = isCreateTemplateDefinedInUrlParams(
        location.search,
    );
    const isCreateTemplateAlreadyInStore = useSelector(
        getIsCreatingTemplateFromDashboard,
    );

    useEffect(() => {
        if (isCreateTemplate && !isCreateTemplateAlreadyInStore) {
            dispatch({
                type: SaveTableTemplate.SET_IS_CREATING_TEMPLATE_FROM_DASHBOARD,
                payload: true,
            });
            // remove query params from URL.
            history.replace(ApplicationPaths.root.getPath());
        }
    }, [dispatch, isCreateTemplateAlreadyInStore, isCreateTemplate, history]);
};
