import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { getHasIntermittentTables } from '../../selectors/Table';

import SelectTablesPageModeSelector from './SelectTablesPageModeSelector';
import SelectedTables from './SelectedTables';
import SelectTablesHint from './SelectTablesHint';
import SkipToHeaderJumpButtons from '../JumpButton/SkipToHeaderJumpButtons';

import { SurveyCode } from '../../typescript/types';

type Props = {
    setModalContentStatus: (status: string) => void;
    surveyCodes: SurveyCode[];
};

const SelectTables = ({ setModalContentStatus, surveyCodes }: Props) => {
    const hasIntermittentTablesSelection = useSelector(
        getHasIntermittentTables,
    );
    const jumpButtons = (
        <SkipToHeaderJumpButtons
            shouldRenderNextButton={hasIntermittentTablesSelection}
        />
    );
    return (
        <>
            <h1>
                <FormattedMessage id="tablesPage.mainHeader" />
            </h1>
            <SelectTablesPageModeSelector
                setModalContentStatus={setModalContentStatus}
                surveyCodes={surveyCodes}
            />
            {hasIntermittentTablesSelection ? jumpButtons : null}
            <SelectedTables surveyCodes={surveyCodes} />
            <SelectTablesHint />
        </>
    );
};

export default SelectTables;
