import React from 'react';
import { useSelector } from 'react-redux';

import * as ReportMetadata from '../../../../selectors/ReportMetadata';
import { truncateLongString } from '../../../../helpers/ValueFormatter';

type Props = {
    leftOffset: number;
    topOffset: number;
};

const TableGeoHeader = React.forwardRef<HTMLTableHeaderCellElement, Props>(
    ({ leftOffset, topOffset }, ref) => {
        const paginatedGeoHeader = useSelector(
            ReportMetadata.getPaginatedGeographiesWithNames,
        );
        const shouldDisplayGeoHeader = useSelector(
            ReportMetadata.shouldDisplayGeoNames,
        );
        if (!shouldDisplayGeoHeader) {
            return null;
        }
        return (
            <tr>
                <th ref={ref} style={{ top: topOffset }} />
                {paginatedGeoHeader.map(({ geoName, colSpan }, geoIndex) => (
                    <th
                        colSpan={colSpan}
                        key={geoIndex}
                        scope="col"
                        style={{ top: topOffset }}
                    >
                        <span
                            className="table-header"
                            style={{ left: leftOffset }}
                            title={geoName}
                            aria-label={geoName}
                        >
                            {truncateLongString(geoName)}
                        </span>
                    </th>
                ))}
            </tr>
        );
    },
);

export default TableGeoHeader;
