import React from 'react';
import { FormattedMessage } from 'react-intl';

import Toggle from './Toggle';

const ToggleSurveysGroup = ({ showToggle, isExpanded, onToggle }) => {
    if (!showToggle) {
        return null;
    }

    return isExpanded ? (
        <Toggle onClick={() => onToggle(false)} open>
            <FormattedMessage id="surveysPage.showLess" />
        </Toggle>
    ) : (
        <Toggle onClick={() => onToggle(true)}>
            <FormattedMessage id="surveysPage.viewAll" />
        </Toggle>
    );
};

export default ToggleSurveysGroup;
