import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import {
    getNumberOfTablesInReport,
    getTotalNumberOfGeographies,
} from '../../../selectors/ReportMetadata';

const SimplifiedNavigator = () => {
    const numberOfTables = useSelector(getNumberOfTablesInReport);
    const numberOfGeographies = useSelector(getTotalNumberOfGeographies);

    return (
        <div className="simplified-navigator">
            <FormattedMessage id="navigator.showing" />
            <div className="item-amount">
                <FormattedMessage
                    id="navigator.numberOfGeographies"
                    values={{
                        count: numberOfGeographies,
                        b: (chunks: React.ReactNode) => (
                            <strong>{chunks}</strong>
                        ),
                    }}
                />
            </div>
            <div className="item-amount">
                <FormattedMessage
                    id="navigator.numberOfTables"
                    values={{
                        count: numberOfTables,
                        b: (chunks: React.ReactNode) => (
                            <strong>{chunks}</strong>
                        ),
                    }}
                />
            </div>
        </div>
    );
};

export default SimplifiedNavigator;
