import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import * as Table from '../../selectors/Table';
import { getIsEditingTemplateFromDashboard } from '../../selectors/Templates';
import * as ModalStatusConstants from '../../constants/TableModalContentStatus';
import { getFetchDatasetsAction } from '../../actionCreators/tableActions';
import { ReportAppDispatch } from '../../typescript/actions/actions';
import { useCreateReportFromTemplate } from '../../customHooks/useCreateReportFromTemplate';

import { withSelectionSafeguard } from '../../hoc/withSelectionSafeguard';
import TablesPageHeader from './TablesPageHeader';
import PageContent from '../base/PageContent/PageContent';
import SelectTemplateTables from './SelectTemplateTables';
import SelectTables from './SelectTables';
import SelectTablesModal from './SelectTablesModal';

import { RouteParams } from '../../typescript/types';

const SelectTablesPage = () => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const params = useParams<RouteParams.SelectTablesPage>();
    const surveyCodes = useMemo(
        () => decodeURIComponent(params.surveyCodes).split(','),
        [params.surveyCodes],
    );
    const shouldLoadDatasets = useSelector((state) =>
        Table.getShouldLoadDatasets(state, surveyCodes),
    );
    const isEditingTemplateFromDashboard = useSelector(
        getIsEditingTemplateFromDashboard,
    );
    const [modalContentStatus, setModalContentStatus] = useState(
        ModalStatusConstants.EMPTY,
    );
    useCreateReportFromTemplate();

    useEffect(() => {
        if (shouldLoadDatasets) {
            dispatch(getFetchDatasetsAction(surveyCodes));
        }
    }, [shouldLoadDatasets, dispatch, surveyCodes]);

    const content = isEditingTemplateFromDashboard ? (
        <SelectTemplateTables
            setModalContentStatus={setModalContentStatus}
            surveyCodes={surveyCodes}
        />
    ) : (
        <SelectTables
            setModalContentStatus={setModalContentStatus}
            surveyCodes={surveyCodes}
        />
    );
    return (
        <>
            <TablesPageHeader />
            <PageContent>
                {content}
                <SelectTablesModal
                    modalContentStatus={modalContentStatus}
                    setModalContentStatus={setModalContentStatus}
                    surveyCodes={surveyCodes}
                />
            </PageContent>
        </>
    );
};

export default withSelectionSafeguard(SelectTablesPage);
