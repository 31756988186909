import React, { useRef } from 'react';

import { useAddGtmAttributes } from '../../../../customHooks/useAddGtmAttributes';
import { GtmAttribute } from '../../../../typescript/types';

import Icon from '../../../Icon/Icon';
import Button from '../../../Buttons/Button';

import './DownloadCardAction.scss';

export type Props = {
    label?: string;
    gtmAttributes: GtmAttribute[];
    onClick: () => void;
};

const DownloadCardAction = ({ label, gtmAttributes, ...rest }: Props) => {
    const buttonRef = useRef(null);
    useAddGtmAttributes(buttonRef, gtmAttributes);

    return (
        <Button className="download-data-button" ref={buttonRef} {...rest}>
            {label && <span>{label}</span>}
            <Icon className="icon">file_download</Icon>
        </Button>
    );
};

export default DownloadCardAction;
