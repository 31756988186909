import DOMElementID from './DOMElementID';

export const MODIFY_REPORT = DOMElementID.SIDEBAR_MODIFY_REPORT;
export const DOWNLOAD_EXCEL = 'download-excel';
export const DOWNLOAD_PDF = 'download-pdf';
export const DOWNLOAD_CSV = 'download-csv';
export const SHARE = 'share';
export const INFO = 'info';
export const CITATION = 'citation';
export const EMPTY = 'empty';

export const SIDEBAR_TABS_ORDER = [
    MODIFY_REPORT,
    DOWNLOAD_EXCEL,
    DOWNLOAD_CSV,
    DOWNLOAD_PDF,
    SHARE,
    INFO,
];

export const SET_ACTIVATED_SIDEBAR_TAB = '@@sidebar/SET_ACTIVATED_SIDEBAR_TAB';

export type SidebarOptions =
    | typeof MODIFY_REPORT
    | typeof DOWNLOAD_EXCEL
    | typeof DOWNLOAD_PDF
    | typeof DOWNLOAD_CSV
    | typeof SHARE
    | typeof INFO
    | typeof EMPTY;
