import React from 'react';
import { useSelector } from 'react-redux';
import { getProgressBarWidth } from '../../selectors/WizardHeader';
import classNames from 'classnames';
import './ProgressBar.scss';
import * as HeaderConstants from '../../constants/Header';

const ProgressBar = ({ currentAppScreen }) => {
    const progressBarWidth = useSelector((state) =>
        getProgressBarWidth(state, currentAppScreen),
    );

    const progressBarFillClasses = classNames('progress-bar-fill', {
        'one-third': progressBarWidth === HeaderConstants.ONE_THIRD_PROGRESS,
        'two-thirds': progressBarWidth === HeaderConstants.TWO_THIRDS_PROGRESS,
        'three-thirds':
            progressBarWidth === HeaderConstants.THREE_THIRDS_PROGRESS,
    });
    return (
        <div className="progress-bar-container">
            <div className={progressBarFillClasses}></div>
        </div>
    );
};

export default ProgressBar;
