import React from 'react';
import { useSelector } from 'react-redux';

import {
    getHasIntermittentTables,
    getIntermittentTables,
} from '../../selectors/Table';
import { SurveyCode, Template } from '../../typescript/types';

import SelectedTablesList from './SelectedTablesList';
import ModifyTemplateTables from './ModifyTemplateTables';
import SkipToHeaderJumpButtons from '../JumpButton/SkipToHeaderJumpButtons';
import SelectTemplateHeroSection from './SelectTemplateHeroSection';

type Props = {
    tableTemplate: Template;
    setModalContentStatus: (status: string) => void;
    surveyCodes: SurveyCode[];
};

const SelectTemplateTablesContent = ({
    tableTemplate,
    setModalContentStatus,
    surveyCodes,
}: Props) => {
    const hasIntermittentTablesSelection = useSelector(
        getHasIntermittentTables,
    );
    const intermittentTables = useSelector(getIntermittentTables);

    const jumpButtons = (
        <SkipToHeaderJumpButtons
            shouldRenderNextButton={hasIntermittentTablesSelection}
        />
    );
    return (
        <>
            <SelectTemplateHeroSection tableTemplate={tableTemplate} />
            <ModifyTemplateTables
                numberOfIntermittentTables={intermittentTables?.length ?? 0}
                setModalContentStatus={setModalContentStatus}
                surveyCodes={surveyCodes}
            />
            {hasIntermittentTablesSelection ? jumpButtons : null}
            {intermittentTables ? (
                <SelectedTablesList selectedTables={intermittentTables} />
            ) : null}
        </>
    );
};

export default SelectTemplateTablesContent;
