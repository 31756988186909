export const DATASETS_LOADING = '@@selectTableScreen/DATASETS_LOADING';
export const DATASETS_ERROR = '@@selectTableScreen/DATASETS_ERROR';
export const DATASETS_SELECTION = '@@selectTableScreen/DATASETS_SELECTION';

export const PREMADE_REPORTS_LOADING =
    '@@selectTableScreen/PREMADE_REPORTS_LOADING';
export const PREMADE_REPORTS_ERROR =
    '@@selectTableScreen/PREMADE_REPORTS_ERROR';
export const PREMADE_REPORTS_SELECTION =
    '@@selectTableScreen/PREMADE_REPORTS_SELECTION';
