import { SET_NORMALIZED_TABLES } from '../constants/Table';

export default (state = {}, action) => {
    switch (action.type) {
        case SET_NORMALIZED_TABLES:
            const tables = action.payload;
            return {
                ...state,
                ...tables,
            };
        default:
            return state;
    }
};
