import * as React from 'react';

const HintArrowRight = ({ color }) => (
    <svg
        width="42px"
        height="59px"
        viewBox="0 0 42 59"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="CR" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
                id="CR-|-S2---Select-Geographies-|-FIPS"
                transform="translate(-695.000000, -229.000000)"
                fill={color}
                fillRule="nonzero"
            >
                <g id="Modal" transform="translate(400.000000, 147.000000)">
                    <g
                        id="hint-arrow-right"
                        transform="translate(319.500000, 108.500000) scale(-1, 1) rotate(45.000000) translate(-319.500000, -108.500000) translate(305.000000, 75.000000)"
                    >
                        <path
                            d="M27.3,64.3 C7.6,54.1 7.3,30.4 12.9,11.5 C12.7,15.5 12.9,19.5 13.7,23.6 C14.1,25.5 16.9,24.7 16.5,22.8 C15.2,16.1 15.5,9.4 17.3,2.8 C17.6,1.6 16.2,0.4 15.1,1.1 C10.3,4.1 5.5,7 0.7,10 C-0.9,11 0.6,13.6 2.2,12.6 C5.2,10.7 8.2,8.9 11.2,7 C4.1,27.7 3.7,55.3 25.8,66.8 C27.5,67.7 28.9,65.1 27.3,64.3 Z"
                            id="Path"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
export default HintArrowRight;
