import React from 'react';
import { FormattedMessage } from 'react-intl';

import ApplicationPaths from '../../../../helpers/ApplicationPaths';

import ButtonLink from '../../../Buttons/ButtonLink';

import './SelectSurveyDone.scss';

type Props = {
    initialSelection: number[];
    selectedYears: Set<number>;
    hasYear: (item: number) => boolean;
    onDone: (newYears: number[]) => void;
};

const SelectSurveyDone = ({
    initialSelection,
    selectedYears,
    hasYear,
    onDone,
}: Props) => {
    const linkToSurvey = ApplicationPaths.surveys.getPath();

    const disabled =
        selectedYears.size === 0 ||
        (initialSelection.every(hasYear) &&
            initialSelection.length === selectedYears.size);

    return (
        <div className="select-survey-done">
            <ButtonLink
                disabled={disabled}
                variant="flat"
                onClick={() => onDone(Array.from(selectedYears))}
                to={linkToSurvey}
            >
                <FormattedMessage id="apply" />
            </ButtonLink>
        </div>
    );
};

export default SelectSurveyDone;
