import {
    CURRENT_USER_LOADING,
    CURRENT_USER_ERROR,
    CURRENT_USER_LOADED,
} from '../constants/CurrentUserStatus';

export const getShouldInitializeLoad = (state) => {
    const { loaded, loading, errorMessage } = state.currentUser;
    return !loaded && !loading && !errorMessage;
};

export const getCurrentUserStatus = (state) => {
    const { loaded, errorMessage } = state.currentUser;
    if (!loaded) {
        if (errorMessage) {
            return CURRENT_USER_ERROR;
        }
        return CURRENT_USER_LOADING;
    }
    return CURRENT_USER_LOADED;
};
