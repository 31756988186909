import * as ReportApp from '../typescript/types';

import oneThreeTwoYears from './definitions/1-3-2013-2018.json';
import oneThreeUpTwoYears from './definitions/1-3-UP-2013-2018.json';
import oneThreeUpTwoYearsCrime from './definitions/1-3-UP-2013-2018-crime.json';
import oneThreeFive from './definitions/1-3-5.json';
import oneThree from './definitions/1-3.json';
import oneThreeCrime from './definitions/1-3-crime.json';

export default function (
    reportId: string | ReportApp.OldReportId,
): ReportApp.Definition | null {
    if (reportId.startsWith('R')) {
        // this is old report id. Return new definition
        return {
            geoGroups: [
                {
                    aggregationType: 1,
                    columnGroups: [
                        {
                            tables: [reportId],
                        },
                    ],
                },
            ],
        };
    }
    switch (reportId) {
        case '1-3-5':
            return oneThreeFive;
        case '1-3-2013-2018':
            return oneThreeTwoYears;
        case '1-3':
            return oneThree;
        case '1-3-UP-2013-2018':
            return oneThreeUpTwoYears;
        case '1-3-UP-2013-2018-crime':
            return oneThreeUpTwoYearsCrime;
        case '1-3-crime':
            return oneThreeCrime;
        default:
            return null;
    }
}
