import { useLocation, useHistory } from 'react-router-dom';
import { TABLE_TEMPLATE_ID_PARAM_NAME } from '../constants/Table';
import { useDispatch, useSelector } from 'react-redux';
import { ReportAppDispatch } from '../typescript/actions/actions';
import { getTemplateId } from '../selectors/Templates';
import { useEffect } from 'react';
import {
    getSetActiveTemplateIdAction,
    getSetIsEditingTemplateAction,
} from '../actionCreators/reportActions';
import ApplicationPaths from '../helpers/ApplicationPaths';
import { getSurveyCode } from '../selectors/Selection';

const extractTemplateIdFromUrlParam = (search: string) => {
    const tableTemplateIdUrlParam = new URLSearchParams(search).get(
        TABLE_TEMPLATE_ID_PARAM_NAME,
    );
    return tableTemplateIdUrlParam
        ? parseInt(tableTemplateIdUrlParam, 10)
        : null;
};

/**
 * If `tableTemplateId` Url parameter is specified on SelectTablesPage, that means
 * that report is being created using tables from a report table template (whose id is specified).
 * Therefore we will store that template Id in redux since we will need it in wizard header to
 * handle back button behavior.
 */
export const useCreateReportFromTemplate = () => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const location = useLocation();
    const history = useHistory();
    const surveyCode = useSelector(getSurveyCode);
    const existingTemplateId = useSelector(getTemplateId);
    const templateId = extractTemplateIdFromUrlParam(location.search);

    useEffect(() => {
        if (!existingTemplateId && templateId) {
            dispatch(getSetActiveTemplateIdAction(templateId));
            dispatch(getSetIsEditingTemplateAction(true));
            // remove query params from URL.
            history.replace(
                ApplicationPaths.tables.getPath({ surveyCodes: surveyCode }),
            );
        }
    }, [dispatch, history, existingTemplateId, templateId, surveyCode]);
};
