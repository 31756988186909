import React, { useRef, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';

import { useClickAway } from '../../../customHooks/useClickAway';

import Surface from '../Surface/Surface';
import ZoomableContent from '../ZoomableContent/ZoomableContent';

import './Popover.scss';

type Props = {
    onClickAway: (e: MouseEvent | KeyboardEvent) => void;
    parent: HTMLElement | null;
} & React.HTMLAttributes<HTMLDivElement>;

const Popover = ({
    children,
    onClickAway,
    parent,
    className,
    ...rest
}: Props) => {
    const root = useRef<HTMLDivElement>(null);
    const parentClassList = parent?.classList;
    useClickAway(root, onClickAway);
    useLayoutEffect(() => {
        if (!parentClassList) {
            return;
        }
        parentClassList.add('popover-container');
        return () => parentClassList.remove('popover-container');
    }, [parentClassList]);
    if (parent == null) {
        return null;
    }
    const classes = classnames('popover', className);
    return createPortal(
        <Surface ref={root} className={classes} level="3" {...rest}>
            <ZoomableContent>{children}</ZoomableContent>
        </Surface>,
        parent,
    );
};

export default Popover;
