import { DefaultRootState } from 'react-redux';
import * as ReportMetadata from './ReportMetadata';
import * as ReportScreenConstants from '../constants/ReportScreen';
import { ReportActionsMainButtonType } from '../constants/ReportActionsEnum';
import { getSurveyOrGroupName } from './ReportMetadata';

export const getHeaderTitleContent = (state: DefaultRootState) => {
    const isSaved = ReportMetadata.getIsSaved(state);
    const reportTitle = ReportMetadata.getReportTitle(state);
    if (isSaved) {
        return reportTitle;
    }
    return getSurveyOrGroupName(state);
};

export const getWhatMainButtonToRender = (state: DefaultRootState) => {
    if (getIsReportOwnedBySomeoneElse(state)) {
        return ReportActionsMainButtonType.MAKE_A_COPY;
    }
    if (ReportMetadata.getIsSaved(state)) {
        return ReportActionsMainButtonType.ALL_CHANGES_SAVED;
    }
    return ReportActionsMainButtonType.SAVE_AS;
};

export const shouldShowAdditionalActions = (state: DefaultRootState) =>
    !!ReportMetadata.getIsSaved(state) && !getIsReportOwnedBySomeoneElse(state);

export const getSaveReportDisplayStatus = (state: DefaultRootState) => {
    const { isOwnedByCurrentUser, reportTitle } = state.reportMetadata;

    if (isOwnedByCurrentUser && reportTitle) {
        return ReportScreenConstants.SAVE_REPORT_STATUS_ALREADY_SAVED;
    } else {
        const { loading, error } = state.reportStatus.saveReport;
        if (loading) {
            return ReportScreenConstants.SAVE_REPORT_STATUS_LOADING;
        } else if (error) {
            return ReportScreenConstants.SAVE_REPORT_STATUS_ERROR;
        } else {
            return ReportScreenConstants.SAVE_REPORT_STATUS_AVAILABLE;
        }
    }
};

export const getDeleteReportDisplayStatus = (state: DefaultRootState) => {
    const { loading, error } = state.reportStatus.deleteReport;
    if (loading) {
        return ReportScreenConstants.DELETE_REPORT_STATUS_LOADING;
    } else if (error) {
        return ReportScreenConstants.DELETE_REPORT_STATUS_ERROR;
    } else {
        return ReportScreenConstants.DELETE_REPORT_STATUS_AVAILABLE;
    }
};

export const getUpdateReportDisplayStatus = (state: DefaultRootState) => {
    const { loading, error } = state.reportStatus.updateReport;
    if (loading) {
        return ReportScreenConstants.UPDATE_REPORT_STATUS_LOADING;
    } else if (error) {
        return ReportScreenConstants.UPDATE_REPORT_STATUS_ERROR;
    } else {
        return ReportScreenConstants.UPDATE_REPORT_STATUS_AVAILABLE;
    }
};

export const getDuplicateReportDisplayStatus = (state: DefaultRootState) => {
    const { loading, error } = state.reportStatus.duplicateReport;
    if (loading) {
        return ReportScreenConstants.DUPLICATE_REPORT_STATUS_LOADING;
    } else if (error) {
        return ReportScreenConstants.DUPLICATE_REPORT_STATUS_ERROR;
    } else {
        return ReportScreenConstants.DUPLICATE_REPORT_STATUS_AVAILABLE;
    }
};

export const getSaveReportError = (state: DefaultRootState) =>
    state.reportStatus.saveReport.error;

export const getDeleteReportError = (state: DefaultRootState) =>
    state.reportStatus.deleteReport.error;

export const getUpdateReportError = (state: DefaultRootState) =>
    state.reportStatus.updateReport.error;

export const getDuplicateReportError = (state: DefaultRootState) =>
    state.reportStatus.updateReport.error;

export const getIsReportOwnedBySomeoneElse = (state: DefaultRootState) => {
    const { isSaved, isOwnedByCurrentUser } = state.reportMetadata;
    return !!isSaved && !isOwnedByCurrentUser;
};
