import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import * as GeoTypes from '../../selectors/GeoTypes';
import * as GeographiesConstants from '../../constants/Geographies';

import Button from '../Buttons/Button';
import SelectionCounter from '../Selection/SelectionCounter';
import SelectedGeographiesList from './SelectedGeographiesList';

import './GeographiesSelection.scss';
/** @param {{surveyCode: string}} param0 */
const SelectedGeographies = ({ surveyCode }) => {
    const dispatch = useDispatch();
    const shouldSetSelectedGeographies = useSelector(
        GeoTypes.getShouldSetSelectedGeographies,
    );
    const selectedGeographies = useSelector((state) =>
        GeoTypes.getSelectedGeographiesSplitBySummaryLevel(state, surveyCode),
    );
    const selectedGeographiesCount = useSelector(
        GeoTypes.getSelectedGeographies,
    )?.length;

    useEffect(() => {
        if (shouldSetSelectedGeographies) {
            dispatch({
                type: GeographiesConstants.FETCH_SELECTED_GEOGRAPHIES_REQUEST,
            });
        }
    }, [dispatch, shouldSetSelectedGeographies]);

    const intl = useIntl();

    if (shouldSetSelectedGeographies) {
        return null;
    }

    const onClearAll = () =>
        dispatch({
            type: GeographiesConstants.SET_SELECTED_GEO_FIPSES,
            payload: [],
        });

    const clearListAriaLabel = intl.formatMessage(
        {
            id: `geographiesPage.selection.clearListAriaLabel.${
                selectedGeographiesCount > 1 ? 'plural' : 'singular'
            }`,
        },
        { count: selectedGeographiesCount },
    );

    return (
        <div className="geographies-selection">
            <div className="geographies-selection-count">
                <SelectionCounter
                    numberOfSelectedItems={selectedGeographiesCount}
                />
                <div className="geographies-selection-divider" />
                <Button
                    variant="flat"
                    onClick={onClearAll}
                    title={clearListAriaLabel}
                    aria-label={clearListAriaLabel}
                >
                    <FormattedMessage id="clear" />
                </Button>
            </div>
            <SelectedGeographiesList
                selectedGeographies={selectedGeographies}
                selectedGeographiesCount={selectedGeographiesCount}
            />
        </div>
    );
};

export default SelectedGeographies;
