import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as ReportConstants from '../../../constants/Report';
import * as ReportNavigator from '../../../selectors/ReportNavigator';
import * as ReportMetadata from '../../../selectors/ReportMetadata';
import { ReportAppDispatch } from '../../../typescript/actions/actions';

import NavigatorParameter from './NavigatorParameter';

const TablesNavigatorParameter = () => {
    const dispatch = useDispatch<ReportAppDispatch>();

    const [tablePage, tablesPerPage] = useSelector(
        ReportNavigator.getTablesNavigationStatus,
    );
    const numberOfSelectedTables = useSelector(
        ReportMetadata.getNumberOfTablesInReport,
    );

    return (
        <NavigatorParameter
            itemPage={tablePage}
            setItemPage={(page: number) =>
                dispatch({
                    type: ReportConstants.SET_TABLE_PAGE_REQUEST,
                    payload: { page },
                })
            }
            itemsPerPage={tablesPerPage}
            itemsTotalCount={numberOfSelectedTables}
        />
    );
};

export default TablesNavigatorParameter;
