import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import DOMElementID from '../../../constants/DOMElementID';
import { NavigatorVariants } from '../../../constants/ReportNavigator';

import { getReportNavigatorDisplayStatus } from '../../../selectors/ReportNavigator';
import SimplifiedNavigator from './SimplifiedNavigator';
import NavigatorPopover from './NavigatorPopover/NavigatorPopover';
import DetailedNavigator from './DetailedNavigator';
import NavigatorJumpButtons from './NavigatorJumpButtons';

import './ReportNavigator.scss';

type Props = {
    className: string | undefined;
};

const ReportNavigator = ({ className }: Props) => {
    const displayStatus = useSelector(getReportNavigatorDisplayStatus);

    const navigatorContainerClasses = classNames(
        'report-navigator-container',
        className,
    );

    let content;
    switch (displayStatus) {
        case NavigatorVariants.SIMPLIFIED_NAVIGATOR:
            content = <SimplifiedNavigator />;
            break;
        case NavigatorVariants.DETAILED_NAVIGATOR:
        default:
            content = <DetailedNavigator />;
            break;
    }

    return (
        <nav className={navigatorContainerClasses}>
            <div id={DOMElementID.FOOTER_PAGINATION_CONTAINER}>
                {content}
                <NavigatorPopover />
            </div>
            <NavigatorJumpButtons />
        </nav>
    );
};

export default ReportNavigator;
