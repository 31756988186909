import React from 'react';
import { FormattedMessage } from 'react-intl';

import DOMElementID from '../../../constants/DOMElementID';

import TableBody from './TableBody';
import ShowMoreRows from './ShowMoreRows';
import TableHeader from './TableHeader/TableHeader';
import SentryErrorBoundary from '../../base/SentryErrorBoundary/SentryErrorBoundary';

import './TableRenderer.scss';

const TableRenderer = () => (
    <main className="report-table">
        <SentryErrorBoundary
            fallbackRenderer={({ error }) => {
                let message: string | JSX.Element = error.message;
                if (error instanceof SyntaxError) {
                    message = (
                        <FormattedMessage id="report.error.clientFunction" />
                    );
                }
                return <h1>{message}</h1>;
            }}
        >
            <table>
                <thead>
                    <TableHeader />
                </thead>
                <tbody id={DOMElementID.CONTENT_TABLE_WRAPPER}>
                    <TableBody />
                    <ShowMoreRows />
                </tbody>
            </table>
        </SentryErrorBoundary>
    </main>
);

export default TableRenderer;
