import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { getSaveTemplateModalProperties } from '../../../../selectors/Templates';

const TemplateModalSurveyText = () => {
    const templateModalContent = useSelector(getSaveTemplateModalProperties);
    if (!templateModalContent) {
        return null;
    }

    const {
        comparabilityStartYear,
        comparabilityEndYear,
        surveyGroupName,
    } = templateModalContent;

    if (!comparabilityStartYear || !comparabilityEndYear) {
        return <>{surveyGroupName}</>;
    }
    return (
        <FormattedMessage
            id="report.tablesTemplate.details.tablesDescription"
            values={{
                surveyGroupName,
                comparabilityStartYear,
                comparabilityEndYear,
            }}
        />
    );
};

export default TemplateModalSurveyText;
