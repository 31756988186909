import React from 'react';
import { useIntl } from 'react-intl';

import Input from '../../base/Input/Input';

const ReportAttributesInputs = ({
    reportTitle,
    onReportTitleChange,
    reportDescription,
    onReportDescriptionChange,
}) => {
    const intl = useIntl();
    return (
        <>
            <Input
                id="reportTitle"
                label={intl.formatMessage({
                    id: 'report.saveReport.title',
                })}
                placeholder={intl.formatMessage({
                    id: 'report.saveReport.titlePlaceholder',
                })}
                onChange={(event) => onReportTitleChange(event.target.value)}
                value={reportTitle}
            />
            <Input
                id="reportDescription"
                multiline={true}
                label={intl.formatMessage({
                    id: 'report.saveReport.desc',
                })}
                placeholder={intl.formatMessage({
                    id: 'report.saveReport.descPlaceholder',
                })}
                value={reportDescription}
                onChange={(event) =>
                    onReportDescriptionChange(event.target.value)
                }
            />
        </>
    );
};

export default ReportAttributesInputs;
