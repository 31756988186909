import React from 'react';

import * as ReportApp from '../../../../typescript/types';

import TableInfoTableForYear from './TableInfoTableForYear';
import WarningMessage from '../../../WarningMessage/WarningMessage';

type Props = {
    year: number;
    analysis: ReportApp.TableAnalysisForYear | null;
};

const TableInfoForYear = ({ year, analysis }: Props) => {
    if (analysis == null) {
        // This year has been removed from report. We don't want to show any
        // info about this year
        return null;
    }

    return (
        <>
            {!analysis.hasFoundAny && (
                <WarningMessage messageId="surveysPage.changeSurvey.zeroTablesFound" />
            )}
            <TableInfoTableForYear results={analysis.elements} year={year} />
        </>
    );
};

export default TableInfoForYear;
