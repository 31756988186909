import { CitationTableDataWithDate } from './../typescript/types';

// Template keys can only be the properties of the CitationDataAndDate object
type CitationTemplateKeys = keyof CitationTableDataWithDate;

// Check this link for more info:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals
export function taggedTemplate(
    strings: TemplateStringsArray,
    ...keys: CitationTemplateKeys[]
) {
    return (...values: CitationTableDataWithDate[]) => {
        const dict = values[values.length - 1] || {};
        const result = [strings[0]];
        keys.forEach((key: CitationTemplateKeys, i) => {
            const value = dict[key].toString();
            if (value) {
                result.push(value, strings[i + 1]);
            }
        });
        return result.join('');
    };
}
