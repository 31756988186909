import React from 'react';
import { useIntl } from 'react-intl';

import Icon from '../../../Icon/Icon';
import Button from '../../../Buttons/Button';
import IconButton from '../../../IconButton/IconButton';

import './PremadeReportsFilterableByTextListItemRow.scss';

const PremadeReportsFilterableByTextListItemRow = ({
    expanded,
    title,
    onClick,
}) => {
    const intl = useIntl();

    const expandButtonAriaLabel = intl.formatMessage(
        { id: 'tablesPage.premadeReports.expandButtonAriaLabel' },
        { title },
    );

    return (
        <div className="premade-reports-item-row" tabIndex={-1}>
            <div className="toggle-button-and-label">
                <IconButton
                    onClick={onClick}
                    title={expandButtonAriaLabel}
                    aria-label={expandButtonAriaLabel}
                    className="toggle-expand"
                >
                    {expanded ? 'arrow_drop_down' : 'arrow_right'}
                </IconButton>
                <Button className="label" title={title} aria-label={title}>
                    {title}
                </Button>
            </div>
            <Icon className="selection-icon">check</Icon>
        </div>
    );
};

export default PremadeReportsFilterableByTextListItemRow;
