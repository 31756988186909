import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import Survey from './Survey';
import ToggleSurveysGroup from './ToggleSurveysGroup';

import './Surveys.scss';

const MIN_AMOUNT_OF_SURVEYS_TO_DISPLAY = 2;

/** @param {{onSelect: Function, surveyCodes: string[], surveyGroupName: string }} param0 */
const Surveys = ({ onSelect, surveyCodes, surveyGroupName }) => {
    const [expandSurveys, toggleExpandSurveys] = useState(false);
    const numberOfElements = expandSurveys
        ? surveyCodes.length
        : MIN_AMOUNT_OF_SURVEYS_TO_DISPLAY;

    const intl = useIntl();

    const listItemAriaLabel = intl.formatMessage(
        { id: 'surveysPage.surveysGroup.listItemAriaLabel' },
        {
            name: surveyGroupName.replace(/\./g, ''),
            count: surveyCodes.length,
        },
    );

    return (
        <ul className="surveys-list" aria-label={listItemAriaLabel}>
            {surveyCodes.slice(0, numberOfElements).map((surveyCode) => (
                <Survey
                    key={surveyCode}
                    surveyCode={surveyCode}
                    onSelect={onSelect}
                />
            ))}
            <ToggleSurveysGroup
                showToggle={
                    surveyCodes.length > MIN_AMOUNT_OF_SURVEYS_TO_DISPLAY
                }
                isExpanded={expandSurveys}
                onToggle={toggleExpandSurveys}
            />
        </ul>
    );
};

export default Surveys;
