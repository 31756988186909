import React from 'react';
import classNames from 'classnames';

import Button from '../../Buttons/Button';

import './SelectItem.scss';

type Props<T> = {
    item: T;
    onClick: () => void;
    selected: boolean;
};

const SelectItem = <T extends number | string>({
    item,
    onClick,
    selected,
}: Props<T>) => {
    const className = classNames('dropdown-list-item', { selected });
    return (
        <li className={className}>
            <Button onClick={onClick} className="dropdown-list-item-button">
                {item}
            </Button>
        </li>
    );
};
export default SelectItem;
