import * as ReportApp from '../typescript/types';

export const formatTable = (table: ReportApp.AspNetWebService.CWsTable) => {
    const variablesByGuid: ReportApp.VariableByGuid = {};
    const output: ReportApp.Table = {
        datasetAbbreviation: table.DatasetAbbreviation,
        datasetName: table.DatasetName,
        displayName: table.DisplayName,
        // docLinks: table.DocLinks,
        dollarYear: table.DollarYear,
        guid: table.GUID,
        name: table.Name,
        // notes: table.Notes,
        outputColumns: table.OutputColumns.OutputColumns.map((column) => ({
            tableGuid: column.TableGuid,
            outputPercent: column.OutputPercent,
            caption: column.Caption,
            colSpan: column.OutputPercent ? 2 : 1,
        })),
        // sideBySideTitle: table.SideBySideTitle,
        surveyCode: table.SurveyName,
        title: table.Title,
        // titleShort: table.TitleShort,
        // universe: table.Universe,
        originalTitle: table.OriginalTitle,
        variableCount: table.VariableCount,
        colSpan: 0,
        variables: table.Variables.reduce((acc, variable) => {
            acc[variable.GUID] = {
                name: variable.Name,
                guid: variable.GUID,
                label: variable.Label,
                originalLabel: variable.OriginalLabel,
                labelNoBrakes: variable.LabelNoBrakes,
                qLabel: variable.QLabel,
                tableGuid: variable.TableGuid,
                notes: variable.Notes,
                indent: variable.Indent,
                aggregationMethod: variable.AggregationMethod,
                parentVariableGuid: variable.ParentVariableGuid,
                percentBaseUniverseVarIndex:
                    variable.PercentBaseUniverseVarIndex,
                docLinks: variable.DocLinks,
                formatting: variable.Formatting,
                dollarYear: table.DollarYear,
                clientFunction: variable.Jsf || '',
            };
            return acc;
        }, variablesByGuid),
    };
    output.colSpan = Math.max(
        2,
        output.outputColumns.reduce((acc, column) => acc + column.colSpan, 0),
    );
    return output;
};

export const formatDataset = (
    rawDataset: ReportApp.AspNetWebService.CWsDataset,
): ReportApp.Dataset => ({
    abbreviation: rawDataset.Abbreviation,
    description: rawDataset.Description,
    descriptionShort: rawDataset.DescriptionShort,
    displayName: rawDataset.DisplayName,
    name: rawDataset.Name,
    notes: rawDataset.Notes,
    publisher: rawDataset.Publisher,
    surveyName: rawDataset.SurveyName,
    tableCount: rawDataset.TableCount,
    tableGuids: rawDataset.Tables.map((table) => table.GUID),
});

export const constructTableForReport = (
    guidBySurveyCode: ReportApp.TableGuidBySurveyCode,
    table: ReportApp.Table,
    isReportCOT: boolean,
): ReportApp.TableForReportBySurveyCode => ({
    guidBySurveyCode,
    composeGuid: Object.values(guidBySurveyCode).sort().join('|'),
    title: table.title,
    displayName: table.displayName,
    name: table.name,
    label: getTableLabel(table, isReportCOT),
    get hasFoundTableInEverySurvey() {
        return Object.values(this.guidBySurveyCode).every(
            (tableGuid) => tableGuid != null,
        );
    },
    get hasFoundTableInAnySurvey() {
        return Object.values(this.guidBySurveyCode).some(
            (tableGuid) => tableGuid != null,
        );
    },
});

const DOLLAR_YEAR_TAG = '<DollarYear>';
export const getDollarAdjustedTitle = (
    title: string,
    year: number,
    isReportCOT: boolean,
) => {
    const yearValue = isReportCOT ? '' : year.toString();
    return title.replace(DOLLAR_YEAR_TAG, yearValue);
};

export const getTableLabel = (
    table: ReportApp.Table,
    isReportCOT: boolean,
    dollarAdjustmentYear?: number,
) => {
    const { originalTitle, datasetAbbreviation, displayName } = table;
    let title = originalTitle;
    const year = dollarAdjustmentYear || table.dollarYear;
    if (year) {
        title = getDollarAdjustedTitle(title, year, isReportCOT);
    }
    return `${datasetAbbreviation}:${displayName}:${title}`;
};

export const formatPremadeReport = (
    rawReport: ReportApp.AspNetWebService.CWsSystemReport,
): ReportApp.PremadeReport => ({
    id: rawReport.Id,
    name: rawReport.Name,
    tableGuids: rawReport.TableGuids,
});

export const getTableCode = (table: ReportApp.Table) =>
    `${table.variableCount}|${table.name}`;

export const getReportDataRowKey = (
    table: ReportApp.Table,
    variable: ReportApp.Variable,
) => `${table.datasetAbbreviation}_${variable.name}`;
