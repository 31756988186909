import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getIsCreatingTemplateFromDashboard } from '../../../../selectors/Templates';
import BackendPaths from '../../../../helpers/BackendPaths';

import LinkButton from '../../../LinkButton/LinkButton';
import Button from '../../../Buttons/Button';

type Props = {
    onClose: () => void;
};

const ExistingTemplateDoneButton = ({ onClose }: Props) => {
    const isCreatingTemplateFromDashboard = useSelector(
        getIsCreatingTemplateFromDashboard,
    );

    return isCreatingTemplateFromDashboard ? (
        <LinkButton
            url={BackendPaths.exploreMaps.getPath()}
            variant="contained"
            className="go-to-dashboard"
            rel="noopener noreferrer"
        >
            <FormattedMessage id="tablesPage.templates.goToDashboardUrl" />
        </LinkButton>
    ) : (
        <Button variant="contained" onClick={onClose}>
            <FormattedMessage id="done" />
        </Button>
    );
};

export default ExistingTemplateDoneButton;
