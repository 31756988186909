import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { FETCH_GEO_TYPES_REQUEST } from '../../constants/Geographies';
import ApplicationPaths from '../../helpers/ApplicationPaths';

import * as GeoTypes from '../../selectors/GeoTypes';
import { getSurveyByCode } from '../../selectors/Survey';
import * as SelectGeographyScreen from '../../constants/SelectGeographyScreen';
import { focusElementOrFirstChild } from '../../customHooks/useLockFocus';
import DOMElementID from '../../constants/DOMElementID';
import { RouteParams } from '../../typescript/types';
import { ReportAppDispatch } from '../../typescript/actions/actions';

import { withSelectionSafeguard } from '../../hoc/withSelectionSafeguard';
import { GeoTypesSelector } from './GeoTypes/GeoTypesSelector';
import GeoItemsSelector from './GeoItems/GeoItemsSelector';
import Loader from '../Loader/Loader';
import SelectedGeographies from './SelectedGeographies';
import GeographyPageHeader from './GeographyPageHeader';
import FipsCodesSelector from './FipsCodes/FipsCodesSelector';
import PageContent from '../base/PageContent/PageContent';
import { SelectGeographiesModeSelector } from './SelectGeographiesModeSelector';
import SelectGeographiesHint from './SelectGeographiesHint';
import SkipToHeaderJumpButtons from '../JumpButton/SkipToHeaderJumpButtons';

const SelectGeographiesPage = () => {
    const history = useHistory();
    const dispatch = useDispatch<ReportAppDispatch>();
    const { surveyCode } = useParams<RouteParams.CreateReport>();
    const selectedSurvey = useSelector((state) =>
        getSurveyByCode(state, surveyCode),
    );

    useEffect(() => {
        if (!selectedSurvey) {
            history.push(ApplicationPaths.root.getPath());
        }
    }, [selectedSurvey, history]);

    const shouldRetrieveGeoTypes = useSelector(
        GeoTypes.getShouldRetrieveGeoTypes,
    );
    const areGeographiesSelected = useSelector(GeoTypes.hasSelectedGeographies);
    const display = useSelector(GeoTypes.getWhatToDisplay);
    const error = useSelector(GeoTypes.getGeoTypesError);

    useEffect(() => {
        if (shouldRetrieveGeoTypes && selectedSurvey) {
            dispatch({
                type: FETCH_GEO_TYPES_REQUEST,
                payload: { surveyCode },
            });
        }
    }, [dispatch, shouldRetrieveGeoTypes, surveyCode, selectedSurvey]);

    useEffect(() => {
        if (!display) {
            focusElementOrFirstChild(DOMElementID.SELECTOR_CONTAINER);
        }
    }, [display]);

    let screenContent;
    switch (display) {
        case SelectGeographyScreen.GEOGRAPHY_PAGE_LOADING:
            screenContent = <Loader />;
            break;
        case SelectGeographyScreen.GEOGRAPHY_PAGE_ERROR:
            screenContent = <p>{error}</p>;
            break;
        case SelectGeographyScreen.GEO_TYPE_SELECTION:
            screenContent = <GeoTypesSelector surveyCode={surveyCode} />;
            break;
        case SelectGeographyScreen.GEO_ITEMS_SELECTION:
            screenContent = <GeoItemsSelector surveyCode={surveyCode} />;
            break;
        case SelectGeographyScreen.GEO_FIPS_SELECTION:
            screenContent = <FipsCodesSelector surveyCode={surveyCode} />;
            break;
        default:
            break;
    }

    const jumpButtons = (
        <SkipToHeaderJumpButtons
            shouldRenderNextButton={areGeographiesSelected}
        />
    );

    return (
        <>
            <GeographyPageHeader />
            <PageContent>
                <h1>
                    <FormattedMessage id="geographiesPage.mainHeader" />
                </h1>
                <SelectGeographiesModeSelector />
                {jumpButtons}
                <SelectedGeographies surveyCode={surveyCode} />
                <SelectGeographiesHint />
                {screenContent}
                {areGeographiesSelected ? jumpButtons : null}
            </PageContent>
        </>
    );
};

export default withSelectionSafeguard(SelectGeographiesPage);
