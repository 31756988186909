import React, { ChangeEvent } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import Input from '../../../base/Input/Input';
import TemplateModalSurveyText from './TemplateModalSurveyText';

type Props = {
    templateTitle: string;
    setTemplateTitle: (title: string) => void;
    tablesCount: number;
};

const SaveTemplateContent = ({
    setTemplateTitle,
    templateTitle,
    tablesCount,
}: Props) => {
    const intl = useIntl();

    return (
        <div className="template-action-modal-content zoomable-content">
            <Input
                id="templateTitle"
                label={intl.formatMessage({
                    id: 'report.tablesTemplate.title',
                })}
                placeholder={intl.formatMessage({
                    id: 'report.tablesTemplate.titlePlaceholder',
                })}
                multiline={false}
                errorText=""
                value={templateTitle}
                onChange={(event: ChangeEvent) => {
                    const target = event.target as HTMLInputElement;
                    setTemplateTitle(target.value);
                }}
            />
            <div className="details">
                <div className="details-title">
                    <FormattedMessage id="report.tablesTemplate.details.title" />
                </div>
                <div className="details-number-of-tables">
                    <FormattedMessage
                        id="report.tablesTemplate.details.numberOfTables"
                        values={{ tablesCount }}
                    />
                </div>
                <div className="details-tables-description">
                    <TemplateModalSurveyText />
                </div>
            </div>
        </div>
    );
};

export default SaveTemplateContent;
