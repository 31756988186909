import { all, call, select } from 'redux-saga/effects';
import * as ReportApp from '../typescript/types';
import * as Selection from '../selectors/Selection';
import * as NewSurvey from '../selectors/NewSurvey';
import * as Survey from '../selectors/Survey';
import api from '../helpers/Api';
import BackendPaths from '../helpers/BackendPaths';
import { intl } from '../helpers/CreateIntl';
import { getOldReportIdsFromDefinition } from '../helpers/Report';
import * as GeographiesHelper from '../helpers/Geographies';
import { filterFalsy } from '../typescript/helper';

export function* fetchReportInfos(oldReportIds: ReportApp.OldReportId[]) {
    const requests = oldReportIds.map((oldReportId) =>
        call(
            api,
            BackendPaths.getReportInfo.getPath({ oldReportId }),
            'GET-CACHE',
        ),
    );
    const responses: ReportApp.AspNetWebService.ReportSelectionResults[] = yield all(
        requests,
    );
    return responses;
}

export function* fetchReportInfosForDefinition(
    definition: ReportApp.Definition,
) {
    const oldReportIds = getOldReportIdsFromDefinition(definition);
    return yield fetchReportInfos(oldReportIds);
}

export function getReportInfoByOldReportId(
    oldReportIds: ReportApp.OldReportId[],
    responses: ReportApp.AspNetWebService.ReportSelectionResults[],
) {
    const reportInfoByOldReportId: ReportApp.ReportInfoByOldReportId = {};
    for (let i = 0; i < oldReportIds.length; i += 1) {
        const oldReportId = oldReportIds[i];
        const response = responses[i];
        reportInfoByOldReportId[oldReportId] = {
            surveyCode: response.d.SurveyName,
            tableGuids: response.d.TableSelection,
            geoFips: GeographiesHelper.parseReportInfoInSelectedFips(response),
        };
    }
    return reportInfoByOldReportId;
}

export function getReportInfo(
    definition: ReportApp.Definition,
    responses: ReportApp.AspNetWebService.ReportSelectionResults[],
) {
    const oldReportIds = getOldReportIdsFromDefinition(definition);
    return getReportInfoByOldReportId(oldReportIds, responses);
}

/**
 * DefinitionRequest object will have reference to ReportInfo objects. If
 * ReportInfo objects change, DefinitionRequest object will be changed as well
 */
const convertDefinitionToDefinitionRequest = (
    definition: ReportApp.Definition,
    reportInfoByOldReportId: ReportApp.ReportInfoByOldReportId,
): ReportApp.DefinitionRequest => {
    const definitionRequest: ReportApp.DefinitionRequest = {
        geoGroups: definition.geoGroups.map((geoGroup) => ({
            aggregationType: geoGroup.aggregationType,
            name: geoGroup.name,
            columnGroups: geoGroup.columnGroups.map((columnGroup) => ({
                name: columnGroup.name,
                tables: columnGroup.tables.map(
                    (oldReportId) => reportInfoByOldReportId[oldReportId],
                ),
            })),
        })),
    };
    // add center point to definition request if it was available in the
    // original report definition
    if (definition.center) {
        definitionRequest.center = definition.center;
    }
    return definitionRequest;
};

function* createDefinitionRequestForSurveyChange(
    definition: ReportApp.Definition,
    reportInfoByOldReportId: ReportApp.ReportInfoByOldReportId,
) {
    const surveysToBeAdded: ReportApp.Survey[] = yield select(
        NewSurvey.getSurveysToBeAdded,
    );
    const surveysToBeRemoved: ReportApp.Survey[] = yield select(
        NewSurvey.getSurveysToBeRemoved,
    );
    const surveyCodesToBeRemoved = surveysToBeRemoved.map(
        (survey) => survey.code,
    );

    const definitionRequest = convertDefinitionToDefinitionRequest(
        definition,
        reportInfoByOldReportId,
    );

    // remove years that are marked for deletion
    definitionRequest.geoGroups.forEach((geoGroup) => {
        geoGroup.columnGroups.forEach((columnGroup) => {
            columnGroup.tables = columnGroup.tables.filter(
                ({ surveyCode }) =>
                    !surveyCodesToBeRemoved.includes(surveyCode),
            );
        });
        geoGroup.columnGroups = geoGroup.columnGroups.filter(
            (columnGroup) => columnGroup.tables.length > 0,
        );
    });

    // add new years
    const tables: ReportApp.TableAnalysisByGeoGroupAndYear = yield select(
        NewSurvey.getTableAnalysis,
    );

    const geos: ReportApp.GeoAnalysisByGeoGroupAndYear = yield select(
        NewSurvey.getGeoAnalyseByGeoGroupAndYear,
    );

    for (
        let geoGroupIndex = 0;
        geoGroupIndex < definitionRequest.geoGroups.length;
        geoGroupIndex++
    ) {
        const geoGroup = definitionRequest.geoGroups[geoGroupIndex];
        const geoByYear = geos[geoGroupIndex].byYear;
        const tableByYear = tables[geoGroupIndex].byYear;
        surveysToBeAdded.forEach((survey) => {
            let columnGroup = geoGroup.columnGroups.find(
                (columnGroup) => columnGroup.name === survey.year.toString(),
            );
            if (!columnGroup) {
                columnGroup = { name: survey.year.toString(), tables: [] };
                geoGroup.columnGroups.push(columnGroup);
            }
            const geoFips = geoByYear[survey.year]?.elements
                .map((element) => element.foundGeoFips)
                .filter(filterFalsy);
            const tableGuids = tableByYear[survey.year]?.elements
                .map((element) => element.foundTableGuid)
                .filter(filterFalsy);
            const surveyCode = survey.code;

            assertSurveyCode(surveyCode);
            assertGeoFips(geoFips);
            assertTableGuids(tableGuids);

            columnGroup.tables.push({
                surveyCode: surveyCode,
                geoFips: geoFips,
                tableGuids: tableGuids,
            });
        });
    }
    // see if there are column groups that miss a name. This can happen when user
    // adds years on a simple report - simple report doesn't have name set
    for (const geoGroup of definitionRequest.geoGroups) {
        for (const columnGroup of geoGroup.columnGroups) {
            if (!columnGroup.name) {
                const { surveyCode } = columnGroup.tables[0];
                const survey: ReportApp.Survey = yield select(
                    Survey.getSurveyByCode,
                    surveyCode,
                );
                columnGroup.name = survey.year.toString();
            }
        }
    }

    // sort by year (column group name)
    definitionRequest.geoGroups.forEach(({ columnGroups }) =>
        columnGroups.sort(
            (columnGroupA, columnGroupB) =>
                Number(columnGroupA.name ?? 0) - Number(columnGroupB.name ?? 0),
        ),
    );

    return definitionRequest;
}

/**
 * This method will change reportInfoByOldReportId object
 */
export function* getDefinitionRequest(
    definition: ReportApp.Definition,
    reportInfoByOldReportId: ReportApp.ReportInfoByOldReportId,
) {
    // figure out what has to be updated
    // surveys?
    const hasSurveyChanged: boolean = yield select(
        NewSurvey.getShouldDisplayChangeSurveyAnalyse,
    );
    if (hasSurveyChanged) {
        return yield createDefinitionRequestForSurveyChange(
            definition,
            reportInfoByOldReportId,
        );
    }

    // geographies?
    const geoFips: string[] = yield select(Selection.getGeoFips);
    const reportInfos = Object.values(reportInfoByOldReportId);
    if (geoFips?.length > 0 && reportInfos.length === 1) {
        // right now we only support change of geographies for simple reports
        // that is why we can assume that we need to change only fips for
        // the first report info
        // When we introduce change of geography for multi year reports, pay
        // attention to order of the SLs, since they have to be the same.
        reportInfos[0].geoFips = geoFips.slice();
    }
    // tables?
    const tables: ReportApp.TableForReportBySurveyCode[] = yield select(
        Selection.getTables,
    );
    if (tables?.length > 0) {
        const surveyCodesAffected = Object.keys(tables[0].guidBySurveyCode);
        // change tables
        reportInfos.forEach((reportInfo) => {
            const { surveyCode } = reportInfo;
            if (surveyCodesAffected.includes(surveyCode)) {
                reportInfo.tableGuids = tables
                    .map((table) => table.guidBySurveyCode[surveyCode])
                    .filter(filterFalsy);
            }
        });
    }
    const definitionRequest = convertDefinitionToDefinitionRequest(
        definition,
        reportInfoByOldReportId,
    );
    let shouldSort = false;
    for (const geoGroup of definitionRequest.geoGroups) {
        for (const columnGroup of geoGroup.columnGroups) {
            for (const reportInfo of columnGroup.tables) {
                assertSurveyCode(reportInfo.surveyCode);
                assertGeoFips(reportInfo.geoFips);
                assertTableGuids(reportInfo.tableGuids);
            }
            if (columnGroup.name) {
                shouldSort = true;
                const { surveyCode } = columnGroup.tables[0];
                const survey: ReportApp.Survey = yield select(
                    Survey.getSurveyByCode,
                    surveyCode,
                );
                columnGroup.name = survey.year.toString();
            }
        }
    }
    if (shouldSort) {
        for (const geoGroup of definitionRequest.geoGroups) {
            geoGroup.columnGroups.sort(
                (columnGroupA, columnGroupB) =>
                    Number(columnGroupA.name) - Number(columnGroupB.name),
            );
        }
    }
    return definitionRequest;
}

export function* createReport(definitionRequest: ReportApp.DefinitionRequest) {
    const response = yield call(
        api,
        BackendPaths.createReport.getPath(),
        'POST',
        { definition: definitionRequest, tenant: process.env.REACT_APP_TENANT },
    );
    return response.id;
}

export function* getDefinitionRequestForNew() {
    const surveyCode: ReportApp.SurveyCode = yield select(
        Selection.getSurveyCode,
    );
    const geoFips: string[] = yield select(Selection.getGeoFips);
    const tables: ReportApp.TableForReportBySurveyCode[] = yield select(
        Selection.getTables,
    );

    const tableGuids = tables.map(
        (table) => table.guidBySurveyCode[surveyCode],
    );

    return {
        geoGroups: [
            {
                aggregationType: 1,
                columnGroups: [
                    { tables: [{ geoFips, surveyCode, tableGuids }] },
                ],
            },
        ],
    };
}

function assertSurveyCode(
    surveyCode: ReportApp.SurveyCode | null | undefined,
): asserts surveyCode is ReportApp.SurveyCode {
    if (!surveyCode) {
        throw new Error(
            intl.formatMessage({ id: 'report.metadata.issue.survey' }),
        );
    }
}

function assertGeoFips(
    geoFips: string[] | null | undefined,
): asserts geoFips is string[] {
    if (!geoFips || geoFips.length === 0) {
        throw new Error(
            intl.formatMessage({ id: 'report.metadata.issue.geographies' }),
        );
    }
}

function assertTableGuids(
    tableGuids: ReportApp.TableGuid[] | null | undefined,
): asserts tableGuids is string[] {
    if (!tableGuids || tableGuids.length === 0) {
        throw new Error(
            intl.formatMessage({ id: 'report.metadata.issue.table' }),
        );
    }
}
