import React from 'react';
import { useIntl } from 'react-intl';

import Button from '../../../Buttons/Button';
import Icon from '../../../Icon/Icon';

type Props = {
    onClose: () => void;
};

const SaveTemplateHeader = ({ onClose }: Props) => {
    const intl = useIntl();
    const closeMessage = intl.formatMessage({ id: 'close' });
    return (
        <div className="template-action-modal-header zoomable-header">
            <div className="close-button-container">
                <Button
                    onClick={onClose}
                    title={closeMessage}
                    aria-label={closeMessage}
                >
                    <Icon>close</Icon>
                </Button>
            </div>
            <Icon>list_alt</Icon>
            <h4>
                {intl.formatMessage({
                    id: 'report.tablesTemplate.tablesTemplateHeader',
                })}
            </h4>
        </div>
    );
};

export default SaveTemplateHeader;
