import React from 'react';
import './LabeledCheckbox.scss';

type Props = {
    id: string;
    label: React.ReactNode;
} & React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
>;

const LabeledCheckbox = ({ id, label, ...rest }: Props) => (
    <div className="labeled-checkbox">
        <input type="checkbox" data-hj-allow={true} id={id} {...rest} />
        <label htmlFor={id}>{label}</label>
    </div>
);

export default LabeledCheckbox;
