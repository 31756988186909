import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { getPartsFromGeoFips } from '../../../helpers/Geographies';
import { REMOVE_FIPS_FROM_SELECTION } from '../../../constants/Geographies';

import Icon from '../../Icon/Icon';
import Button from '../../Buttons/Button';

import './GeoTypeSelectionItem.scss';

const GeoTypeSelectionItem = ({ identifier }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    // identifier is constructed as fips, summary level and label joined with semicolon
    // eg:
    // 01;040;Alabama
    // 100030024001005;100;Block 1005, Block Group 1, Census Tract 24, New Castle County, Delaware
    const { label } = getPartsFromGeoFips(identifier);

    const onClear = () =>
        dispatch({
            type: REMOVE_FIPS_FROM_SELECTION,
            payload: identifier,
        });

    const listItemAriaLabel = intl.formatMessage(
        { id: 'geographiesPage.geoTypes.clearItemAriaLabel' },
        { label },
    );

    return (
        <li className="selected-list-item">
            {label}
            <Button
                title={intl.formatMessage({ id: 'selection.remove' })}
                aria-label={listItemAriaLabel}
                onClick={onClear}
            >
                <Icon>clear</Icon>
            </Button>
        </li>
    );
};

export default GeoTypeSelectionItem;
