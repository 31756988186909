import { SurveyCode } from '../typescript/types';

export const getSurveyButtonGtmAttributes = (surveyCode: SurveyCode) => {
    return [
        {
            name: 'gtm-track',
            value: '',
        },
        {
            name: 'gtm-eventc',
            value: 'Reports beta',
        },
        {
            name: 'gtm-eventa',
            value: 'Begin report',
        },
        {
            name: 'gtm-eventl',
            value: surveyCode,
        },
    ];
};
export const getProceedToTablesButtonGtmAttributes = (
    surveyCode: SurveyCode,
) => {
    return [
        {
            name: 'gtm-track',
            value: '',
        },
        {
            name: 'gtm-eventc',
            value: 'Reports beta',
        },
        {
            name: 'gtm-eventa',
            value: 'Proceed to tables',
        },
        {
            name: 'gtm-eventl',
            value: surveyCode,
        },
    ];
};

export const getCreateReportButtonGtmAttributes = (surveyName: string) => {
    return [
        {
            name: 'gtm-track',
            value: '',
        },
        {
            name: 'gtm-eventc',
            value: 'Reports beta',
        },
        {
            name: 'gtm-eventa',
            value: 'Show results',
        },
        {
            name: 'gtm-eventl',
            value: surveyName,
        },
    ];
};

export const getModifyReportButtonGtmAttributes = (
    surveyName: string,
    years: number[],
) => {
    let joinedYears = '';
    if (years.length > 1) {
        joinedYears = ` (${years.join(',')})`;
    }
    return [
        {
            name: 'gtm-track',
            value: '',
        },
        {
            name: 'gtm-eventc',
            value: 'Reports beta',
        },
        {
            name: 'gtm-eventa',
            value: 'Show results',
        },
        {
            name: 'gtm-eventl',
            value: `${surveyName}${joinedYears}`,
        },
    ];
};

export const getDownloadCsvButtonGtmAttributes = (geoTypeLabel: string) => {
    return [
        {
            name: 'gtm-track',
            value: '',
        },
        {
            name: 'gtm-eventc',
            value: 'Reports beta',
        },
        {
            name: 'gtm-eventa',
            value: 'CSV download',
        },
        {
            name: 'gtm-eventl',
            value: geoTypeLabel,
        },
    ];
};

export const getDownloadSasButtonGtmAttributes = () => {
    return [
        {
            name: 'gtm-track',
            value: '',
        },
        {
            name: 'gtm-eventc',
            value: 'Reports beta',
        },
        {
            name: 'gtm-eventa',
            value: 'CSV download',
        },
        {
            name: 'gtm-eventl',
            value: 'SAS',
        },
    ];
};

export const getDownloadSpssButtonGtmAttributes = () => {
    return [
        {
            name: 'gtm-track',
            value: '',
        },
        {
            name: 'gtm-eventc',
            value: 'Reports beta',
        },
        {
            name: 'gtm-eventa',
            value: 'CSV download',
        },
        {
            name: 'gtm-eventl',
            value: 'SPSS',
        },
    ];
};

export const getDownloadDataDictionaryGtmAttributes = () => {
    return [
        {
            name: 'gtm-track',
            value: '',
        },
        {
            name: 'gtm-eventc',
            value: 'Reports beta',
        },
        {
            name: 'gtm-eventa',
            value: 'CSV download',
        },
        {
            name: 'gtm-eventl',
            value: 'DATADICT',
        },
    ];
};

export const getDownloadStataDctFileGtmAttributes = () => {
    return [
        {
            name: 'gtm-track',
            value: '',
        },
        {
            name: 'gtm-eventc',
            value: 'Reports beta',
        },
        {
            name: 'gtm-eventa',
            value: 'CSV download',
        },
        {
            name: 'gtm-eventl',
            value: 'STATA dct file',
        },
    ];
};

export const getDownloadStataDoFileGtmAttributes = () => {
    return [
        {
            name: 'gtm-track',
            value: '',
        },
        {
            name: 'gtm-eventc',
            value: 'Reports beta',
        },
        {
            name: 'gtm-eventa',
            value: 'CSV download',
        },
        {
            name: 'gtm-eventl',
            value: 'STATA do file',
        },
    ];
};

export const getDownloadExcelButtonGtmAttributes = () => {
    return [
        {
            name: 'gtm-track',
            value: '',
        },
        {
            name: 'gtm-eventc',
            value: 'Reports beta',
        },
        {
            name: 'gtm-eventa',
            value: 'Excel download',
        },
    ];
};
