import React from 'react';
import { useIntl } from 'react-intl';

import { score } from '../../../../helpers/Search';
import * as ReportApp from '../../../../typescript/types';

import PremadeReportsFilterableByTextListItem from './PremadeReportsFilterableByTextListItem';
import DividersFilterableByTextList from '../../../base/FilterableByTextList/DividersFilterableByTextList';

/**
 * Used by FilterableByTextList to score premade reports
 */
const scoreCallback = (
    report: ReportApp.PremadeReportWithTables,
    lowerCaseTokens: string[],
) => score(report.name || '', lowerCaseTokens);

type Props = {
    onChange: (
        actionItem: ReportApp.PremadeReportWithTables,
        affectedItems: ReportApp.PremadeReportWithTables[],
    ) => void;
    isSelected: (id: number) => boolean;
    list: ReportApp.PremadeReportWithTables[];
};

const PremadeReportsFilterableByTextList = ({
    onChange,
    isSelected,
    list,
}: Props) => {
    const intl = useIntl();

    const itemRenderer = (report: ReportApp.PremadeReportWithTables) => (
        <PremadeReportsFilterableByTextListItem
            key={report.id}
            report={report}
            isSelected={(id) => isSelected(+id)}
        />
    );

    const placeholder = intl.formatMessage({
        id: 'tablesPage.premadeReports.searchPlaceholder',
    });

    const ariaListDescription = intl.formatMessage({
        id: 'tablesPage.premadeReports.listDescription',
    });

    return (
        <DividersFilterableByTextList
            scoreCallback={scoreCallback}
            render={itemRenderer}
            placeholder={placeholder}
            resultsClassName="premade-reports-list"
            description={ariaListDescription}
            list={list}
            onChange={onChange}
        />
    );
};

export default PremadeReportsFilterableByTextList;
