import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import Icon from '../Icon/Icon';
import Button from '../Buttons/Button';

import { REMOVE_TABLE_FROM_TEMPORARY_SELECTION } from '../../constants/Table';

const SelectedTablesListItem = ({ label, index }) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const onClear = () =>
        dispatch({
            type: REMOVE_TABLE_FROM_TEMPORARY_SELECTION,
            payload: index,
        });

    const selectedListItemAriaLabel = intl.formatMessage(
        { id: 'tablesPage.selection.listItemAriaLabel' },
        { title: label },
    );

    return (
        <li className="selected-list-item">
            {label}
            <Button
                onClick={onClear}
                title={selectedListItemAriaLabel}
                aria-label={selectedListItemAriaLabel}
            >
                <Icon>close</Icon>
            </Button>
        </li>
    );
};

export default SelectedTablesListItem;
