import { SET_TABLES_BY_SURVEY_CODE } from '../constants/Table';

/**
 * @typedef {Object.<string, Object.<string, string[]>>} TableGuidBySurveyCodeTableName
 */
export default (state = {}, action) => {
    switch (action.type) {
        case SET_TABLES_BY_SURVEY_CODE: {
            const { surveyCode, tableGuidByTableName } = action.payload;
            return {
                ...state,
                [surveyCode]: tableGuidByTableName,
            };
        }
        default:
            return state;
    }
};
