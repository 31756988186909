import React, { useRef } from 'react';
import { createPortal } from 'react-dom';

import { useClickAway } from '../../../customHooks/useClickAway';
import { useLockFocus } from '../../../customHooks/useLockFocus';

import Surface from '../Surface/Surface';
import PageContent from '../PageContent/PageContent';
import ZoomableContent from '../ZoomableContent/ZoomableContent';

import './Modal.scss';

type Props = {
    children: React.ReactNode;
    onClickAway: (e: MouseEvent | KeyboardEvent) => void;
    focusFirstChild?: boolean;
    className?: string;
};

const Modal = ({
    children,
    onClickAway,
    focusFirstChild = false,
    ...rest
}: Props) => {
    const root = useRef(null);
    useClickAway(root, onClickAway);
    useLockFocus(root, focusFirstChild);

    const rootElement = document.getElementById('root');
    if (!rootElement) {
        return null;
    }

    return createPortal(
        <div className="modal" role="dialog" aria-modal={true}>
            <PageContent>
                <Surface ref={root} {...rest} level="3">
                    <ZoomableContent>{children}</ZoomableContent>
                </Surface>
            </PageContent>
        </div>,
        rootElement,
    );
};

export default Modal;
