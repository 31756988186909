import React from 'react';
import { useIntl } from 'react-intl';

import { SHARE } from '../../../constants/Sidebar';

import SidebarIcon from '../SidebarIcon';

import { ReactComponent as ShareIcon } from '../../../icons/share.svg';

const ShareIconTab = ({ activateTab, activatedTabId, focusedTabId }) => {
    const intl = useIntl();

    return (
        <SidebarIcon
            onClick={() => activateTab(SHARE)}
            active={activatedTabId === SHARE}
            icon={
                <ShareIcon
                    title={intl.formatMessage({
                        id: 'report.sidebar.hints.share',
                    })}
                />
            }
            id={SHARE}
            tabIndex={focusedTabId === SHARE ? undefined : -1}
        />
    );
};

export default ShareIconTab;
