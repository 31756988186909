import React from 'react';
import { PREMADE_REPORT_ITEM_TYPE } from '../../../../constants/Report';
import { FormattedMessage } from 'react-intl';

type Props = {
    id: number;
};

const PremadeReportsFilterableByTextListDividerItem = ({ id }: Props) => {
    let dividerLabel;
    switch (id) {
        case PREMADE_REPORT_ITEM_TYPE.PREMADE_REPORT:
            dividerLabel = (
                <FormattedMessage id="tablesPage.premadeReports.general" />
            );
            break;
        case PREMADE_REPORT_ITEM_TYPE.TEMPLATE:
        default:
            dividerLabel = (
                <FormattedMessage id="tablesPage.premadeReports.yourTemplatesLabel" />
            );
    }
    return (
        <li className="premade-reports-item divider">
            <span>{dividerLabel}</span>
        </li>
    );
};

export default PremadeReportsFilterableByTextListDividerItem;
