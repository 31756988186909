import React from 'react';
import classNames from 'classnames';
import Button from '../Buttons/Button';
import './SidebarIcon.scss';

const SidebarIcon = ({ active, icon, id, ...rest }) => {
    const classes = classNames('sidebar-icon-container', `${id}-icon`);
    const indicatorClass = classNames({
        'sidebar-active-tab-indicator': active,
        'sidebar-active-tab-indicator-not-active': !active,
    });
    return (
        <div className={classes}>
            <div className="sidebar-icon">
                <Button
                    className="sidebar-icon-button"
                    role="tab"
                    aria-selected={active}
                    aria-controls={`${id}-tab`}
                    id={id}
                    {...rest}
                >
                    {icon}
                </Button>
            </div>
            <div className={indicatorClass} />
        </div>
    );
};

export default SidebarIcon;
