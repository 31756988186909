import browserUpdate from 'browser-update';

export function init(): void {
    browserUpdate({
        required: {
            e: -3, // version more than 3 behind current Edge
            i: 12, // Setting required version of Internet Explorer to 12 ensures all ie users are prompted to update
            f: -3, // version more than 3 behind current Firefox
            o: -3, // version more than 3 behind current Opera
            s: 10.1, // Safari older than 10.1 version
            c: -3, // version more than 3 behind current Chrome
        },
        insecure: true, // Notify all browser versions with severe security issues
    });
}
