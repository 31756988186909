import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import * as Table from '../../../selectors/Table';
import * as ReportMetadata from '../../../selectors/ReportMetadata';
import * as DownloadExcelSelector from '../../../selectors/DownloadExcel';
import { getShouldAllowDownload } from '../../../selectors/CurrentUser';
import { getFetchDatasetsAction } from '../../../actionCreators/tableActions';
import { ReportAppDispatch } from '../../../typescript/actions/actions';
import { DownloadExcelScreen } from '../../../constants/DownloadExcelScreen';

import Loader from '../../Loader/Loader';
import DownloadExcelButtons from './DownloadExcelButtons';
import TrialWarning from '../../TrialWarning/TrialWarning';
import ExcelLimitWarning from './ExcelLimitWarning';

import './DownloadExcelPanel.scss';

const DownloadExcelPanel = () => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const intl = useIntl();
    const downloadExcelWhatToRender = useSelector(
        DownloadExcelSelector.getDownloadExcelWhatToRender,
    );
    const downloadExcelError = useSelector(
        DownloadExcelSelector.getDownloadExcelError,
    );
    const shouldAllowDownload = useSelector(getShouldAllowDownload);
    const surveyCodesUsedInReport = useSelector(
        ReportMetadata.getSurveyCodesUsedInReport,
    );
    const shouldLoadDatasets = useSelector((state) =>
        Table.getShouldLoadDatasets(state, surveyCodesUsedInReport),
    );
    useEffect(() => {
        if (shouldLoadDatasets) {
            dispatch(getFetchDatasetsAction(surveyCodesUsedInReport));
        }
    }, [shouldLoadDatasets, dispatch, surveyCodesUsedInReport]);

    let content;
    switch (downloadExcelWhatToRender) {
        case DownloadExcelScreen.DOWNLOAD_EXCEL_ERROR:
            content = <div>{downloadExcelError}</div>;
            break;
        case DownloadExcelScreen.DOWNLOAD_EXCEL_LOADING:
            content = (
                <Loader
                    text={intl.formatMessage({
                        id: 'report.sidebar.downloadExcel.preparingDownload',
                    })}
                />
            );
            break;
        case DownloadExcelScreen.DOWNLOAD_EXCEL_TOO_MANY_COLUMNS:
            content = <ExcelLimitWarning />;
            break;
        case DownloadExcelScreen.DOWNLOAD_EXCEL_AVAILABLE:
        default:
            content = shouldAllowDownload ? (
                <DownloadExcelButtons />
            ) : (
                <TrialWarning />
            );
    }

    return <div id="download-excel-panel">{content}</div>;
};

export default DownloadExcelPanel;
