import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Table from '../../../selectors/Table';
import * as ReportApp from '../../../typescript/types';
import { ReportAppDispatch } from '../../../typescript/actions/actions';
import * as PremadeReport from '../../../selectors/PremadeReport';
import { getPreparedTablesForSelectionAction } from '../../../actionCreators/tableActions';

import PremadeReportsSelectorHeader from './PremadeReportsSelectorHeader';
import PremadeReportsActions from './PremadeReportsSelectorActions';
import PremadeReportsFilterableByTextList from './PremadeReportsFilterableByTextList/PremadeReportsFilterableByTextList';
import SurveySelector from './SurveySelector';

type Props = {
    closeModal: () => void;
    surveyCodes: ReportApp.SurveyCode[];
};

const PremadeReportsSelector = ({ closeModal, surveyCodes }: Props) => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const [selectedSurveyCode, setSelectedSurveyCode] = useState(
        surveyCodes[0],
    );
    const premadeReportsAndTemplates = useSelector((state) =>
        PremadeReport.getPremadeReportsAndTemplatesWithDividers(
            state,
            selectedSurveyCode,
            surveyCodes,
        ),
    );
    const intermittentTables = useSelector(Table.getIntermittentTables);
    const allPremadeReports = useSelector((state) =>
        PremadeReport.getPremadeReportsAndTemplatesForAllSurveyCodes(
            state,
            surveyCodes,
        ),
    );

    const [selectedPremadeReportIds, setSelectedPremadeReportIds] = useState<
        number[]
    >([]);
    const isPremadeReportSelected = useCallback(
        (id) => selectedPremadeReportIds.includes(id),
        [selectedPremadeReportIds],
    );

    const onAddTables = () => {
        if (intermittentTables) {
            dispatch(
                getPreparedTablesForSelectionAction(
                    selectedPremadeReportIds,
                    allPremadeReports,
                    intermittentTables,
                ),
            );
            closeModal();
        }
    };

    const modifyTablesSelection = (
        actionItem: ReportApp.PremadeReportWithTables,
        affectedItems: ReportApp.PremadeReportWithTables[],
    ) => {
        const premadeReportsIds = affectedItems.map((item) => item.id);

        let newSelection;
        if (!isPremadeReportSelected(actionItem.id)) {
            newSelection = [...selectedPremadeReportIds, ...premadeReportsIds];
        } else {
            newSelection = selectedPremadeReportIds.filter(
                (el) => !affectedItems.some((item) => item.id === el),
            );
        }

        setSelectedPremadeReportIds(Array.from(new Set(newSelection)));
    };

    return (
        <>
            <PremadeReportsSelectorHeader
                onClose={closeModal}
                numberOfSelectedItems={selectedPremadeReportIds.length}
            />
            <SurveySelector
                selectedSurveyCode={selectedSurveyCode}
                setSelectedSurveyCode={setSelectedSurveyCode}
                surveyCodes={surveyCodes}
            />
            <hr />
            <PremadeReportsFilterableByTextList
                list={premadeReportsAndTemplates}
                isSelected={isPremadeReportSelected}
                onChange={modifyTablesSelection}
            />
            <hr />
            <PremadeReportsActions
                onAddTables={onAddTables}
                onClose={closeModal}
            />
        </>
    );
};

export default PremadeReportsSelector;
