import React from 'react';
import { useSelector } from 'react-redux';

import AppScreen from '../../constants/AppScreen';
import * as SurveySelector from '../../selectors/Survey';
import * as NewSurveySelector from '../../selectors/NewSurvey';
import SelectSurveyScreen from '../../constants/SelectSurveyScreen';
import { useCreateTemplate } from '../../customHooks/useCreateTemplate';

import { withSelectionSafeguard } from '../../hoc/withSelectionSafeguard';
import WizardHeader from '../WizardHeader/WizardHeader';
import SelectSurvey from './SelectSurvey';
import Loader from '../Loader/Loader';
import PageContent from '../base/PageContent/PageContent';
import ChangeSurvey from './ChangeSurvey/ChangeSurvey';
import CreateTemplateMessage from './CreateTemplateMessage';

const SelectSurveyPage = () => {
    const display = useSelector(SurveySelector.getWhatToDisplay);
    const error = useSelector(SurveySelector.getError);
    const isForwardButtonEnabled = useSelector(NewSurveySelector.canGoForward);

    // Check if we are on this page to create template. If so, set state in the
    // store to indicate such behavior
    useCreateTemplate();

    let content;
    switch (display) {
        case SelectSurveyScreen.LOADING:
            content = <Loader />;
            break;
        case SelectSurveyScreen.ERROR:
            content = JSON.stringify(error, null, 2);
            break;
        case SelectSurveyScreen.SELECT_SURVEY:
            content = <SelectSurvey />;
            break;
        case SelectSurveyScreen.CHANGE_SURVEY_INFO:
            content = <ChangeSurvey />;
            break;
    }

    return (
        <>
            <WizardHeader
                currentAppScreen={AppScreen.SURVEY_SELECTOR}
                isForwardButtonEnabled={isForwardButtonEnabled}
            />
            <CreateTemplateMessage />
            <PageContent>{content}</PageContent>
        </>
    );
};

export default withSelectionSafeguard(SelectSurveyPage);
