import React from 'react';
import { useIntl } from 'react-intl';

import DropdownMenu from '../../DropdownMenu/DropdownMenu';
import FipsCodesDropdownFilterableByTextList from './FipsCodesDropdownFilterableByTextList';

import './FipsCodesGeoTypeDropdown.scss';

/** @param {{
 *   onChange: Function,
 *   selectedGeoType?: import('../../../typescript/types').GeoType
 *   list: import('../../../typescript/types').GeoType[]
 * }} param0 */
const FipsCodesGeoTypeDropdown = ({ onChange, selectedGeoType, list }) => {
    const intl = useIntl();
    const isSelected = selectedGeoType != null;
    let label;

    if (isSelected) {
        label = `${selectedGeoType.sumLev} ${selectedGeoType.label}`;
    } else {
        label = intl.formatMessage({
            id: 'geographiesPage.fipsSelection.dropdownDefault',
        });
    }

    return (
        <div className="zoomable-header fips-codes-selector-dropdown-wrapper">
            <DropdownMenu
                label={label}
                selected={isSelected}
                onChange={onChange}
            >
                <FipsCodesDropdownFilterableByTextList list={list} />
            </DropdownMenu>
        </div>
    );
};

export default FipsCodesGeoTypeDropdown;
