import React from 'react';
import { useSelector } from 'react-redux';

import * as ReportMetadata from '../../../../selectors/ReportMetadata';
import { TablesUsedInReport } from '../../../../typescript/types';

import TableBodyJumpButtons from '../TableBodyJumpButtons';
import SingleTableDollarAdjustmentInfo from './SingleTableDollarAdjustmentInfo';

import './SingleTableTitle.scss';

type Props = {
    table: TablesUsedInReport;
    displayNavigationButtons: boolean;
};

const SingleTableTitle = ({ table, displayNavigationButtons }: Props) => {
    const { title, url, showDollarAdjustmentInfo, dollarYear } = table;
    const colSpan = useSelector(ReportMetadata.getNumberOfValueColumnsInReport);

    return (
        <tr className="table-title">
            <th scope="colgroup" colSpan={colSpan + 1}>
                <div className="sticky-title">
                    <a
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={title}
                    >
                        {title}
                    </a>
                    {showDollarAdjustmentInfo && dollarYear && (
                        <SingleTableDollarAdjustmentInfo
                            dollarYear={dollarYear}
                        />
                    )}
                    {displayNavigationButtons && (
                        <div>
                            <TableBodyJumpButtons />
                        </div>
                    )}
                </div>
            </th>
        </tr>
    );
};

export default SingleTableTitle;
