import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { score } from '../../../../helpers/Search';
import * as ReportApp from '../../../../typescript/types';
import { getGeoTypes } from '../../../../selectors/GeoTypes';

import FilterableByTextList from '../../../base/FilterableByTextList/FilterableByTextList';
import GeoTypesFilterableByTextListItem from './GeoTypesFilterableByTextListItem';

import './GeoTypesFilterableByTextList.scss';

type Props = {
    surveyCode: ReportApp.SurveyCode;
    onSelect: (
        selectedItem: ReportApp.GeoType,
        affectedItems: ReportApp.GeoType[],
    ) => void;
};

/**
 * Used by FilterableByTextList to score out surveys
 */
const scoreCallback = (geoType: ReportApp.GeoType, lowerCaseTokens: string[]) =>
    score(`${geoType.sumLev} ${geoType.label}`, lowerCaseTokens);

const itemRenderer = (geoType: ReportApp.GeoType) => (
    <GeoTypesFilterableByTextListItem key={geoType.guid} geoType={geoType} />
);

const GeoTypesFilterableByTextList = ({ onSelect, surveyCode }: Props) => {
    const geoTypes = useSelector((state) => getGeoTypes(state, surveyCode));
    const intl = useIntl();

    if (!geoTypes) {
        return null;
    }

    const placeholder = intl.formatMessage({
        id: 'geographiesPage.geoTypes.searchPlaceholder',
    });

    const ariaListDescription = intl.formatMessage({
        id: 'geographiesPage.geoTypes.listDescription',
    });

    return (
        <FilterableByTextList
            list={geoTypes}
            scoreCallback={scoreCallback}
            render={itemRenderer}
            resultsClassName="geotypes-filterable-by-text-list"
            placeholder={placeholder}
            onChange={onSelect}
            description={ariaListDescription}
        />
    );
};

export default GeoTypesFilterableByTextList;
