import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TRACTIQ_PALETTE } from '../../../../../typescript/enums';
import HintArrowIcon from '../../../../SelectionHint/ArrowIcon';
import './CompareHint.scss';
import HintComapreChartIcon from './HintCompareChart';

const CompareHint = () => {
    let hintArrowIcon = <></>;
    let hintCompareChartIcon = <></>;
    hintArrowIcon = <HintArrowIcon color={TRACTIQ_PALETTE['--color-800']} />;
    hintCompareChartIcon = (
        <HintComapreChartIcon
            circleColor={TRACTIQ_PALETTE['--color-800']}
            backgroundColor={TRACTIQ_PALETTE['--color-50']}
        />
    );
    return (
        <div className="compare-hint">
            <div className="icon-chart">{hintCompareChartIcon}</div>
            <div className="compare-hint-text">
                <FormattedMessage
                    id="report.sidebar.modify.surveys.yearSelection"
                    values={{
                        b: (chunks: React.ReactNode) => (
                            <strong>{chunks}</strong>
                        ),
                    }}
                />
            </div>
            <div className="icon-right-arrow">{hintArrowIcon}</div>
        </div>
    );
};

export default CompareHint;
