import React from 'react';
import { FormattedMessage } from 'react-intl';

import JumpButton from '../../JumpButton/JumpButton';

import './ReportActions.scss';

import DOMElementID from '../../../constants/DOMElementID';

const ReportActionsJumpButtons = () => {
    return (
        <>
            <JumpButton
                key="skip2menu"
                variant="flat"
                targetElementID={DOMElementID.SIDEBAR_MODIFY_REPORT}
            >
                <FormattedMessage id={'layoutNavigation.skip2menu'} />
            </JumpButton>
            <JumpButton
                key="skip2reportContent"
                variant="flat"
                targetElementID={DOMElementID.CONTENT_TABLE_WRAPPER}
            >
                <FormattedMessage id={'layoutNavigation.skip2reportContent'} />
            </JumpButton>
            <JumpButton
                key="skip2pagination"
                variant="flat"
                targetElementID={DOMElementID.FOOTER_PAGINATION_CONTAINER}
            >
                <FormattedMessage id={'layoutNavigation.skip2pagination'} />
            </JumpButton>
        </>
    );
};

export default ReportActionsJumpButtons;
