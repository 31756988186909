import React from 'react';
import { useSelector } from 'react-redux';

import * as ReportApp from '../../../../typescript/types';
import * as ReportMetadata from '../../../../selectors/ReportMetadata';

import './SingleTableVariableLabel.scss';

type Props = {
    tableGuidByReportId: ReportApp.TableGuidByOldReportId;
    variableIndex: number;
};

const SingleTableVariableLabel = ({
    tableGuidByReportId,
    variableIndex,
}: Props) => {
    const firstVariable = useSelector((state) =>
        ReportMetadata.getFirstVariable(
            state,
            tableGuidByReportId,
            variableIndex,
        ),
    );
    if (!firstVariable) {
        return null;
    }
    const { indent, label } = firstVariable;
    return (
        <th scope="row" className="variable-label">
            <span className={`indent-${indent}`} title={label}>
                {label}
            </span>
        </th>
    );
};

export default SingleTableVariableLabel;
