import general from './general.json';
import geographiesPage from './geographiesPage.json';
import surveysPage from './surveysPage.json';
import tablesPage from './tablesPage.json';
import report from './report.json';
import header from './header.json';
import navigator from './navigator.json';

export default {
    ...general,
    geographiesPage,
    surveysPage,
    tablesPage,
    report,
    header,
    navigator,
};
