import React from 'react';

import { useToggleSet } from '../../../../customHooks/useToggleSet';

import SelectYearsTitle from './SelectYearsTitle';
import SelectYearsButtons from './SelectYearsButtons';
import SelectSurveyDone from './SelectSurveyDone';
import SelectYearsInfo from './SelectYearsInfo';

const MAX_NUMBER_OF_YEARS_SELECTED = 3;

type Props = {
    years: number[];
    initialSelection: number[];
    onDone: (newYears: number[]) => void;
    onClose: () => void;
};

const SelectYears = ({ years, initialSelection, onDone, onClose }: Props) => {
    const {
        toggleSet: selectedYears,
        toggle: toggleYear,
        has: hasYear,
    } = useToggleSet(initialSelection);

    return (
        <div className="select-years">
            <SelectYearsTitle
                onClose={onClose}
                maximumNumberOfYears={MAX_NUMBER_OF_YEARS_SELECTED}
            />
            <SelectYearsButtons
                years={years}
                hasYear={hasYear}
                onToggle={toggleYear}
                isMaxNumberOfSelectedYearsReached={
                    selectedYears.size === MAX_NUMBER_OF_YEARS_SELECTED
                }
            />
            <hr />
            <SelectYearsInfo />
            <SelectSurveyDone
                initialSelection={initialSelection}
                selectedYears={selectedYears}
                hasYear={hasYear}
                onDone={onDone}
            />
        </div>
    );
};

export default SelectYears;
