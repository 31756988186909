import en from './en';
import bs from './bs';
import hr from './hr';
import sr from './sr';
import es from './es';
import { flattenMessages } from '../helpers/FlattenMessages';
import { Languages } from '../typescript/enums';

let languageToUse;
switch (process.env.REACT_APP_LANGUAGE) {
    case Languages.EN:
        languageToUse = en;
        break;
    case Languages.BS:
        languageToUse = bs;
        break;
    case Languages.HR:
        languageToUse = hr;
        break;
    case Languages.SR:
        languageToUse = sr;
        break;
    case Languages.ES:
        languageToUse = es;
        break;
}

export default flattenMessages(languageToUse);

export const locale = process.env.REACT_APP_LANGUAGE;
