import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ReportAppDispatch } from '../../../typescript/actions/actions';
import {
    getSelectedGeographies,
    isSelectedGeoTypeLevel,
} from '../../../selectors/GeoTypes';
import { SET_INTERMITTENT_FIPS_SELECTION } from '../../../constants/Geographies';
import { CLEAR_SELECTED_SCREEN } from '../../../constants/SelectGeographyScreen';

import Button from '../../Buttons/Button';
import AddToSelection from '../AddToSelection';
import MultipleSelectionHint from '../../Selection/MultipleSelectionHint';

import './GeoItemsActions.scss';

export const GeoItemsActions = () => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const selectedGeographies = useSelector(getSelectedGeographies);
    const shouldRenderHint = useSelector(isSelectedGeoTypeLevel);

    const resetFipsSelection = useCallback(() => {
        dispatch({
            type: SET_INTERMITTENT_FIPS_SELECTION,
            payload: selectedGeographies,
        });
        dispatch({ type: CLEAR_SELECTED_SCREEN });
    }, [dispatch, selectedGeographies]);

    return (
        <div className="geo-items-action">
            <MultipleSelectionHint preventHintRender={!shouldRenderHint} />
            <div className="geo-items-action-actions">
                <Button variant="flat" onClick={resetFipsSelection}>
                    <FormattedMessage id="cancel" />
                </Button>
                <AddToSelection />
            </div>
        </div>
    );
};
