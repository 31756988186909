import React from 'react';
import { useSelector } from 'react-redux';

import * as ReportMetadata from '../../../../selectors/ReportMetadata';

import './SingleTableEmptyRow.scss';

const SingleTableEmptyRow = () => {
    const colSpan = useSelector(ReportMetadata.getNumberOfValueColumnsInReport);
    return (
        <tr className="empty-row">
            <td aria-hidden />
            <td colSpan={colSpan} aria-hidden />
        </tr>
    );
};

export default SingleTableEmptyRow;
