import { FETCH_CPI_SUCCESS } from '../constants/Report';
import { ReportAppActions } from '../typescript/actions/actions';
import { ConsumerPriceIndex } from '../typescript/types';

export default (state = {}, action: ReportAppActions): ConsumerPriceIndex => {
    switch (action.type) {
        case FETCH_CPI_SUCCESS:
            return {
                ...state,
                ...action.payload.consumerPriceIndices,
            };
        default:
            return state;
    }
};
