import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import Button from '../../../Buttons/Button';

type Props = {
    requestSaveTemplate: () => void;
    templateTitle: string;
    onClose: () => void;
};

const SaveTemplateFooter = ({
    requestSaveTemplate,
    templateTitle,
    onClose,
}: Props) => {
    const intl = useIntl();
    return (
        <div className="template-action-modal-footer zoomable-actions">
            <Button variant="flat" onClick={onClose} className="small">
                {intl.formatMessage({ id: 'cancel' })}
            </Button>
            <Button
                variant="contained"
                onClick={requestSaveTemplate}
                disabled={!templateTitle.trim().length}
                className="small"
            >
                <FormattedMessage id="save" />
            </Button>
        </div>
    );
};

export default SaveTemplateFooter;
