import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getItemsPerPage } from '../../../../selectors/ReportNavigator';
import * as ReportConstants from '../../../../constants/Report';
import { useTranslatedConstantsForNavigator } from '../../../../customHooks/useTranslatedConstants';

import SelectItemsPerPage from './SelectItemsPerPage';

type Props = {
    onClosePopover: () => void;
};

const NavigatorPopoverContent = ({ onClosePopover }: Props) => {
    const [tablesPerPage, geoItemsPerPage] = useSelector(getItemsPerPage);

    const {
        tablesPerPageList,
        geographiesPerPageList,
    } = useTranslatedConstantsForNavigator();

    return (
        <div className="navigator-popover-content">
            <div className="navigator-popover-content-row title">
                <div className="label">
                    <FormattedMessage id="navigator.popover.setMaximumPageSize" />
                </div>
                <div className="placeholder" />
            </div>
            <div className="navigator-popover-content-row">
                <div className="label">
                    <FormattedMessage id="navigator.popover.geosPerPage" />
                </div>
                <SelectItemsPerPage
                    itemsPerPageList={geographiesPerPageList}
                    itemsPerPage={geoItemsPerPage}
                    setItemsPerPageRequestType={
                        ReportConstants.SET_GEO_ITEMS_PER_PAGE_REQUEST
                    }
                    onClosePopover={onClosePopover}
                />
            </div>
            <div className="navigator-popover-content-row">
                <div className="label">
                    <FormattedMessage id="navigator.popover.tablesPerPage" />
                </div>
                <SelectItemsPerPage
                    itemsPerPageList={tablesPerPageList}
                    itemsPerPage={tablesPerPage}
                    setItemsPerPageRequestType={
                        ReportConstants.SET_TABLES_PER_PAGE_REQUEST
                    }
                    onClosePopover={onClosePopover}
                />
            </div>
        </div>
    );
};

export default NavigatorPopoverContent;
