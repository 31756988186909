import React from 'react';
import classnames from 'classnames';
import './PageContent.scss';

const PageContent = ({
    children,
    className,
    ...rest
}: React.ButtonHTMLAttributes<HTMLDivElement>) => {
    const classes = classnames(className, 'page-content');
    return (
        <div className={classes} {...rest}>
            {children}
        </div>
    );
};

export default PageContent;
