import React from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from '../../Icon/Icon';
import Surface from '../../base/Surface/Surface';

import './ChangeSurveyMatchMessage.scss';

type Props = {
    years: number[];
};

const ChangeSurveyMatchMessage = ({ years }: Props) => (
    <Surface level="1" variant="green" className="change-survey-match">
        <div className="match-icon">
            <Icon>check_circle</Icon>
        </div>
        <span>
            <FormattedMessage
                id="surveysPage.changeSurvey.matchDescription"
                values={{
                    years: years.join(', '),
                    b: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
                }}
            />
        </span>
    </Surface>
);

export default ChangeSurveyMatchMessage;
