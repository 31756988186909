import React from 'react';
import { useSelector } from 'react-redux';

import * as ReportData from '../../../../selectors/ReportData';
import * as ReportApp from '../../../../typescript/types';

type Props = {
    tableGuidByReportId: ReportApp.TableGuidByOldReportId;
    sideBySides: ReportApp.SideBySideDefinition[];
    variableIndex: number;
    geoGroupIndex: number;
    geoFips: ReportApp.FipsCodeWithSummaryLevel;
};

const SingleTableSideBySideDiff = ({
    tableGuidByReportId,
    sideBySides,
    variableIndex,
    geoGroupIndex,
    geoFips,
}: Props) => {
    const value = useSelector((state) =>
        ReportData.getDiff(
            state,
            tableGuidByReportId,
            // make a diff between first (0) and the last side by side element
            0,
            // last is length-2 and not length-1 because length-1 points to
            // virtual sideBySide object, the one where isDiff === true.
            sideBySides.length - 2,
            variableIndex,
            geoGroupIndex,
            geoFips,
        ),
    );
    return <td className="variable-percent">{value}</td>;
};

export default SingleTableSideBySideDiff;
