import React from 'react';
import { FormattedMessage } from 'react-intl';

import { GeoTypeSelection } from '../../../typescript/types';

import './GeoTypeSelectionDescription.scss';

type Props = {
    selection: GeoTypeSelection;
};

const GeoTypeSelectionDescription = ({
    selection: { sumLev, geoNesting, selectionCount },
}: Props) => {
    return (
        <div className="summary-level-download">
            <div className="description">
                <div className="description-item">
                    <div className="item-title">
                        <FormattedMessage id="report.sidebar.downloadCsv.geoTypeSelectionDescription.sl" />
                    </div>
                    <div className="item-value">{sumLev}</div>
                </div>
                <div className="description-item">
                    <div className="item-title">
                        <FormattedMessage id="report.sidebar.downloadCsv.geoTypeSelectionDescription.geoNesting" />
                    </div>
                    <div className="item-value">
                        {geoNesting.replace(/-/g, ' > ')}
                    </div>
                </div>
                <div className="description-item">
                    <div className="item-title">
                        <FormattedMessage id="report.sidebar.downloadCsv.geoTypeSelectionDescription.selected" />
                    </div>
                    <div className="item-value">{selectionCount}</div>
                </div>
            </div>
        </div>
    );
};

export default GeoTypeSelectionDescription;
