import React from 'react';
import { isElementChildOf } from './HtmlHelper';

type StackPair = {
    callback: (e: MouseEvent | KeyboardEvent) => void;
    ref: React.MutableRefObject<HTMLElement> | React.RefObject<HTMLElement>;
};

let stack: StackPair[] = [];

function callCallbackAndStopPropagation(
    callback: (e: MouseEvent | KeyboardEvent) => void,
    event: MouseEvent | KeyboardEvent,
): void {
    callback(event);
    event.stopPropagation();
    event.preventDefault();
}

const handleClick = (event: MouseEvent) => {
    if (stack.length) {
        // check last element in stack
        const { ref, callback } = stack[stack.length - 1];
        const rootElement = ref.current;
        if (
            rootElement &&
            // event.target (https://developer.mozilla.org/en-US/docs/Web/API/Event/target)
            // is of type EventTarget (https://developer.mozilla.org/en-US/docs/Web/API/EventTarget)
            // and it doesn't need to be of the type Element. But, since we know
            // that handleClick method will be called on HTMLElement, we can use
            // this cast over here
            !isElementChildOf(event.target as HTMLElement | null, rootElement)
        ) {
            callCallbackAndStopPropagation(callback, event);
        }
    }
};

const handleEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && stack.length) {
        const { callback } = stack[stack.length - 1];
        callCallbackAndStopPropagation(callback, event);
    }
};

export const subscribe = (
    ref: React.MutableRefObject<HTMLElement> | React.RefObject<HTMLElement>,
    callback: (e: MouseEvent | KeyboardEvent) => void,
) => {
    if (stack.length === 0) {
        document.addEventListener('click', handleClick, true);
        document.addEventListener('keyup', handleEscape, true);
        stack = [];
    }
    stack.push({ ref, callback });
};

export const unsubscribe = (
    ref: React.MutableRefObject<HTMLElement> | React.RefObject<HTMLElement>,
    callback: (e: MouseEvent | KeyboardEvent) => void,
) => {
    const index = stack.findIndex(
        (element) => element.ref === ref && element.callback === callback,
    );
    if (index !== -1) {
        stack.splice(index, 1);
    }
};
