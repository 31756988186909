import React, { useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import Popover from '../../base/Popover/Popover';
import IconButton from '../../IconButton/IconButton';
import HelpMenuItems from '../../HelpMenuItems/HelpMenuItems';

import './HelpMenu.scss';

const HelpMenu = () => {
    const intl = useIntl();
    const buttonLabel = intl.formatMessage({ id: 'help' });

    const root = useRef<HTMLDivElement>(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const openPopover = useCallback(() => setIsPopoverOpen(true), []);
    const closePopover = useCallback(() => setIsPopoverOpen(false), []);

    return (
        <div ref={root} style={{display: 'none'}}>
            <IconButton
                onClick={openPopover}
                title={buttonLabel}
                aria-label={buttonLabel}
                className="help-button"
                aria-haspopup={true}
                aria-expanded={isPopoverOpen}
            >
                help_outline
            </IconButton>
            {isPopoverOpen && (
                <Popover
                    parent={root.current}
                    onClickAway={closePopover}
                    className="more-options-popover"
                >
                    <HelpMenuItems />
                </Popover>
            )}
        </div>
    );
};

export default HelpMenu;
