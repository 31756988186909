import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import * as Table from '../../selectors/Table';
import { getFetchDatasetsAction } from '../../actionCreators/tableActions';
import * as SelectTablesPageConstants from '../../constants/SelectTablesPage';
import * as ReportMetadata from '../../selectors/ReportMetadata';

import Loader from '../Loader/Loader';
import SourceNodes from './SourceNodes';

import './SourcesPanel.scss';

const SourcesPanel = () => {
    const dispatch = useDispatch();

    const surveyCodesUsedInReport = useSelector(
        ReportMetadata.getSurveyCodesUsedInReport,
    );
    const shouldLoadDatasets = useSelector((state) =>
        Table.getShouldLoadDatasets(state, surveyCodesUsedInReport),
    );
    const hasMultipleDatasets = useSelector(
        ReportMetadata.getHasMultipleDatasets,
    );
    const datasetsDisplayStatus = useSelector((state) =>
        Table.getDatasetsDisplayStatus(state, surveyCodesUsedInReport),
    );
    const datasetsLoadingError = useSelector(Table.getDatasetsError);

    useEffect(() => {
        if (shouldLoadDatasets) {
            dispatch(getFetchDatasetsAction(surveyCodesUsedInReport));
        }
    }, [shouldLoadDatasets, dispatch, surveyCodesUsedInReport]);

    let sources;
    switch (datasetsDisplayStatus) {
        case SelectTablesPageConstants.DATASETS_ERROR:
            sources = (
                <div>
                    <FormattedMessage
                        id="tablesPage.tablesSelection.errorLoadingDatasets"
                        values={{
                            error: datasetsLoadingError,
                        }}
                    />
                </div>
            );
            break;
        case SelectTablesPageConstants.DATASETS_LOADING:
            sources = <Loader />;
            break;
        case SelectTablesPageConstants.DATASETS_SELECTION: {
            sources = <SourceNodes hasMultipleDatasets={hasMultipleDatasets} />;
            break;
        }
        default:
            sources = null;
    }

    const sourcesLabel = hasMultipleDatasets ? (
        <FormattedMessage id="report.sidebar.info.sources" />
    ) : (
        <FormattedMessage id="report.sidebar.info.source" />
    );

    return (
        <>
            <div className="title sources-label">{sourcesLabel}</div>
            {sources}
        </>
    );
};

export default SourcesPanel;
