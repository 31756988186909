import React from 'react';

import Button from '../../../Buttons/Button';

import './SelectYearsButtons.scss';

type Props = {
    years: number[];
    onToggle: (year: number) => void;
    hasYear: (year: number) => boolean;
    isMaxNumberOfSelectedYearsReached: boolean;
};

const SelectYearsButtons = ({
    years,
    onToggle,
    hasYear,
    isMaxNumberOfSelectedYearsReached,
}: Props) => {
    const elements = years.map((year) => (
        <Button
            key={year}
            className="select-year-button"
            variant={hasYear(year) ? 'contained' : 'flat'}
            disabled={isMaxNumberOfSelectedYearsReached && !hasYear(year)}
            onClick={() => onToggle(year)}
        >
            {year}
        </Button>
    ));
    return <div className="select-year-buttons">{elements}</div>;
};

export default SelectYearsButtons;
