import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import IconButton from '../../../IconButton/IconButton';

import './SelectYearsTitle.scss';

type Props = {
    onClose: React.MouseEventHandler<HTMLButtonElement>;
    maximumNumberOfYears: number;
};

const SelectYearsTitle = ({ onClose, maximumNumberOfYears }: Props) => {
    const intl = useIntl();
    const closeMessage = intl.formatMessage({ id: 'close' });
    return (
        <div className="select-years-title">
            <h5>
                <FormattedMessage
                    id="report.sidebar.modify.surveys.title"
                    values={{ count: maximumNumberOfYears }}
                />
            </h5>
            <IconButton
                onClick={onClose}
                title={closeMessage}
                aria-label={closeMessage}
                className="default"
            >
                close
            </IconButton>
        </div>
    );
};

export default SelectYearsTitle;
