import React from 'react';
import { useSelector } from 'react-redux';

import * as ReportApp from '../../../../typescript/types';
import * as ReportMetadata from '../../../../selectors/ReportMetadata';
import { getOldReportIdByGeoGroupIndexColumnGroupIndex } from '../../../../helpers/Report';

import SingleTableVariableValuePair from './SingleTableVariableValuePair';
import SingleTableSideBySideDiff from './SingleTableSideBySideDiff';

type Props = {
    tableGuidByReportId: ReportApp.TableGuidByOldReportId;
    variableIndex: number;
};

const SingleTableVariableValues = ({
    tableGuidByReportId,
    variableIndex,
}: Props) => {
    const geos = useSelector(ReportMetadata.getPaginatedGeographies);
    const definition = useSelector(ReportMetadata.getDefinition);
    if (!definition) {
        return null;
    }
    const elements = geos.flatMap((geoItem) =>
        geoItem.sideBySide.map((sideBySide, sideBySideIndex, sideBySides) => {
            if (sideBySide.isDiff) {
                return (
                    <SingleTableSideBySideDiff
                        key={`diff${geoItem.geoGroupIndex}|${geoItem.geoFips}`}
                        tableGuidByReportId={tableGuidByReportId}
                        sideBySides={sideBySides}
                        variableIndex={variableIndex}
                        geoGroupIndex={geoItem.geoGroupIndex}
                        geoFips={geoItem.geoFips}
                    />
                );
            }
            const oldReportId = getOldReportIdByGeoGroupIndexColumnGroupIndex(
                definition,
                tableGuidByReportId,
                geoItem.geoGroupIndex,
                sideBySideIndex,
            );
            const tableGuid = oldReportId
                ? tableGuidByReportId[oldReportId]
                : undefined;

            return (
                <SingleTableVariableValuePair
                    oldReportId={oldReportId}
                    tableGuid={tableGuid}
                    variableIndex={variableIndex}
                    geoFips={geoItem.geoFips}
                    maxColSpan={sideBySide.colSpan}
                    key={`${geoItem.geoGroupIndex}|${geoItem.geoFips}|${sideBySideIndex}`}
                />
            );
        }),
    );
    return <>{elements}</>;
};

export default SingleTableVariableValues;
