const LETTER_REGEX = /[a-zA-Z0-9-_%]/;
const NOT_VARIABLE_REGEX = /[0-9/]/;

interface Params {
    [key: string]: string | number | boolean;
}

type ParseResult = {
    valid: boolean;
    value: string;
    endIndex: number;
    key?: string;
};

export class StringPart {
    part: string;
    constructor(part: string) {
        this.part = part;
    }

    parse(path: string, startFrom: number): ParseResult {
        if (path.slice(startFrom).startsWith(this.part)) {
            return {
                valid: true,
                value: this.part,
                endIndex: startFrom + this.part.length,
            };
        }
        return {
            valid: false,
            endIndex: -1,
            value: '',
        };
    }

    getPath() {
        return this.part;
    }
}

export class ParamPart {
    part: string;
    paramName: string;
    constructor(part: string) {
        this.part = part;
        this.paramName = part.slice(1);
    }

    parse(path: string, startFrom: number): ParseResult {
        const workingPart = path.slice(startFrom);
        const { length } = workingPart;
        let value = '';
        let i = 0;
        while (LETTER_REGEX.test(workingPart[i]) && i < length) {
            value += workingPart[i];
            i += 1;
        }
        return {
            value,
            valid: value.length > 0,
            key: this.paramName,
            endIndex: startFrom + i,
        };
    }

    getPath(params?: Params) {
        if (!params) {
            return '';
        }
        return encodeURIComponent(params[this.paramName]);
    }
}

export default class Route {
    path: string;
    parts: (StringPart | ParamPart)[];
    constructor(path: string) {
        this.path = path;
        this.parts = [];
        this._parse();
    }

    isPath(path: string) {
        const pathIndex = this._getPathIndex(path);
        if (pathIndex == null) {
            return false;
        }
        return pathIndex > 0;
    }

    isExactPath(path: string) {
        const pathIndex = this._getPathIndex(path);
        return pathIndex === path.length;
    }

    getParams(path: string) {
        const params: { [key: string]: string } = {};
        let pathIndex = 0;
        for (let i = 0; i < this.parts.length; i += 1) {
            const part = this.parts[i];
            const result = part.parse(path, pathIndex);
            if (!result.valid) {
                return undefined;
            }
            if (result.key) {
                params[result.key] = result.value;
            }
            pathIndex = result.endIndex;
        }
        return params;
    }

    getPath(params?: Params) {
        let path = '';
        for (let i = 0; i < this.parts.length; i += 1) {
            const part = this.parts[i];
            path += part.getPath(params);
        }
        return path;
    }

    _getPathIndex(path: string) {
        let pathIndex = 0;
        for (let i = 0; i < this.parts.length; i += 1) {
            const part = this.parts[i];
            const result = part.parse(path, pathIndex);
            if (!result.valid) {
                return undefined;
            }
            pathIndex = result.endIndex;
        }
        return pathIndex;
    }

    _parse() {
        let lastPart = '';
        let isParam = false;
        for (let i = 0; i < this.path.length; i += 1) {
            const char = this.path[i];
            // if letter just append to lastPart
            if (LETTER_REGEX.test(char)) {
                lastPart += char;
                continue;
            }
            if (lastPart.length > 0) {
                const part = isParam
                    ? new ParamPart(lastPart)
                    : new StringPart(lastPart);
                this.parts.push(part);
            }
            lastPart = char;
            isParam =
                char === ':' &&
                !NOT_VARIABLE_REGEX.test(this.path[i + 1]) &&
                this.path.indexOf('mailto:') !== 0;
        }
        if (lastPart.length > 0) {
            const part = isParam
                ? new ParamPart(lastPart)
                : new StringPart(lastPart);
            this.parts.push(part);
        }
    }
}
