import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as GeoType from '../../../selectors/GeoTypes';
import { CLEAR_SELECTED_SCREEN } from '../../../constants/SelectGeographyScreen';
import {
    FETCH_GEO_ITEMS_REQUEST,
    SET_GEO_SELECTION,
} from '../../../constants/Geographies';
import { SurveyCode } from '../../../typescript/types';

import Modal from '../../base/Modal/Modal';
import { GeoItemsActions } from './GeoItemsActions';
import GeoItemParentSelector from './GeoItemParentSelector';
import { GeographiesModalHeader } from '../GeographiesModalHeader';
import { GeoItemFilterableByTextList } from './GeoItemFilterableByTextList';

type Props = {
    surveyCode: SurveyCode;
};

const GeoItemsSelector = ({ surveyCode }: Props) => {
    const dispatch = useDispatch();
    const geoType = useSelector((state) =>
        GeoType.getSelectedGeoType(state, surveyCode),
    );
    const initialGeoSelectionsState = useSelector((state) =>
        GeoType.getInitialGeoSelectionsState(state, surveyCode),
    );
    const shouldInitializeGeoSelections = useSelector((state) =>
        GeoType.shouldInitializeGeoSelectionsState(state, surveyCode),
    );
    const finalSelection = useSelector(GeoType.getFinalSelection);

    useEffect(() => {
        if (finalSelection !== undefined) {
            dispatch({
                type: FETCH_GEO_ITEMS_REQUEST,
                payload: {
                    geoType: geoType,
                    geoTypeSelectionName: geoType.name,
                    geoSelectionFips: finalSelection,
                },
            });
        }
    }, [dispatch, finalSelection, geoType]);

    useEffect(() => {
        if (shouldInitializeGeoSelections) {
            dispatch({
                type: SET_GEO_SELECTION,
                payload: initialGeoSelectionsState,
            });
        }
    }, [dispatch, shouldInitializeGeoSelections, initialGeoSelectionsState]);

    const closeModal = useCallback(() => {
        dispatch({
            type: CLEAR_SELECTED_SCREEN,
        });
    }, [dispatch]);

    return (
        <Modal className="full-width" onClickAway={closeModal}>
            <GeographiesModalHeader geoTypeSelected surveyCode={surveyCode} />
            <GeoItemParentSelector surveyCode={surveyCode} />
            <hr />
            <GeoItemFilterableByTextList surveyCode={surveyCode} />
            <GeoItemsActions />
        </Modal>
    );
};

export default GeoItemsSelector;
