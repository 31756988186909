import * as ReportApp from '../typescript/types';
// NOTE: similar implementation is present in the map app.
// If you make any changes here, you should check the map app
// implementation as well.

/**
 * Since number of client computed functions is relatively small, but it get's
 * created for every geo item displayed (think of excel download), it makes
 * sense to memoize them
 */
const memoize: {
    [functionBody: string]:
        | ReportApp.ClientFunction
        | ReportApp.AsyncClientFunction;
} = {};

/**
 * Parse a string function definition and return a function object.
 * Does not use eval.
 *
 * Heavily inspired by: https://gist.github.com/lamberta/3768814
 *
 * @example
 *  var f = function (x, y) { return x * y; };
 *  var g = parseFunction(f.toString());
 *  g(33, 3); //=> 99
 *
 */
function parseFunction(str: string): Function {
    const fnBodyIdx = str.indexOf('{'),
        fnBody = str.substring(fnBodyIdx + 1, str.lastIndexOf('}')),
        fnDeclare = str.substring(0, fnBodyIdx),
        fnParams = fnDeclare.substring(
            fnDeclare.indexOf('(') + 1,
            fnDeclare.lastIndexOf(')'),
        ),
        args = fnParams.split(',');

    args.push(fnBody);

    return Function.apply(undefined, args);
}
const VARIABLE_REGEX = /#([a-zA-Z0-9_]+):([a-zA-Z0-9_]+)/g;
function variableParser(functionBody: string): string {
    return functionBody.replace(
        VARIABLE_REGEX,
        (_, datasetCode, variableCode) => {
            return `getValue('${datasetCode}', '${variableCode}')`;
        },
    );
}

function getFunction(functionBody: string): ReportApp.ClientFunction {
    const fcWithParsedVariables = variableParser(functionBody);
    const fcString = `function (getValue, clientValues) {${fcWithParsedVariables}}`;
    return parseFunction(fcString) as ReportApp.ClientFunction;
}

function getAsyncFunction(functionBody: string): ReportApp.AsyncClientFunction {
    const fcWithParsedVariables = variableParser(functionBody);
    const fcString = `function (getValue, clientValues) {
        return async function () {${fcWithParsedVariables}};
    }`;
    return parseFunction(fcString) as ReportApp.AsyncClientFunction;
}
/**
 * @param functionBody Javascript function received from metadata backend service
 */
// This function is covered with tests. If you make any change to it,
// please run tests. Also, if you find some edge case, cover it in tests as well.
export default function (functionBody: string) {
    if (!memoize[functionBody]) {
        let fc: ReportApp.ClientFunction | ReportApp.AsyncClientFunction;
        if (functionBody.includes('await ')) {
            fc = getAsyncFunction(functionBody);
        } else {
            fc = getFunction(functionBody);
        }
        memoize[functionBody] = fc;
    }
    return memoize[functionBody];
}
