import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import * as ReportApp from '../../../typescript/types';

import Surveys from './Surveys';
import Surface from '../../base/Surface/Surface';
import Button from '../../Buttons/Button';

import './SurveyGroup.scss';

type Props = {
    surveyGroup: ReportApp.SurveyGroup;
    onSelect: (survey: ReportApp.Survey) => void;
};

const SurveyGroup = ({ surveyGroup, onSelect }: Props) => {
    const intl = useIntl();
    const [descriptionVisible, setDescriptionVisible] = useState(false);
    const { name, description, surveyCodes } = surveyGroup;

    const toggleDescription = () =>
        setDescriptionVisible((descriptionVisible) => !descriptionVisible);

    const buttonLabel = intl.formatMessage({
        id: descriptionVisible
            ? 'surveysPage.hideInfo'
            : 'surveysPage.showInfo',
    });

    return (
        <Surface level="1" className="survey-group">
            <div className="description">
                <div>
                    <h4>{name}</h4>
                    <Button onClick={toggleDescription}>{buttonLabel}</Button>
                </div>
                {descriptionVisible && (
                    <p dangerouslySetInnerHTML={{ __html: description }} />
                )}
            </div>
            <Surveys
                surveyCodes={surveyCodes}
                onSelect={onSelect}
                surveyGroupName={name}
            />
        </Surface>
    );
};

export default SurveyGroup;
