/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

import FilterableByTextListNoResults from './FilterableByTextListNoResults';
import { getFirstChildOf } from '../../../helpers/HtmlHelper';
import { getFirstFocusableChild } from '../../../customHooks/useLockFocus';

import './FilterableByTextListItems.scss';

/**
 * Properties that are passthrough (...rest) in FilterableByTextList component
 */
export interface BaseProps<T> {
    list: T[];
    scoreCallback: (item: T, lowerCaseTokens: string[]) => number;
    render: (item: T) => JSX.Element;
    resultsClassName?: string;
    onChange?: (selectedItem: T, affectedItems: T[]) => void;
    description?: string;
}

interface Props<T> extends BaseProps<T> {
    lowerCaseTokens: string[];
}

function FilterableByTextListItems<T>({
    list,
    scoreCallback,
    render,
    resultsClassName,
    lowerCaseTokens,
    onChange,
    description,
}: Props<T>) {
    const [selectionItemIndex, setSelectionItemIndex] = useState<null | number>(
        null,
    );
    const elementsList = useRef<HTMLUListElement | null>(null);

    const searchLowerCaseTokens = lowerCaseTokens.join();

    // when user changes input (types in something) selection index has to reset
    useEffect(() => setSelectionItemIndex(null), [searchLowerCaseTokens]);

    const intl = useIntl();
    if (list.length === 0) {
        return null;
    }

    let filteredList: T[];
    if (lowerCaseTokens.length > 0) {
        filteredList = list
            .map((item) => ({
                item,
                score: scoreCallback(item, lowerCaseTokens),
            }))
            .filter((itemAndScore) => itemAndScore.score > 0)
            .sort(
                (itemAndScoreA, itemAndScoreB) =>
                    itemAndScoreB.score - itemAndScoreA.score,
            )
            .map((itemAndScore) => itemAndScore.item);
    } else {
        filteredList = list.slice(0);
    }

    const results = filteredList.map(render);

    const resultsElement =
        results.length === 0 ? <FilterableByTextListNoResults /> : results;

    const resultsClasses = classnames(
        'filterable-by-text-list',
        resultsClassName,
    );
    const handleClick = (
        event: React.MouseEvent<HTMLUListElement, MouseEvent>,
    ) => {
        const target = event.target as HTMLElement;
        const currentTarget = event.currentTarget as HTMLElement;
        if (target === currentTarget) {
            return;
        }
        const item = getFirstChildOf(currentTarget, target);
        if (!item) {
            return;
        }
        const index = Array.from(currentTarget.childNodes).indexOf(item);
        if (index === -1) {
            return;
        }
        const selectedItem = filteredList[index];
        let affectedItems: T[] = [];
        if (event.shiftKey && selectionItemIndex !== null) {
            const lowerIndex = Math.min(selectionItemIndex, index);
            const higherIndex = Math.max(selectionItemIndex, index);
            affectedItems = [
                ...affectedItems,
                ...filteredList.slice(lowerIndex, higherIndex + 1),
            ];
        } else {
            affectedItems.push(selectedItem);
        }
        if (onChange) {
            onChange(selectedItem, affectedItems);
        }
        setSelectionItemIndex(index);
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLUListElement>) => {
        const { key } = event;
        if (!key || !elementsList.current) {
            return;
        }
        switch (key) {
            case 'End': {
                const { lastChild } = elementsList.current;
                if (lastChild instanceof HTMLElement) {
                    getFirstFocusableChild(lastChild)?.focus();
                } else {
                    console.error(
                        `Element ${lastChild} is not of type HTMLElement`,
                    );
                }
                break;
            }
            case 'Home': {
                const { firstChild } = elementsList.current;
                if (firstChild instanceof HTMLElement) {
                    getFirstFocusableChild(firstChild)?.focus();
                } else {
                    console.error(
                        `Element ${firstChild} is not of type HTMLElement`,
                    );
                }
                break;
            }
            default:
                break;
        }
    };

    const searchPlaceholder =
        description ||
        intl.formatMessage({
            id: 'searchResults',
        });

    const listDescription = intl.formatMessage(
        {
            id:
                results.length === 0
                    ? 'notFound'
                    : `filterableList.${
                          lowerCaseTokens.length > 0
                              ? 'withSearchTerm'
                              : 'noSearchTerm'
                      }`,
        },
        { searchPlaceholder, searchText: lowerCaseTokens.join(' ') },
    );

    return (
        <ul
            ref={elementsList}
            className={resultsClasses}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            aria-label={listDescription}
        >
            {resultsElement}
        </ul>
    );
}

export default FilterableByTextListItems;
