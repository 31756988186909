import React from 'react';
import * as Sentry from '@sentry/react';
import { FallbackRender } from '@sentry/react/dist/errorboundary';

import ErrorPage from '../ErrorPage/ErrorPage';

type Props = {
    children: any;
    fallbackRenderer?: FallbackRender | null;
};

const SentryErrorBoundary = ({ children, fallbackRenderer = null }: Props) => {
    let fallback: FallbackRender;
    if (fallbackRenderer) {
        fallback = fallbackRenderer;
    } else {
        fallback = ({ eventId }) => <ErrorPage eventId={eventId} />;
    }
    return (
        <Sentry.ErrorBoundary fallback={fallback}>
            {children}
        </Sentry.ErrorBoundary>
    );
};

export default SentryErrorBoundary;
