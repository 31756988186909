import { takeEvery, all, put, call } from 'redux-saga/effects';

import * as TableConstants from '../constants/Table';
import api from '../helpers/Api';
import BackendPaths from '../helpers/BackendPaths';
import * as TableHelper from '../helpers/Tables';
import * as counterEvents from '../helpers/CounterEvents';
import * as HelperSaga from './Helper';

import { COUNTER_LOG_REQUEST } from '../constants/Counter';

/** @param {{payload: string[]}} param0 */
export function* fetchDatasetsWithTables({ payload }) {
    let responses;
    const surveyCodes = payload;

    const requests = surveyCodes.map((surveyCode) =>
        call(
            api,
            BackendPaths.getDatasets.getPath({
                surveyName: surveyCode,
                includeTables: true,
            }),
        ),
    );
    try {
        responses = yield all(requests);
    } catch (error) {
        return yield HelperSaga.handleError(
            TableConstants.FETCH_DATASETS_FAILURE,
            error,
        );
    }
    const events = [];
    for (let i = 0; i < surveyCodes.length; i += 1) {
        const surveyCode = surveyCodes[i];
        const response = responses[i];
        const datasets = response.d.map(TableHelper.formatDataset);
        datasets.forEach((ds) => {
            const eventValue = `${surveyCode}|${ds.abbreviation}`;
            events.push(counterEvents.getDatasetsFetchedEvent(eventValue));
        });
        yield put({
            type: TableConstants.SET_NORMALIZED_DATASETS,
            payload: {
                surveyCode,
                datasets,
            },
        });
        /** @type {import('../typescript/types').Table} */
        const normalizedTables = {};
        /** @type {Object.<string, string>} */
        const tableGuidByTableName = {};
        response.d
            .flatMap((d) => d.Tables)
            .forEach((rawTable) => {
                const table = TableHelper.formatTable(rawTable);
                normalizedTables[table.guid] = table;
                const tableCode = TableHelper.getTableCode(table);
                if (!tableGuidByTableName[tableCode]) {
                    tableGuidByTableName[tableCode] = [];
                }
                tableGuidByTableName[tableCode].push(table.guid);
            });
        yield put({
            type: TableConstants.SET_NORMALIZED_TABLES,
            payload: normalizedTables,
        });
        yield put({
            type: TableConstants.SET_TABLES_BY_SURVEY_CODE,
            payload: { surveyCode, tableGuidByTableName },
        });
    }
    yield put({ type: COUNTER_LOG_REQUEST, payload: events });
    yield put({
        type: TableConstants.FETCH_DATASETS_SUCCESS,
    });
}

/** @param {{payload: string[]}} param0 */
function* fetchPremadeReports({ payload }) {
    let responses;
    const surveyCodes = payload;
    const requests = surveyCodes.map((surveyCode) =>
        call(api, BackendPaths.getPremadeReports.getPath({ surveyCode })),
    );
    try {
        responses = yield all(requests);
    } catch (error) {
        return yield HelperSaga.handleError(
            TableConstants.FETCH_PREMADE_REPORTS_FAILURE,
            error,
        );
    }

    for (let i = 0; i < surveyCodes.length; i += 1) {
        const surveyCode = surveyCodes[i];
        const response = responses[i];
        yield put({
            type: TableConstants.SET_NORMALIZED_PREMADE_REPORTS,
            payload: {
                premadeReports: response.d.map(TableHelper.formatPremadeReport),
                surveyCode,
            },
        });
    }

    yield put({
        type: TableConstants.FETCH_PREMADE_REPORTS_SUCCESS,
    });
}

export default function* () {
    yield all([
        yield takeEvery(
            TableConstants.FETCH_DATASETS_REQUEST,
            fetchDatasetsWithTables,
        ),
        yield takeEvery(
            TableConstants.FETCH_PREMADE_REPORTS_REQUEST,
            fetchPremadeReports,
        ),
    ]);
}
