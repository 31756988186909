export enum AggregationTypes {
    NONE = 0,
    SHOW,
    ONLY_TOTALS,
    ALL_SLS_TOTAL,
}
/**
 * Definition of available languages can be found here:
 * https://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
 *
 * Note that `locale` for Intl.NumberFormat, IntlProvider and similar methods can
 * contain state after language in format en-US, sr-BA, etc. This is called BCP 47 code.
 * https://tools.ietf.org/html/bcp47
 * https://en.wikipedia.org/wiki/IETF_language_tag
 */
export enum Languages {
    EN = 'en',
    SR = 'sr',
    BS = 'bs',
    HR = 'hr',
    ES = 'es',
}

export const TRACTIQ_PALETTE = {
    '--color-50': 'rgba(18, 87, 221, 0.04)',
    '--color-100': 'rgba(18, 87, 221, 0.12)',
    '--color-200': 'rgba(18, 87, 221, 0.2)',
    '--color-300': ' rgba(18, 87, 221, 0.32)',
    '--color-400': 'rgba(18, 87, 221, 0.46)',
    '--color-500': 'rgba(18, 87, 221, 0.56)',
    '--color-600': 'rgba(18, 87, 221, 0.72)',
    '--color-700': 'rgba(18, 87, 221, 0.84)',
    '--color-800': 'rgba(18, 87, 221, 1)',
    '--color-900': 'rgba(6, 65, 178, 1)',
};
