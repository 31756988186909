import React from 'react';
import { FormattedMessage } from 'react-intl';

import PageContent from '../PageContent/PageContent';

import { ReactComponent as ErrorIllustration } from './error.svg';
import './ErrorPage.scss';

type Props = {
    eventId: string | null;
};

const ErrorPage = ({ eventId }: Props) => {
    return (
        <PageContent className="error-page">
            <ErrorIllustration className="error-illustration" />
            <h1>
                <FormattedMessage id="unhandledExceptionTitle" />
            </h1>
            <p>
                <FormattedMessage id="unhandledExceptionBody" />
            </p>
            {eventId && <p>EventId: {eventId}</p>}
        </PageContent>
    );
};
export default ErrorPage;
