import React from 'react';
import classnames from 'classnames';

import './Tag.scss';

type Props = {
    children: React.ReactNode;
    className?: string;
};

const Tag = ({ children, className, ...rest }: Props) => {
    const classes = classnames(className, 'tag');
    return (
        <div className={classes} {...rest}>
            {children}
        </div>
    );
};

export default Tag;
