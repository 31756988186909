import * as SelectionConstants from '../constants/Selection';
import * as Report from '../constants/Report';
/**
 * @typedef Selection
 * @property {string} reportId
 * @property {string} newReportId used to redirect from NewReport to ExistingReport
 * @property {string} duplicateReportId used to redirect to NewReport after duplicate action
 * @property {string[]} geoFips
 * @property {import('../typescript/types').TableForReportBySurveyCode[]} tables
 * @property {string} surveyCode
 */
const INITIAL_STATE = {
    reportId: undefined,
    newReportId: undefined,
    duplicateReportId: undefined,
    surveyCode: undefined,
    geoFips: [],
    tables: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SelectionConstants.SET_SURVEY_CODE: {
            const surveyCode = action.payload;
            return {
                ...state,
                surveyCode,
            };
        }
        case SelectionConstants.SET_GEO_FIPS: {
            const geoFips = action.payload;
            return {
                ...state,
                geoFips,
            };
        }
        case SelectionConstants.SET_TABLES: {
            const tables = action.payload;
            return {
                ...state,
                tables,
            };
        }
        case SelectionConstants.SET_DATASETS: {
            const datasets = action.payload;
            return {
                ...state,
                datasets,
            };
        }
        case SelectionConstants.SET_REPORT_ID: {
            const reportId = action.payload;
            return {
                ...state,
                reportId,
            };
        }
        case SelectionConstants.SET_NEW_REPORT_ID: {
            const newReportId = action.payload;
            return {
                ...state,
                newReportId,
            };
        }
        case Report.DUPLICATE_REPORT_FAILURE: {
            return {
                ...state,
                duplicateReportId: undefined,
            };
        }
        case Report.DUPLICATE_REPORT_SUCCESS: {
            return {
                ...state,
                duplicateReportId: action.payload,
            };
        }
        case SelectionConstants.CLEAR_REPORT_ID: {
            return {
                ...state,
                reportId: null,
            };
        }
        case SelectionConstants.RESET_SELECTION: {
            return { ...INITIAL_STATE };
        }
        default:
            return state;
    }
};
