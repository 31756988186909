import { takeEvery, all, put, call } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import api from '../helpers/Api';
import BackendPaths from '../helpers/BackendPaths';
import * as HelperSaga from './Helper';

import { FETCH_SURVEYS_REQUEST } from '../constants/Survey';
import * as CurrentUser from '../constants/CurrentUser';
import { getUserFromResponse } from '../helpers/User';
import * as ReportApp from '../typescript/types';
import { ReportAppActions } from '../typescript/actions/actions';
import hotjar from '../helpers/Hotjar';

function* fetchCurrentUser() {
    let whoAmI: ReportApp.OmniWebService.WhoAmIResponse | null,
        session: ReportApp.OmniWebService.UserSession;
    try {
        whoAmI = yield call(api, BackendPaths.whoami.getPath());
    } catch (error) {
        // status code 401 could be returned for users that have professional
        // plan provided through institutional login
        if (error.status !== 401) {
            return yield HelperSaga.handleError(
                CurrentUser.FETCH_ME_FAILURE,
                error,
            );
        }
        whoAmI = null;
    }

    try {
        session = yield call(api, BackendPaths.railsSessionUrl.getPath());
    } catch (error) {
        return yield HelperSaga.handleError(
            CurrentUser.FETCH_ME_FAILURE,
            error,
        );
    }

    if (!whoAmI && getUserFromResponse(session).licenceTitle === 'Basic plan') {
        // In case when user is not signed in at all, neither through his account, nor through
        // institutional account, whoAmI request will fail with code 401 (unauthorized),
        // but rails session will return OK response with 'Basic plan' as licenceTitle body property
        window.location.href = BackendPaths.loginUrl.getPath();
        return;
    }

    yield put<ReportAppActions>({
        type: CurrentUser.FETCH_ME_SUCCESS,
        payload: whoAmI,
    });
    yield put<ReportAppActions>({
        type: CurrentUser.SET_SESSION_INFO,
        payload: getUserFromResponse(session),
    });
    Sentry.configureScope((scope) => {
        scope.setUser({
            id: `${whoAmI?.id}`,
            email: whoAmI?.email,
            licenceTitle: getUserFromResponse(session).licenceTitle,
        });
    });
    hotjar.identifyUser(
        whoAmI?.id || -1, // identify users with id -1 if they came through institutional login
        getUserFromResponse(session).licenceTitle,
        process.env.REACT_APP_TENANT,
    );
    // only start recording for non-ghost users
    if (whoAmI?.id) {
        hotjar.startRecording();
    }
    // surveys are used on multiple pages (/surveys - to display all surveys,
    // /report/:reportId - to display survey title if report is not saved).
    // for this reason we will load surveys in all cases. We could later think
    // about potential optimizations
    yield put<ReportAppActions>({ type: FETCH_SURVEYS_REQUEST });
}

function* signOut() {
    try {
        yield call(api, BackendPaths.accountsSessionUrl.getPath(), 'DELETE');
        Sentry.configureScope((scope) => scope.setUser(null));
        window.location.href = BackendPaths.loginUrl.getPath();
    } catch (error) {
        return yield HelperSaga.handleError(
            CurrentUser.SIGN_OUT_FAILURE,
            error,
        );
    }
}

function* watchFetchCurrentUser() {
    yield takeEvery(CurrentUser.FETCH_ME_REQUEST, fetchCurrentUser);
    yield takeEvery(CurrentUser.SIGN_OUT_REQUEST, signOut);
}

export default function* () {
    yield all([watchFetchCurrentUser()]);
}
