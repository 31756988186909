import { DefaultRootState } from 'react-redux';

export const getConsumerPriceIndices = (state: DefaultRootState) =>
    state.db.cpi;

export const getConsumerPriceIndexForTable = (
    state: DefaultRootState,
    yearFrom: number,
    yearTo: number,
) => {
    const cpi = getConsumerPriceIndices(state);
    const cpiYears = Object.keys(cpi)
        .map((year) => parseInt(year, 10))
        .sort();
    const minYear = cpiYears[0];
    const maxYear = cpiYears[cpiYears.length - 1];

    const calculationYearFrom = Math.max(yearFrom, minYear);
    const calculationYearTo = Math.min(yearTo, maxYear);

    return cpi[calculationYearTo] / cpi[calculationYearFrom];
};
