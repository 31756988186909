import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';

import * as NewSurvey from '../../../selectors/NewSurvey';
import ApplicationPaths from '../../../helpers/ApplicationPaths';

import GeoInfo from './GeoInfo/GeoInfo';
import TableInfo from './TableInfo/TableInfo';
import CollapseInfo from './CollapseInfo/CollapseInfo';
import ChangeSurveyTitle from './ChangeSurveyTitle';
import ChangeSurveyDescription from './ChangeSurveyDescription';

import './ChangeSurveyInfo.scss';

const ChangeSurveyInfo = () => {
    const {
        foundGeoFips,
        selectedGeoFips,
        foundTables,
        selectedTables,
    } = useSelector(NewSurvey.getChangeSurveyAnalysis);
    const shouldProceedToReportCreation = useSelector(
        NewSurvey.getShouldProceedToReportCreation,
    );

    if (shouldProceedToReportCreation) {
        return <Redirect to={ApplicationPaths.newReport.getPath()} />;
    }

    return (
        <>
            <ChangeSurveyTitle />
            <ChangeSurveyDescription
                isMatch={
                    foundGeoFips === selectedGeoFips &&
                    foundTables === selectedTables
                }
            />
            <CollapseInfo
                className="change-survey-collapse"
                found={foundGeoFips}
                total={selectedGeoFips}
                title={
                    <FormattedMessage id="surveysPage.changeSurvey.geoResultsHeader" />
                }
                allFoundMessageId="surveysPage.changeSurvey.allGeosFound"
                someFoundMessageId="surveysPage.changeSurvey.someGeosFound"
                renderOnExpand={() => <GeoInfo />}
            />
            <CollapseInfo
                className="change-survey-collapse"
                found={foundTables}
                total={selectedTables}
                title={
                    <FormattedMessage id="surveysPage.changeSurvey.tableResultsHeader" />
                }
                allFoundMessageId="surveysPage.changeSurvey.allTablesFound"
                someFoundMessageId="surveysPage.changeSurvey.someTablesFound"
                renderOnExpand={() => <TableInfo />}
            />
        </>
    );
};

export default ChangeSurveyInfo;
