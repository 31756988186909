/**
 * This is the structure of translations in our json files
 */
type TranslationInJson = {
    [key: string]: TranslationInJson | string;
};

/**
 * This is the flatten structure that react-intel expects and works with
 */
type Translation = {
    [key: string]: string;
};

export function flattenMessages(
    nestedMessages: TranslationInJson,
    prefix = '',
) {
    return Object.keys(nestedMessages).reduce((messages: Translation, key) => {
        let value = nestedMessages[key];
        const prefixedKey = prefix ? `${prefix}.${key}` : key;

        if (typeof value === 'string') {
            // if value starts with triple bang it means it needs to go through
            // official translation. In the meantime we still want to show to
            // user something without triple bang prefix
            if (value.startsWith('!!!')) {
                value = value.replace('!!!', '');
            }
            messages[prefixedKey] = value;
        } else {
            Object.assign(messages, flattenMessages(value, prefixedKey));
        }

        return messages;
    }, {});
}
