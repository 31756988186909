import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import BackendPaths from '../../helpers/BackendPaths';
import AppScreen from '../../constants/AppScreen';

import * as WizardHeaderSelector from '../../selectors/WizardHeader';
import { getBackwardButtonOptions } from '../../selectors/WizardHeader';
import {
    getTableTemplateTitleById,
    getTemplateId,
    getIsEditingTemplateFromDashboard,
} from '../../selectors/Templates';
import { isCurrentTemplateEditInProgress } from '../../selectors/Table';

import Icon from '../Icon/Icon';
import Link from '../base/Link/Link';
import UpdateCurrentTemplateControl from '../Tables/UpdateCurrentTemplateControl';

type Props = {
    currentAppScreen: AppScreen;
    modifyMode: boolean;
};

const WizardHeaderTitle = ({ currentAppScreen, modifyMode }: Props) => {
    const intl = useIntl();
    const dashboardUrl = BackendPaths.dashboardUrl.getPath();
    const tableTemplateId = useSelector(getTemplateId);
    const tableTemplateTitle = useSelector((state) =>
        getTableTemplateTitleById(state, tableTemplateId),
    );
    const headerTitle = useSelector((state) =>
        WizardHeaderSelector.getHeaderTitle(state, currentAppScreen),
    );
    const { text, link } = useSelector((state) =>
        getBackwardButtonOptions(state, currentAppScreen),
    );
    const isEditingTemplateFromDashboard = useSelector(
        getIsEditingTemplateFromDashboard,
    );
    const currentTemplateEditInProgress = useSelector(
        isCurrentTemplateEditInProgress,
    );

    const backToDashboardLabel = intl.formatMessage({
        id: 'header.actions.backToDashboard',
    });

    const linkUrl = modifyMode ? link : dashboardUrl;
    const linkText = modifyMode ? text : backToDashboardLabel;

    return (
        <div className="header-content-left">
            <Link
                url={linkUrl}
                role="button"
                title={linkText}
                aria-label={linkText}
            >
                <Icon className="header-close-button">close</Icon>
            </Link>
            <div className="header-title">
                {isEditingTemplateFromDashboard
                    ? intl.formatMessage(
                          { id: 'header.title.noReport.createWithName' },
                          { name: tableTemplateTitle },
                      )
                    : headerTitle}
            </div>
            {isEditingTemplateFromDashboard && currentTemplateEditInProgress && (
                <div className="header-subtitle">
                    {intl.formatMessage({
                        id: 'report.tablesTemplate.unsavedChanges',
                    })}
                    <UpdateCurrentTemplateControl
                        hideTablesModifier={() => {}}
                        disabled={false}
                    >
                        <FormattedMessage id="report.tablesTemplate.updateTemplate" />
                    </UpdateCurrentTemplateControl>
                </div>
            )}
        </div>
    );
};

export default WizardHeaderTitle;
