import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import * as GeoTypeSelector from '../../../../selectors/GeoTypes';

import {
    CLEAR_BELOW_SUMMARY_LEVEL,
    FETCH_GEO_ITEMS_REQUEST,
} from '../../../../constants/Geographies';
import * as ReportApp from '../../../../typescript/types';

import Button from '../../../Buttons/Button';
import Icon from '../../../Icon/Icon';

import './ParentGeoItemSelector.scss';

type Props = {
    geoType: ReportApp.GeoType;
    parentGeoType: ReportApp.GeoType;
    surveyCode: ReportApp.SurveyCode;
};

const ParentGeoItemSelector = ({
    geoType,
    surveyCode,
    parentGeoType,
}: Props) => {
    const dispatch = useDispatch();
    const geoItems = useSelector((state) =>
        GeoTypeSelector.getGeoItems(state, geoType.name),
    );
    const currentGeoType = useSelector((state) =>
        GeoTypeSelector.getSelectedGeoType(state, surveyCode),
    );
    const geoSelectionFips = useSelector((state) =>
        GeoTypeSelector.getParentGeoSelectionFips(state, geoType),
    );
    const shouldFetchGeoItems = useSelector((state) =>
        GeoTypeSelector.shouldFetchGeoItemsForGeoType(state, geoType),
    );
    const selectedGeoItem = useSelector((state) =>
        GeoTypeSelector.getSelectedGeoItemByGeoType(state, geoType),
    );
    const selectedParentGeoItem = useSelector((state) =>
        GeoTypeSelector.getSelectedGeoItemByGeoType(state, parentGeoType),
    );
    const setGeoSelectionAndClearBelow = useCallback(
        (geoItem) => {
            const isTheSameItemSelected =
                selectedGeoItem?.fips === geoItem?.fips &&
                selectedGeoItem?.sumLev === geoItem?.sumLev;
            if (!selectedGeoItem || !isTheSameItemSelected) {
                dispatch({
                    type: CLEAR_BELOW_SUMMARY_LEVEL,
                    payload: { geoType, value: geoItem?.fips },
                });
            }
        },
        [dispatch, geoType, selectedGeoItem],
    );

    useEffect(() => {
        if (shouldFetchGeoItems) {
            dispatch({
                type: FETCH_GEO_ITEMS_REQUEST,
                payload: {
                    geoType: geoType,
                    geoTypeSelectionName: currentGeoType.name,
                    geoSelectionFips: geoSelectionFips,
                },
            });
        }
    }, [
        shouldFetchGeoItems,
        geoType,
        currentGeoType,
        geoSelectionFips,
        dispatch,
    ]);

    useEffect(() => {
        // if selector has only one child, set it to selected to save user from unnecessary click
        if (geoItems?.length === 1) {
            setGeoSelectionAndClearBelow(geoItems[0]);
        }
    }, [geoItems, setGeoSelectionAndClearBelow]);

    const intl = useIntl();
    const onClear = () => {
        setGeoSelectionAndClearBelow(undefined);
    };

    const isButtonDisabled = geoSelectionFips === undefined;

    if (isButtonDisabled && !selectedParentGeoItem) {
        return null;
    }

    const label = selectedParentGeoItem
        ? selectedParentGeoItem.label
        : intl.formatMessage({ id: 'all' });

    return (
        <div className="parent-geo-item">
            <Icon className="breadcrumb-separator">keyboard_arrow_right</Icon>
            <Button
                className="button-flat small contained container-small"
                onClick={onClear}
                title={label}
                aria-label={label}
            >
                {label}
            </Button>
            <Button
                className="button-flat small contained container-small disabled-label"
                disabled={true}
            >
                {label}
            </Button>
        </div>
    );
};

export default ParentGeoItemSelector;
