import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getNumberOfSelectedColumns } from '../../../selectors/ReportMetadata';
import { ReportAppDispatch } from '../../../typescript/actions/actions';
import {
    SET_ACTIVATED_SIDEBAR_TAB,
    DOWNLOAD_CSV,
} from '../../../constants/Sidebar';
import { MAX_COLUMNS_ALLOWED_FOR_PDF_DOWNLOAD } from '../../../constants/Report';

import WarningMessage from '../../WarningMessage/WarningMessage';

/**
 * We will not allow users to download excel for reports with too high number of columns (more than 6k).
 * Instead, we will give them a link to CSV download.
 */
const PdfLimitWarning = () => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const numberOfSelectedColumns = useSelector(getNumberOfSelectedColumns);

    return (
        <WarningMessage
            messageId="report.sidebar.downloadPdf.tooManyColumns"
            values={{
                max: MAX_COLUMNS_ALLOWED_FOR_PDF_DOWNLOAD,
                selected: numberOfSelectedColumns,
                div: (message: string): React.ReactNode => (
                    <div className="description">{message}</div>
                ),
                a: (message: string): React.ReactNode => (
                    <button
                        onClick={() => {
                            // Since a report may have multiple geotypes/geogroups
                            // we can't start the download from within DownloadPdfPanel.
                            // Rather we will only redirect user to Download CSV panel and
                            // let him/her decide which data exactly to download.
                            dispatch({
                                type: SET_ACTIVATED_SIDEBAR_TAB,
                                payload: DOWNLOAD_CSV,
                            });
                        }}
                    >
                        {message}
                    </button>
                ),
            }}
        />
    );
};

export default PdfLimitWarning;
