enum DOMElementID {
    SIDEBAR_MODIFY_REPORT = 'modify-report',
    CONTENT_TABLE_WRAPPER = 'content-table',
    FOOTER_PAGINATION_CONTAINER = 'report-navigator',
    REPORT_HEADER = 'report-header',
    HEADER_NEXT_BUTTON = 'header-next-button',
    HEADER_BACK_BUTTON = 'header-back-button',
    CLEAR_BUTTON = 'clear-button',
    SELECTOR_CONTAINER = 'selector-container',
}

export default DOMElementID;
