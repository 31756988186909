import * as Templates from '../constants/Templates';
import { ReportAppActions } from '../typescript/actions/actions';
import { TemplateStatus } from '../typescript/types';

const INITIAL_STATE: TemplateStatus = {
    saveTemplateRequest: {
        loading: false,
        error: null,
    },
    fetchTemplatesRequest: {
        loading: false,
        error: null,
        isLoaded: false,
    },
    isCreatingTemplateFromDashboard: false,
    isEditingTemplateFromDashboard: false,
    templateId: null,
};

export default (state = INITIAL_STATE, action: ReportAppActions) => {
    switch (action.type) {
        case Templates.SAVE_TEMPLATE_REQUEST:
            return {
                ...state,
                saveTemplateRequest: {
                    loading: true,
                    error: null,
                },
            };
        case Templates.SAVE_TEMPLATE_FAILURE:
            return {
                ...state,
                saveTemplateRequest: {
                    loading: false,
                    error: action.payload,
                },
            };
        case Templates.SAVE_TEMPLATE_SUCCESS:
            return {
                ...state,
                saveTemplateRequest: {
                    loading: false,
                    error: null,
                },
            };
        case Templates.FETCH_TEMPLATES_REQUEST:
            return {
                ...state,
                fetchTemplatesRequest: {
                    loading: true,
                    error: null,
                    isLoaded: false,
                },
            };
        case Templates.FETCH_TEMPLATES_FAILURE:
            return {
                ...state,
                fetchTemplatesRequest: {
                    loading: false,
                    error: action.payload,
                    isLoaded: true,
                },
            };
        case Templates.FETCH_TEMPLATES_SUCCESS:
            return {
                ...state,
                fetchTemplatesRequest: {
                    loading: false,
                    error: null,
                    isLoaded: true,
                },
            };
        case Templates.SaveTableTemplate
            .SET_IS_CREATING_TEMPLATE_FROM_DASHBOARD:
            return {
                ...state,
                isCreatingTemplateFromDashboard: action.payload,
            };
        case Templates.SET_ACTIVE_TEMPLATE_ID:
            return {
                ...state,
                templateId: action.payload,
            };
        case Templates.SET_IS_EDITING_TEMPLATE:
            return {
                ...state,
                isEditingTemplateFromDashboard: action.payload,
            };
        default:
            return state;
    }
};
