import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../../Buttons/Button';
import MultipleSelectionHint from '../../Selection/MultipleSelectionHint';

import './PremadeReportsSelectorActions.scss';

type Props = {
    onAddTables: () => void;
    onClose: () => void;
};

const TablesSelectorSelectorActions = ({ onAddTables, onClose }: Props) => {
    return (
        <div className="premade-reports-selector-action">
            <MultipleSelectionHint />
            <div className="premade-reports-selector-action-actions">
                <Button variant="flat" className="small" onClick={onClose}>
                    <FormattedMessage id="cancel" />
                </Button>
                <Button
                    variant="contained"
                    className="small"
                    onClick={onAddTables}
                >
                    <FormattedMessage id="add" />
                </Button>
            </div>
        </div>
    );
};

export default TablesSelectorSelectorActions;
