import React from 'react';

import * as ReportApp from '../../../../typescript/types';

import SingleTableEmptyRow from './SingleTableEmptyRow';
import SingleTableSubheader from './SingleTableSubheader';
import SingleTableTitle from './SingleTableTitle';
import SingleTableVariables from '../TableVariable/SingleTableVariables';

type Props = {
    table: ReportApp.TablesUsedInReport;
    displayNavigationButtons: boolean;
};

const SingleTable = ({ table, displayNavigationButtons }: Props) => {
    return (
        <>
            <SingleTableEmptyRow />
            <SingleTableTitle
                table={table}
                displayNavigationButtons={displayNavigationButtons}
            />
            <SingleTableSubheader
                tableGuidByReportId={table.guidByOldReportId}
            />
            <SingleTableVariables
                tableGuidByReportId={table.guidByOldReportId}
            />
        </>
    );
};

export default SingleTable;
