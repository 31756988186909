import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { getGeoItemsByFipsResults } from '../../../../selectors/GeoTypes';
import * as Geographies from '../../../../constants/Geographies';
import { CLEAR_SELECTED_SCREEN } from '../../../../constants/SelectGeographyScreen';

import Button from '../../../Buttons/Button';
import Icon from '../../../Icon/Icon';

import './FoundList.scss';

const FoundList = ({ onCloseModal, onOpenNotFoundList }) => {
    const dispatch = useDispatch();
    const [geoSelection, notMatched] = useSelector(getGeoItemsByFipsResults);

    const clearListAndCloseModal = () => {
        dispatch({ type: Geographies.CLEAR_GEO_ITEMS_BY_FIPS_RESULTS });
        onCloseModal();
    };
    return (
        <>
            <h4 className="found-list-header">
                <FormattedMessage
                    id="geographiesPage.fipsSelection.results.found"
                    values={{ count: geoSelection.length }}
                />
            </h4>
            <hr />
            {geoSelection.length > 0 && (
                <ul className="found-list-selection-list">
                    {geoSelection.map((selection) => (
                        <li
                            key={selection.fips}
                            className="found-list-selection-list-item"
                        >
                            <div>
                                <span className="fips">{selection.fips}</span>
                                <span className="label">{selection.label}</span>
                            </div>
                            <Icon className="icon">check</Icon>
                        </li>
                    ))}
                </ul>
            )}
            {notMatched && (
                <>
                    <hr />
                    <div className="found-list-warning">
                        <div className="found-list-warning-icon">
                            <Icon>warning</Icon>
                        </div>
                        <div className="found-list-warning-label">
                            <FormattedMessage id="geographiesPage.fipsSelection.results.someNotFound" />
                            <Button onClick={onOpenNotFoundList}>
                                <FormattedMessage id="geographiesPage.fipsSelection.results.viewList" />
                            </Button>
                        </div>
                    </div>
                </>
            )}
            <hr />
            <div className="found-list-actions">
                <Button variant="flat small" onClick={clearListAndCloseModal}>
                    <FormattedMessage id="cancel" />
                </Button>
                <Button
                    variant="contained small"
                    onClick={() => {
                        dispatch({ type: CLEAR_SELECTED_SCREEN });
                        dispatch({
                            type: Geographies.APPEND_FIPS_SELECTION,
                            payload: { geoSelection },
                        });
                    }}
                >
                    <FormattedMessage id="add" />
                </Button>
            </div>
        </>
    );
};
export default FoundList;
