import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
    getSaveTemplatePayload,
    getTableTemplateTitleById,
    getTemplateId,
} from '../../selectors/Templates';
import { EDIT_TEMPLATE_REQUEST } from '../../constants/Templates';
import { ReportAppDispatch } from '../../typescript/actions/actions';

import Button from '../Buttons/Button';

type Props = {
    hideTablesModifier: () => void;
    disabled: boolean;
} & React.HTMLAttributes<HTMLElement>;

const UpdateCurrentTemplateControl = ({
    children,
    hideTablesModifier,
    disabled,
}: Props) => {
    const intl = useIntl();
    const dispatch = useDispatch<ReportAppDispatch>();
    const payload = useSelector(getSaveTemplatePayload);
    const templateId = useSelector(getTemplateId);
    const templateTitle = useSelector((state) =>
        getTableTemplateTitleById(state, templateId),
    );
    const onUpdate = () => {
        if (!payload || !templateTitle) {
            return;
        }
        if (typeof templateId === 'number') {
            dispatch({
                type: EDIT_TEMPLATE_REQUEST,
                payload: {
                    ...payload,
                    title: templateTitle,
                    templateId,
                },
            });
        }
        hideTablesModifier();
    };
    return (
        <Button
            variant="flat"
            onClick={onUpdate}
            title={intl.formatMessage({ id: 'update' })}
            aria-label={intl.formatMessage({ id: 'update' })}
            disabled={disabled}
        >
            {children}
        </Button>
    );
};

export default UpdateCurrentTemplateControl;
