import * as ReportApp from '../typescript/types';

export const parseDataSet = (
    dataSet: ReportApp.OmniWebService.DataSet,
): ReportApp.Survey => ({
    code: dataSet.code,
    description: dataSet.description,
    enabled: dataSet.enabled,
    isNew: dataSet.isNew,
    name: dataSet.name,
    year: dataSet.year,
    additionalMetadata: dataSet.additionalMetadata,
    comparabilityGroup: dataSet.comparabilityGroup,
});

export const parseDataGroup = (
    dataGroup: ReportApp.OmniWebService.DataGroup,
): ReportApp.SurveyGroup => ({
    description: dataGroup.description,
    enabled: dataGroup.enabled,
    id: dataGroup.id,
    name: dataGroup.name,
    surveyCodes: dataGroup.dataSets.map((dataSet) => dataSet.code),
});
