import React from 'react';
import { useSelector } from 'react-redux';

import * as ReportMetadata from '../../../../selectors/ReportMetadata';
import { truncateLongString } from '../../../../helpers/ValueFormatter';

import './TableSideBySideHeader.scss';

type Props = {
    leftOffset: number;
    topOffset: number;
};

const TableSideBySideHeader = ({ leftOffset, topOffset }: Props) => {
    const geoHeader = useSelector(ReportMetadata.getPaginatedGeographies);
    const shouldDisplaySideBySide = useSelector(
        ReportMetadata.getShouldDisplaySideBySide,
    );

    if (!shouldDisplaySideBySide) {
        return null;
    }

    return (
        <tr className="side-by-side">
            <th style={{ top: topOffset }} />
            {geoHeader.flatMap((geographyInHeader, geoIndex) =>
                geographyInHeader.sideBySide.map(({ name, colSpan }, index) => (
                    <th
                        colSpan={colSpan}
                        key={`${geoIndex}-${index}`}
                        style={{ top: topOffset }}
                    >
                        <span
                            className="table-header"
                            style={{ left: leftOffset }}
                            title={name}
                            aria-label={name}
                        >
                            {truncateLongString(name || '')}
                        </span>
                    </th>
                )),
            )}
        </tr>
    );
};

export default TableSideBySideHeader;
