import { DefaultRootState } from 'react-redux';
import * as ReportMetadata from './ReportMetadata';
import { formatValue } from '../helpers/ValueFormatter';
import * as ReportHelper from '../helpers/Report';
import ClientFunctionParser from '../helpers/ClientFunctionParser';
import ValueFormat from '../constants/ValueFormat';
import * as ReportConstants from '../constants/Report';
import * as ReportApp from '../typescript/types';
import { getReportDataRowKey } from '../helpers/Tables';

export const getReportData = (state: DefaultRootState) => state.db.reportData;
export const getReportTotalsMetaData = (state: DefaultRootState) =>
    state.reportTotalsMetadata;

export const getReportDataForReport = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId,
) => getReportData(state)[oldReportId];

export const getReportTotalsMetadataForReport = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId,
) => getReportTotalsMetaData(state)[oldReportId];

export const getShouldFetchData = (state: DefaultRootState) =>
    Object.keys(getReportData(state)).length === 0;

export const getOldReportData = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId,
) => state.db.reportData[oldReportId];

export const getGeoQNames = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId,
) => {
    const reportData = getOldReportData(state, oldReportId);
    if (!reportData) {
        return [];
    }
    return reportData[ReportConstants.GEO_COLUMN_NAME] as (string | null)[];
};

export const getFipsCodesWithSummaryLevel = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId,
) => {
    const reportData = getOldReportData(state, oldReportId);
    if (!reportData) {
        return [];
    }
    return reportData[
        ReportConstants.GEO_FIPS
    ] as (ReportApp.FipsCodeWithSummaryLevel | null)[];
};

const getClientComputedValue = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId,
    variable: ReportApp.Variable,
    geoFips: ReportApp.FipsCodeWithSummaryLevel,
) => {
    const clientFunction = ClientFunctionParser(variable.clientFunction);
    const getParsedVariableValues: ReportApp.GetValueCallback = (
        datasetCode,
        variableCode,
    ) => {
        const {
            table,
            variable,
        } = ReportMetadata.getTableAndVariableByOldReportIdDatasetVariableCode(
            state,
            oldReportId,
            datasetCode,
            variableCode,
        );
        if (!table || !variable) {
            return null;
        }
        const value = getValue(state, oldReportId, table, variable, geoFips);
        if (typeof value === 'function') {
            throw new Error('Using nested async function is forbidden.');
        }
        return value;
    };

    const clientValues: ReportApp.ClientValues = ReportMetadata.getClientValues(
        state,
    );
    return clientFunction(getParsedVariableValues, clientValues);
};

const getValue = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId,
    table: ReportApp.Table,
    variable: ReportApp.Variable,
    geoFips: ReportApp.FipsCodeWithSummaryLevel,
) => {
    if (variable.clientFunction.length > 0) {
        return getClientComputedValue(state, oldReportId, variable, geoFips);
    }
    const reportData = getReportDataForReport(state, oldReportId);
    if (!reportData) {
        return null;
    }
    const reportDataRowKey = getReportDataRowKey(table, variable);
    const reportDataRow = reportData[reportDataRowKey];

    let index = reportData[ReportConstants.GEO_FIPS].indexOf(geoFips);

    if (index !== -1) {
        return reportDataRow[index];
    }
    return null;
};

/**
 * @param geoFips GeoFIPS of geography in geoGroup for which to get value and percent
 */
const getValueAndMetadata = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId,
    table: ReportApp.Table,
    variable: ReportApp.Variable,
    geoFips: ReportApp.FipsCodeWithSummaryLevel,
    showPercent: boolean,
) => {
    const rawValue = getValue(state, oldReportId, table, variable, geoFips);
    let percent = undefined;
    if (showPercent) {
        const parentVariable = ReportHelper.findParentVariableForPercent(
            table,
            variable,
        );
        if (parentVariable) {
            const universe = getValue(
                state,
                oldReportId,
                table,
                parentVariable,
                geoFips,
            );
            if (typeof rawValue === 'number' && typeof universe === 'number') {
                percent = rawValue / universe;
            } else {
                // because, guess what, in js null in numeric operation is treated
                // as 0. So null * 100 = 0, etc.
                percent = null;
            }
        } else {
            percent = null;
        }
    }

    // determine if the variable needs an asterisk
    const hasAsterisk = isAggregatedWithNulls(
        state,
        table,
        variable,
        oldReportId,
        geoFips,
    );

    return {
        percent,
        percentFormat: ValueFormat.FORMAT_REAL_PERCENT_1_DECIMAL,
        value: rawValue,
        valueFormat: variable.formatting,
        hasAsterisk,
    };
};

/**
 * Return one pair element for every output column. If there are no output
 * columns, only one element in array is present
 */

export const getValueAndMetadataPairs = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId | undefined,
    tableGuid: ReportApp.TableGuid | undefined,
    variableIndex: number,
    geoFips: ReportApp.FipsCodeWithSummaryLevel,
) => {
    if (oldReportId == null || tableGuid == null) {
        return [];
    }

    const table = ReportMetadata.getTableByGuid(state, oldReportId, tableGuid);
    if (!table) {
        return [];
    }

    if (table.outputColumns.length > 0) {
        return table.outputColumns.map(({ outputPercent, tableGuid }) => {
            const outputTable = ReportMetadata.getTableByGuid(
                state,
                oldReportId,
                tableGuid,
            )!; // ! -> we are sure that this table by tableGuid is loaded at this point
            const variable = Object.values(outputTable.variables)[
                variableIndex
            ]!; // ! -> we are sure that variable on this index exists
            return getValueAndMetadata(
                state,
                oldReportId,
                outputTable,
                variable,
                geoFips,
                outputPercent,
            );
        });
    }
    // ! -> we are sure that variable on this index exists
    const variable = Object.values(table.variables)[variableIndex]!;

    return [
        getValueAndMetadata(state, oldReportId, table, variable, geoFips, true),
    ];
};

export const getDiff = (
    state: DefaultRootState,
    tableGuidByOldReportId: ReportApp.TableGuidByOldReportId,
    columnGroupsFirst: number,
    columnGroupsLast: number,
    variableIndex: number,
    geoGroupIndex: number,
    geoFips: ReportApp.FipsCodeWithSummaryLevel,
    preFormatted = true,
) => {
    const nullReturnValue = preFormatted
        ? null
        : {
              value: null,
              format: ValueFormat.FORMAT_REAL_PERCENT_1_DECIMAL,
          };
    const definition = ReportMetadata.getDefinition(state);
    if (!definition) {
        return nullReturnValue;
    }
    const leftOldReportId = ReportHelper.getOldReportIdByGeoGroupIndexColumnGroupIndex(
        definition,
        tableGuidByOldReportId,
        geoGroupIndex,
        columnGroupsFirst,
    );
    const rightOldReportId = ReportHelper.getOldReportIdByGeoGroupIndexColumnGroupIndex(
        definition,
        tableGuidByOldReportId,
        geoGroupIndex,
        columnGroupsLast,
    );
    if (leftOldReportId == null || rightOldReportId == null) {
        return nullReturnValue;
    }
    const leftReportData = getReportDataForReport(state, leftOldReportId);
    const rightReportData = getReportDataForReport(state, rightOldReportId);
    const leftTableGuid = tableGuidByOldReportId[leftOldReportId];
    const rightTableGuid = tableGuidByOldReportId[rightOldReportId];
    if (
        leftReportData == null ||
        rightReportData == null ||
        leftTableGuid == null ||
        rightTableGuid == null
    ) {
        return nullReturnValue;
    }
    const leftTable = ReportMetadata.getTableByGuid(
        state,
        leftOldReportId,
        leftTableGuid,
    );
    const rightTable = ReportMetadata.getTableByGuid(
        state,
        rightOldReportId,
        rightTableGuid,
    );

    if (leftTable == null || rightTable == null) {
        return nullReturnValue;
    }

    const leftVariable = Object.values(leftTable.variables)[variableIndex];
    const rightVariable = Object.values(rightTable.variables)[variableIndex];

    if (leftVariable == null || rightVariable == null) {
        return nullReturnValue;
    }

    const leftValue = getValue(
        state,
        leftOldReportId,
        leftTable,
        leftVariable,
        geoFips,
    );

    const rightValue = getValue(
        state,
        rightOldReportId,
        rightTable,
        rightVariable,
        geoFips,
    );
    if (typeof leftValue !== 'number' || typeof rightValue !== 'number') {
        return nullReturnValue;
    }
    const value = (rightValue - leftValue) / leftValue;
    const format = ValueFormat.FORMAT_REAL_PERCENT_1_DECIMAL;

    return preFormatted ? formatValue(value, format) : { value, format };
};

export const isAggregatedWithNulls = (
    state: DefaultRootState,
    table: ReportApp.Table,
    variable: ReportApp.Variable,
    oldReportId: ReportApp.OldReportId,
    geoFips: ReportApp.FipsCodeWithSummaryLevel,
) => {
    let hasNulls = false;
    const rowName = getReportDataRowKey(table, variable);
    const reportData = getReportDataForReport(state, oldReportId);

    if (!reportData) {
        return false;
    }

    const columnIndex = String(
        reportData[ReportConstants.GEO_FIPS].indexOf(geoFips),
    );

    const reportTotalsMetadata = getReportTotalsMetadataForReport(
        state,
        oldReportId,
    );

    if (!reportTotalsMetadata) {
        return false;
    }

    // If we are in a totals column, determine if the list of null value rows
    // for this column contains our row, and if so, set hasNulls to true
    if (reportTotalsMetadata.nullValueRows[columnIndex]?.includes(rowName)) {
        hasNulls = true;
    }

    return hasNulls;
};
