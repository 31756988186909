import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import * as ReportConstants from '../../../constants/Report';
import { getAreDatasetsLoaded } from '../../../selectors/Table';

import Icon from '../../Icon/Icon';
import Button from '../../Buttons/Button';

type Props = {
    orientation: string;
};
const DownloadPdfButton = (options: Props) => {
    const dispatch = useDispatch();
    const activeButton = useSelector(getAreDatasetsLoaded);

    const onClick = () => {
        dispatch({
            type: ReportConstants.DOWNLOAD_PDF_REQUEST,
            payload: options,
        });
    };
    return (
        <Button
            className={classNames('download-pdf-button', {
                inactive: !activeButton,
            })}
            onClick={onClick}
            disabled={!activeButton}
        >
            <Icon>file_download</Icon>
            <span className="download-text">
                <FormattedMessage
                    id={`report.sidebar.downloadPdf.button.${
                        activeButton ? 'active' : 'inactive'
                    }`}
                />
            </span>
        </Button>
    );
};
export default DownloadPdfButton;
