import React from 'react';

import { Props as DownloadCardActionProps } from './DownloadCardAction';

import Surface from '../../../base/Surface/Surface';
import DownloadCardHeaderWithActions from './DownloadCardHeaderWithActions';
import DownloadCardSupportingText from './DownloadCardSupportingText';

type Props = {
    title: string;
    actions: DownloadCardActionProps[];
    supportingText?: React.ReactNode;
    className?: string;
};

const DownloadCard = ({ title, actions, supportingText, ...rest }: Props) => {
    return (
        <Surface level="1" variant="gray" {...rest}>
            <DownloadCardHeaderWithActions title={title} actions={actions} />
            {supportingText && (
                <DownloadCardSupportingText>
                    {supportingText}
                </DownloadCardSupportingText>
            )}
        </Surface>
    );
};

export default DownloadCard;
