import React from 'react';
import { useDispatch } from 'react-redux';

import { ItemsPerPageListItemTranslated } from '../../../../typescript/types';
import { ReportAppDispatch } from '../../../../typescript/actions/actions';
import {
    SET_GEO_ITEMS_PER_PAGE_REQUEST,
    SET_TABLES_PER_PAGE_REQUEST,
} from '../../../../constants/Report';

import Select from '../../../base/Select/Select';

type Props = {
    itemsPerPageList: ItemsPerPageListItemTranslated[];
    itemsPerPage: number;
    setItemsPerPageRequestType:
        | typeof SET_GEO_ITEMS_PER_PAGE_REQUEST
        | typeof SET_TABLES_PER_PAGE_REQUEST;
    onClosePopover: () => void;
};

const SelectItemsPerPage = ({
    itemsPerPageList,
    itemsPerPage,
    setItemsPerPageRequestType,
    onClosePopover,
}: Props) => {
    const dispatch = useDispatch<ReportAppDispatch>();

    const itemsPerPagePrepared = itemsPerPageList.map((item) => item.label);
    const selectedItem = itemsPerPageList.find(
        (el) => el.value === itemsPerPage,
    );
    if (!selectedItem) {
        return null;
    }

    return (
        <Select
            value={selectedItem.label}
            list={itemsPerPagePrepared}
            className="bottom-up-direction"
            onChange={(newValue) => {
                const item = itemsPerPageList.find(
                    (element) => element.label === newValue,
                );

                if (!item) {
                    return;
                }

                dispatch({
                    type: setItemsPerPageRequestType,
                    payload: { value: item.value },
                });
                onClosePopover();
            }}
        />
    );
};

export default SelectItemsPerPage;
