import React from 'react';
import { FormattedMessage } from 'react-intl';

import DOMElementID from '../../constants/DOMElementID';

import JumpButton from '../JumpButton/JumpButton';

import './SidebarPanel.scss';

const SidebarPanelJumpButtons = () => {
    return (
        <>
            <JumpButton
                key="back2menu"
                variant="flat"
                targetElementID={DOMElementID.SIDEBAR_MODIFY_REPORT}
            >
                <FormattedMessage id={'layoutNavigation.back2menu'} />
            </JumpButton>
            <JumpButton
                key="skip2reportContent"
                variant="flat"
                targetElementID={DOMElementID.CONTENT_TABLE_WRAPPER}
            >
                <FormattedMessage id={'layoutNavigation.skip2reportContent'} />
            </JumpButton>
            <JumpButton
                key="skip2pagination"
                variant="flat"
                targetElementID={DOMElementID.FOOTER_PAGINATION_CONTAINER}
            >
                <FormattedMessage id={'layoutNavigation.skip2pagination'} />
            </JumpButton>
            <JumpButton
                key="back2header"
                variant="flat"
                targetElementID={DOMElementID.REPORT_HEADER}
            >
                <FormattedMessage id={'layoutNavigation.back2header'} />
            </JumpButton>
        </>
    );
};

export default SidebarPanelJumpButtons;
