import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import PageContent from '../base/PageContent/PageContent';
import Surface from '../base/Surface/Surface';
import UpgradePlanActionButton from '../UpgradePlanActionButton/UpgradePlanActionButton';
import './UpgradePlanBanner.scss';

type Props = {
    className: string;
};

const UpgradePlanBanner = ({ className }: Props) => {
    const intl = useIntl();
    const upgradePlanBannerClassname = classNames(
        'upgrade-plan-banner',
        className,
    );
    return (
        <div className={upgradePlanBannerClassname}>
            <Surface className="upgrade-plan-banner-card" level={'3'}>
                <PageContent className="upgrade-plan-banner-card-inner">
                    <h1>
                        {intl.formatMessage({ id: 'upgradePlanBanner.title' })}
                    </h1>
                    <div className="text">
                        {intl.formatMessage(
                            {
                                id: 'upgradePlanBanner.description',
                            },
                            {
                                tenantName: 'TractIQ SelfStorage',
                            },
                        )}
                    </div>
                    <UpgradePlanActionButton />
                </PageContent>
            </Surface>
        </div>
    );
};

export default UpgradePlanBanner;
