import SelectSurveyScreen from '../constants/SelectSurveyScreen';
import * as NewSurveySelector from './NewSurvey';
import { DefaultRootState } from 'react-redux';
import * as ReportApp from '../typescript/types';

export const getIsLoading = (state: DefaultRootState) =>
    !!state.selectSurveyPageStatus.loading;

export const getIsLoaded = (state: DefaultRootState) =>
    !!state.selectSurveyPageStatus.loaded;

export const getError = (state: DefaultRootState) =>
    state.selectSurveyPageStatus.error;

export const getSurveyGroupById = (
    state: DefaultRootState,
    surveyGroupId: number,
) => state.db.surveyGroups[surveyGroupId];

export const getAllSurveyGroups = (state: DefaultRootState) => {
    const { surveyGroupIds } = state.selectSurveyPageStatus;
    return surveyGroupIds?.map((surveyGroupId) =>
        getSurveyGroupById(state, surveyGroupId),
    );
};

export const getAllSurveys = (state: DefaultRootState) =>
    Object.values(state.db.surveys);

export const getWhatToDisplay = (state: DefaultRootState) => {
    if (getIsLoading(state)) {
        return SelectSurveyScreen.LOADING;
    }
    if (getError(state)) {
        return SelectSurveyScreen.ERROR;
    }
    if (NewSurveySelector.getShouldDisplayChangeSurveyAnalyse(state)) {
        return SelectSurveyScreen.CHANGE_SURVEY_INFO;
    }
    return SelectSurveyScreen.SELECT_SURVEY;
};

export const getSurveyByCode = (
    state: DefaultRootState,
    surveyCode: ReportApp.SurveyCode,
) => state.db.surveys[surveyCode];

export const getSurveyNameByCode = (
    state: DefaultRootState,
    surveyCode: ReportApp.SurveyCode,
) => getSurveyByCode(state, surveyCode).name;

export const getSurveyGroupBySurveyCode = (
    state: DefaultRootState,
    surveyCode: ReportApp.SurveyCode,
) =>
    Object.values(state.db.surveyGroups).find((surveyGroup) =>
        surveyGroup.surveyCodes.includes(surveyCode),
    );

export const getSurveysInSurveyGroupBySurveyCode = (
    state: DefaultRootState,
    surveyCode: ReportApp.SurveyCode,
) => {
    const surveyGroup = getSurveyGroupBySurveyCode(state, surveyCode);
    if (!surveyGroup) {
        return [];
    }
    return surveyGroup.surveyCodes.map((surveyCode: ReportApp.SurveyCode) =>
        getSurveyByCode(state, surveyCode),
    );
};

export const getSurveyByYearSurveyGroupIdComparabilityGroup = (
    state: DefaultRootState,
    year: number,
    surveyGroupId: number,
    comparabilityGroup: ReportApp.ComparabilityGroup,
) => {
    const surveyGroup = getSurveyGroupById(state, surveyGroupId);
    for (const surveyCode of surveyGroup.surveyCodes) {
        const survey = getSurveyByCode(state, surveyCode);
        if (
            survey.year === year &&
            survey.comparabilityGroup === comparabilityGroup
        ) {
            return survey;
        }
    }
    return null;
};

/**
 * Find out if a given survey is in same surveyGroup as selectedSurvey, and if
 * it has the same comparabilityGroup.
 */
export const areSurveysComparable = (
    state: DefaultRootState,
    selectedSurveyCode: ReportApp.SurveyCode,
    comparedSurveyCode: ReportApp.SurveyCode,
) => {
    if (selectedSurveyCode === comparedSurveyCode) {
        return true;
    }
    const surveyGroup = getSurveyGroupBySurveyCode(state, selectedSurveyCode);
    if (!surveyGroup?.surveyCodes.includes(comparedSurveyCode)) {
        return false;
    }
    const selectedSurvey = getSurveyByCode(state, selectedSurveyCode);
    if (!selectedSurvey.comparabilityGroup) {
        // comparabilityGroup group with value null or 0 means it can't be
        // compared with any other survey
        return false;
    }
    const comparedSurvey = getSurveyByCode(state, comparedSurveyCode);

    return (
        selectedSurvey.comparabilityGroup === comparedSurvey.comparabilityGroup
    );
};
