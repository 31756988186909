import React from 'react';
import { useSelector } from 'react-redux';

import * as Templates from '../../selectors/Templates';
import * as TemplateConstants from '../../constants/Templates';
import { useLoadTemplateAndSetIntermittentTables } from '../../customHooks/useLoadTemplateAndSetIntermittentTables';

import Loader from '../Loader/Loader';
import SelectTemplateTablesContent from './SelectTemplateTablesContent';
import { SurveyCode } from '../../typescript/types';

type Props = {
    setModalContentStatus: (status: string) => void;
    surveyCodes: SurveyCode[];
};

const SelectTemplateTables = ({
    setModalContentStatus,
    surveyCodes,
}: Props) => {
    const tableTemplateId = useSelector(Templates.getTemplateId);
    const tableTemplate = useSelector((state) =>
        Templates.getTableTemplateById(state, tableTemplateId),
    );
    const loadTableTemplateError = useSelector(
        Templates.getTableTemplateLoadingError,
    );
    const tableTemplateDisplayStatus = useSelector((state) =>
        Templates.getTableTemplateDisplayStatusById(state, tableTemplateId),
    );
    useLoadTemplateAndSetIntermittentTables({
        tableTemplateId,
        tableTemplate,
    });

    let content;
    switch (tableTemplateDisplayStatus) {
        case TemplateConstants.FetchTableTemplate.FETCH_TABLE_TEMPLATE_ERROR:
            content = <div>{loadTableTemplateError}</div>;
            break;
        case TemplateConstants.FetchTableTemplate.FETCH_TABLE_TEMPLATE_LOADING:
            content = <Loader />;
            break;
        case TemplateConstants.FetchTableTemplate.TABLE_TEMPLATE_LOADED:
            content = tableTemplate ? (
                <SelectTemplateTablesContent
                    tableTemplate={tableTemplate}
                    setModalContentStatus={setModalContentStatus}
                    surveyCodes={surveyCodes}
                />
            ) : null;
            break;
        default:
            content = null;
    }

    return content;
};

export default SelectTemplateTables;
