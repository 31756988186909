import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { CLEAR_SELECTED_SCREEN } from '../../../constants/SelectGeographyScreen';

import Button from '../../Buttons/Button';
import AddToSelection from '../AddToSelection';

import './GeoTypesActions.scss';

const GeoTypesActions = () => {
    const dispatch = useDispatch();
    return (
        <div className="zoomable-actions geo-types-actions">
            <Button
                variant="flat small"
                onClick={() => {
                    dispatch({ type: CLEAR_SELECTED_SCREEN });
                }}
            >
                <FormattedMessage id="cancel" />
            </Button>
            <AddToSelection />
        </div>
    );
};

export default GeoTypesActions;
