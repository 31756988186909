import React from 'react';
import { useIntl } from 'react-intl';

import './CollapseInfoTriggerDescription.scss';

export type Props = {
    found: number;
    total: number;
    allFoundMessageId: string;
    someFoundMessageId: string;
};

const CollapseInfoTriggerDescription = ({
    found,
    total,
    allFoundMessageId,
    someFoundMessageId,
}: Props) => {
    const intl = useIntl();

    let geoAnalysisMessage;
    if (found === total) {
        geoAnalysisMessage = intl.formatMessage(
            { id: allFoundMessageId },
            { count: found },
        );
    } else {
        geoAnalysisMessage = intl.formatMessage(
            { id: someFoundMessageId },
            { value: found, base: total },
        );
    }

    return (
        <div className="collapse-info-trigger-left-text-description">
            {geoAnalysisMessage}
        </div>
    );
};

export default CollapseInfoTriggerDescription;
