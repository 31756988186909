import React from 'react';
import { FormattedMessage } from 'react-intl';

import SourcesPanel from '../../Sources/SourcesPanel';

import './InfoPanel.scss';

const InfoPanel = () => {
    return (
        <div id="info-panel">
            <div>
                <FormattedMessage id="report.sidebar.info.documentationInfo" />
            </div>
            <SourcesPanel />
        </div>
    );
};

export default InfoPanel;
