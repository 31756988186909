import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { formatValue } from '../../../../helpers/ValueFormatter';
import ValueFormat from '../../../../constants/ValueFormat';
import * as ReportApp from '../../../../typescript/types';

type Props = {
    valueOrCallback:
        | ReportApp.ReportDataValue
        | (() => Promise<ReportApp.ReportDataValue>);
    format: ValueFormat;
    hasAsterisk: Boolean;
};

const SingleTableVariableCell = ({
    valueOrCallback,
    format,
    hasAsterisk,
}: Props) => {
    const intl = useIntl();
    const initValue =
        typeof valueOrCallback === 'function'
            ? intl.formatMessage({ id: 'loading' })
            : formatValue(valueOrCallback, format);
    const [value, setValue] = useState<string | null>(initValue);

    useEffect(() => {
        if (typeof valueOrCallback === 'function') {
            valueOrCallback().then((response) => {
                setValue(formatValue(response, format) ?? '');
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    let title: string | undefined;
    let content: string | null = value;
    if (hasAsterisk) {
        title = intl.formatMessage({ id: 'report.table.asteriskTooltip' });
        content = `* ${content}`;
    }

    return (
        <td title={title} className="variable-value">
            {content}
        </td>
    );
};

export default SingleTableVariableCell;
