import { all, put, takeEvery } from 'redux-saga/effects';

import { LOAD, PARSE_URL } from '../constants/Init';
import { FETCH_ME_REQUEST } from '../constants/CurrentUser';
import { FETCH_CPI_REQUEST } from '../constants/Report';
import { SET_SURVEY_CODE } from '../constants/Selection';

import ApplicationPaths from '../helpers/ApplicationPaths';
import * as InitActions from './../typescript/actions/InitActions';
import { ReportAppActions } from '../typescript/actions/actions';

function* init({ payload: { pathname } }: InitActions.Load) {
    yield put({ type: FETCH_ME_REQUEST });
    // Consumer price indices are used in every report that has dollar
    // adjustment option. Since the CPI values do not change, we will fetch them
    // once, at init.
    yield put<ReportAppActions>({ type: FETCH_CPI_REQUEST });
    yield put<ReportAppActions>({ type: PARSE_URL, payload: { pathname } });
}

function* parseUrl({ payload: { pathname } }: InitActions.ParseUrl) {
    const geoParams = ApplicationPaths.geographies.getParams(pathname);
    const tableParams = ApplicationPaths.tables.getParams(pathname);
    const surveyCode = geoParams?.surveyCode || tableParams?.surveyCodes;
    if (surveyCode) {
        yield put({
            type: SET_SURVEY_CODE,
            payload: surveyCode,
        });
    }
}

export default function* () {
    yield all([
        yield takeEvery(LOAD, init),
        yield takeEvery(PARSE_URL, parseUrl),
    ]);
}
