export const REPORT_LOADING = '@@reportScreen/REPORT_LOADING';
export const REPORT_ERROR = '@@reportScreen/REPORT_ERROR';
export const REPORT = '@@reportScreen/REPORT';

export const CREATE_REPORT_LOADING = '@@reportScreen/CREATE_REPORT_LOADING';
export const CREATE_REPORT_ERROR = '@@reportScreen/CREATE_REPORT_ERROR';
export const CREATE_REPORT_DONE = '@@reportScreen/CREATE_REPORT_DONE';

export const SAVE_REPORT_STATUS_LOADING =
    '@@reportScreen/SAVE_REPORT_STATUS_LOADING';
export const SAVE_REPORT_STATUS_ERROR =
    '@@reportScreen/SAVE_REPORT_STATUS_ERROR';
export const SAVE_REPORT_STATUS_AVAILABLE =
    '@@reportScreen/SAVE_REPORT_STATUS_AVAILABLE';
export const SAVE_REPORT_STATUS_ALREADY_SAVED =
    '@@reportScreen/SAVE_REPORT_STATUS_ALREADY_SAVED';

export const DELETE_REPORT_STATUS_LOADING =
    '@@reportScreen/DELETE_REPORT_STATUS_LOADING';
export const DELETE_REPORT_STATUS_ERROR =
    '@@reportScreen/DELETE_REPORT_STATUS_ERROR';
export const DELETE_REPORT_STATUS_AVAILABLE =
    '@@reportScreen/DELETE_REPORT_STATUS_AVAILABLE';

export const UPDATE_REPORT_STATUS_LOADING =
    '@@reportScreen/UPDATE_REPORT_STATUS_LOADING';
export const UPDATE_REPORT_STATUS_ERROR =
    '@@reportScreen/UPDATE_REPORT_STATUS_ERROR';
export const UPDATE_REPORT_STATUS_AVAILABLE =
    '@@reportScreen/UPDATE_REPORT_STATUS_AVAILABLE';

export const DUPLICATE_REPORT_STATUS_LOADING =
    '@@reportScreen/DUPLICATE_REPORT_STATUS_LOADING';
export const DUPLICATE_REPORT_STATUS_ERROR =
    '@@reportScreen/DUPLICATE_REPORT_STATUS_ERROR';
export const DUPLICATE_REPORT_STATUS_AVAILABLE =
    '@@reportScreen/DUPLICATE_REPORT_STATUS_AVAILABLE';
