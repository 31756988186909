export const GEOGRAPHY_PAGE_LOADING =
    '@@selectGeographyScreen/GEOGRAPHY_PAGE_LOADING';
export const GEOGRAPHY_PAGE_ERROR =
    '@@selectGeographyScreen/GEOGRAPHY_PAGE_ERROR';
export const GEO_TYPE_SELECTION = '@@selectGeographyScreen/GEO_TYPE_SELECTION';
export const GEO_ITEMS_SELECTION =
    '@@selectGeographyScreen/GEO_ITEMS_SELECTION';
export const GEO_FIPS_SELECTION = '@@selectGeographyScreen/GEO_FIPS_SELECTION';
export const SET_SELECTED_SCREEN =
    '@@selectGeographyScreen/SET_SELECTED_SCREEN';
export const CLEAR_SELECTED_SCREEN =
    '@@selectGeographyScreen/CLEAR_SELECTED_SCREEN';
