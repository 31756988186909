import { useEffect } from 'react';
import { isElementChildOf } from '../helpers/HtmlHelper';

const nestedModalsRootElements: HTMLElement[] = [];

export const focusElementOrFirstChild = (elementId: string): void => {
    const element = document.getElementById(elementId);
    if (!element) {
        return;
    }
    const firstFocusableChild = getFirstFocusableChild(element);
    if (firstFocusableChild) {
        firstFocusableChild.focus();
    } else {
        element.focus();
    }
};

export const getFirstFocusableChild = (root: HTMLElement | null) => {
    if (!root) {
        return null;
    }

    return root.querySelector<HTMLElement>(
        'input:not(:disabled), textarea:not(:disabled), button:not(:disabled), a:not(:disabled)',
    );
};

const getLastFocusableChild = (root: HTMLElement | null) => {
    if (!root) {
        return null;
    }

    const focusableChildren = root.querySelectorAll<HTMLElement>(
        'input:not(:disabled), textarea:not(:disabled), button:not(:disabled), a:not(:disabled)',
    );

    return focusableChildren[focusableChildren.length - 1] ?? null;
};

const moveFocusToFirstChild = (element: HTMLElement) => {
    const firstFocusableChild = getFirstFocusableChild(element);
    if (!firstFocusableChild) {
        return;
    }

    firstFocusableChild.focus();
};

const returnFocusInContainer = (event: KeyboardEvent) => {
    if (event.key !== 'Tab') {
        return;
    }

    const root = nestedModalsRootElements[nestedModalsRootElements.length - 1];
    const { target } = event;
    if (!(target instanceof HTMLElement)) {
        console.error(`Element ${target} is not instance of HTMLElement`);
        return;
    }

    // Once the focus leaves overlay container, return focus back inside
    const tabOnLastChild = target === getLastFocusableChild(root);
    const reverseTabOnFirstChild =
        target === getFirstFocusableChild(root) && event.shiftKey;

    if (
        tabOnLastChild ||
        reverseTabOnFirstChild ||
        !isElementChildOf(target, root)
    ) {
        event.preventDefault();
        moveFocusToFirstChild(root);
    }
};

const lockFocusInsideModal = (modal: HTMLElement) => {
    if (nestedModalsRootElements.length === 0) {
        // we need only one listener, no matter how many nested modals we have
        document.addEventListener('keydown', returnFocusInContainer);
    }
    nestedModalsRootElements.push(modal);
};

const unlockFocusFromModal = () => {
    nestedModalsRootElements.pop();
    if (nestedModalsRootElements.length === 0) {
        // if this is the last nested modal, then remove event listener
        document.removeEventListener('keydown', returnFocusInContainer);
    }
};

export const useLockFocus = (
    root: React.MutableRefObject<HTMLElement> | React.RefObject<HTMLElement>,
    focusFirstChild: boolean,
) => {
    useEffect(() => {
        if (!root.current) {
            return;
        }
        lockFocusInsideModal(root.current);
        if (focusFirstChild) {
            moveFocusToFirstChild(root.current);
        }

        return unlockFocusFromModal;
    }, [focusFirstChild, root]);
};
