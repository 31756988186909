import React from 'react';
import classnames from 'classnames';

import Button from '../../Buttons/Button';
import Icon from '../../Icon/Icon';

import './Toggle.scss';

const Toggle = ({ children, className, open = false, ...rest }) => {
    const classes = classnames(className, 'survey-group-toggle-button');
    const iconName = open ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    return (
        <li className="survey-group-toggle-item">
            <Button className={classes} variant="flat" {...rest}>
                <Icon className="toggle-icon">{iconName}</Icon>
                {children}
            </Button>
        </li>
    );
};

export default Toggle;
