import { all, put, select, takeEvery } from 'redux-saga/effects';
import {
    SET_SELECTED_GEO_FIPSES,
    APPEND_FIPS_SELECTION,
    CLEAR_BELOW_SUMMARY_LEVEL,
    CLEAR_GEO_ITEMS_BELOW_SUMMARY_LEVEL,
    CLEAR_GEO_ITEMS_BY_FIPS_RESULTS,
    CLEAR_GEO_SELECTION_BELOW_SUMMARY_LEVEL,
    CLEAR_SELECTABLE_PARENT_GEO_TYPES,
    EXIT_GEO_ITEMS_SELECTION,
    FETCH_PARENT_GEO_TYPES_REQUEST,
    MODIFY_FIPS_SELECTION,
    SELECT_GEO_TYPE_ITEM,
    SET_SELECTED_GEO_TYPE,
} from '../constants/Geographies';
import {
    CLEAR_SELECTED_SCREEN,
    GEO_TYPE_SELECTION,
    SET_SELECTED_SCREEN,
} from '../constants/SelectGeographyScreen';
import { getSelectedGeographies } from '../selectors/GeoTypes';
import { getFipsCodeForSelection } from '../helpers/Geographies';

function* onSelectGeoTypeItem({ payload: { surveyCode, geoTypeName } }) {
    yield put({ type: SET_SELECTED_GEO_TYPE, payload: geoTypeName });
    yield put({
        type: FETCH_PARENT_GEO_TYPES_REQUEST,
        payload: { surveyCode, geoTypeName },
    });
}

function* onClearBelowSummaryLevel({ payload: { geoType, value } }) {
    yield put({
        type: CLEAR_GEO_ITEMS_BELOW_SUMMARY_LEVEL,
        payload: { sumLev: geoType.name },
    });
    yield put({
        type: CLEAR_GEO_SELECTION_BELOW_SUMMARY_LEVEL,
        payload: { geoType, value },
    });
}
function* onExitGeoItemsSelection() {
    yield put({ type: CLEAR_SELECTABLE_PARENT_GEO_TYPES });
    yield put({ type: SET_SELECTED_SCREEN, payload: GEO_TYPE_SELECTION });
}

function* onModifyFipsSelection({ payload: { fipsArray } }) {
    yield put({ type: SET_SELECTED_GEO_FIPSES, payload: fipsArray });
    yield put({ type: CLEAR_SELECTED_SCREEN });
}

function* onAppendFipsSelection({ payload: { geoSelection } }) {
    const currentFipsSelection = yield select(getSelectedGeographies);
    const fipsSelection = geoSelection.map(getFipsCodeForSelection);
    const appendedSelection = [
        ...new Set([...currentFipsSelection, ...fipsSelection]),
    ];
    yield put({ type: CLEAR_GEO_ITEMS_BY_FIPS_RESULTS });
    yield put({ type: SET_SELECTED_GEO_FIPSES, payload: appendedSelection });
}

export default function* () {
    yield all([
        yield takeEvery(SELECT_GEO_TYPE_ITEM, onSelectGeoTypeItem),
        yield takeEvery(EXIT_GEO_ITEMS_SELECTION, onExitGeoItemsSelection),
        yield takeEvery(CLEAR_BELOW_SUMMARY_LEVEL, onClearBelowSummaryLevel),
        yield takeEvery(MODIFY_FIPS_SELECTION, onModifyFipsSelection),
        yield takeEvery(APPEND_FIPS_SELECTION, onAppendFipsSelection),
    ]);
}
