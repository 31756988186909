import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '../../Buttons/Button';
import Icon from '../../Icon/Icon';

import './SurveysFilterableByTextListButton.scss';

const SurveysFilterableByTextListButton = ({ onClick }) => {
    const intl = useIntl();

    const searchButtonAriaLabel = intl.formatMessage({
        id: 'surveysPage.searchButtonAriaLabel',
    });

    return (
        <Button
            onClick={onClick}
            className="survey-filterable-by-text-list-button"
            role="search"
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={true}
            title={searchButtonAriaLabel}
            aria-label={searchButtonAriaLabel}
        >
            <Icon className="survey-filterable-by-text-list-button-icon">
                search
            </Icon>
            <FormattedMessage id="surveysPage.searchSurveys" />
        </Button>
    );
};

export default SurveysFilterableByTextListButton;
