export const FETCH_DATASETS_REQUEST = '@@table/FETCH_DATASETS_REQUEST';
export const FETCH_DATASETS_SUCCESS = '@@table/FETCH_DATASETS_SUCCESS';
export const FETCH_DATASETS_FAILURE = '@@table/FETCH_DATASETS_FAILURE';

export const SET_NORMALIZED_TABLES = '@@table/SET_NORMALIZED_TABLES';
export const SET_TABLES_BY_SURVEY_CODE = '@@table/SET_TABLES_BY_SURVEY_CODE';
export const SET_NORMALIZED_DATASETS = '@@table/SET_NORMALIZED_DATASETS';
export const SET_NORMALIZED_PREMADE_REPORTS =
    '@@table/SET_NORMALIZED_PREMADE_REPORTS';

export const SET_INTERMITTENT_TABLES_SELECTION =
    '@@table/SET_INTERMITTENT_TABLES_SELECTION';
export const CLEAR_TEMPORARY_TABLES_SELECTION =
    '@@table/CLEAR_TEMPORARY_TABLES_SELECTION';
export const REMOVE_TABLE_FROM_TEMPORARY_SELECTION =
    '@@table/REMOVE_TABLE_FROM_TEMPORARY_SELECTION';

export const FETCH_PREMADE_REPORTS_REQUEST =
    '@@table/FETCH_PREMADE_REPORTS_REQUEST';
export const FETCH_PREMADE_REPORTS_SUCCESS =
    '@@table/FETCH_PREMADE_REPORTS_SUCCESS';
export const FETCH_PREMADE_REPORTS_FAILURE =
    '@@table/FETCH_PREMADE_REPORTS_FAILURE';

export const TABLE_TEMPLATE_ID_PARAM_NAME = 'tableTemplateId';
export const CREATE_TEMPLATE_PARAM_NAME = 'createTemplate';
