import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ReportAppDispatch } from '../typescript/actions/actions';
import { getShouldLoadTableTemplateById } from '../selectors/Templates';
import { FETCH_TABLE_TEMPLATE_REQUEST } from '../constants/Templates';
import {
    prepareNormalizedTablesFromUrlParams,
    getHasIntermittentTables,
} from '../selectors/Table';
import { SET_INTERMITTENT_TABLES_SELECTION } from '../constants/Table';
import { Template } from '../typescript/types';

type Props = {
    tableTemplateId: number | null;
    tableTemplate: Template | undefined;
};

export const useLoadTemplateAndSetIntermittentTables = ({
    tableTemplateId,
    tableTemplate,
}: Props) => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const shouldLoadTableTemplate = useSelector((state) =>
        getShouldLoadTableTemplateById(state, tableTemplateId),
    );
    useEffect(() => {
        // First, fetch template with a given ID
        if (shouldLoadTableTemplate && tableTemplateId != null) {
            dispatch({
                type: FETCH_TABLE_TEMPLATE_REQUEST,
                payload: tableTemplateId,
            });
        }
    }, [shouldLoadTableTemplate, dispatch, tableTemplateId]);

    const fullTablesFromUrlParams = useSelector((state) =>
        prepareNormalizedTablesFromUrlParams(
            state,
            tableTemplate?.tables,
            tableTemplate?.surveyCode,
        ),
    );
    const hasIntermittentTables = useSelector(getHasIntermittentTables);

    useEffect(() => {
        if (fullTablesFromUrlParams.length && !hasIntermittentTables) {
            dispatch({
                type: SET_INTERMITTENT_TABLES_SELECTION,
                payload: fullTablesFromUrlParams,
            });
        }
    }, [fullTablesFromUrlParams, hasIntermittentTables, dispatch]);
};
