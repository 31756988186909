import React from 'react';
import { useSelector } from 'react-redux';

import * as NewSurveySelectors from '../../../selectors/NewSurvey';
import NewSurveyScreen from '../../../constants/NewSurveyScreen';

import Loader from '../../Loader/Loader';
import ChangeSurveyInfo from './ChangeSurveyInfo';

const ChangeSurvey = () => {
    const display = useSelector(NewSurveySelectors.getWhatToDisplay);
    const error = useSelector(NewSurveySelectors.getError);

    switch (display) {
        case NewSurveyScreen.LOADING:
            return <Loader />;
        case NewSurveyScreen.ERROR:
            return <div>{JSON.stringify(error, null, 2)}</div>;
        case NewSurveyScreen.ANALYSIS_RESULTS:
            return <ChangeSurveyInfo />;
        default:
            return null;
    }
};

export default ChangeSurvey;
