import { DefaultRootState } from 'react-redux';
import * as ReportApp from '../typescript/types';
import { filterFalsy } from '../typescript/helper';
import * as Table from './Table';
import * as SelectTablesPageConstants from '../constants/SelectTablesPage';
import * as Template from './Templates';
import { PREMADE_REPORT_ITEM_TYPE } from '../constants/Report';

/**
 * @param state Global redux state
 * @param surveyCode Survey for which to get premade reports
 * @param surveyCodes Codes to map into tables
 */
export const getPremadeReports = (
    state: DefaultRootState,
    surveyCode: ReportApp.SurveyCode,
    surveyCodes: ReportApp.SurveyCode[],
): ReportApp.PremadeReportWithTables[] =>
    getPremadeReportBySurveyCode(state, surveyCode).map((report) => {
        return {
            id: report.id,
            name: report.name,
            tables: report.tableGuids
                .map((tableGuid) =>
                    mapTableGuidToPremadeReportTables(
                        state,
                        tableGuid,
                        surveyCodes,
                    ),
                )
                .filter(filterFalsy),
        };
    });

export const getPremadeReportsAndTemplatesForAllSurveyCodes = (
    state: DefaultRootState,
    surveyCodes: ReportApp.SurveyCode[],
) => {
    return surveyCodes.flatMap((surveyCode) => {
        const premadeReports = getPremadeReports(
            state,
            surveyCode,
            surveyCodes,
        );
        const templatesOfSurvey = Template.getTemplatesForPremadeReports(
            state,
            surveyCode,
            surveyCodes,
        );
        return [...premadeReports, ...templatesOfSurvey];
    });
};

export const getPremadeReportsAndTemplatesWithDividers = (
    state: DefaultRootState,
    surveyCode: ReportApp.SurveyCode,
    surveyCodes: ReportApp.SurveyCode[],
) => {
    const premadeReports = getPremadeReports(state, surveyCode, surveyCodes);
    if (premadeReports.length) {
        // If there is no premade report, do not add the "General" divider item
        // under your template report items
        premadeReports.unshift({
            id: PREMADE_REPORT_ITEM_TYPE.PREMADE_REPORT,
            tables: [],
            isDivider: true,
        });
    }

    const templates = Template.getTemplatesForPremadeReports(
        state,
        surveyCode,
        surveyCodes,
    );
    if (templates.length) {
        // If there no templates, do not add the "Your templates" divider item
        // above premade report items
        templates.unshift({
            id: PREMADE_REPORT_ITEM_TYPE.TEMPLATE,
            tables: [],
            isDivider: true,
        });
    }

    return [...templates, ...premadeReports];
};

export const getPremadeReportsDisplayStatus = (
    state: DefaultRootState,
    surveyCodes: ReportApp.SurveyCode[],
) => {
    const { loading, error } = state.selectTablesPageStatus.premadeReports;
    const datasetsStatus = Table.getDatasetsDisplayStatus(state, surveyCodes);
    if (
        loading ||
        datasetsStatus === SelectTablesPageConstants.DATASETS_LOADING ||
        getShouldLoadPremadeReports(state, surveyCodes) ||
        Template.getShouldFetchTemplates(state)
    ) {
        return SelectTablesPageConstants.PREMADE_REPORTS_LOADING;
    } else if (error) {
        return SelectTablesPageConstants.PREMADE_REPORTS_ERROR;
    } else {
        return SelectTablesPageConstants.PREMADE_REPORTS_SELECTION;
    }
};

export const getPremadeReportBySurveyCode = (
    state: DefaultRootState,
    surveyCode: ReportApp.SurveyCode,
) => state.db.premadeReports[surveyCode];

export const getShouldLoadPremadeReports = (
    state: DefaultRootState,
    surveyCodes: ReportApp.SurveyCode[],
) => {
    const { loading, error } = state.selectTablesPageStatus.premadeReports;
    return (
        !loading &&
        !error &&
        !surveyCodes.every((surveyCode) =>
            getPremadeReportBySurveyCode(state, surveyCode),
        )
    );
};

export const getPremadeReportsAndTemplatesIsEmpty = (
    state: DefaultRootState,
    surveyCodes: ReportApp.SurveyCode[],
) => {
    return surveyCodes.every((surveyCode) => {
        const premadeReportsForSurveyCode = state.db.premadeReports[surveyCode];
        const templatesForPremadeReports = Template.getTemplatesForPremadeReports(
            state,
            surveyCode,
            surveyCodes,
        );
        return (
            !premadeReportsForSurveyCode?.length &&
            !templatesForPremadeReports?.length
        );
    });
};

export const getPremadeReportsError = (state: DefaultRootState) =>
    state.selectTablesPageStatus.premadeReports.error;

export const mapTableGuidToPremadeReportTables = (
    state: DefaultRootState,
    tableGuid: string | undefined,
    surveyCodes: ReportApp.SurveyCode[],
) => {
    if (!tableGuid) {
        return null;
    }

    const pivotTable = Table.getTableByGuid(state, tableGuid);
    if (!pivotTable) {
        return null;
    }
    const tableBySurveyCode = Table.getTableForReportBySurveyCode(
        state,
        pivotTable,
        surveyCodes,
    );
    return tableBySurveyCode.hasFoundTableInEverySurvey
        ? tableBySurveyCode
        : null;
};
