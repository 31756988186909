import * as NewSurvey from './NewSurvey';
import * as Survey from './Survey';
import * as Selection from './Selection';
import * as ReportData from './ReportData';
import * as ReportMetadata from './ReportMetadata';
import * as ReportScreen from '../constants/ReportScreen';
import { intl } from '../helpers/CreateIntl';
import * as WizardHeader from './WizardHeader';
import ApplicationPaths from '../helpers/ApplicationPaths';

/** @param {import('react-redux').DefaultRootState} state */
export const getShouldCreateReport = (state) => {
    const { loading, error } = state.reportStatus.createReport;
    if (WizardHeader.reportAlreadyExists(state)) {
        return (
            // has something in selection
            (NewSurvey.getShouldDisplayChangeSurveyAnalyse(state) ||
                Selection.hasGeographiesSelected(state) ||
                Selection.hasTablesSelected(state)) &&
            // but haven't created report yet
            !Selection.getNewReportId(state) &&
            // and not in process of creating report
            !loading &&
            !error
        );
    }
    return (
        Selection.hasSurveyCodeSelected(state) &&
        Selection.hasGeographiesSelected(state) &&
        Selection.hasTablesSelected(state) &&
        !Selection.getReportId(state) &&
        !Selection.getNewReportId(state) &&
        !loading &&
        !error
    );
};

/** @param {import('react-redux').DefaultRootState} state */
export const getCreateReportStatus = (state) => {
    const { error, loading } = state.reportStatus.createReport;
    if (error) {
        return ReportScreen.CREATE_REPORT_ERROR;
    } else if (loading || !Selection.getNewReportId(state)) {
        return ReportScreen.CREATE_REPORT_LOADING;
    }
    return ReportScreen.CREATE_REPORT_DONE;
};

/** @param {import('react-redux').DefaultRootState} state */
export const getIsCpiLoading = (state) =>
    state.reportStatus.retrieveCpi.loading;

/** @param {import('react-redux').DefaultRootState} state */
export const getCreateReportError = (state) =>
    state.reportStatus.createReport.error;

/** @param {import('react-redux').DefaultRootState} state */
export const getCsvDownloadOptions = (state) => state.downloadReport.csvOptions;

export const getDownloadExcelOptions = (state) => {
    const surveyCode = ReportMetadata.getSurveyCodesInReportBySurveyGroup(
        state,
    );
    const reportId = ReportMetadata.getSingleReportId(state);
    const reportType = 'Excel2007';
    const outputChange = false;

    const {
        percents,
        aggregatesOnly,
        wrapVarLabels,
        freezeGeoRowAndVarCol,
        varHighlightPercentAndAgg,
        marginsToHalfIn,
        printZoomTo75,
        varLablesGeoNamesOnEachPage,
    } = state.downloadReport.excelOptions;

    return {
        surveyCode,
        reportId,
        reportType,
        percents,
        aggregatesOnly,
        outputChange,
        wrapVarLabels,
        freezeGeoRowAndVarCol,
        varHighlightPercentAndAgg,
        marginsToHalfIn,
        printZoomTo75,
        varLablesGeoNamesOnEachPage,
    };
};

// report status
export const getShouldFetchReport = (state, reportId) => {
    const error = getRetrieveReportError(state);
    const loading = getIsRetrievingReport(state);
    const shouldUpdateSelectedReportId = Selection.getShouldUpdateSelectedReportId(
        state,
        reportId,
    );
    const isSurveyLoading = Survey.getIsLoading(state);
    const isCpiLoading = getIsCpiLoading(state);
    return (
        !shouldUpdateSelectedReportId &&
        !error &&
        !loading &&
        !isSurveyLoading &&
        !isCpiLoading &&
        ReportData.getShouldFetchData(state)
    );
};

export const getExistingReportScreen = (state, reportId) => {
    if (
        getIsRetrievingReport(state) ||
        getShouldFetchReport(state, reportId) ||
        Selection.getShouldUpdateSelectedReportId(state, reportId)
    ) {
        return ReportScreen.REPORT_LOADING;
    }
    if (getRetrieveReportError(state)) {
        return ReportScreen.REPORT_ERROR;
    }
    return ReportScreen.REPORT;
};

/**@param {import('react-redux').DefaultRootState} state */
export const getRetrieveReportError = (state) =>
    state.reportStatus.retrieveReport.error;

export const getIsRetrievingReport = (state) =>
    state.reportStatus.retrieveReport.loading;

// selectors related to reportView reducer
/**@param {import('react-redux').DefaultRootState} state */
export const getAggregationType = (state) => state.reportView.aggregationType;

/**@param {import('react-redux').DefaultRootState} state */
export const getSelectedDollarAdjustment = (state) =>
    state.reportView.selectedDollarAdjustment;

/**@param {import('react-redux').DefaultRootState} state */
export const getSelectedDollarAdjustmentLabel = (state) => {
    const selectedDollarAdjustment = getSelectedDollarAdjustment(state);
    if (selectedDollarAdjustment === 0) {
        return intl.formatMessage({ id: 'none' });
    }
    return selectedDollarAdjustment;
};

/**@param {import('react-redux').DefaultRootState} state */
export const getOnSuccessfulDuplicationURL = (state) => {
    const reportId = getDuplicateReportId(state);
    if (!reportId || reportId === state.selection.reportId) {
        return false;
    }
    return ApplicationPaths.existingReport
        .getPath({
            reportId,
        })
        .replace(/\/+/g, '/');
};

/**@param {import('react-redux').DefaultRootState} state */
export const getDuplicateReportId = (state) =>
    state.selection.duplicateReportId;

/**@param {import('react-redux').DefaultRootState} state */
export const getReportHorizontallyScrolled = (state) =>
    state.reportView.reportHorizontallyScrolled;
