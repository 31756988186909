import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import { EXIT_GEO_ITEMS_SELECTION } from '../../constants/Geographies';
import { CLEAR_SELECTED_SCREEN } from '../../constants/SelectGeographyScreen';
import * as GeoTypes from '../../selectors/GeoTypes';
import { ReportAppDispatch } from '../../typescript/actions/actions';
import { SurveyCode } from '../../typescript/types';

import Icon from '../Icon/Icon';
import Button from '../Buttons/Button';
import SelectionCounter from '../Selection/SelectionCounter';

import './GeographiesModalHeader.scss';

type Props = {
    surveyCode: SurveyCode;
    geoTypeSelected: boolean;
};

/** @param {{surveyCode: string, geoTypeSelected: boolean}} param0 */
export const GeographiesModalHeader = ({
    surveyCode,
    geoTypeSelected,
}: Props) => {
    const dispatch = useDispatch<ReportAppDispatch>();
    const geoType = useSelector((state) =>
        GeoTypes.getSelectedGeoType(state, surveyCode),
    );
    const fipsSelection = useSelector(GeoTypes.getIntermittentFipsSelection);
    const intl = useIntl();
    const goBack = () => {
        dispatch({
            type: EXIT_GEO_ITEMS_SELECTION,
        });
    };
    const closeModal = () =>
        dispatch({
            type: CLEAR_SELECTED_SCREEN,
        });

    let title = <FormattedMessage id="geographiesPage.selection.header" />;
    let selectionCountClasses = '';

    if (geoTypeSelected) {
        title = (
            <>
                {geoType.sumLev} {geoType.label}
            </>
        );
        selectionCountClasses = 'offset';
    }
    const goBackButtonLabel = intl.formatMessage({
        id: 'goBack',
    });
    const closeButtonLabel = intl.formatMessage({
        id: 'close',
    });
    return (
        <div className="zoomable-header geographies-modal-header">
            <div className="geographies-modal-header-title-row">
                <div className="geographies-modal-header-title-wrapper">
                    {geoTypeSelected && (
                        <Button
                            variant="icon"
                            onClick={goBack}
                            title={goBackButtonLabel}
                            aria-label={goBackButtonLabel}
                        >
                            <Icon>arrow_back</Icon>
                        </Button>
                    )}
                    <div className="geographies-modal-header-title">
                        {title}
                    </div>
                </div>
                <Button
                    variant="icon"
                    onClick={closeModal}
                    title={closeButtonLabel}
                    aria-label={closeButtonLabel}
                >
                    <Icon>close</Icon>
                </Button>
            </div>
            <SelectionCounter
                numberOfSelectedItems={fipsSelection.length}
                active={fipsSelection.length > 0}
                className={selectionCountClasses}
            />
        </div>
    );
};
