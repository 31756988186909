import React, { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';

import Surface from '../base/Surface/Surface';
import InfoPanel from './InfoPanel/InfoPanel';
import CitationPanel from './InfoPanel/CitationPanel';
import InfoPanelTabControls from '../InfoPanel/InfoPanelTabControls';
import SharePanel from './SharePanel/SharePanel';
import IconButton from '../IconButton/IconButton';
import DownloadCsvPanel from './DownloadCsvPanel/DownloadCsvPanel';
import ModifyReportPanel from './ModifyReportPanel/ModifyReportPanel';
import DownloadExcelPanel from './DownloadExcelPanel/DownloadExcelPanel';
import DownloadPdfPanel from './DownloadPdfPanel/DownloadPdfPanel';
import SidebarPanelJumpButtons from './SidebarPanelJumpButtons';
import DownloadCsvHeaderInfo from './DownloadCsvPanel/DownloadCsvHeaderInfo';

import './SidebarPanel.scss';

import * as SidebarConstants from '../../constants/Sidebar';

const SidebarPanel = ({ onClose, currentSidebarPanel, activateTab }) => {
    let sidebarPanelTitle,
        sidebarPanelTitleInfo,
        sidebarPanelContent,
        sidebarPanelId,
        sidebarHeaderTabControls;
    const intl = useIntl();
    const closeButton = useRef(null);
    const sidebarPanelHeader = useRef(null);
    useEffect(() => {
        closeButton.current.focus();
    }, []);
    switch (currentSidebarPanel) {
        case SidebarConstants.MODIFY_REPORT:
            sidebarPanelTitle = intl.formatMessage({
                id: 'report.sidebar.modify.header',
            });
            sidebarPanelContent = <ModifyReportPanel />;
            sidebarPanelId = SidebarConstants.MODIFY_REPORT;
            break;
        case SidebarConstants.DOWNLOAD_EXCEL:
            sidebarPanelTitle = intl.formatMessage({
                id: 'report.sidebar.downloadExcel.header',
            });
            sidebarPanelContent = <DownloadExcelPanel />;
            sidebarPanelId = SidebarConstants.DOWNLOAD_EXCEL;
            break;
        case SidebarConstants.DOWNLOAD_PDF:
            sidebarPanelTitle = intl.formatMessage({
                id: 'report.sidebar.downloadPdf.header',
            });
            sidebarPanelContent = <DownloadPdfPanel />;
            sidebarPanelId = SidebarConstants.DOWNLOAD_PDF;
            break;
        case SidebarConstants.DOWNLOAD_CSV:
            sidebarPanelTitle = intl.formatMessage({
                id: 'report.sidebar.downloadCsv.header',
            });
            sidebarPanelTitleInfo = (
                <DownloadCsvHeaderInfo parent={sidebarPanelHeader.current} />
            );
            sidebarPanelContent = <DownloadCsvPanel />;
            sidebarPanelId = SidebarConstants.DOWNLOAD_CSV;
            break;
        case SidebarConstants.SHARE:
            sidebarPanelTitle = intl.formatMessage({
                id: 'report.sidebar.share.header',
            });
            sidebarPanelContent = <SharePanel />;
            sidebarPanelId = SidebarConstants.SHARE;
            break;
        case SidebarConstants.INFO:
            sidebarPanelTitle = intl.formatMessage({
                id: 'report.sidebar.info.panelTitle',
            });
            sidebarPanelContent = <InfoPanel />;
            sidebarPanelId = SidebarConstants.INFO;
            sidebarHeaderTabControls = (
                <InfoPanelTabControls
                    currentSidebarPanel={currentSidebarPanel}
                    activateTab={activateTab}
                />
            );
            break;
        case SidebarConstants.CITATION:
            sidebarPanelTitle = intl.formatMessage({
                id: 'report.sidebar.info.panelTitle',
            });
            sidebarPanelContent = <CitationPanel />;
            sidebarPanelId = SidebarConstants.CITATION;
            sidebarHeaderTabControls = (
                <InfoPanelTabControls
                    currentSidebarPanel={currentSidebarPanel}
                    activateTab={activateTab}
                />
            );
            break;
        default:
            sidebarPanelTitle = undefined;
            sidebarPanelContent = undefined;
            sidebarPanelId = undefined;
    }

    const title = intl.formatMessage({ id: 'report.sidebar.hide' });
    return (
        <Surface
            level={2}
            className="sidebar-panel"
            id={`${sidebarPanelId}-tab`}
            aria-labelledby={sidebarPanelId}
        >
            <div ref={sidebarPanelHeader} className="sidebar-panel-header">
                <div className="sidebar-panel-header-container">
                    <div className="sidebar-panel-title">
                        <h5>{sidebarPanelTitle}</h5>
                        {sidebarPanelTitleInfo}
                    </div>
                    <IconButton
                        onClick={onClose}
                        title={title}
                        aria-label={title}
                        ref={closeButton}
                        className="default"
                    >
                        close
                    </IconButton>
                </div>
                <div className="sidebar-panel-tab-controls">
                    {sidebarHeaderTabControls}
                </div>
            </div>
            <div className="sidebar-panel-content">
                {sidebarPanelContent}
                <SidebarPanelJumpButtons />
            </div>
        </Surface>
    );
};

export default SidebarPanel;
