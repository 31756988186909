import { getWhatMainButtonToRender } from './ReportHeader';
import { ReportActionsMainButtonType } from '../constants/ReportActionsEnum';
import { DefaultRootState } from 'react-redux';
import { getIsDefinitionAndByOldReportIdLoaded } from './ReportMetadata';

export const getActivatedSidebarPanel = (state: DefaultRootState) =>
    state.sidebar.activatedPanel;

export const getShouldOpenModifyReportPanel = (state: DefaultRootState) => {
    return (
        // When report is fully loaded and we know if user saved it or not,
        // we can then proceed and figure out if report is already saved.
        getIsDefinitionAndByOldReportIdLoaded(state) &&
        getWhatMainButtonToRender(state) !==
            ReportActionsMainButtonType.ALL_CHANGES_SAVED
    );
};
