import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '../../Buttons/Button';
import Icon from '../../Icon/Icon';

import './FipsCodesSelectorHeader.scss';

type Props = {
    closeModal: () => void;
};

const FipsCodesSelectorHeader = ({ closeModal }: Props) => {
    const intl = useIntl();

    const closeMessage = intl.formatMessage({ id: 'close' });
    return (
        <div className="zoomable-header fips-codes-selector-header">
            <h4>
                <FormattedMessage id="geographiesPage.fipsSelection.header" />
            </h4>
            <Button
                onClick={closeModal}
                title={closeMessage}
                aria-label={closeMessage}
            >
                <Icon>close</Icon>
            </Button>
        </div>
    );
};

export default FipsCodesSelectorHeader;
