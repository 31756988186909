import * as React from 'react';

const HintComapreChartIcon = ({ circleColor, backgroundColor }) => (
    <svg
        width={32}
        height={32}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <rect id="a" x={0} y={0} width={32} height={32} rx={16} />
        </defs>
        <g fill="none" fillRule="evenodd">
            <use fill="#D7E4EA" xlinkHref="#a" />
            <use fill={backgroundColor} xlinkHref="#a" />
            <path
                d="M29 3a3 3 0 0 1 .363 5.978l-7.009 13.606a2 2 0 0 1-3.544.022l-.068-.14-3.37-7.75-3.553 6.137a2 2 0 0 1-.721.725l-.153.08a2 2 0 0 1-2.592-.812l-.072-.139-2.864-6.046-3.049 5.335L1 22l-.5-1.5c-.078-.62-.051-1.085.08-1.392l.052-.104 3.049-5.335a2 2 0 0 1 3.462-.019l.081.155 2.865 6.046L14.635 12a1 1 0 0 1 1.725-.01l.057.112 4.16 9.567 6.778-13.159A3 3 0 0 1 29 3Z"
                fill="#718492"
            />
            <circle fill={circleColor} cx={15} cy={14} r={5} />
            <circle fill="#FFF" cx={15} cy={14} r={3} />
        </g>
    </svg>
);

export default HintComapreChartIcon;
