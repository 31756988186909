import { useCallback, useState } from 'react';

export function useToggleSet<T>(initialState: T[] | null) {
    const initialSet = new Set(initialState);
    const [toggleSet, setToggleSet] = useState(initialSet);

    const toggle = useCallback(
        (item: T) => {
            setToggleSet((previousSet) => {
                if (previousSet.has(item)) {
                    return new Set(
                        Array.from(previousSet).filter((i) => i !== item),
                    );
                } else {
                    return new Set([...Array.from(previousSet), item]);
                }
            });
        },
        [setToggleSet],
    );

    const has = useCallback((item: T) => toggleSet.has(item), [toggleSet]);

    const reset = useCallback(() => setToggleSet(initialSet), [
        setToggleSet,
        initialSet,
    ]);

    return { toggleSet, toggle, has, reset };
}
