import { DefaultRootState } from 'react-redux';
import * as ReportMetadata from './ReportMetadata';
import { DownloadExcelScreen } from '../constants/DownloadExcelScreen';
import { MAX_COLUMNS_ALLOWED_FOR_EXCEL_DOWNLOAD } from '../constants/Report';

const getIsExcelColumnsLimitExceeded = (state: DefaultRootState) => {
    const numberOfSelectedColumnsPerGeography = ReportMetadata.getNumberOfSelectedColumns(
        state,
    );

    return (
        numberOfSelectedColumnsPerGeography >
        MAX_COLUMNS_ALLOWED_FOR_EXCEL_DOWNLOAD
    );
};

export const getDownloadExcelWhatToRender = (state: DefaultRootState) => {
    const { error, loading } = state.reportStatus.downloadExcel;
    if (error) {
        return DownloadExcelScreen.DOWNLOAD_EXCEL_ERROR;
    } else if (
        loading ||
        !ReportMetadata.getIsDefinitionAndByOldReportIdLoaded(state) ||
        !ReportMetadata.getGeographies(state).length
    ) {
        return DownloadExcelScreen.DOWNLOAD_EXCEL_LOADING;
    } else if (getIsExcelColumnsLimitExceeded(state)) {
        return DownloadExcelScreen.DOWNLOAD_EXCEL_TOO_MANY_COLUMNS;
    }
    return DownloadExcelScreen.DOWNLOAD_EXCEL_AVAILABLE;
};

export const getDownloadExcelError = (state: DefaultRootState) =>
    state.reportStatus.downloadExcel.error;
