import * as Report from '../constants/Report';
import { ReportTotalsMetadata } from '../typescript/types';
import { ReportAppActions } from '../typescript/actions/actions';

const INITIAL_STATE: ReportTotalsMetadata = {};

export default (
    state = INITIAL_STATE,
    action: ReportAppActions,
): ReportTotalsMetadata => {
    switch (action.type) {
        case Report.SET_REPORT_TOTALS_DATA:
            const { oldReportId, totalsData } = action.payload;
            return {
                ...state,
                [oldReportId]: totalsData,
            };
        case Report.RESET_REPORT_TOTALS_DATA: {
            return {};
        }
        default:
            return state;
    }
};
