import * as ReportApp from '../typescript/types';
import { ALL_SLS_TOTAL_COLUMN_NAME } from '../constants/Report';
import { AggregationTypes } from '../typescript/enums';

export const MAX_GEOGRAPHIES_ALLOWED_FOR_DOWNLOAD = 3000;

const formatGeoType = (
    geoType: ReportApp.AspNetWebService.CWsGeoType,
): ReportApp.GeoType => {
    return {
        name: geoType.Name,
        label: geoType.Label,
        pluralName: geoType.PluralName,
        qLabel: geoType.QLabel,
        guid: geoType.GUID,
        sumLev: geoType.SumLev,
        surveyName: geoType.SurveyName,
        indent: geoType.Indent,
        majorGeo: geoType.MajorGeo,
    };
};

const formatGeoItem = (
    geoItem: ReportApp.AspNetWebService.CWsGeoItem,
): ReportApp.GeoItem => ({
    fips: geoItem.FIPS,
    label: geoItem.Label,
    qLabel: geoItem.QLabel,
    sumLev: geoItem.SumLev,
});

export const parseGeoTypesResponse = (
    response: ReportApp.AspNetWebService.GeoTypeResults,
) => {
    return response.d.map((geo) => formatGeoType(geo));
};

export const parseGeoItemsResponse = (
    response: ReportApp.AspNetWebService.GeoItemResults,
) => {
    return response.d.map((geo) => formatGeoItem(geo));
};

export const parseGeoItemsByFipsResponse = (
    response: ReportApp.AspNetWebService.FipsResults,
): [ReportApp.GeoItem[], string, string] | null[] => {
    const responseRoot = response.d;
    if (responseRoot.GeoSelection == null) {
        // if GeoSelection is null that means that geography level (SL)
        // doesn't exist for requested survey.
        return [null, null, null];
    }
    const geoSelection = responseRoot.GeoSelection.GeoItems.map((geo) =>
        formatGeoItem(geo),
    );
    const notMatched = responseRoot.NotMatched;
    const warningLabel = responseRoot.WarningLabel;
    return [geoSelection, notMatched, warningLabel];
};

/**
 * Convert response retrieved from BackendPaths.getGeoTypeSelections into array of
 * GeoTypeSelections
 */
export const parseGeoTypeSelections = (
    response: ReportApp.AspNetWebService.GeoTypeSelectionResults,
): ReportApp.GeoTypeSelection[] =>
    response.d.map((geoTypeSelection) => ({
        sumLev: geoTypeSelection.SumLev,
        label: geoTypeSelection.Label,
        geoNesting: geoTypeSelection.GeoNesting,
        selectionCount: geoTypeSelection.SelectionCount,
        pluralName: geoTypeSelection.PluralName,
    }));
/**
 * Convert response retrieved from BackendPaths.getReportInfo into array of
 * fips that can be used to create new report
 */
export const parseReportInfoInSelectedFips = (
    response: ReportApp.AspNetWebService.ReportSelectionResults,
) =>
    response.d.GeoSelections.flatMap((geoSelection) =>
        geoSelection.GeoItems.map(
            (geoItem) => `${geoItem.FIPS};${geoItem.SumLev};${geoItem.Label}`,
        ),
    );

export const getGeoFipsBySummaryLevel = (
    reportInfoByOldReportId: ReportApp.ReportInfoByOldReportId,
) => {
    const geosBySl: { [key: string]: Set<string> } = {};
    Object.values(reportInfoByOldReportId).reduce(
        (memo, reportInfo) =>
            reportInfo.geoFips.reduce((acc, geoFips) => {
                const { fips, sumLev } = getPartsFromGeoFips(geoFips);
                if (!acc[sumLev]) {
                    acc[sumLev] = new Set();
                }
                acc[sumLev].add(fips);
                return acc;
            }, memo),
        geosBySl,
    );
    return geosBySl;
};

export const prepareFipsCodes = (
    rawFipsCodesAndSumLevel: ReportApp.AspNetWebService.FipsCodeAndSumLev[],
    aggregationType: number,
): ReportApp.FipsCodeWithSummaryLevel[] =>
    rawFipsCodesAndSumLevel.flatMap(({ FipsCodes, SumLev }) =>
        FipsCodes.map((fips: string) =>
            aggregationType === AggregationTypes.ALL_SLS_TOTAL
                ? ALL_SLS_TOTAL_COLUMN_NAME
                : getFipsCodeForFipsAndSumLev(fips, SumLev),
        ),
    );

/**
 * Creates a string that can be sent as a report request. String is in format
 * `${fips};${sumLev};${label || qLabel}`
 */
export const getFipsCodeForSelection = (geoItem: ReportApp.GeoItem) => {
    const { fips, sumLev, label, qLabel } = geoItem;
    return [fips, sumLev, label || qLabel].join(';');
};

export const getFipsCodeForFipsAndSumLev = (
    fips: string,
    sumLevel: string,
): ReportApp.FipsCodeWithSummaryLevel => `${fips};${sumLevel}`;

/**
 * @param geoFips Fips code in format `${fips};${sumLev};${label}`
 */
export const getPartsFromGeoFips = (geoFips: string) => {
    const [fips, sumLev, label] = geoFips.split(';');
    return { fips, sumLev, label };
};

export const getTotalFipsCodeForSl = (sl: string) => `total-${sl}`;

export const isFipsTotal = (fips: string) => fips.startsWith('total-');
