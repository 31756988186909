import React from 'react';
import { FormattedMessage } from 'react-intl';

import './MultipleSelectionHint.scss';

const MultipleSelectionHint = ({ preventHintRender = false }) => {
    return (
        <div className="multiple-selection-hint">
            {!preventHintRender && (
                <FormattedMessage
                    id="selection.multipleSelectionHint"
                    values={{
                        b: (chunks: React.ReactNode) => (
                            <strong>{chunks}</strong>
                        ),
                    }}
                />
            )}
        </div>
    );
};

export default MultipleSelectionHint;
