import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';

import { LOAD } from '../../constants/Init';
import * as CurrentUserStatusConstants from '../../constants/CurrentUserStatus';
import {
    getCurrentUserStatus,
    getShouldInitializeLoad,
} from '../../selectors/Init';
import { getIsLoaded } from '../../selectors/Survey';
import routes from '../../helpers/ApplicationPaths';
import gtm from '../../helpers/GoogleTagManager';

import Loader from '../Loader/Loader';
import NewReport from '../Report/NewReport';
import ExistingReport from '../Report/ExistingReport';
import SelectTablesPage from '../Tables/SelectTablesPage';
import SelectSurveyPage from '../Surveys/SelectSurveyPage';
import SelectGeographiesPage from '../Geographies/SelectGeographiesPage';

import AppTheme from './AppTheme';
import './App.scss';

const App = () => {
    const dispatch = useDispatch();
    const shouldInitializeLoad = useSelector(getShouldInitializeLoad);
    const currentUserStatus = useSelector(getCurrentUserStatus);
    const areSurveysLoaded = useSelector(getIsLoaded);
    const location = useLocation();

    useEffect(() => {
        if (shouldInitializeLoad) {
            dispatch({ type: LOAD, payload: { pathname: location.pathname } });
        }
    }, [dispatch, location, shouldInitializeLoad]);

    useEffect(() => {
        // initialize GoogleTagManager (gtm)
        const gtmAppId = process.env.REACT_APP_GTM_ID;
        if (gtmAppId) {
            gtm.initialize(gtmAppId);
        }
    }, []);

    const app = (
        <Switch>
            <Route path={routes.surveys.path}>
                <SelectSurveyPage />
            </Route>
            <Route path={routes.geographies.path}>
                <SelectGeographiesPage />
            </Route>
            <Route path={routes.tables.path}>
                <SelectTablesPage />
            </Route>
            <Route path={routes.existingReport.path}>
                <ExistingReport />
            </Route>
            <Route path={routes.newReport.path}>
                <NewReport />
            </Route>
            <Route path={routes.root.path}>
                <SelectSurveyPage />
            </Route>
        </Switch>
    );

    let content;
    // Render loader until user info and surveys are loaded
    if (!areSurveysLoaded) {
        content = <Loader />;
    } else {
        switch (currentUserStatus) {
            case CurrentUserStatusConstants.CURRENT_USER_LOADING:
                content = <Loader />;
                break;
            case CurrentUserStatusConstants.CURRENT_USER_ERROR:
                content = <div>Error has happened</div>;
                break;
            case CurrentUserStatusConstants.CURRENT_USER_LOADED:
                content = app;
                break;
            default:
                break;
        }
    }

    return (
        <>
            <AppTheme />
            {content}
        </>
    );
};

export default App;
