import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import * as ReportMetadata from '../../../../selectors/ReportMetadata';

import ModifyOptionLink from './ModifyOptionLink';

const ModifyTables = () => {
    const surveyCodesBySurveyGroups = useSelector(
        ReportMetadata.getSurveyGroupsAndModifyTableLink,
    );
    const tablesForReport = useSelector(ReportMetadata.getTablesUsedInReport);
    const tableTitles = tablesForReport.map((table) => table.title).join('; ');
    const intl = useIntl();

    return (
        <>
            {surveyCodesBySurveyGroups.map(({ surveyGroup, link }) => (
                <ModifyOptionLink
                    key={surveyGroup.id}
                    link={link}
                    hint={intl.formatMessage({
                        id: 'report.sidebar.modify.hint.table',
                    })}
                    highlightIcon="format_list_bulleted"
                    title={intl.formatMessage({
                        id: 'report.sidebar.modify.titles.tables',
                    })}
                    description={tableTitles}
                />
            ))}
        </>
    );
};

export default ModifyTables;
